import { UserAvatar } from '../../../../../components/Sidebar/Sidebar';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../components/PhoneNumberDisplay';
import { Listbox, SquareProgress } from '../../../../../design-lib';
import React from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath } from '../../../../../utilities';
import { usePipesQuery } from '../../../../../sdk';
import useEnsureModuleItemId from '../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { MenuButton } from '../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { useListUsersQuery } from '../../../../../hooks/queries/user';
import { useListDevicesQuery } from '../../../../../hooks/queries/device';
import UseGroupsListQuery from '../../../../../hooks/queries/group/useGroupsListQuery';
import { NextModuleButton } from './NextModuleButton';

export const RingGroupModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const onSelect = (id: string) => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }

    setAtPath(callflow, `${modifyPath}.data.data.id`, id);
    setCallflow({ ...callflow });
  };

  const { data: groupsResp, isLoading: groupsIsLoading } = UseGroupsListQuery({
    // quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { groups = [] } = groupsResp ?? {};
  const group = groups.find(group => group.id === moduleItem?.data?.data?.id);

  // console.log('user resp', usersResp);

  // console.log('ring user:', user);

  if (groupsIsLoading)
    return (
      <div className={`w-full h-full grid place-items-center`}>
        <div className={`text-center`}>
          <SquareProgress />
          <span>Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <Listbox
        label={'Select Group'}
        emptyLabel={'No Groups'}
        // size={'md'}
        onChange={onSelect}
        placeholder="Select a Group"
        className={`w-full mt-1 self-center`}
        // onOpenChanged={setDisableScroll}
        options={groups.map(({ id, doc: { name } }) => ({
          value: id,
          label: name!,
        }))}
        value={moduleItem?.data.data.id}
      />
      <MenuButton
        type={'ring'}
        current={moduleItem?.data?.ring_type}
        data={selectedElement.data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          'replace',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <NextModuleButton />
    </>
  );
};
