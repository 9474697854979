import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Button, IconButton } from 'app/design-lib';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';
import { setAtPath, useToggleReducer } from '../../../../../utilities';
import { useBuilderContext } from 'app/components/IvrBuilder/IvrBuilder';
// import useEnsureModuleItemId from '../common/useEnsureModuleItemId';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';
import { Box } from '../common/Box';
// import { ModifyDialog } from './ModifyDialog';
import { MenuButton } from '../common/MenuButton';
import { EditPencil, SkipNext } from 'iconoir-react';
import { GoToInFlowDialog } from './GoToInFlowDialog';
import { Tooltip } from '@mui/material';
import useEnsureModuleItemId from '../common/useEnsureModuleItemId';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditCallRouting/MobileView/MiniMapNode';

const getItems = callflow => {
  // navigate through each callflow strategy and return items
  let items = [
    // {
    //   label: '', // name of module
    //   id: '', // id of module
    //   module: obj // actual moduleItem
    // }
  ];
};

// why use memo??
const GoToInFlowNode = memo(({ data, ...rest }) => {
  const {
    rootData,
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  const [showModifyDialog, toggleModifyDialog] = useToggleReducer(false);
  // const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);
  const [tempId, setTempId] = useState(undefined);
  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const { setDisableScroll, miniMap, setOpenMiniMap } = useBuilderContext();

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    // if (data.optsPath === modifyPath) {
    //   setShowModifyDialog(true);
    // }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeText = tmpText => {
    // remove whitespace around text to autorespond with
    // - todo: optional?
    let text = tmpText?.trim();
    if (!text?.length) {
      return false;
    }
    setAtPath(callflow, `${modifyPath}.data.text`, text);
    setCallflow({ ...callflow });
    setTempId(null);
    toggleModifyDialog();
    // setShowModifyDialogWrap(null);
  };

  const { invalidModules, setInvalidModules } = useBuilderContext();

  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  // add id if does not exist
  useEnsureModuleItemId(data);

  const text = '';

  // console.log(
  //   'rootData.elements',
  //   rootData.elements.filter(node => node.data?.moduleItem?.id),
  // );

  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const handleStartSelecting = () => {
    setSharedFlow(s => ({
      ...s,
      state: 'select-module',
      data: {
        currentId: moduleItem?.data?.id,
        onSelect: id => {
          handleUpdate({
            ...(moduleItem ?? {}),
            data: {
              ...(moduleItem?.data ?? {}),
              id,
              // timeout: timeoutDefault,
            },
          });
        },
      },
    }));
  };

  // add id if does not exist
  useEnsureModuleItemId(data);

  const currentModuleLink = rootData.elements.find(
    element => element.data?.moduleItem?.id === moduleItem.data?.id,
  );

  if (miniMap) {
    return <MiniMapNode pref={boxRef} data={data} />;
  }

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <GoToInFlowDialog
        text={text}
        onComplete={handleChangeText}
        open={showModifyDialog}
        onClose={toggleModifyDialog}
      />
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <div className={`flex flex-col items-center w-full pt-4 px-2 pb-2`}>
        <div className={`flex space-x-2 text-neutral-60`}>
          <div
            className={`text-sm flex items-center space-x-2 whitespace-nowrap`}
          >
            <SkipNext width={14} height={14} />
            <span>Jump to Module</span>
          </div>
        </div>
        <div
          className={`text-md  pl-2 w-full flex flex-col text-center justify-center font-medium`}
        >
          {text?.length ? (
            <div
              className={`flex space-x-0 w-full justify-center items-center`}
            >
              <Tooltip placement={'left'} arrow title={text}>
                <p className={`line-clamp-2 text-left`}>{text}</p>
              </Tooltip>
              <IconButton
                onClick={toggleModifyDialog}
                pill
                color={'neutral'}
                variant={'ghost'}
              >
                <EditPencil />
              </IconButton>
            </div>
          ) : (
            <>
              {currentModuleLink ? (
                <div>
                  {currentModuleLink.data?.moduleItem?.name ??
                    currentModuleLink.data?.moduleItem?.type}
                </div>
              ) : null}
              <Button
                color={'attention'}
                variant={'ghost'}
                size={'sm'}
                className={`self-center`}
                // startIcon={<WarningTriangle />}
                onClick={handleStartSelecting}
                disabled={skipEditing}
              >
                {currentModuleLink &&
                (!!currentModuleLink.data?.moduleItem?.name ||
                  !!currentModuleLink.data?.moduleItem?.type)
                  ? 'Change'
                  : 'Jump to...'}
              </Button>
            </>
            // <Listbox
            //   // disabled={skipEditing}
            //   size={'sm'}
            //   placeholder="Select Module"
            //   className={`w-full mt-1 self-center`}
            //   onOpenChanged={setDisableScroll}
            //   options={rootData.elements
            //     // only "nodes" have a ".data" value
            //     .filter(node => node.data?.moduleItem?.id)
            //     // .filter(pipe => pipe.type === 'external')
            //     .map(node => {
            //       // TODO: do different depending on node info (in node.data)
            //       return {
            //         label: (
            //           <div className={'flex space-x-2  min-w-0 items-center'}>
            //             <div className="text-left py-2">{node.type}</div>
            //           </div>
            //         ),
            //         value: node.data?.moduleItem?.id, // node.data.id (this MUST exist?)
            //       };
            //     })}
            //   value={moduleItem?.data?.id}
            //   onChange={id => {
            //     handleUpdate({
            //       ...(moduleItem ?? {}),
            //       data: {
            //         ...(moduleItem?.data ?? {}),
            //         id,
            //         // timeout: timeoutDefault,
            //       },
            //     });
            //   }}
            // />
          )}
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );
});

export default GoToInFlowNode;
