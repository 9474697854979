import { Alert, Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';
import {
  Plus as AddIcon,
  CheckCircle as CheckCircleIcon,
  DeleteCircle as DeleteCircleIcon,
} from 'iconoir-react';
import React from 'react';
import { Button } from 'app/design-lib';
import { Link as RouterLink } from 'react-router-dom';

import { useAccountStripeCustomerMutate } from 'app/hooks/mutations/account';
import { useTioMutation, useTioQuery } from 'app/sdk';
import { AddIntegrationButton } from 'app/components/AddIntegrationButton';
import { useCustomBackdrop } from 'app/components/CustomBackdrop';
import { TrunkingioTopupDialog } from 'app/components/TrunkingioTopupDialog';
import { TrunkingioAddToBalanceDialog } from 'app/components/TrunkingioAddToBalanceDialog';
import currency from 'currency.js';
import moment from 'moment';
import useDevMode from 'app/utilities/useDevMode';
import { useToggleReducer } from 'app/utilities';

import { sortBy, groupBy, keys, sum } from 'lodash';

function Integrations({ account, customer, refetch }) {
  // const {
  //   toggleOpen: toggleOpenIntegrationAddDialog,
  //   Dialog: IntegrationAddDialog,
  //   DialogProps: IntegrationAddDialogProps,
  // } = useIntegrationAddDialog({ initialOpen: false });

  return (
    <SettingsPanel
      leftChild={
        <Setting
          label={'Integrations'}
          body={'Active integrations for account.'}
        />
      }
      rightChild={
        <div className="space-y-2">
          <TrunkingIOIntegrationTemporary />
          {/* <TrunkingIOIntegration
            account={account}
            customer={customer}
            refetch={refetch}
          /> */}
          {/* <AiIntegration
            account={account}
            customer={customer}
            refetch={refetch}
          /> */}
        </div>
      }
    />
  );

  // return (
  //   <>
  //     <div>
  //       <span className={'text-lg font-bold'}>Integrations</span>
  //
  //       <Divider />
  //       <Grid
  //         container
  //         columnSpacing={1}
  //         alignItems="center"
  //         sx={{ padding: 1 }}
  //       >
  //         <Grid item md={3}>
  //           <span>TrunkingIO</span>
  //         </Grid>
  //         <Grid item md={9}>
  //           <span style={{ fontStyle: 'italic' }}>
  //             Billing via TrunkingIO is not yet available.
  //           </span>
  //         </Grid>
  //         {/* {account.doc.integrations?.trunkingio_account_id ? (
  //           <>
  //             <Grid item>
  //               <Typography variant="body2">Basic Plan</Typography>
  //             </Grid>
  //             <Grid item>
  //               <Button
  //                 color="info"
  //                 // variant="contained"
  //                 // onClick={toggleOpenIntegrationAddDialog}
  //               >
  //                 View Account
  //               </Button>
  //             </Grid>
  //           </>
  //         ) : (
  //           <>
  //             <Grid item>
  //               <AddIntegrationButton onComplete={handleOnComplete} />
  //             </Grid>
  //           </>
  //         )} */}
  //       </Grid>
  //       <Divider />
  //     </div>
  //   </>
  // );
}

const TrunkingIOIntegrationTemporary = () => {
  const { devMode, toggleDevMode } = useDevMode();

  return (
    <div
      className={`bg-neutral-10/10 opacity-70 border border-neutral-20 overflow-x-auto rounded-lg w-full p-4 text-md font-normal whitespace-normal`}
    >
      <div className="">
        <div className="flex">
          <div className="w-1/2">
            <div className="text-lg">Trunking.io</div>
            <div className="text-sm text-neutral-50">
              Provider of Phone Numbers, Minutes, and SMS/MMS capabilities.
            </div>
          </div>
          <div className="w-1/2">
            <Button
              color={'accent'}
              variant={'outline'}
              // onClick={handleAddIntegration}
              className="ml-auto"
              disabled={true}
            >
              Coming Soon!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TrunkingIOIntegration = ({ account, customer, refetch }) => {
  const { devMode, toggleDevMode } = useDevMode();

  const accountBillingMutate = useAccountStripeCustomerMutate();
  const createSharedCustomerMutate = useAccountStripeCustomerMutate();

  const tmp_trunkingio_action = useTioMutation();

  const create_payment = useTioMutation();
  const update_topup_config = useTioMutation();

  const [openAddToBalance, toggleOpenAddToBalance] = useToggleReducer(false);
  const [openAutoRecharge, toggleOpenAutoRecharge] = useToggleReducer(false);

  const latest_ledger = useTioQuery(
    `/partner_ledgers?page_size=1&order_by=id,desc`,
    account.internal?.integrations?.trunkingio?.account_id ? true : false,
  );
  // console.log('latest_ledger (balance):', latest_ledger.data?.data?.[0]?.balance , latest_ledger.data)

  const messaging_setup_ledger = useTioQuery(
    `/messaging/payment_setup`,
    account.internal?.integrations?.trunkingio?.account_id ? true : false,
  );

  const ledgers = useTioQuery(
    `/partner_ledgers?page_size=100&order_by=id,desc`,
    account.internal?.integrations?.trunkingio?.account_id ? true : false,
  );
  // console.log('ledgers:', ledgers)

  const topup_config = useTioQuery(
    `/payments/topup_config`,
    account.internal?.integrations?.trunkingio?.account_id ? true : false,
  );
  // console.log('topup_config', topup_config.data)

  const handleOnComplete = () => {
    refetch();
    // toggleOpenIntegrationAddDialog();
  };

  const handleTmpSummarizeUsage = (category, allow_duplicate) => async () => {
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/payments/summarize_usage',
      data: {
        category,
        allow_duplicate,
      },
    });
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleTmpCheckTopup = async () => {
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/payments/check_topup',
      data: {},
    });
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleTmpJobsDailySummarize = async () => {
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/payments/summarize_daily',
      data: {},
    });
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleTmpOneTimeCharge = async () => {
    let amount: any = prompt('Amount to charge immediately', '7.77');
    if (!amount) {
      return false;
    }
    amount = Number(amount);
    if (!amount || amount < 0) {
      return false;
    }
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/payments/one_time',
      data: {
        amount,
        customer_desc: 'customer description here',
        details: {
          internal_test: true,
        },
      },
    });
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleTmpNonBalanceCharge = async () => {
    let amount: any = prompt(
      'Amount to charge immediately to card (but NOT add to balance)',
      '8',
    );
    if (!amount) {
      return false;
    }
    amount = Number(amount);
    if (!amount || amount < 0) {
      return false;
    }
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/payments/nonbalancecharge',
      data: {
        amount,
        customer_desc: 'nonbalance customer description here',
        details: {
          internal_test: true,
        },
      },
    });
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleCreatePayment = async () => {
    let amount: any = prompt('Amount to charge immediately', '10');
    if (!amount) {
      return false;
    }
    amount = Number(amount);
    if (!amount || amount < 0) {
      return false;
    }
    console.log('amount:', amount);

    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/payments/partner',
      data: {
        amount,
      },
    });
    console.log('payment result:', result);
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleCreateMessagingPayment = async () => {
    // @ts-ignore
    const result = await create_payment.mutateAsync({
      method: 'POST',
      url: '/messaging/payment_setup',
      data: {},
    });
    console.log('payment result:', result);
    messaging_setup_ledger.refetch();
    latest_ledger.refetch();
    ledgers.refetch();
  };

  const handleCreateSharedCustomer = async () => {
    try {
      const result = await createSharedCustomerMutate.mutateAsync({
        data: {
          action: 'createSharedCustomer',
          data: {},
        },
      });
      console.log('handleCreateSharedCustomer Result:', result);
      alert('Done');
    } catch (e: any) {
      // setError('ptn', {
      //   type: 'error',
      //   message: 'Error creating trunkingio account',
      // });
      console.error('failed handleCreateSharedCustomer', e);
      alert('Failed');
      // setSearching(false);
      return;
    }
    await refetch();
  };

  const handleAddIntegration = async () => {
    try {
      const result = await accountBillingMutate.mutateAsync({
        data: {
          action: 'addIntegration',
          data: {
            // name: ccForm.name,
            // source: token?.id,
            // // @ts-ignore
            // setDefault: formDefaults?.setDefault ? true : false,
          },
        },
      });
      console.log('handleAddIntegration Result:', result);
      alert('Done');
    } catch (e: any) {
      // setError('ptn', {
      //   type: 'error',
      //   message: 'Error creating trunkingio account',
      // });
      console.error('failed creating trunkingio account', e);
      alert('Failed');
      // setSearching(false);
      return;
    }
    await refetch();
  };

  const handleUpdateTopupConfig = field => async () => {
    // could be updating any part!
    // - including "enabled" and whatnot
    let amount: any = prompt(field, topup_config.data?.[field]);
    if (!amount) {
      return false;
    }
    amount = Number(amount);
    if (!amount || amount < 0) {
      return false;
    }
    console.log('amount:', amount);

    // @ts-ignore
    await update_topup_config.mutateAsync({
      method: 'PATCH',
      url: '/payments/topup_config',
      data: {
        // @ts-ignore
        [field]: amount,
        // status: 'enabled', // disabled, failed
        // low_balance_threshold,
        // topup_amount
      },
    });
    topup_config.refetch();
  };

  const handleToggleTopupConfig = async () => {
    // disable if enabled
    // - otherwise, enable (if disabled, failed, etc)

    // @ts-ignore
    await update_topup_config.mutateAsync({
      method: 'PATCH',
      url: '/payments/topup_config',
      data: {
        // @ts-ignore
        status:
          topup_config.data?.data?.status === 'enabled'
            ? 'disabled'
            : 'enabled', // disabled, failed
        // low_balance_threshold,
        // topup_amount
      },
    });
    topup_config.refetch();
  };

  console.log('Account:', account);

  const monthly_ledgers = groupIntoMonthsArr(ledgers.data?.data ?? []);
  console.log('monthly_ledgers:', monthly_ledgers);

  return (
    <div
      className={`border border-neutral-20 overflow-x-auto rounded-lg w-full p-4 text-md font-normal whitespace-normal`}
    >
      <div className="">
        <div className="flex">
          <div className="w-1/2">
            <div className="text-lg">Trunking.io</div>
            <div className="text-sm text-neutral-50">
              Provider of Phone Numbers, Minutes, and SMS/MMS capabilities.
            </div>
          </div>
          <div className="w-1/2">
            {account.internal?.integrations?.trunkingio?.account_id ? (
              <>
                <div className="flex justify-end items-center space-x-2">
                  <div className="">
                    <span className="">Balance:</span>
                  </div>
                  <div className="">
                    <span
                      className={` text-2xl ${
                        latest_ledger.isLoading
                          ? 'text-neutral-60'
                          : latest_ledger.data?.data?.[0]?.balance > 0
                          ? 'text-positive-60'
                          : 'text-negative-60'
                      }`}
                    >
                      {latest_ledger.isFetching
                        ? '--.--'
                        : currency(
                            latest_ledger.data?.data?.[0]?.balance,
                          ).format()}
                    </span>
                  </div>
                </div>
                <div className="text-right">
                  <TrunkingioAddToBalanceDialog
                    account={account}
                    open={openAddToBalance}
                    onClose={() => {
                      latest_ledger.refetch();
                      ledgers.refetch();
                      toggleOpenAddToBalance();
                    }}
                  />
                  <Button
                    color={'accent'}
                    variant={'outline'}
                    onClick={toggleOpenAddToBalance}
                    className="inline-flex"
                    // disabled={create_payment.isLoading}
                  >
                    Add to Balance
                  </Button>
                </div>

                <div className="flex items-center justify-end mt-4">
                  <div className="text-right">
                    {topup_config.data?.data?.status === 'enabled' ? (
                      <div>
                        <CheckCircleIcon
                          fr={undefined}
                          height={16}
                          width={16}
                          className="text-positive-60 inline align-top"
                        />
                        &nbsp; Auto-recharge is Enabled
                      </div>
                    ) : (
                      <div>
                        <DeleteCircleIcon
                          fr={undefined}
                          height={16}
                          width={16}
                          className="inline align-top"
                        />
                        &nbsp; Auto-recharge is Disabled
                      </div>
                    )}
                  </div>
                  {/* <div className="mx-2 text-left">
                        <span
                          className={` ${
                            topup_config.data?.data?.status === 'enabled'
                              ? 'text-positive-60'
                              : 'text-neutral-60'
                          }`}
                        >
                          {topup_config.data?.data?.status === 'enabled'
                            ? 'Enabled'
                            : 'Disabled'}
                        </span>
                        <br />
                        <span className="">
                          {currency(
                            topup_config.data?.data?.low_balance_threshold,
                          ).format(formatCurrencyPretty)}
                        </span>{' '}
                        to{' '}
                        <span className="">
                          {currency(
                            topup_config.data?.data?.topup_amount,
                          ).format(formatCurrencyPretty)}
                        </span>
                      </div> */}
                </div>
                <div className="text-right">
                  <TrunkingioTopupDialog
                    account={account}
                    open={openAutoRecharge}
                    onClose={() => {
                      topup_config.refetch();
                      toggleOpenAutoRecharge();
                    }}
                  />
                  <Button
                    color={'accent'}
                    variant={'outline'}
                    onClick={toggleOpenAutoRecharge}
                    className="inline-flex"
                    // disabled={create_payment.isLoading}
                  >
                    Configure
                  </Button>
                </div>

                {/* <br />
                    low_balance_threshold:{' '}
                    {topup_config.data?.data?.low_balance_threshold}
                    <br />
                    topup_amount: {topup_config.data?.data?.topup_amount}
                    <br />
                    <span className="text-sm text-neutral-400 whitespace-normal">
                      When your balance falls below $low_balance_threshold,
                      we will charge your card on file $topup_amout.
                      <br />
                      If the top-up is not enough to bring your balance
                      above the low_balance_threshold, instead we will
                      charge your card once to bring it to
                      $low_balance_threshold + $topup_amount.
                      <br />
                      For example: when $balance = 0, $low_balance_threshold
                      = 10, $topup_amount = $5...we will charge $15
                    </span>
                    <br />
                    <Button
                      color={'accent'}
                      variant={'outline'}
                      onClick={handleUpdateTopupConfig(
                        'low_balance_threshold',
                      )}
                      // className="inline-flex"
                      disabled={update_topup_config.isLoading}
                    >
                      Change Topup low_balance_threshold
                    </Button>
                    <br />
                    <Button
                      color={'accent'}
                      variant={'outline'}
                      onClick={handleUpdateTopupConfig('topup_amount')}
                      // className="inline-flex"
                      disabled={update_topup_config.isLoading}
                    >
                      Change Topup amount
                    </Button>
                    <br />
                    <Button
                      color={'accent'}
                      variant={'outline'}
                      onClick={handleToggleTopupConfig}
                      // className="inline-flex"
                      disabled={update_topup_config.isLoading}
                    >
                      {topup_config?.data?.data?.status === 'enabled'
                        ? 'Disable'
                        : 'Enable'}{' '}
                      Auto-Topup
                    </Button> */}
              </>
            ) : (
              <Button
                color={'accent'}
                variant={'outline'}
                onClick={handleAddIntegration}
                // className="inline-flex"
                disabled={accountBillingMutate.isLoading}
              >
                Add Integration
              </Button>
            )}
          </div>
        </div>
      </div>

      {devMode && false ? (
        <div className="w-1/2">
          <Button
            color={'accent'}
            variant={'outline'}
            onClick={() => {
              topup_config.refetch();
              ledgers.refetch();
              latest_ledger.refetch();
              messaging_setup_ledger.refetch();
              refetch();
            }}
          >
            REFRESH
          </Button>
          <br />
          {account.internal?.integrations?.trunkingio?.account_id ? (
            <>
              {!account.internal?.integrations?.trunkingio
                ?.stripe_customer_id ? (
                <>
                  <br />
                  <Button
                    color={'neutral'}
                    variant={'outline'}
                    onClick={handleCreateSharedCustomer}
                    // className="inline-flex"
                    disabled={createSharedCustomerMutate.isLoading}
                  >
                    Create Shared Customer
                  </Button>
                  <br />
                </>
              ) : null}
              Messaging:
              <br />
              <Button
                color={'neutral'}
                variant={'outline'}
                onClick={handleCreateMessagingPayment}
                // className="inline-flex"
                disabled={create_payment.isLoading}
              >
                Setup Messaging Payment (
                {messaging_setup_ledger.isLoading
                  ? 'loading'
                  : messaging_setup_ledger.data?.id
                  ? 'found'
                  : 'MISSING'}
                )
              </Button>
              <br />
              <br />
              Balance:
              <br />
              <div className="flex flex-col space-y-1">
                <Button
                  color={'neutral'}
                  variant={'outline'}
                  onClick={handleTmpSummarizeUsage('incremental', false)}
                  // className="inline-flex"
                  disabled={tmp_trunkingio_action.isLoading}
                >
                  TMP: Summarize Usage (incremental)
                </Button>
                <Button
                  color={'neutral'}
                  variant={'outline'}
                  onClick={handleTmpSummarizeUsage('daily', false)}
                  // className="inline-flex"
                  disabled={tmp_trunkingio_action.isLoading}
                >
                  TMP: Summarize Usage (daily)
                </Button>
                <Button
                  color={'neutral'}
                  variant={'outline'}
                  onClick={handleTmpSummarizeUsage('daily', true)}
                  // className="inline-flex"
                  disabled={tmp_trunkingio_action.isLoading}
                >
                  TMP: Summarize Usage (daily, allow duplicate)
                </Button>
              </div>
              <br />
              <Button
                color={'neutral'}
                variant={'outline'}
                onClick={handleTmpCheckTopup}
                // className="inline-flex"
                disabled={tmp_trunkingio_action.isLoading}
              >
                TMP: Check Topup
              </Button>
              <br />
              <Button
                color={'neutral'}
                variant={'outline'}
                onClick={handleTmpOneTimeCharge}
                // className="inline-flex"
                disabled={tmp_trunkingio_action.isLoading}
              >
                TMP: One-time charge (added to balance)
              </Button>
              <br />
              <Button
                color={'neutral'}
                variant={'outline'}
                onClick={handleTmpNonBalanceCharge}
                // className="inline-flex"
                disabled={tmp_trunkingio_action.isLoading}
              >
                TMP: NonBalance Charge
              </Button>
              <br />
              <Button
                color={'neutral'}
                variant={'outline'}
                onClick={handleTmpJobsDailySummarize}
                // className="inline-flex"
                disabled={tmp_trunkingio_action.isLoading}
              >
                TMP: create jobs (summarize daily - today)
              </Button>
            </>
          ) : null}
        </div>
      ) : null}

      {account.internal?.integrations?.trunkingio?.account_id ? (
        <div className="mt-4">
          <div className="text-neutral-50 mb-2">Monthly Summaries:</div>

          <div className="border border-neutral-20 rounded-lg w-full">
            <table className="table-auto w-full rounded-lg border-collapse">
              <thead className="border-b border-neutral-10">
                <th></th>
                <th className="text-left text-sm text-neutral-50 font-normal">
                  Charges
                </th>
                <th className="text-left text-sm text-neutral-50 font-normal">
                  Payments
                </th>
              </thead>
              <tbody>
                {monthly_ledgers.map(month => (
                  <MonthlySummary key={month.date} month={month} />
                ))}
              </tbody>
            </table>
          </div>
          {/* <br />
              All Ledger Entries:
              <br />
              <div className="space-y-2 whitespace-normal">
                {ledgers.data?.data?.map(ledger => (
                  <div key={ledger.id}>
                    <span className="font-bold">
                      {ledger.amount}
                    </span>{' '}
                    #{ledger.uuid} {ledger.created_at}
                    <br />
                    <span className="text-neutral-600 text-sm">
                      {ledger.customer_desc}
                    </span>
                  </div>
                ))}
              </div> */}
        </div>
      ) : null}
    </div>
  );
};

const AiIntegration = ({ account, customer, refetch }) => {
  const { devMode, toggleDevMode } = useDevMode();

  return (
    <div
      className={`bg-neutral-10/30 opacity-70 border border-neutral-20 overflow-x-auto rounded-lg w-full p-4 text-md font-normal whitespace-normal`}
    >
      <div className="">
        <div className="flex">
          <div className="w-1/2">
            <div className="text-lg">Calling.ai</div>
            <div className="text-sm text-neutral-50">
              AI capabilities for calls and texts
            </div>
          </div>
          <div className="w-1/2">
            <Button
              color={'accent'}
              variant={'outline'}
              // onClick={handleAddIntegration}
              // className="inline-flex"
              disabled={true}
            >
              Add Integration
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const groupIntoMonthsArr = ledger_entries => {
  // helper function to get the month name from an item
  const grouping = item => moment(item.created_at).format('YYYY-MM');
  const result = groupBy(ledger_entries, grouping);
  const output: any = [];
  for (const [date, entries] of Object.entries(result)) {
    output.push({
      date,
      entries,
    });
  }
  return output;
};

const MonthlySummary = ({ month }) => {
  // summarize:
  // - usage costs
  // - payments they made (topup, manual)

  const debits = sum(
    month.entries
      .filter(e => parseFloat(e.amount) < 0)
      .filter(
        e =>
          !['neutral_charge_credit', 'neutral_charge_debit'].includes(
            e.category,
          ),
      )
      .map(v => parseFloat(v.amount)),
  );
  const credits = sum(
    month.entries
      .filter(e => parseFloat(e.amount) > 0)
      .filter(
        e =>
          !['neutral_charge_credit', 'neutral_charge_debit'].includes(
            e.category,
          ),
      )
      .map(v => parseFloat(v.amount)),
  );

  const usage = sum(
    month.entries
      .filter(e => ['usage_incremental', 'usage_daily'].includes(e.category))
      .map(v => parseFloat(v.amount)),
  );
  const onetimeDebits = sum(
    month.entries
      .filter(e => ['onetime'].includes(e.category))
      .filter(e => parseFloat(e.amount) < 0)
      .map(v => parseFloat(v.amount)),
  );
  const onetimeCredits = sum(
    month.entries
      .filter(e => ['onetime'].includes(e.category))
      .filter(e => parseFloat(e.amount) > 0)
      .map(v => parseFloat(v.amount)),
  );
  // console.log(
  //   'usage:',
  //   month.date,
  //   month.entries.filter(e =>
  //     ['usage_incremental', 'usage_daily'].includes(e.category),
  //   ),
  // );
  const charges = sum(
    month.entries
      .filter(e => ['topup'].includes(e.category))
      .map(v => parseFloat(v.amount)),
  );
  const nonbalanceCharges = sum(
    month.entries
      .filter(e => ['neutral_charge_credit'].includes(e.category))
      .map(v => parseFloat(v.amount)),
  );
  // console.log(
  //   'charges:',
  //   month.date,
  //   month.entries.filter(e => ['topup'].includes(e.category)),
  // );

  return (
    <tr className="text-md border-b first:border-t-0 last:border-b-0 border-neutral-20 w-full whitespace-nowrap">
      <td className="py-2 px-4 align-top">
        {moment(month.date, 'YYYY-MM').format('MMMM YYYY')}
      </td>

      <td className="text-red-600 py-2 align-top">
        {/* {currency(debits * -1).format()} debited (bal-)
          <div>{currency(usage * -1).format()} usage</div>
          {onetimeDebits ? (
            <div>{currency(onetimeDebits * -1).format()} one-time</div>
          ) : null} */}
        {currency(debits * -1).format()}
      </td>
      <td className="py-2 align-top">
        <div>{currency(charges).format()}</div>
        {/* {currency(credits).format()} credited (bal+)
          <div>{currency(charges).format()} topup charges</div> */}
        {onetimeCredits ? (
          <div className="text-neural-70">
            +{currency(onetimeCredits).format()} credits
          </div>
        ) : null}
        {nonbalanceCharges ? (
          <div>{currency(nonbalanceCharges).format()} non-balance charges</div>
        ) : null}
      </td>
    </tr>
  );
};

function formatCurrencyPretty(currency, options) {
  // call original format function to get its string value
  let format = options.format(currency, options);

  if (currency.cents() === 0) {
    // trim decimal and any trailing zeros
    return format.slice(0, format.length - (options.precision + 1));
  }

  return format;
}

export default Integrations;
