import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Divider, Grid, InputTextField, Link, Typography } from 'app/design';

import { useCallflowQuery } from 'app/hooks/queries/callflow';

import { CalendarMonth as CalendarMonthIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
import { ModifyDialog } from './ModifyDialog';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';
import { GreetingDialog } from '../Menu/GreetingDialog';
import { setAtPath } from '../../../../../utilities';
import { Clock, Dialpad, NavArrowRight, WarningTriangle } from 'iconoir-react';
import { AudioPlayer } from '../../../../AudioPlayer';
import { useAccountQuery } from '../../../../../hooks/queries/account';
import { TIMEZONE_LIST } from '../../../../TimezoneDialogAccount/TimezoneDialogAccount';
import { ButtonDropdown, IconButton, Listbox } from 'app/design-lib';
import { useBuilderContext } from '../../../MessageRoutingVisualBuilder';
import useEnsureModuleItemId from '../../../../IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { ModeSelectModuleThis } from '../common/ModeSelectModule';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditMessageRouting/MobileView/MiniMapNode';
// why use memo??
const MatchTextNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;
  const { data: account } = useAccountQuery();

  // // Load schedule via query
  // const {
  //   data: schedule,
  //   isLoading,
  //   isFetched,
  // } = useCallflowQuery({ id: moduleItem?.data?.time?.callflow?.id });

  // add id if does not exist
  useEnsureModuleItemId(data);

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);
  const { setDisableScroll, miniMap } = useBuilderContext();
  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [];

  const createDefaults = () => {
    console.log('creating MatchText default');
    // const schedules = {
    //   [id]: {
    //     categories: [
    //       {
    //         id: (Date.now() + 100).toString(),
    //         name: 'Holidays',
    //         values: [],
    //       },
    //       {
    //         id: (Date.now() + 101).toString(),
    //         name: 'Open',
    //         values: [],
    //       },
    //     ],
    //     fallback: {
    //       name: 'Closed',
    //     },
    //     timezone: account.doc.timezone, // account default
    //     name: 'Schedule', // not used, unless multiple schedules on a callflow
    //   },
    //   ...JSON.parse(JSON.stringify(callflow?.schedules ?? {})),
    // };
    // setAtPath(callflow, `.schedules`, schedules);
    // setAtPath(callflow, `${modifyPath}.data.schedule_id`, id);
    // console.log('schedules1:', { schedules, callflow, modifyPath });
    // setCallflow({ ...callflow });
  };

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    console.log('Created:', data.optsPath, modifyPath);
    if (data.optsPath === modifyPath) {
      createDefaults();
      // setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (miniMap) {
    return <MiniMapNode pref={boxRef} data={data} />;
  }

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <ModeSelectModuleThis id={moduleItem?.id} modifyPath={modifyPath} />

      <div className={`flex flex-col items-center w-full pt-4 px-2 pb-2`}>
        <div className={`flex space-x-2 text-neutral-60`}>
          <div
            className={`text-sm flex items-center space-x-2 whitespace-nowrap`}
          >
            <Clock width={14} height={14} />
            <span>Text Match</span>
          </div>
        </div>
        <span className={`text-sm text-neutral-500`}>
          Matches <span className={`italic`}>only one (1)</span>, <br />
          from left-to-right:
        </span>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );
});

export default MatchTextNode;
