import { Alert, Button, IconButton } from '../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../utilities';
import useEnsureModuleItemId from '../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';

import { useUpdateVmboxPartial } from '../../../../../hooks/mutations/vmbox';
import { AudioPlayer } from '../../../../../components/AudioPlayer';
import {
  Cancel,
  DeleteCircle,
  EditPencil,
  Plus,
  Trash,
  WarningTriangle,
} from 'iconoir-react';
import { MenuButton } from '../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import AudioEditor from '../../../../../components/AudioEditor/AudioEditor';
import { useBuilderContext } from '../../../../../components/IvrBuilder/IvrBuilder';
import { INLINE_BLANK_CALLFLOW } from '../../../../../components/IvrBuilder/Flow/nodes/InsertMenuOption/InsertMenuOption';
import { PreviousModuleButton } from './PreviousModuleButton';

export const MenuModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const updateVmbox = useUpdateVmboxPartial();
  const [edit, toggleEdit] = useToggleReducer();

  const boxRef = useRef(null);

  const insertMenuOptionElement = elements.find(
    elem =>
      elem.type === 'InsertMenuOptionNode' &&
      elem.data.menuData.moduleItem.id === moduleItem.id,
  );

  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  const greeting_id = moduleItem?.data?.greeting_id;

  const targets = moduleItem.data.targets;

  const handleEdit = target => {
    const path =
      modifyPath + `.data.targets.${target}.callflow.strategy.data.module`;
    const test = getAtPath(callflow, path);

    setSelectedElementKey(
      modifyPath + `.data.targets.${target}.callflow.strategy.data.modules`,
    );
  };

  const handleAddOption = targetKey => {
    const menuData = insertMenuOptionElement.data.menuData;
    if (
      !menuData.moduleItem?.data?.targets?.hasOwnProperty(targetKey.toString())
    ) {
      setAtPath(menuData.moduleItem, `data.targets`, {
        ...menuData.moduleItem.data.targets,
        [targetKey]: INLINE_BLANK_CALLFLOW,
      });
      setAtPath(menuData.callflow, menuData.modifyPath, menuData.moduleItem);
      menuData.setCallflow({ ...menuData.callflow }, { name: 'Add Menu Flow' });
    }

    handleEdit(targetKey);
  };

  return (
    <>
      <div className={`flex my-4 items-center justify-between`}>
        <label className={' text-md font-medium text-content'}>
          Menu Greeting
        </label>
        <Button
          variant={'ghost'}
          color={'accent'}
          size={'md'}
          onClick={toggleEdit}
        >
          {edit
            ? 'Cancel Editing'
            : greeting_id
            ? 'Edit Greeting'
            : 'Add Greeting'}
        </Button>
      </div>
      {edit ? (
        <AudioEditor
          hideCancel
          mediaName={`menu_greeting_${Date.now()}`}
          mediaId={greeting_id}
          onComplete={async mediaId => {
            if (invalidModules.includes(moduleItem.id)) {
              setInvalidModules(prev =>
                prev.filter(id => id !== moduleItem.id),
              );
            }
            setAtPath(callflow, `${modifyPath}.data.greeting_id`, mediaId);
            setCallflow({ ...callflow });
            toggleEdit();
          }}
          subType={'menu-greeting'}
          onClose={toggleEdit}
          hasPlatformDefault={false}
        />
      ) : greeting_id ? (
        <AudioPlayer mediaId={greeting_id} />
      ) : (
        <Alert
          reverse
          icon={<WarningTriangle fr={undefined} />}
          color={'attention'}
          label={'No greeting'}
          body={
            <div className={`flex flex-col space-y-2 pr-2`}>
              <span>Add a greeting to your menu to direct callers.</span>
            </div>
          }
          // actionProps={{
          //   onClick: toggleEdit,
          //   children: 'Customize greeting',
          // }}
        />
      )}
      <span className={`mt-10 text-md font-medium`}>Menu Options</span>
      <p className={`text-neutral-60 text-sm my-2`}>
        Provide menu options to route callers to the appropriate destination.
      </p>
      <div
        className={
          'flex-1 justify-center divide-y divide-neutral-20 items-center flex flex-col'
        }
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '_', 'timeout'].map(targetKey => {
          const target = targets[targetKey];
          const isPresent = target;
          const modules = target?.callflow?.strategy.data.modules ?? [];
          const required = targetKey === '_' || targetKey === 'timeout';

          const handleClear = () => {
            // clear modules if present, otherwise delete target route
            const menuData = insertMenuOptionElement.data.menuData;
            if (modules.length) {
              setAtPath(
                menuData.moduleItem,
                `data.targets.${targetKey}.callflow.strategy.data.modules`,
                [],
              );
            } else {
              setAtPath(menuData.moduleItem, `data.targets`, {
                ...menuData.moduleItem.data.targets,
                [targetKey]: undefined,
              });
            }

            setAtPath(
              menuData.callflow,
              menuData.modifyPath,
              menuData.moduleItem,
            );
            menuData.setCallflow(
              { ...menuData.callflow },
              { name: 'Edit Menu Flow' },
            );
          };

          let label;
          switch (targetKey) {
            case '_':
              label = 'Invalid Entry';
              break;
            case 'timeout':
              label = `Timeout`;
              break;
            default:
              label = `Dial ${targetKey}`;
              break;
          }

          return (
            <div
              key={targetKey}
              className={`w-full flex px-4  items-center justify-between  ${
                isPresent && modules.length
                  ? 'text-accent-60'
                  : required && !modules.length
                  ? 'text-attention-60 bg-attention-10'
                  : 'bg-neutral-10/50 text-neutral-60'
              }`}
            >
              <div
                onClick={() => {
                  if (isPresent) {
                    handleEdit(targetKey);
                    return;
                  }

                  handleAddOption(targetKey);
                }}
                // onTouchStart={e => {
                //   e.preventDefault();
                //   if (isPresent) {
                //     handleEdit(targetKey);
                //     return;
                //   }
                //
                //   handleAddOption(targetKey);
                // }}
                className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
              >
                {required && !modules.length ? (
                  <WarningTriangle
                    fr={undefined}
                    className={`text-attention-60`}
                  />
                ) : null}
                <div className={`flex flex-col`}>
                  {target?.name ? (
                    <div className={`flex space-x-2`}>
                      <span
                      // className={
                      //   isPresent && modules.length
                      //     ? ''
                      //     : 'text-neutral-60'
                      // }
                      >
                        {target?.name}
                      </span>{' '}
                      <span className={`text-neutral-60`}>({label})</span>
                    </div>
                  ) : (
                    <span
                    // className={
                    //   isPresent && modules.length
                    //     ? 'text-accent-60'
                    //     : 'text-neutral-60'
                    // }
                    >
                      {label}
                    </span>
                  )}
                  {isPresent && !modules.length ? (
                    <span
                      className={`text-sm ${
                        modules.length ? 'text-neutral-60' : 'text-negative-60'
                      }`}
                    >
                      No Modules
                    </span>
                  ) : null}
                </div>
              </div>
              <div className={`flex space-x-2`}>
                {isPresent ? (
                  <>
                    <IconButton
                      size={'md'}
                      onClick={() => handleEdit(targetKey)}
                      variant={'outline'}
                      color={'accent'}
                    >
                      <EditPencil fr={undefined} />
                    </IconButton>
                    {required && !modules.length ? null : (
                      <IconButton
                        size={'md'}
                        onClick={handleClear}
                        variant={'outline'}
                        color={'negative'}
                        title={modules.length ? 'Clear' : 'Delete'}
                      >
                        {modules.length ? (
                          <DeleteCircle fr={undefined} />
                        ) : (
                          <Trash fr={undefined} />
                        )}
                      </IconButton>
                    )}
                  </>
                ) : (
                  <>
                    <IconButton
                      size={'md'}
                      onClick={() => handleAddOption(targetKey)}
                      variant={'outline'}
                      color={'accent'}
                    >
                      <Plus fr={undefined} />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          );
        })}

        {/*{targets.map(([key, target]) => {*/}
        {/*  let label = key;*/}

        {/*  if (key === '_') {*/}
        {/*    label = 'Invalid Entry';*/}
        {/*  } else if (key === 'timeout') {*/}
        {/*    label = 'Timeout';*/}
        {/*  }*/}
        {/*

          const handleClick = () => {
            console.log('path', modifyPath, key);
            const path =
              modifyPath + `.data.targets.${key}.callflow.strategy.data.module`;
            const test = getAtPath(callflow, path);
            console.log();
            setSelectedElementKey(
              modifyPath +
                `.data.targets.${key}.callflow.strategy.data.modules`,
            );
          };
*/}

        {/*  return (*/}
        {/*    <div*/}
        {/*      key={key}*/}
        {/*      className={`w-full flex justify-center items-center`}*/}
        {/*    >*/}
        {/*      <Button size={'md'} onClick={handleClick} color={'accent'}>*/}
        {/*        {label}*/}
        {/*      </Button>*/}
        {/*    </div>*/}
        {/*  );*/}
        {/*})}*/}
      </div>

      <MenuButton
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
      </div>
    </>
  );
};
