import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormTextField } from 'app/components/reactHookFormComponents/HookFormTextField';
import { Dialog } from 'app/design-lib';
import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { GenericMutationDialogContent } from 'app/design/components/tailwind/GenericMutationDialogContent';
import { useCreateGroup } from 'app/hooks/mutations/group';
import { EditGroupDialog } from 'app/pages/settings/Groups/Groups';
import {
  parseAndSetKazooMutationErrors,
  useToggleReducer,
} from 'app/utilities';
import Joi from 'joi';
import { pickBy } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// schema for form validation. Passed to useForm to only trigger submit when
//  - the below conditions are met. Any known serverside constraints (min/max,
//  - character limits, numbers only, etc.) should be added. Remove 128 max below
//  - to demonstrate serverside invalidation and handling
const schema = Joi.object({
  name: Joi.string().max(128).required(),
});

interface GroupCreateForm {
  name: string;
}

// interface declaring which props are required/allowed
interface ContentProps {
  // groupId: string;
  onCancel: () => void;
  onComplete?: (groupId: string) => void;
  navigateOnComplete?: boolean;
}

interface GroupCreateDialogProps {
  ContentProps: ContentProps;
  DialogProps: AnimationDialogProps;
}

const GroupCreateDialog = ({
  ContentProps,
  DialogProps,
}: GroupCreateDialogProps) => {
  return (
    <Dialog open={DialogProps.open} onClose={ContentProps.onCancel}>
      <GroupCreateDialogContent {...ContentProps} />
    </Dialog>
  );
};

const GroupCreateDialogContent = ({
  onCancel,
  onComplete,
  navigateOnComplete = true,
}: ContentProps) => {
  const router = useHistory();
  const [editGroup, toggleEditGroup] = useToggleReducer();
  const createGroup = useCreateGroup();
  const formMethods = useForm<GroupCreateForm>({
    defaultValues: {
      name: '',
    },
    resolver: joiResolver(schema), // pass in custom validation
  });
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
    setError,
    watch,
  } = formMethods;

  const onSubmit = async (groupForm: GroupCreateForm) => {
    // "sanitize" and remove any unset values (empty strings)
    // - so that they are not sent to server.
    // - ex: An empty string would override the existing password(?)
    // - no current way to unset password
    const sanitizedFormData = pickBy(groupForm, value => !!value?.length);

    try {
      const resp = await createGroup.mutateAsync({
        name: sanitizedFormData.name ?? '',
      });
    } catch (e: any) {
      parseAndSetKazooMutationErrors({ response: e.response, setError });
    }
  };

  const handleComplete = () => {
    if (onComplete) onComplete(createGroup.data.data.id);

    // redirect to Group
    if (navigateOnComplete)
      router.push(`/admin/ring_groups/view/${createGroup.data.data.id}`);
  };

  return (
    <>
      {editGroup ? (
        <EditGroupDialog
          // pipe={pipe}
          group={{ doc: createGroup.data.data }}
          onCancel={() => {
            handleComplete();
          }}
          // onSend={toggleOpenSendDialog}
        />
      ) : (
        <GenericMutationDialogContent
          onComplete={toggleEditGroup}
          onCancel={onCancel}
          onSubmit={handleSubmit(onSubmit)}
          onSuccessLabel={'Ring group added'}
          isLoadingLabel={'Adding ring group'}
          title={'Add Ring Group'}
          mutation={createGroup}
          formMethods={formMethods}
          submitButtonLabel={'Add Ring Group'}
          successButtonLabel={'Continue'}
        >
          <HookFormTextField
            autoComplete={'off'}
            // fullWidth
            autoFocus
            name={'name'}
            label={'Name'}
          />
          <p className={`font-medium mt-1 text-sm`}>
            You'll customize your ring group in the next step.
          </p>
        </GenericMutationDialogContent>
      )}
    </>
  );
};

export default GroupCreateDialog;
