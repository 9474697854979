import { UserAvatar } from 'app/components/Sidebar/Sidebar';
import { AvatarGroup } from 'app/design-lib';
import { useListUsersQuery } from 'app/hooks/queries/user';
import * as React from 'react';
import { Group } from 'types/group';

interface UsersAndDevicesCellProps {
  group: Group;
}

const UsersAndDevicesCell = ({ group }: UsersAndDevicesCellProps) => {
  const deviceCount =
    group?.doc?.preEndpoints?.filter(
      endpoint => endpoint?.endpoint_type === 'device',
    ).length ?? 0;
  const userIds =
    group?.doc?.preEndpoints
      ?.filter(endpoint => endpoint?.endpoint_type === 'user')
      .map(ep => ep.id) ?? [];
  const userCount = userIds.length ?? 0;

  // console.log('userIds', userIds);
  const { data: usersPage } = useListUsersQuery({
    quick: true,
    filters: {
      context: {
        ids: userIds,
      },
    },
  });

  // @ts-ignore
  const users = usersPage?.users?.users ?? [];

  return (
    <div className={'flex justify-start items-center space-x-1'}>
      <AvatarGroup>
        {users.map(user => (
          <UserAvatar key={user.id} user={user} size={'sm'} />
        ))}
      </AvatarGroup>
      {deviceCount ? (
        <span className={'text-neutral-60 text-sm'}>
          and {deviceCount} device{deviceCount > 1 ? 's' : ''}
        </span>
      ) : null}
    </div>
  );

  // return (
  //   <div className={'text-sm'}>
  //     <div className={'flex space-x-1'}>
  //       <div>
  //         <span className={'font-bold'}>Users:</span>
  //       </div>
  //       <div>
  //         <span>{userCount}</span>
  //       </div>
  //     </div>
  //     <div className={'flex space-x-1'}>
  //       <div>
  //         <span className={'font-bold'}>Devices:</span>
  //       </div>
  //       <div>
  //         <span>{deviceCount}</span>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default UsersAndDevicesCell;
