import PersonIcon from '@mui/icons-material/Person';
import { Backdrop } from '@mui/material';
import { CustomBackdrop } from 'app/components/CustomBackdrop';
import { LinesDisplay } from 'app/components/Sidebar/components/LinesDisplay';
import { Loading } from 'app/components/Sidebar/components/Loading';
import { LogoAndExpander } from 'app/components/Sidebar/components/LogoAndExpander';
import { StatusSwitches } from 'app/components/Sidebar/components/StatusSwitches';
import { Avatar } from 'app/design-lib';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuthSelector } from 'app/data/auth';
import { useTheme } from 'app/design';

import { sum } from 'lodash';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

import { useTour } from '@reactour/tour';
import { useGlobalTour } from 'app/components/Tourguide/Helper';
import { useHint } from 'app/utilities';

import constants from 'app/constants';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import {
  useAuthenticatedUserQuery,
  useListUsersQuery,
  useUserQuery,
} from 'app/hooks/queries/user';

import { Emoji } from 'emoji-picker-react';

import { ScheduleWidget } from 'app/components/ScheduleWidget';
import { useListSchedulesQuery } from 'app/hooks/queries/schedule';
import { WebphoneButton } from './components/WebphoneButton';
import { WebphoneContainer } from './components/WebphoneContainer';

import { useAccountQuery } from 'app/hooks/queries/account';

import {
  usePipeConvosQuery,
  usePipeConvoUsersQuery,
  usePipeMessagesQuery,
  usePipesQuery,
} from 'app/sdk';
import { store } from 'store';
import { usePNContext, usePubNubSub } from 'app/utilities/pubnub';
import SimpleBar from 'simplebar-react';
import { DirectMessages } from './components/DirectMessages';
import { SidebarSettings } from './components/SidebarSettings';

import { UserName } from 'app/components/UserName';

export const RouteMatchComponent = props => {
  // @ts-ignore
  const { to, children, ...rest } = props;

  let active: any = useRouteMatch(to);
  const { pathname } = useLocation();

  // console.log('test', test);

  // if DM TODO verify only DMs use this pattern?
  if (to?.includes('::')) active = pathname === to;

  const history = useHistory();

  const nav = newLink => {
    history.push(newLink || to);
  };

  return children({ active, to, href: to, nav });
};

export const Sidebar = () => {
  const { sidebar_key: selectedSidebarKey, sidebar_mini } = useLocalSelector();
  const { actions } = useLocalSlice();
  const dispatch = useDispatch();
  const { data: authenticatedUser, refetch } = useAuthenticatedUserQuery();

  const [{ openTour }] = useGlobalTour();
  const [isHiddenSetupTour, hide, refetchHints, setAdminSetupTourHintValue] =
    useHint('admin.setup.all');

  // @ts-ignore
  const { userIsOnline, userIsOnlineSet, onlineUsers } = usePNContext();

  // scroll to top on sidebar change
  let location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  // determine if menu should be open or closed
  // - sets default if not set
  const theme = useTheme();
  // const isMediaQueryMd = useMediaQuery(theme.breakpoints.up('md'));
  const isMediaQueryMd = true;
  let normalMenu = sidebar_mini === null ? isMediaQueryMd : !sidebar_mini;

  const handleScrollY = () => {
    // handle if we should show elevation below the Appbar/toolbar
  };

  const toggleSidebarMini = () => {
    // normal menu is inverse of sidebar_mini
    dispatch(actions.set_sidebar_mini(normalMenu));
  };

  return (
    <>
      <WebphoneContainer />
      <div
        className={
          'h-screen overflow-hidden max-h-screen bg-red-500 flex flex-col bg-white w-60 border-r border-gray-20'
        }
      >
        {/* <WebphoneButton /> */}
        <LogoAndExpander />
        {authenticatedUser ? (
          <>
            <StatusSwitches user={authenticatedUser} onComplete={refetch} />
            <div className={'overflow-hidden flex flex-col h-full'}>
              <SimpleBar className={'h-full w-full'}>
                <LinesDisplay />
                <DirectMessages user={authenticatedUser} />
              </SimpleBar>
            </div>
            <SidebarSettings user={authenticatedUser} />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );

  // console.log('rendering sidebar');
  // return (
  //   // width: normalMenu
  //   //   ? constants.nav.DRAWER_WIDTH
  //   //   : constants.nav.DRAWER_MINI_WIDTH,
  //   <>
  //     <WebphoneContainer />
  //     <div
  //       className={`flex-none w-[265px] border-solid border-0 border-r-2 border-neutral-200 bg-bluegray-50  shadow-xl shadow-neutral-200`}
  //     >
  //       <div className="overflow-hidden flex flex-col h-screen w-full ">
  //         {/* <PerfectScrollbar
  //           options={{ wheelPropagation: false }}
  //           onScrollY={handleScrollY}
  //         > */}
  //
  //         <SimpleBar
  //           // {/* PerfectScrollbar */}
  //           // @ts-ignore
  //           // ref={messagesScrollRef}
  //           // ref={setRef}
  //           style={{ flexGrow: '1', height: '100%', overflowY: 'auto' }}
  //           // scrollableNodeProps={{ ref: setRef }}
  //           // options={{ wheelPropagation: false }}
  //           // className="scroll-smooth"
  //           // onScrollY={handleScrollY}
  //         >
  //           <div className={'bg-background-neutral/75 space-y-4 px-6 py-6'}>
  //             {/*<div
  //               className={
  //                 'flex space-x-2 w-full items-center pl-1 text-xl font-bold'
  //               }
  //             >
  //               <Link to="/">
  //                 <img
  //                   alt={''}
  //                   src="https://ucarecdn.com/0bdb1986-1c08-4bf6-a6e9-fe324355d111/callingio1.svg"
  //                 />
  //               </Link>
  //               <Link
  //                 component={RouterLink}
  //                 to="/"
  //                 className={'text-black -mt-1 no-underline'}
  //               >
  //                 CallingIO
  //               </Link>
  //             </div>*/}
  //             <div>
  //               <div
  //                 onClick={e => userIsOnlineSet(!userIsOnline)}
  //                 className="cursor-pointer text-xl"
  //                 data-tour-username
  //               >
  //                 <HelpModeIndicator
  //                   videoUrl={
  //                     'https://www.loom.com/embed/91bd07d87e634247b6d08dcc3a2ffaff?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
  //                   }
  //                   position={'left'}
  //                   xOffset={2}
  //                   yOffset={1}
  //                 >
  //                   <UserName owner_id={store.getState().auth.owner_id!} />
  //                 </HelpModeIndicator>
  //               </div>
  //               {/* <span onClick={e => userIsOnlineSet(!userIsOnline)}>
  //               ({userIsOnline ? 'Online' : 'Away'})
  //             </span> */}
  //               {/* <span onClick={e => console.log('onlineUsers:', onlineUsers)}>
  //               who is online
  //             </span> */}
  //             </div>
  //             {authenticatedUser ? (
  //               <div data-tour-dnd>
  //                 <HelpModeIndicator
  //                   videoUrl={
  //                     'https://www.loom.com/embed/50ba0b8ce200452787813a38f014f60c?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
  //                   }
  //                   position={'left'}
  //                   xOffset={2}
  //                   yOffset={1}
  //                 >
  //                   <LinesHeader
  //                     userDoc={authenticatedUser?.doc}
  //                     onComplete={refetch}
  //                   />
  //                 </HelpModeIndicator>
  //               </div>
  //             ) : null}
  //
  //             <div className="text-center">
  //               <WebphoneButton />
  //             </div>
  //
  //             <div className="flex flex-col p-2 space-y-2">
  //               <div className={'flex space-x-2 items-center'}>
  //                 <span className={'text-neutral-500'}>•</span>
  //                 <NavLink
  //                   to="/v2/contacts"
  //                   className={isActive =>
  //                     `text-content-accent ${
  //                       isActive ? 'font-bold underline' : 'no-underline'
  //                     }`
  //                   }
  //                 >
  //                   Contacts
  //                 </NavLink>
  //               </div>
  //
  //               {/* <div>
  //               <NavLink
  //                 to="/v2/mentions"
  //                 className={isActive => (isActive ? 'font-bold' : '')}
  //               >
  //                 @mentions/reactions
  //               </NavLink>
  //             </div> */}
  //
  //               <div className={'flex space-x-2 items-center'}>
  //                 <span className={'text-neutral-500'}>•</span>
  //                 <NavLink
  //                   to="/v2/settings"
  //                   className={isActive =>
  //                     `text-content-accent ${
  //                       isActive ? 'font-bold underline' : 'no-underline'
  //                     }`
  //                   }
  //                   data-tour-settings
  //                 >
  //                   Settings
  //                 </NavLink>
  //               </div>
  //             </div>
  //
  //             {/* <Schedules /> */}
  //
  //             <div>
  //               <Pipes />
  //             </div>
  //             {/* <div>
  //             <Rooms />
  //           </div> */}
  //             <div className="">
  //               {authenticatedUser ? (
  //                 <Users user={authenticatedUser} refetch={refetch} />
  //               ) : null}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //
  //         <div
  //           className={
  //             'py-4 sticky px-4 border-solid border-0 border-t border-border-neutral'
  //           }
  //           data-tour-help-mode
  //         >
  //           <HelpModeToggleSwitch />
  //         </div>
  //         {/* {!isHiddenSetupTour ? ( */}
  //         <div className={'pb-4 px-4 '}>
  //           <Button
  //             variant={'outline'}
  //             color={'attention'}
  //             size={'sm'}
  //             // className="p-2 text-md cursor-pointer hover:bg-teal-100/50 border-solid border-neutral-200 border-x-0 border-t-0"
  //             onClick={e => openTour('setup2')}
  //             data-tour-start
  //             startIcon={<DirectionsIcon />}
  //           >
  //             Restart Onboarding Tour
  //           </Button>
  //         </div>
  //         {/* ) : null} */}
  //         {/* </PerfectScrollbar> */}
  //       </div>
  //     </div>
  //   </>
  // );
};

const Schedules = () => {
  // schedule list
  const { refetch, isLoading, isFetching, dataUpdatedAt, data } =
    useListSchedulesQuery({
      skip: 0, //skip
      take: 10, // take
      orderBy: undefined, // order by
      filters: {}, // filters
    });
  const { callflows: schedules, totalCount = 0 } = data ?? {};

  // const pinned_schedules = schedules?.map(sc => sc.doc.pinned_to_sidebar) ?? [];

  if (!schedules) {
    return null;
  }

  return (
    <div className="border-solid border-x-0 border-y-0 border-b-2 border-neutral-200">
      {schedules.map(sc => (
        <div key={sc.id}>
          <ScheduleWidget callflow={sc.doc} />
        </div>
      ))}
    </div>
  );
};

export const UserAvatar = ({
  user,
  user_id,
  user_ext,
  size,
  enabled,
}: {
  user?: any;
  user_id?: string;
  user_ext?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  enabled?: boolean;
}) => {
  const { account_id, auth_token } = useAuthSelector();

  const { data: loadedUser, isLoading: userIsLoading } = useUserQuery(user_id);
  const {
    data: usersPage,
    isLoading: usersLoading,
    // refetch,
  } = useListUsersQuery({
    take: 1,
    filters: {
      context: {
        presenceIds: [user_ext],
      },
    },
    // orderBy: [['presence_id', 'asc']],
    options: {
      enabled: user_ext?.length ? true : false,
    },
    quick: true,
  });
  // console.log('usersPage1:', user_ext, usersPage);
  // @ts-ignore
  const loadedUser2 = usersPage?.users?.users?.[0];

  let resource = user || loadedUser || loadedUser2;
  let initials =
    resource?.doc?.first_name || resource?.doc?.last_name
      ? `${resource?.doc?.first_name?.charAt(0) ?? ''}${
          resource?.doc?.last_name?.charAt(0) ?? ''
        }`
      : resource?.doc?.company?.charAt(0) ?? '';

  const src = resource
    ? `${
        constants.env.REACT_APP_CIO_API_SERVER
      }/api/kproxy/accounts/${account_id}/users/${
        resource?.id
      }/photo?auth_token=${auth_token}&_=${user?.updatedAt || ''}`
    : undefined;

  // let width = Math.round(size / 4);

  return <Avatar active={enabled} size={size} src={src} initials={initials} />;

  // console.log('width:', width);
  // return (
  //   <Badge
  //     overlap="circular"
  //     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  //     // variant="dot"
  //     // color={}
  //     badgeContent={
  //       typeof enabled === 'boolean' ? (
  //         enabled ? (
  //           <div
  //             className={`inline-block rounded-full bg-green-500 ring-1 ring-green-500 mb-[2px] mr-1`}
  //             style={{ height: width, width }}
  //           ></div>
  //         ) : (
  //           <div
  //             className={`inline-block rounded-full bg-white ring-1 ring-neutral-500 mb-[2px] mr-1`}
  //             style={{ height: width, width }}
  //           ></div>
  //         )
  //       ) : null
  //     }
  //   >
  //     {avatarGroup ? (
  //       <Avatar
  //         alt={initials}
  //         src={src}
  //         sx={{ height: size, width: size, border: '1px solid #ccc' }}
  //       />
  //     ) : (
  //       <div
  //         className={
  //           'border-solid border border-border-neutral-pressed rounded-full '
  //         }
  //       >
  //         <Avatar
  //           alt={initials}
  //           src={src}
  //           sx={{ height: size, width: size, border: '1px solid #ccc' }}
  //         />
  //       </div>
  //     )}
  //   </Badge>
  // );
};

// const DmUserName = ({
//   owner_id,
//   size,
//   showTitle = true,
// }: {
//   size?: 'sm' | 'md' | 'lg';
//   showTitle?: boolean;
//   owner_id: string;
// }) => {
//   const { data: user, isLoading: userIsLoading } = useUserQuery(owner_id);

//   // @ts-ignore
//   const { onlineUsers } = usePNContext();
//   const isOnline = onlineUsers?.[owner_id] ? true : false;

//   return (
//     <div className="inline-block flex items-center">
//       {/* <AvatarCell
//         resource={user}
//         size={16}
//         sx={{ fontSize: '11px !important' }}
//         badgeColor="green"
//         forceBadge={isOnline}
//       /> */}
//       <span className="mr-2 font-normal align-text-bottom">
//         <UserAvatar user={user} enabled={isOnline} size={size} />
//       </span>
//       {/* {isOnline ? (
//         <div className="inline-block rounded-full w-[6px] h-[6px] bg-green-500 ring-1 ring-green-500 mb-[2px] mr-1"></div>
//       ) : (
//         <div className="inline-block rounded-full w-[6px] h-[6px] ring-2 ring-neutral-500 mb-[2px] mr-1"></div>
//       )} */}
//       <div>
//         <div>
//           {userIsLoading ? '...' : user ? `${user.extra.fullName}` : 'Unknown'}
//         </div>
//         {showTitle && user?.doc?.title ? (
//           <div className={'text-sm text-neutral-500'}>{user.doc?.title}</div>
//         ) : null}
//       </div>
//     </div>
//   );
// };

const Pipes = () => {
  const { data: accountData } = useAccountQuery();
  // const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const schedulesQuery = useListSchedulesQuery({
    skip: 0, //skip
    take: 100, // take
    orderBy: undefined, // order by
    filters: {}, // filters
  });
  const { callflows: schedules } = schedulesQuery.data ?? {};

  const pipesQuery = usePipesQuery({
    filters: {
      users: {
        path: [store.getState().auth.owner_id, 'enabled'],
        equals: true,
      },
      NOT: {
        type: 'room',
      },
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];

  // console.log('authenticatedUser?.doc.id:', authenticatedUser?.doc.id);
  return (
    <div className="" data-tour-sidebar-lines>
      {/* <div className="font-bold text-md mt-4 mb-1 pl-2">
        <div className={'flex space-x-1 items-center '}>
          <NumbersIcon
            className={'fill-content-accent -mt-0.5'}
            sx={{ fontSize: 'inherit' }}
          />
          <span>Lines</span>
        </div>
      </div> */}
      <div className="">
        {/* {pipes
          .filter(pipe => pipe.type === 'personal')
          .map(pipe => (
            <PersonalPipe
              key={pipe.id}
              pipe={pipe}
              // @ts-ignore
              schedule={schedules?.find(s => s.doc?.pipe_id === pipe.id)}
            />
          ))} */}
        {pipes
          .filter(pipe => pipe.type === 'external')
          .map(pipe => (
            <Pipe
              key={pipe.id}
              pipe={pipe}
              // @ts-ignore
              schedule={schedules?.find(s => s.doc?.pipe_id === pipe.id)}
            />
          ))}
        {pipes
          .filter(pipe => pipe.type === 'shared')
          .map(pipe => (
            <Pipe
              key={pipe.id}
              pipe={pipe}
              // @ts-ignore
              schedule={schedules?.find(s => s.doc?.pipe_id === pipe.id)}
            />
          ))}
        {pipesQuery.isLoading ? <div>Loading lines...</div> : null}
        {!pipesQuery.isLoading && !pipes?.length ? <div>No lines</div> : null}
      </div>
    </div>
  );
};

const Pipe = ({ pipe, schedule }) => {
  const pipe_key = pipe.key;
  const pipeConvosQuery = usePipeConvosQuery({
    filters: {
      pipe_id: pipe.id,
    },
    // key: pipe_key,
  });
  const pipeConvos = pipeConvosQuery.data?.pipeConvos ?? [];

  const pipeConvoUsersQuery = usePipeConvoUsersQuery({
    pipe_id: pipe.id,
    user_id: store.getState().auth.owner_id,
  });
  const pipeConvoUsers = pipeConvoUsersQuery.data?.pipeConvoUsers ?? [];

  const pipeMessagesQuery = usePipeMessagesQuery({
    filters: {
      pipe_id: pipe.id,
      // convo_key: pc.key,
      AND: [
        { metadata: { path: ['unresolved'], equals: true } },
        // { NOT: { type: 'internal' } },
        // { NOT: { type: 'internal-activity' } },
      ].filter(v => !!v),
    },
  });
  const pipeMessagesUnresolved = (pipeMessagesQuery.data?.pipeMessages ?? [])
    .length;

  const handleMessage = event => {
    const message = event.message;
    switch (message?.type) {
      case 'added':
      case 'convo:added':
      case 'convo_updated':
        // console.log('pubnub sidebar event triggered2', pipe.id, message.type);
        pipeConvosQuery.refetch();
        pipeConvoUsersQuery.refetch();
        pipeMessagesQuery.refetch();
        break;
      case 'message:updated':
        // pipeMessage updated in existing list
        // - don't care about this here
        break;
    }
  };

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();

  usePubNubSub(handleMessage, pipe ? [`pipe:${pipe.id}`] : []); // UNCOMMENT!!

  const unread_convos = sum(pipeConvos.map(pc => (pc.metadata.unread ? 1 : 0)));
  const unread_internal = sum(
    pipeConvoUsers.map(pc => (pc.metadata.unread_internal ? 1 : 0)),
  );
  // console.log('pipeConvoUsers:', pipeConvoUsers);
  return (
    // @ts-ignore
    <RouteMatchComponent to={`/pipe/${pipe.id}`}>
      {({ active, nav, href }) => (
        <div
          className={`p-1 mb-1 rounded hover:bg-background-accent-secondary-hover/30 ${
            active ? 'bg-background-accent-secondary-active/30' : ''
          }`}
          {...(pipe.type === 'personal'
            ? { 'data-tour-sidebar-line-personal': true }
            : pipe.type === 'external'
            ? { 'data-tour-sidebar-line-external': true }
            : {})}
        >
          <div
            className="p-1 flex space-x-1 items-center cursor-pointer"
            onClick={e => {
              nav();
              // nextStep();
              if (isOpen) {
                setCurrentStep(v => v + 1);
              }
            }}
          >
            <div className="px-1">
              <Emoji unified={pipe.metadata?.emoji || '1f937'} size={25} />
            </div>
            <div className="flex-auto min-w-0 pr-1">
              <div className="truncate">
                <span className="font-bold">{pipe.metadata?.name}</span>
              </div>
              <div>
                <PhoneNumberDisplay ptn={pipe.key} />
              </div>
              {schedule ? (
                <div>
                  <ScheduleWidget callflow={schedule.doc} />
                </div>
              ) : null}
            </div>
            <div className="space-y-1 flex flex-col">
              {pipeMessagesUnresolved ? (
                <span className="bg-background-attention-secondary-hover text-content-attention text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                  {pipeMessagesUnresolved}
                </span>
              ) : null}
              {unread_convos ? (
                <span className="bg-background-accent-secondary-hover text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                  {unread_convos}
                </span>
              ) : null}
              {unread_internal ? (
                <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                  {unread_internal}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </RouteMatchComponent>
  );
};

const PersonalPipe = ({ pipe, schedule }) => {
  const pipe_key = pipe.key;
  const pipeConvosQuery = usePipeConvosQuery({
    filters: {
      pipe_id: pipe.id,
    },
    // key: pipe_key,
  });
  const pipeConvos = pipeConvosQuery.data?.pipeConvos ?? [];

  const pipeConvoUsersQuery = usePipeConvoUsersQuery({
    pipe_id: pipe.id,
    user_id: store.getState().auth.owner_id,
  });
  const pipeConvoUsers = pipeConvoUsersQuery.data?.pipeConvoUsers ?? [];

  const pipeMessagesQuery = usePipeMessagesQuery({
    filters: {
      pipe_id: pipe.id,
      // convo_key: pc.key,
      AND: [
        { metadata: { path: ['unresolved'], equals: true } },
        // { NOT: { type: 'internal' } },
        // { NOT: { type: 'internal-activity' } },
      ].filter(v => !!v),
    },
  });
  const pipeMessagesUnresolved = (pipeMessagesQuery.data?.pipeMessages ?? [])
    .length;

  const handleMessage = event => {
    const message = event.message;
    switch (message?.type) {
      case 'added':
      case 'convo:added':
      case 'convo_updated':
        // console.log('pubnub sidebar event triggered2', pipe.id, message.type);
        pipeConvosQuery.refetch();
        pipeConvoUsersQuery.refetch();
        pipeMessagesQuery.refetch();
        break;
      case 'message:updated':
        // pipeMessage updated in existing list
        // - don't care about this here
        break;
    }
  };

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour();

  usePubNubSub(handleMessage, pipe ? [`pipe:${pipe.id}`] : []);

  const unread_convos = sum(pipeConvos.map(pc => (pc.metadata.unread ? 1 : 0)));
  const unread_internal = sum(
    pipeConvoUsers.map(pc => (pc.metadata.unread_internal ? 1 : 0)),
  );
  // console.log('pipeConvoUsers:', pipeConvoUsers);
  return (
    // @ts-ignore
    <RouteMatchComponent to={`/pipe/${pipe.id}`}>
      {({ active, nav, href }) => (
        <div
          className={`p-1 mb-4 rounded hover:bg-background-accent-secondary-hover/30 ${
            active ? 'bg-background-accent-secondary-active/30' : ''
          }`}
          {...(pipe.type === 'personal'
            ? { 'data-tour-sidebar-line-personal': true }
            : pipe.type === 'external'
            ? { 'data-tour-sidebar-line-external': true }
            : {})}
        >
          <div
            className="p-1 flex space-x-1 items-center cursor-pointer"
            onClick={e => {
              nav();
              // nextStep();
              if (isOpen) {
                setCurrentStep(v => v + 1);
              }
            }}
          >
            {/* <div className="px-1">
              <Emoji unified={pipe.metadata?.emoji || '1f937'} size={25} />
            </div> */}
            <div className="flex-auto min-w-0 pr-1">
              <div className="truncate">
                <span className="font-bold">
                  Personal Extension ({pipe.key})
                </span>
              </div>
              {schedule ? (
                <div>
                  <ScheduleWidget callflow={schedule.doc} />
                </div>
              ) : null}
            </div>
            <div className="space-y-1 flex flex-col">
              {pipeMessagesUnresolved ? (
                <span className="bg-background-attention-secondary-hover text-content-attention text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                  {pipeMessagesUnresolved}
                </span>
              ) : null}
              {unread_convos ? (
                <span className="bg-background-accent-secondary-hover text-content-accent text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                  {unread_convos}
                </span>
              ) : null}
              {unread_internal ? (
                <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                  {unread_internal}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </RouteMatchComponent>
  );
};

const Rooms = () => {
  const { data: accountData } = useAccountQuery();
  // const { data: authenticatedUser } = useAuthenticatedUserQuery();

  const pipesQuery = usePipesQuery({
    filters: {
      users: {
        path: [store.getState().auth.owner_id, 'enabled'],
        equals: true,
      },
      type: 'room',
    },
  });
  const pipes = pipesQuery.data?.pipes ?? [];

  // console.log('authenticatedUser?.doc.id:', authenticatedUser?.doc.id);
  return (
    <div className="" data-tour-sidebar-rooms>
      <div className="font-bold text-md mt-4 mb-3 pl-2">#rooms</div>
      <div className="">
        {pipes.map(pipe => (
          <Room key={pipe.id} pipe={pipe} />
        ))}
        {pipesQuery.isLoading ? (
          <div className="text-center">Loading rooms...</div>
        ) : null}
        {!pipesQuery.isLoading && !pipes?.length ? (
          <div className="text-center">No rooms</div>
        ) : null}
      </div>
    </div>
  );
};

const Room = ({ pipe }) => {
  const pipe_key = pipe.key;
  const pipeConvosQuery = usePipeConvosQuery({
    filters: {
      pipe_id: pipe.id,
    },
    // key: pipe_key,
  });
  const pipeConvos = pipeConvosQuery.data?.pipeConvos ?? [];

  const pipeConvoUsersQuery = usePipeConvoUsersQuery({
    pipe_id: pipe.id,
    user_id: store.getState().auth.owner_id,
  });
  const pipeConvoUsers = pipeConvoUsersQuery.data?.pipeConvoUsers ?? [];

  const pipeMessagesQuery = usePipeMessagesQuery({
    filters: {
      pipe_id: pipe.id,
      // convo_key: pc.key,
      AND: [
        { metadata: { path: ['unresolved'], equals: true } },
        // { NOT: { type: 'internal' } },
        // { NOT: { type: 'internal-activity' } },
      ].filter(v => !!v),
    },
  });
  const pipeMessagesUnresolved = (pipeMessagesQuery.data?.pipeMessages ?? [])
    .length;

  const handleMessage = event => {
    const message = event.message;
    switch (message?.type) {
      case 'added':
      case 'convo:added':
      case 'convo_updated':
        // console.log('pubnub sidebar event triggered2', pipe.id, message.type);
        pipeConvosQuery.refetch();
        pipeConvoUsersQuery.refetch();
        pipeMessagesQuery.refetch();
        break;
      case 'message:updated':
        // pipeMessage updated in existing list
        // - don't care about this here
        break;
    }
  };

  usePubNubSub(handleMessage, pipe ? [`pipe:${pipe.id}`] : []); // UNCOMMENT!!

  const unread_convos = sum(pipeConvos.map(pc => (pc.metadata.unread ? 1 : 0)));
  const unread_internal = sum(
    pipeConvoUsers.map(pc => (pc.metadata.unread_internal ? 1 : 0)),
  );
  // console.log('pipeConvoUsers:', pipeConvoUsers);
  return (
    // @ts-ignore
    <RouteMatchComponent to={`/room/${pipe.id}`}>
      {({ active, nav, href }) => (
        <div
          className={`p-1 hover:bg-neutral-200/50 ${
            active ? 'bg-neutral-200/60' : ''
          }`}
        >
          <div className="p-1 flex items-center cursor-pointer" onClick={nav}>
            <div className="px-1">
              <Emoji unified={pipe.metadata?.emoji || '1f937'} size={16} />
            </div>
            <div className="flex-1">
              {/* <div>
                <span className="font-bold">{pipe.metadata?.name}</span>
              </div> */}
              <div className="font-semibold">
                {/* <PhoneNumberDisplay ptn={pipe.key} /> */}#{pipe.key}
              </div>
            </div>
            <div className="space-x-1">
              {pipeMessagesUnresolved ? (
                <span className="bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                  {pipeMessagesUnresolved}
                </span>
              ) : null}
              {unread_convos ? (
                <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                  {unread_convos}
                </span>
              ) : null}
              {unread_internal ? (
                <span className="bg-purple-100 text-purple-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">
                  {unread_internal}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </RouteMatchComponent>
  );
};

// const Users = ({ user, refetch }) => {
//   // Find team members
//   const {
//     data: usersPage,
//     isLoading: usersLoading,
//     // refetch,
//   } = useListUsersQuery({
//     take: 500,
//     filters: {},
//     orderBy: [['presence_id', 'asc']],
//     options: {},
//     quick: true,
//   });

//   const {
//     // @ts-ignore
//     users: { users = [] },
//   } = usersPage ?? { users: {} };
//   // console.log('users:', users);

//   // Find my Personal Pipe/Line for my extension
//   const pipesQuery = usePipesQuery({
//     filters: {
//       // users: {
//       //   path: [store.getState().auth.owner_id, 'enabled'],
//       //   equals: true,
//       // },
//       // type: 'personal',
//       key: user.doc.presence_id,
//     },
//   });
//   const pipes = pipesQuery.data?.pipes ?? [];
//   const personalPipe = pipes?.length ? pipes[0] : null;

//   // Get convos for that Personal Line
//   // - match convos to users/team (by presence_id!)
//   const pipeConvosQuery = usePipeConvosQuery(
//     {
//       filters: {
//         pipe_id: personalPipe?.id,
//       },
//       // key: pipe_key,
//     },
//     personalPipe ? true : false,
//   );
//   const pipeConvos = pipeConvosQuery.data?.pipeConvos ?? [];

//   const [orderedUsers, orderedUsersSet] = useState([]);

//   useEffect(() => {
//     const user_id = store.getState().auth.owner_id;
//     const me = users.find(u => u.id === user_id);
//     const rest = users.filter(u => u.id !== user_id);
//     if (me) {
//       // orderedUsersSet([me, ...rest])
//       // @ts-ignore
//       let orderedWithConvos: any = [];
//       // if (pipeConvos) {
//       for (let user of [me, ...rest] as any[]) {
//         let convo = pipeConvos.find(c => c.key === user.doc.presence_id);
//         orderedWithConvos.push({
//           user,
//           convo,
//         });
//       }
//       // console.log('orderedWithConvos:', { orderedWithConvos, pipeConvos });
//       orderedUsersSet(orderedWithConvos);
//       // orderedUsersSet([]);
//       // }
//     }
//   }, [users, pipeConvosQuery.data]);

//   useEffect(() => {
//     console.log('pipeConvos updated in sidebar', pipeConvosQuery.data);
//   }, [pipeConvosQuery.data]);

//   if (!orderedUsers) {
//     // return null;
//   }

//   return (
//     <div className="mt-6" data-tour-sidebar-team>
//       <div className="text-md font-bold flex space-x-1 items-center p-2">
//         <PersonIcon
//           className={'fill-content-accent -mt-[2px]'}
//           sx={{ fontSize: 'inherit' }}
//         />
//         <span>Team</span>
//       </div>
//       <div className="">
//         {orderedUsers.map((info: any) => (
//           // @ts-ignore
//           <RouteMatchComponent
//             key={info.user.id}
//             to={`/v2/pipe/${personalPipe?.id}/convo/${info.user.doc.presence_id}`}
//           >
//             {({ active, nav, href }) => (
//               <Link
//                 className={`block flex items-center no-underline justify-between hover:bg-background-accent-secondary-hover/30 cursor-pointer text-neutral-800 p-2 ${
//                   active ? 'bg-background-accent-secondary-active/30' : ''
//                 }`}
//                 to={href}
//               >
//                 <div className="text-sm font-semibold ">
//                   {/* @ts-ignore */}
//                   <DmUserName
//                     showTitle={false}
//                     owner_id={info.user.id}
//                     size={'md'}
//                   />
//                 </div>
//                 {info.convo ? (
//                   <div className="">
//                     {info.convo.metadata?.unread ? (
//                       <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
//                         {info.convo.metadata.unread}
//                       </span>
//                     ) : null}
//                   </div>
//                 ) : null}
//               </Link>
//             )}
//           </RouteMatchComponent>
//         ))}
//       </div>
//     </div>
//   );
// };

export default Sidebar;
