import { NextModuleButton } from './NextModuleButton';

export const MenuOptionModule = () => {
  return (
    <>
      <div className={`flex flex-wrap justify-between`}>
        <NextModuleButton />
      </div>
    </>
  );
};
