import { DetailsDialogAccount } from 'app/components/DetailsDialogAccount';
import { Grid, InfoTooltip, Link, Typography } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { TimezoneDialogAccount } from 'app/components/TimezoneDialogAccount';
import {
  Alert,
  Button,
  Setting,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';

import { Account } from 'types/account';
import { getTimezoneName, useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';
import { EditPencil, WarningTriangle } from 'iconoir-react';

interface TimezoneAccountProps {
  account: Account;
  onComplete: () => void;
}

const TimezoneAccount = ({ account, onComplete }: TimezoneAccountProps) => {
  const { t } = useMarkdownTranslate();
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'timezone',
    options: [
      {
        label: 'Timezone',
        value: 'timezone',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const [showDialog, toggleShowDialog] = useToggleReducer();

  const timezone = account.doc.timezone;

  const handleComplete = () => {
    toggleShowDialog();
    onComplete();
  };

  return (
    <SettingsPanel
      leftChild={
        <>
          <Setting
            label={'Timezone'}
            body={'Timezone where your business is located.'}
            iconButtonProps={{
              children: <EditPencil fr={undefined} />,
              onClick: toggleShowDialog,
              pill: true,
            }}
          />
          <TimezoneDialogAccount
            DialogProps={{
              open: showDialog,
            }}
            ContentProps={{
              onCancel: toggleShowDialog,
              onComplete: handleComplete,
            }}
          />
        </>
      }
      rightChild={
        timezone ? (
          <Table>
            <TableRow showBorder={false}>
              <TableCell className={`font-medium`}>
                {getTimezoneName(timezone).replace('_', ' ')}
              </TableCell>
            </TableRow>
          </Table>
        ) : (
          <Alert
            reverse
            label={'No timezone set'}
            body={
              'A timezone has not been specified for your account. This may cause unexpected behavior.'
            }
            icon={<WarningTriangle fr={undefined} />}
            color={'attention'}
            actionProps={{
              onClick: toggleShowDialog,
              children: 'Set timezone',
            }}
          />
        )
      }
    />
  );

  // return (
  //   <>
  //     <TimezoneDialogAccount
  //       DialogProps={{
  //         open: showDialog,
  //       }}
  //       ContentProps={{
  //         onCancel: toggleShowDialog,
  //         onComplete: handleComplete,
  //       }}
  //     />
  //     {/*@ts-ignore*/}
  //     <div ref={ref}>
  //       <HighlightPulse {...highlightPulseProps} />
  //       <Grid container justifyContent="space-between">
  //         <Grid item xs={6}>
  //           <Grid container spacing={1}>
  //             <Grid item>
  //               <span className={'text-lg font-medium text-neutral-500'}>
  //                 Timezone{' '}
  //                 <InfoTooltip
  //                   // @ts-ignore
  //                   title={t('timezone.company_dashboard.tooltip')}
  //                   size={'small'}
  //                 />
  //               </span>
  //             </Grid>
  //           </Grid>
  //           {timezone ? (
  //             <span className={'text-lg font-medium'}>
  //               {getTimezoneName(timezone).replace('_', ' ')}
  //             </span>
  //           ) : (
  //             <Typography
  //               variant="h4"
  //               sx={{ fontStyle: 'italic', color: 'text.secondary' }}
  //             >
  //               Not set...
  //             </Typography>
  //           )}
  //         </Grid>
  //         <Grid item>
  //           <Button
  //             variant={'ghost'}
  //             color={'accent'}
  //             // variant="body2"
  //             onClick={toggleShowDialog}
  //             // underline="hover"
  //             className={'font-sans text-content-access'}
  //           >
  //             Edit Timezone
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </>
  // );
};

export default TimezoneAccount;
