import React, { useEffect, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { NextModuleButton } from './NextModuleButton';
import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { ModifyMatchPopoverContent } from '../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/OptionMatchText/OptionMatchText';
import { setAtPath } from '../../../../../utilities';
import { HookFormListbox } from '../../../../../components/reactHookFormComponents/HookFormListbox';
import { HookFormTextField } from '../../../../../components/reactHookFormComponents/HookFormTextField';
import { Listbox, TextField } from '../../../../../design-lib';
import { useDebounce } from 'react-use';

export const MatchTextOptionModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { moduleItem, modifyPath, targetIdx } = selectedElement.data;

  const match =
    selectedElement.data?.matchTextData?.moduleItem?.data?.targets[targetIdx]
      ?.match;

  const handleUpdateType = type => {
    setAtPath(callflow, `${modifyPath}.${targetIdx}.match`, { ...match, type });
    setCallflow({ ...callflow });
  };

  const [text, setTextValue] = useState(match.data.text);
  const [debouncedInput, setDebouncedInput] = useState(text);
  useDebounce(() => setDebouncedInput(text), 250, [text]);

  useEffect(() => {
    setAtPath(callflow, `${modifyPath}.${targetIdx}.match.data`, {
      ...match.data,
      text: debouncedInput,
    });
    setCallflow({ ...callflow });
  }, [debouncedInput]);

  return (
    <>
      <Listbox
        onChange={handleUpdateType}
        value={match.type}
        label={'Match type'}
        size={'md'}
        className={`w-full !mb-4`}
        name={'type'}
        options={[
          { value: 'exact', label: 'Exact' },
          // { value: 'startsWith', label: 'Starts With' },
          { value: 'contains', label: 'Contains' },
        ]}
      />
      <br />
      <TextField
        onChange={e => setTextValue(e.target.value)}
        className={`!mt-4`}
        name={'data.text'}
        value={text}
        label={'Match text'}
        placeholder={'match text...'}
      />
      <div className={`flex flex-wrap mt-4 justify-between`}>
        <NextModuleButton />
      </div>
    </>
  );
};
