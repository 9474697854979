import { useBuilderContext } from '../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { useCallRouteEditor } from './CallflowContext';
import { getAtPath } from '../../../../utilities';
import {
  Calendar,
  Cancel,
  Check,
  Clock,
  DeleteCircle,
  TimerOff,
} from 'iconoir-react';
import { SidebarButton } from './ModuleButton';
import { AddModuleButton } from './AddModuleButton';
import React, { ReactElement } from 'react';
import { MenuKeyMap } from './CallflowSidebar';
import { SidebarSeparator } from './SidebarSeparator';
import CheckIcon from '@mui/icons-material/Check';
import { startCase } from 'lodash';

export const MatchTextOptionButton = ({ route, expand, rootPath, modules }) => {
  const { selectedElementKey, setSelectedElementKey, invalidModules } =
    useBuilderContext();
  const { selectedElement, elements, callflow } = useCallRouteEditor();

  let name;
  let icon: ReactElement;
  let targetPath;
  if (route.includes('no_match')) {
    icon = <Cancel fr={undefined} />;
    targetPath = rootPath + route.split('no_match')[0] + 'no_match';
    const targetModule = getAtPath(callflow, targetPath);
    name = targetModule?.name ?? 'No Match';
  } else {
    icon = <Check fr={undefined} />;
    const segments = route.split('.');
    const targetIndex = segments.findIndex(val => val === 'targets') + 1;
    const target = segments[targetIndex];
    targetPath =
      rootPath + segments.slice(0, targetIndex).join('.') + '.' + target;
    const targetModule = getAtPath(callflow, targetPath);

    name =
      targetModule?.name ?? `${startCase(targetModule?.match?.type)} Match`;
  }

  const targetModule = getAtPath(callflow, targetPath);

  // switch (target) {
  //   case 'fallback':
  //     icon = <DeleteCircle fr={undefined} />;
  //     name = schedule?.fallback.name ?? 'Fallback';
  //     break;
  //   default:
  //     icon = <Calendar fr={undefined} />;
  //     name =
  //       schedule?.categories.find(cat => cat.id === target)?.name ?? `Route`;
  //     break;
  // }

  const targetModulesPath =
    targetPath + '.callflow.message_strategy.data.modules';

  const nextElement = modules?.length
    ? null
    : elements.find(elem => elem.data?.modifyPath === targetPath + '.callflow');

  return (
    <>
      <SidebarButton
        expand={expand}
        onClick={() => {
          setSelectedElementKey(targetModulesPath);
        }}
        isActive={rootPath + route === selectedElementKey}
        icon={icon}
        name={targetModule?.name || name}
      />
      {!nextElement ? null : (
        <>
          {nextElement?.type === 'InsertNode' ? (
            <>
              <SidebarSeparator />
              <AddModuleButton expanded={expand} data={nextElement.data} />
            </>
          ) : null}
        </>
      )}
    </>
  );
};
