import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from 'app/design';
import {
  ExpandLess as ExpandLessIcon,
  Person as PersonIcon,
} from 'app/design/icons-material';
import { QuickFinderUserDialog } from 'app/components/QuickFinderUserDialog';
import { useListUsersQuery, useUserQuery } from 'app/hooks/queries/user';
// import { UserCallerIdCard } from 'app/pages/admin_old/Users/View/components/UserCallerIdCard';
// import { UserSettingsCard } from 'app/pages/admin_old/Users/View/components/UserSettingsCard';
import { getAtPath, setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NodeEditDialog } from '../../IvrBuilder/Dialogs';
import { NodeInfoDisplay } from '../../IvrBuilder/Flow/nodes/common/NodeInfoDisplay';
import { Link as RouterLink } from 'react-router-dom';
import { Listbox, SquareProgress } from 'app/design-lib';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { WarningTriangle } from 'iconoir-react';
import { useBuilderContext } from 'app/components/IvrBuilder/IvrBuilder';
import { UserAvatar } from 'app/components/Sidebar/Sidebar';
import { Button } from 'app/design-lib';
// import Select from 'react-select';

// Select users from list of users
export const User = props => {
  const {
    skipEditing,
    modifyPath,
    callflow,
    setCallflow,
    variant,
    showModifyDialog,
    setShowModifyDialog,
  } = props;

  const dataModifyPath = `${modifyPath}.data`;
  const { setDisableScroll } = useBuilderContext();
  const moduleItem = getAtPath(callflow, modifyPath, {}); // { ring_type: 'custom', data: {...} }
  const ringItem = moduleItem?.data;

  // console.log('moduleItem:', moduleItem);
  /*
  moduleItem = {
    type: 'RingStrategy',
    data: {
      ring_type: 'user',
      data: {
        userId: 'xyz',
        timeout: 10
      }
    }
  }
  */

  // const {
  //   data: user,
  //   isLoading: userIsLoading,
  //   error: userError,
  //   refetch: refetchUser,
  // } = useUserQuery(ringItem?.data?.id);

  const { data: usersResp, isLoading: usersIsLoading } = useListUsersQuery({
    // mask: 'id,extra(fullName)', // mask
    // quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp ?? {};

  // console.log('user resp', usersResp);

  // console.log('ring user:', user);

  const [isModifying, setIsModifying] = useState(null);

  useEffect(() => {
    if (setShowModifyDialog) {
      // parent is managing "display" state, NOT this component
      // setShowModifyDialog()
      setIsModifying(showModifyDialog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModifyDialog]);

  const { invalidModules, setInvalidModules } = useBuilderContext();
  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${dataModifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  const handleShowModifying = value =>
    setShowModifyDialog ? setShowModifyDialog(value) : setIsModifying(value);

  const user = users.find(user => user.id === ringItem?.data?.id);

  return (
    <div className={`w-full flex justify-center px-2`}>
      {usersIsLoading ? (
        <SquareProgress size={'sm'} />
      ) : users.length ? (
        skipEditing ? (
          user ? (
            <div className={`flex space-x-2 py-2 min-w-0 text-sm items-center`}>
              <UserAvatar size={'sm'} user={user} />
              <span className={'truncate'}>{user.extra.fullName}</span>
            </div>
          ) : (
            <Button
              color={'neutral'}
              variant={'ghost'}
              size={'sm'}
              className={`self-center`}
              startIcon={<WarningTriangle fr={undefined} />}
              // onClick={() => setShowModifyDialog(true)}
              disabled
            >
              not assigned
            </Button>
          )
        ) : (
          <Listbox
            placeholder="Select a User"
            onOpenChanged={setDisableScroll}
            size={'sm'}
            className={`w-full mt-1 self-center`}
            options={users.map(({ id, extra: { fullName } }) => ({
              value: id,
              label: fullName!,
            }))}
            value={ringItem?.data?.id}
            onChange={id => {
              handleUpdate({
                ...ringItem,
                data: {
                  id,
                  timeout: timeoutDefault,
                },
              });
            }}
          />
        )
      ) : (
        <Button
          color={'neutral'}
          variant={'ghost'}
          size={'sm'}
          className={`self-center`}
          startIcon={<WarningTriangle fr={undefined} />}
          // onClick={() => setShowModifyDialog(true)}
          disabled
        >
          no users
        </Button>
      )}
    </div>
  );

  // if (variant === 'ivr_builder') {
  //   return (
  //     <>
  //       {isModifying && (
  //         <ModifyRingSettings
  //           ringInfo={ringItem}
  //           onChangeRingInfo={handleUpdate}
  //           onClose={e => handleShowModifying(null)}
  //         />
  //       )}
  //       <NodeInfoDisplay
  //         type={'Ring User'}
  //         icon={<PersonIcon />}
  //         title={
  //           user ? (
  //             user.extra?.fullName
  //           ) : skipEditing ? (
  //             'None Selected'
  //           ) : (
  //             <Link onClick={() => handleShowModifying(true)}>Select User</Link>
  //           )
  //         }
  //         footer={
  //           ringItem?.data?.timeout &&
  //           user &&
  //           `${ringItem?.data?.timeout} seconds`
  //         }
  //       />
  //     </>
  //   );
  // }
  //
  // return (
  //   <>
  //     {isModifying && (
  //       <ModifyRingSettings
  //         ringInfo={ringItem}
  //         onChangeRingInfo={handleUpdate}
  //         onClose={e => handleShowModifying(null)}
  //       />
  //     )}
  //     <Paper variant="outlined" elevation={0}>
  //       {user ? (
  //         <>
  //           <Typography variant="body1">
  //             Ringing <strong>{user.extra.fullName}</strong>
  //           </Typography>
  //           <Typography variant="subtitle1">
  //             {ringItem?.data?.timeout} seconds
  //           </Typography>
  //         </>
  //       ) : (
  //         <Typography variant="body1" style={{ fontStyle: 'italic' }}>
  //           No user chosen for ringing
  //         </Typography>
  //       )}
  //       <div>
  //         <Link onClick={e => handleShowModifying(true)}>Modify</Link>
  //       </div>
  //     </Paper>
  //   </>
  // );
};

const timeoutDefault = 20;

const ModifyRingSettings = props => {
  const { ringInfo, onChangeRingInfo, onClose } = props;

  const [modifyData, setModifyData] = useState(() => cloneDeep(ringInfo));

  const userId = modifyData?.data?.id;

  const {
    data: user,
    isLoading: userIsLoading,
    error: userError,
    refetch: refetchUser,
  } = useUserQuery(userId);

  // defaults
  useEffect(() => {
    // timeout
    if (!getAtPath(modifyData, `data.timeout`)) {
      setAtPath(modifyData, `data.timeout`, timeoutDefault);
      setModifyData({ ...modifyData });
    }
  }, [modifyData, setModifyData]);

  const [initialState] = useState({
    id: ringInfo?.data?.id,
    timeout: ringInfo?.data?.timeout ?? timeoutDefault,
  });

  const saveLabel = !userId
    ? 'Leave Empty'
    : !initialState.id
    ? 'Add'
    : initialState.id === userId &&
      Number(initialState.timeout) === Number(modifyData?.data?.timeout)
    ? 'Done'
    : 'Update';

  // Finder Dialog
  const [showFinderDialog, toggleShowFindUserDialog] = useToggleReducer(
    !userId,
  );

  const onChangeUserSelect = selected => {
    const [userId] = selected;
    setAtPath(modifyData, `data.id`, userId); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindUserDialog();
  };

  const handleClear = () => {
    setAtPath(modifyData, `data.id`, null); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindUserDialog();
  };

  const [showAllSettings, toggleShowAllSettings] = useToggleReducer(false);

  return (
    <>
      <NodeEditDialog
        title={'Edit User Ringing'}
        maxWidth={showAllSettings ? '4xl' : 'xl'}
        onClose={onClose}
        onCancel={onClose}
        onSave={() => {
          onChangeRingInfo(modifyData);
          onClose();
        }}
        titleButton={
          user ? (
            <Button onClick={toggleShowFindUserDialog}>Change User</Button>
          ) : null
        }
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        isLoading={userIsLoading}
        errorMessage={userError ? 'Failed loading selected user' : undefined}
      >
        {user ? (
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <span className={'text-lg font-medium'}>User Settings</span>
              <Divider />
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <UserSettings
                    {...props}
                    modifyData={modifyData}
                    setModifyData={setModifyData}
                    user={user}
                  />
                </Grid>
                {showAllSettings ? (
                  <Grid item>
                    <Button onClick={toggleShowAllSettings}>
                      <ExpandLessIcon />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              {showAllSettings ? (
                <>
                  <Box sx={{ paddingX: 2 }}>
                    {/* <UserSettingsCard user={user} onComplete={refetchUser} />
                    <br />
                    <UserCallerIdCard user={user} onComplete={refetchUser} /> */}
                  </Box>
                  <br />
                  <Divider />
                </>
              ) : null}
              <br />
              <Link underline={'hover'} onClick={toggleShowAllSettings}>
                {showAllSettings
                  ? 'Hide additional settings'
                  : 'Show all settings'}
              </Link>
            </Grid>
            <Grid item xs={5}>
              <span className={'text-lg font-medium'}>Ring Settings</span>
              <Divider />
              <RingSettings
                {...props}
                modifyData={modifyData}
                setModifyData={setModifyData}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No User Selected...
            </Typography>
            <Button onClick={toggleShowFindUserDialog}>Choose User</Button>
          </>
        )}
      </NodeEditDialog>
      <QuickFinderUserDialog
        open={showFinderDialog}
        onSelect={onChangeUserSelect}
        onCancel={toggleShowFindUserDialog}
        onClear={handleClear}
        initialSelected={userId ? [userId] : []}
        selectionTitle={user ? user.extra.fullName : null}
      />
    </>
  );
};

const UserSettings = ({ modifyData, setModifyData, user }) => {
  const userId = modifyData?.data?.id;
  console.log('UserId:', userId, modifyData);

  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <span
        className={'text-lg'}
        // variant="h1"
        // to={`/admin/users/view/${userId}`}
        // underline="hover"
        // color={'text.primary'}
        // component={RouterLink}
      >{`${user.extra.fullName}`}</span>
    </Box>
  );
};

const RingSettings = ({ modifyData, setModifyData }) => {
  const handleChangeText = e => {
    setAtPath(modifyData, `data.timeout`, e.target.value); // newValue?.value === id
    setModifyData({ ...modifyData });
  };
  return (
    <Box sx={{ marginTop: 2 }}>
      <div>
        <TextField
          label="Ring Duration (seconds)"
          value={modifyData?.data?.timeout}
          onChange={handleChangeText}
          type="number"
          inputProps={{
            min: 0,
          }}
          size="small"
        />
      </div>
    </Box>
  );
};

export default User;
