import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Button, IconButton, Listbox, TextField } from 'app/design-lib';

import { Handle } from 'react-flow-renderer';
import { useDebounce, useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import { setAtPath, useToggleReducer } from '../../../../../utilities';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';
import { Box } from '../common/Box';
// import { ModifyDialog } from './ModifyDialog';
import { MenuButton } from '../common/MenuButton';
import {
  ClockRotateRight,
  EditPencil,
  NavArrowLeft,
  NavArrowRight,
  WarningTriangle,
} from 'iconoir-react';
import { useBuilderContext } from '../../../MessageRoutingVisualBuilder';
import { CustomLastCommunicationDialog } from './CustomLastCommuncationDialog';
import { HookFormTextField } from '../../../../reactHookFormComponents/HookFormTextField';
import { startCase } from 'lodash';
import useEnsureModuleItemId from '../../../../IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { ModeSelectModuleThis } from '../common/ModeSelectModule';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditMessageRouting/MobileView/MiniMapNode';

// why use memo??
const LastCommunicatedNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // Load media via query
  // const {
  //   data: media,
  //   isLoading,
  //   isFetched,
  // } = useMediaByIdQuery(moduleItem?.data?.id);
  // const mediaUrl = useMediaUrl({ id:moduleItem?.data?.id });

  // add id if does not exist
  useEnsureModuleItemId(data);

  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);
  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {};

  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMatch = () => {
    let newText = prompt('Match', JSON.stringify(matches));
    if (!newText) {
      return false;
    }
    try {
      newText = JSON.parse(newText);
    } catch (err) {
      alert('Sorry, unable to parse that');
      return false;
    }
    setAtPath(callflow, `${modifyPath}.data.match`, newText);
    setCallflow({ ...callflow });
    // setShowModifyDialogWrap(null);
  };

  const handleAdd = value => {
    console.log('Matches:', matches, value);
    setAtPath(callflow, `${modifyPath}.data.match`, [...matches, value]);
    setCallflow({ ...callflow });
    // setShowModifyDialogWrap(null);
  };

  let matches = moduleItem?.data?.match ?? [];

  const { miniMap } = useBuilderContext();

  if (miniMap) {
    return <MiniMapNode pref={boxRef} data={data} />;
  }

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <ModeSelectModuleThis id={moduleItem?.id} modifyPath={modifyPath} />

      {/* <AudioDialog
        onComplete={mediaId => {
          setAtPath(callflow, `${modifyPath}.data.id`, mediaId);
          setCallflow({ ...callflow });
        }}
        onClose={() => setShowModifyDialog(false)}
        open={!!showModifyDialog}
        mediaId={mediaId}
      /> */}
      <div
        className={`flex flex-col items-center  whitespace-nowrap w-full pt-4 px-2 pb-2`}
      >
        <div className={`flex space-x-2 text-neutral-60`}>
          <div
            className={`text-sm flex items-center space-x-2 whitespace-nowrap`}
          >
            <ClockRotateRight width={14} height={14} />
            <span>Communicated within</span>
          </div>
        </div>
        <div
          className={`text-md w-full flex flex-col text-center justify-center font-medium`}
        >
          {matches.length ? (
            <div className="my-2 space-y-4">
              {matches.map((match, idx) => {
                return (
                  <MatchCondition
                    key={idx}
                    idx={idx}
                    conditionPath={idx}
                    match={match}
                    data={data}
                  />
                );
              })}
            </div>
          ) : null}
          {/*
          {skipEditing ? null : (
            <IconButtonDropdown
              variant={'ghost'}
              subArrowRight
              // onClick={() => handleAdd([ringOptions[0], terminationOptions[1]])}
              pill
              align={'left'}
              color={'neutral'}
              className={`opacity-50 hover:opacity-100 my-2 mx-auto`}
              size={'sm'}
              // disabled={skipEditing}
              menuItems={[
                {
                  label: 'Time since last communicated',
                  onClick: () =>
                    handleAdd({
                      type: 'time_since_communication',
                      data: {
                        timeframe: 'never',
                      },
                    }),
                },
                // {
                //   label: 'Time since last sent',
                //   onClick: () =>
                //     handleAdd({
                //       type: 'time_since_message_sent',
                //     }),
                // },
                // {
                //   label: 'Time since last call',
                //   onClick: () =>
                //     handleAdd({
                //       type: 'time_since_recent_call',
                //     }),
                // },
              ]}
            >
              <PlusIcon />
            </IconButtonDropdown>
          )}
*/}
          {/* {mediaId ? (
            <AudioPlayer
              mini
              variant={'ghost'}
              mediaId={mediaId}
              onEdit={skipEditing ? undefined : () => setShowModifyDialog(true)}
            />
          ) : (
            <Button
              color={'attention'}
              variant={'ghost'}
              size={'sm'}
              className={`self-center`}
              startIcon={<WarningTriangle />}
              onClick={() => setShowModifyDialog(true)}
              disabled={skipEditing}
            >
              {skipEditing ? 'No' : 'Add'} audio
            </Button>
          )} */}
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );
});

const MatchCondition = ({ data, idx, conditionPath, match }) => {
  switch (match?.type || match) {
    case 'time_since_communication':
      return (
        <Condition_time_since_communication
          {...{ data, idx, conditionPath, match }}
        />
      );
  }
  return <div className="">Unknown match type</div>;
};

const Condition_time_since_communication = ({
  data,
  idx,
  conditionPath,
  match,
}) => {
  // const { setDisableScroll } = useBuilderContext();
  const { skipEditing, moduleItem, callflow, setCallflow, modifyPath } = data;
  // const [showCustomDialog, toggleCustomDialog] = useToggleReducer();

  // get the most recent communication time
  // - "how long since we last communicated over phone/message" (checks PipeMessage types "call" and "message" for most recent)

  // TODO: use date-fns for start/end of dates (today, yesterday)
  // const items = [
  //   {
  //     text: 'Never',
  //     value: 'never',
  //   },
  //   {
  //     text: '5 min',
  //     value: [
  //       [
  //         'sub',
  //         {
  //           minutes: 5,
  //         },
  //       ],
  //     ],
  //   },
  //   {
  //     text: '30 min',
  //     value: [
  //       [
  //         'sub',
  //         {
  //           minutes: 30,
  //         },
  //       ],
  //     ],
  //   },
  //   {
  //     text: '1 hour',
  //     value: [
  //       [
  //         'sub',
  //         {
  //           hour: 1,
  //         },
  //       ],
  //     ],
  //   },
  //   {
  //     text: 'Today',
  //     value: [['startOfDay']],
  //   },
  //   {
  //     text: 'Yesterday',
  //     value: [['startOfYesterday']],
  //   },
  //   {
  //     text: '90 Days',
  //     value: [
  //       [
  //         'sub',
  //         {
  //           days: 90,
  //         },
  //       ],
  //     ],
  //   },
  // ];

  const timeframe = match?.data?.timeframe;
  // console.log('timeframe', timeframe);

  const handleUpdateTimeframe = field => {
    // preserve value if set
    const value =
      timeframe === 'never' ? 0 : Object.values(timeframe?.[0]?.[1])?.[0] ?? 0;

    setAtPath(
      callflow,
      `${modifyPath}.data.match.${conditionPath}.data.timeframe`,
      field === 'never' ? field : [['sub', { [`${field}`]: value }]],
    );
    setCallflow({ ...callflow });
  };

  const handleUpdateValue = value => {
    // preserve field if set
    const field =
      timeframe === 'never' ? 0 : Object.keys(timeframe?.[0]?.[1])?.[0] ?? 0;

    setAtPath(
      callflow,
      `${modifyPath}.data.match.${conditionPath}.data.timeframe`,
      field === 'never' ? field : [['sub', { [`${field}`]: value }]],
    );
    setCallflow({ ...callflow });
  };

  return (
    <div className=" flex flex-col items-center justify-center w-full">
      {/* <CustomLastCommunicationDialog
        timeframe={match?.data?.timeframe}
        onComplete={handleUpdateTimeframe}
        open={showCustomDialog}
        onClose={toggleCustomDialog}
      />*/}
      <ModifyLastCommunicated
        field={
          timeframe === 'never'
            ? timeframe
            : Object.keys(timeframe?.[0]?.[1])?.[0] ?? 'never'
        }
        updateField={handleUpdateTimeframe}
        value={
          timeframe === 'never' ? 0 : Object.values(timeframe?.[0]?.[1])?.[0]
        }
        updateValue={handleUpdateValue}
        disable={skipEditing}
      />
    </div>
  );
};

export default LastCommunicatedNode;

export const ModifyLastCommunicated = ({
  field,
  updateField,
  value: originalValue,
  updateValue,
  disable,
}) => {
  const { setDisableScroll } = useBuilderContext();
  const [value, setValue] = useState(originalValue);

  useDebounce(
    () => {
      if (value !== originalValue) updateValue(value);
    },
    2000,
    [value],
  );

  const { miniMap } = useBuilderContext();

  return (
    <div
      className={`flex flex-col ${
        miniMap ? 'mb-4' : ''
      } space-y-2 w-full items-center mt-2`}
    >
      <Listbox
        label={miniMap ? 'Last Communication' : undefined}
        disabled={disable}
        onOpenChanged={setDisableScroll}
        className={`w-full`}
        options={[
          'never',
          'seconds',
          'minutes',
          'hours',
          'days',
          'weeks',
          'months',
          'years',
        ].map(value => ({ value, label: startCase(value) }))}
        onChange={updateField}
        value={field}
      />
      {field !== 'never' ? (
        <div className={`flex space-x-1 items-center`}>
          {disable ? null : (
            <IconButton
              onClick={() => {
                setValue(value - 1);
              }}
              disabled={value <= 0}
              variant={`ghost`}
              color={'accent'}
            >
              <NavArrowLeft fr={undefined} />
            </IconButton>
          )}
          <div className={`w-16`}>
            <TextField
              disabled={disable}
              value={value}
              onChange={e => setValue(Number.parseInt(e.target.value))}
              className={`text-sm h-8 w-16`}
              // name={`timeframe.${field}`}
              // label={startCase(field)}
              type={'number'}
            />
          </div>
          {disable ? null : (
            <IconButton
              onClick={() => {
                setValue(value + 1);
              }}
              variant={`ghost`}
              color={'accent'}
            >
              <NavArrowRight fr={undefined} />
            </IconButton>
          )}
        </div>
      ) : null}
    </div>
  );
};
