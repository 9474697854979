import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonDropdownMenu,
} from 'app/design';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  Add as AddIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities/utils';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';

import { InTemplate } from '../common/InTemplate';
import { Button, ButtonDropdown } from 'app/design-lib';
// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';

import { ModifyDialog, useKeyInputDialog } from '../Menu/ModifyDialog';
import { Plus } from 'iconoir-react';
import { useBuilderContext } from '../../../IvrBuilder';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditCallRouting/MobileView/MiniMapNode';

////////////////////////
// THIS IS OLD (well, the InsertNode isnt, but the rest is)!!!!!
// - Use the "MenuButton" from "./nodes/common/MenuButton" instead?

const InsertMenuOptionNode = memo(({ data }) => {
  const {
    skipEditing,
    callflow: rootCallflow,
    setCallflow,
    modifyPath,
    index,
    requireAllowBefore, // always false
    requireAllowAfter,
    onClose,
    onAdd, // replaces handleAdd from below!
    templateParent,
    templateRef,
    menuData,
    fix,
    fixData,
    fixBySpliceIdx,
  } = data;

  const [showAdd, setShowAdd] = useState(null);
  const setShowAddWrap = setTo => () => setShowAdd(setTo);

  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const sharedFlowState = sharedFlow?.state;

  const {
    Dialog: InputDialog,
    DialogProps: InputDialogProps,
    toggleOpen: toggleInputDialogOpen,
  } = useKeyInputDialog();

  let icon = null, //<AddIcon />,
    canMove = true;
  if (fix) {
    icon = <LocalHospitalOutlinedIcon />;
  } else if (
    // determine if after or on same level as trying to move-to
    sharedFlow?.state === 'move-to' &&
    (onAdd ||
      `${modifyPath}.strategy.data.modules`.indexOf(
        sharedFlow?.data?.pathLevel,
      ) > -1)
  ) {
    icon = <CloseIcon />;
    canMove = false;
  } else if (
    (sharedFlow?.state === 'duplicate-to' ||
      sharedFlow?.state === 'paste-to') &&
    onAdd
  ) {
    icon = <CloseIcon />;
  }

  const duplicateHere = () => {
    alert('unable to duplicate here');
  };

  const pasteHere = () => {
    alert('unable to paste here');
  };

  const moveHere = () => {
    alert('Unable to move a branch to here');
  };

  const handleClick = () => {
    // // if (rootCallflow.type != 'template' && templateParent) {
    // //   alert(
    // //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
    // //   );
    // // }
    // switch (sharedFlow?.state) {
    //   case 'duplicate-to':
    //     if (onAdd) {
    //       alert('Unable to duplicate here');
    //       return;
    //     }
    //     duplicateHere();
    //     break;
    //   case 'paste-to':
    //     if (onAdd) {
    //       alert('Unable to copy here');
    //       return;
    //     }
    //     pasteHere();
    //     break;
    //   case 'move-to':
    //     if (onAdd) {
    //       alert('Unable to move here');
    //       return;
    //     }
    //     moveHere();
    //     break;
    //   default:
    //     if (onAdd) {
    //       onAdd(); // TODO: finish (used by Menu, and nothing else?)
    //     } else {
    //       setShowAdd(true);
    //     }
    // }
    setShowAdd(true);
  };

  // const handleFix = () => {
  //   const blankCallflow = {
  //     id: 'inline',
  //     flow: {},
  //     numbers: [],
  //     strategy: {
  //       id: 'blank',
  //       config: {
  //         components: [],
  //       },
  //       data: {
  //         opts: [],
  //       },
  //     },
  //   };
  //   if (fixBySpliceIdx > -1) {
  //     let newArr = getAtPath(callflow, modifyPath, []);
  //     newArr.splice(fixBySpliceIdx, 0, fixData || blankCallflow);
  //     setAtPath(callflow, modifyPath, newArr);
  //     setCallflow({ ...callflow }, { name: 'Fix Missing' });
  //     return;
  //   }
  //   setAtPath(callflow, modifyPath, fixData || blankCallflow);
  //   setCallflow({ ...callflow }, { name: 'Fix Missing' });
  // };
  const handleAddKey = value => {
    console.log('menuData:', menuData, value);
    if (!menuData.moduleItem?.data?.targets?.hasOwnProperty(value)) {
      setAtPath(menuData.moduleItem, `data.targets`, {
        ...menuData.moduleItem.data.targets,
        [value]: INLINE_BLANK_CALLFLOW,
      });
      setAtPath(menuData.callflow, menuData.modifyPath, menuData.moduleItem);
      menuData.setCallflow({ ...menuData.callflow }, { name: 'Add Menu Flow' });
    }
    // toggleInputDialogOpen();
  };

  const { miniMap } = useBuilderContext();
  if (miniMap) {
    return <MiniMapNode data={data} type={'InsertMenuOption'} />;
  }

  return (
    <div
      style={{
        // width: 150,
        // height: 24,
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      {showAdd && (
        <ModifyDialog
          {...menuData}
          menuId={menuData?.moduleItem?.data?.id}
          initialInputString={'+'}
          // menu={menu}
          onClose={e => setShowAdd(null)}
        />
      )}
      <InputDialog
        {...InputDialogProps}
        onComplete={handleAddKey}
        onCancel={toggleInputDialogOpen}
        existingValues={[]}
      />
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        {icon ? (
          <IconButton
            disabled={skipEditing}
            // onClick={handleClick}
            onClick={toggleInputDialogOpen}
            size="small"
            variant="outlined"
          >
            {icon}
          </IconButton>
        ) : (
          <ButtonDropdown
            variant={'fill'}
            size={'sm'}
            color={'accent'}
            startIcon={<Plus />}
            // className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
            disabled={skipEditing}
            // onClick={handleClick}
            menuItems={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(val => ({
              label: (
                <>
                  <span className={`text-neutral-60`}>Dial: </span>
                  {`${val}`}
                </>
              ),
              onClick: () => handleAddKey(val),
            }))}
          >
            Add menu option
          </ButtonDropdown>
        )}
        {templateParent ? <InTemplate template={templateParent} /> : ''}
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    </div>
  );
});

export const INLINE_BLANK_CALLFLOW = {
  id: 'inline',
  strategy: {
    type: 'blank',
    data: {
      modules: [],
    },
  },
};

export default InsertMenuOptionNode;
