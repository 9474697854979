import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cloneDeep } from 'lodash';

import { Callflow, CallflowDoc } from '../../../../../types/callflow';
import walkStrategyModules from '../../../../components/IvrBuilder/Flow/walkStrategyModules';
import ConvertCallflowToFlowElements from '../../../../components/IvrBuilder/Flow/convertCallflowToFlowElements';
import eventEmitter from '../../../../components/IvrBuilder/eventEmitter';
import {
  useBuilderContext,
  useSharedFlow,
  useSharedFlowSetup,
} from 'app/components/IvrBuilder/IvrBuilder';
import { useSetEditingCallflowWrap } from '../../../../components/IvrBuilder/IvrBuilder';
import {
  useActiveState,
  useBuildFlow,
} from '../../../../components/IvrBuilder/Flow/flow';
import { useToggleReducer } from '../../../../utilities';

// @ts-ignore
const CallflowContext = createContext<any>();

export default function CallflowProvider({
  children,
  pipe,
  rootCallflow,
  editingCallflow,
  sharedFlow,
  setSharedFlow,
  setEditingCallflow,
  onSave,
  onCancel,
  elements,
}) {
  const { selectedElementKey, invalidModules, setInvalidModules, openMiniMap } =
    useBuilderContext();

  const selectedElement = selectedElementKey
    ? elements.find(elem => elem.key === selectedElementKey)
    : elements[0];
  // console.log('selected elem', selectedElementKey, elements);

  const elementMap = useMemo(() => {
    return new Map(
      elements.map(elem => {
        switch (elem.type) {
          case 'OptionNode':
            return [`${elem.data.modifyPath}.${elem.data.targetKey}`, elem];
          case 'OptionScheduleNode':
            // console.log('elem here', elem);
            return [`${elem.data.modifyPath}.${elem.data.category.id}`, elem];
          default:
            return [elem.key, elem];
        }
      }),
    );
  }, [elements]);

  return (
    <CallflowContext.Provider
      value={{
        pipe,
        callflow: editingCallflow,
        setCallflow: setEditingCallflow,
        selectedElement,
        // selectedIndex,
        // setSelectedIndex,
        elements,
        elementMap,
        invalidModules,
        setInvalidModules,
        // currentModule,
        // modifyPath: currentPath,
        // originalCallflow: callflow,
        // invalidModules,
        // setInvalidModules,
      }}
    >
      {children}
    </CallflowContext.Provider>
  );
}

export const useCallRouteEditor = () => useContext(CallflowContext);
