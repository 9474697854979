import DevicesIcon from '@mui/icons-material/Devices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { DeviceListOptions } from 'app/components/ListDevices/components/DeviceListOptions';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { DeviceType } from 'app/components/ListDevices/components/DeviceType';
import { Alert, Table, TableCell, TableRow } from 'app/design-lib';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { formatMacAddress } from 'app/pages/settings/Devices/Devices';
import { DeviceDisableSettings } from 'app/pages/settings/Devices/View/components/DeviceDisableSettings';
import { Plus as PlusIcon } from 'iconoir-react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import SimpleBar from 'simplebar-react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Box,
  Button,
  Typography,
  Grid,
  AnimationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogInProgress,
  DialogTitle,
} from 'app/design';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
  useLocation,
} from 'react-router-dom';

import useDevMode from 'app/utilities/useDevMode';
import useToggleReducer from 'app/utilities/useToggleReducer';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { useListDevicesQuery } from 'app/hooks/queries/device';
import { CreatePhonesBulkv2 } from 'app/components/CreatePhonesBulk';
import { MobileAppButton } from './MobileAppButton';
import { useMediaQuery } from '@mui/material';

function UserDevices() {
  // useRouteMatch() // no argument, returns current match? (but that is the PARENT Route, not the Setting Route...)

  // console.log('store', store.getState());

  const { devMode } = useDevMode();

  const {
    data: devicesPage,
    isLoading: devicesLoading,
    refetch,
  } = useListDevicesQuery({
    take: 500,
    filters: {
      raw: [
        [
          {
            variables: {
              docFilters: [
                // ['subtype', '!=', 'appphone'],
                // ['subtype', 'exists'],
                // ['subtype', '!=', 'webphone'],
                // ['subtype', '!=', 'appphone'],
                ['owner_id', '=', store.getState().auth.owner_id],
              ],
            },
          },
          // {
          //   variables: {
          //     docFilters: [['subtype', '!=', 'appphone']],
          //   },
          // },
        ],
        [
          {
            variables: {
              docFilters: [
                // ['device_type', '!=', 'appphone'],
                // ['subtype', 'exists'],
                ['subtype', '!=', 'webphone'],
              ],
            },
          },
          {
            or: [
              {
                variables: {
                  docFilters: [
                    // ['device_type', '!=', 'appphone'],
                    ['subtype', 'not_exists'],
                  ],
                },
              },
            ],
          },
        ],
        // AND (not a webphone, OR not specified at all)
        // or: [
        //   {
        //     variables: {
        //       docFilters: [
        //         // ['device_type', '!=', 'appphone'],
        //         // ['subtype', 'exists'],
        //         ['subtype', '!=', 'webphone'],
        //       ],
        //     },
        //   },
        //   {
        //     variables: {
        //       docFilters: [
        //         // ['device_type', '!=', 'appphone'],
        //         ['subtype', 'not_exists'],
        //       ],
        //     },
        //   },
        // ],
      ],
    },

    options: {},
  });
  const devices = devicesPage?.devices ?? [];
  const location = useLocation();

  const [openCreateDialog, toggleOpenCreateDialog] = useToggleReducer(false);
  const isLinkFocus = location.hash === `#mobile_app` ? true : false; // id matches hash

  const isSmallMobile = useMediaQuery('(max-width:475px)', { noSsr: true });

  return (
    <>
      <SettingsPageWrapper
        isLoading={devicesLoading}
        loadingLabel={'Loading devices...'}
        label={'My Devices'}
        subLabel={'Manage your personal external devices and third party apps'}
        buttonNode={
          isSmallMobile ? null : (
            <MobileAppButton
              className={[isLinkFocus ? 'checklist-highlight' : ''].join(' ')}
            />
          )
        }
      >
        {devices.length ? (
          <Table>
            {devices.map((device, index) => {
              // let onlineCount = 0;

              // Object.keys(device.doc.endpoints).forEach(member => {
              //   if (onlineUsers?.[member]) onlineCount++;
              // });
              //
              // const color = !device.doc.type
              //   ? 'negative'
              //   : onlineCount > 0
              //   ? 'positive'
              //   : 'attention';

              return (
                <TableRow
                  showBorder={devices.length > 1 && index < devices.length - 1}
                  key={device.id}
                >
                  <TableCell className={`font-medium flex flex-col`} flex>
                    <Link to={`/settings/devices/${device.id}`} className="">
                      {device.doc.name?.length ? device.doc.name : 'Unnamed'}
                    </Link>
                    {device.doc.device_type === 'sip_device' &&
                    device.doc.mac_address ? (
                      <span className={`text-sm text-neutral-60`}>
                        {formatMacAddress(device.doc.mac_address)}
                      </span>
                    ) : null}
                  </TableCell>
                  {isSmallMobile ? null : (
                    <TableCell flex>
                      <DeviceType device={device} />
                    </TableCell>
                  )}
                  {/*<TableCell flex>
                    <DeviceOwnerCell device={device} owner={device.Owner} />
                    <UsersAndDevicesCell device={device} />
                  </TableCell>*/}
                  <TableCell>
                    {/* {device.doc.enabled ? null : (
                              <div className="w-min select-none bg-background-negative-secondary whitespace-nowrap text-content-negative text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                                DISABLED
                              </div>
                            )} */}
                    <DeviceDisableSettings deviceDoc={device.doc} />
                    {/* <div className="h-full whitespace-nowrap flex items-center">
                              <button
                                // @ts-ignore
                                onClick={e => editDeviceSet(device)}
                                type="button"
                                className={`cursor-pointer
                                bg-background-${color}-secondary
                                text-content-${color}
                                border-none
                                text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
                              >
                                {!device.doc.type
                                  ? 'REQUIRES SET UP'
                                  : `${onlineCount} MEMBER${
                                      onlineCount > 1 || onlineCount === 0
                                        ? 'S'
                                        : ''
                                    } ONLINE`}*/}
                    {/*</button>*/}
                    {/*</div>*/}
                  </TableCell>
                  <TableCell>
                    <div className="h-full flex items-center">
                      {/* <button>Actions</button> */}
                      <DeviceListOptions
                        onDelete={() => {
                          refetch();
                        }}
                        device={device}
                        onComplete={() => {
                          refetch();
                        }}
                        toggleRowSelected={() => {}}
                        // vertical={true}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <Alert
            reverse
            label={'No personal devices'}
            body={
              'No personal devices could be found associated with your account'
            }
          />
        )}
      </SettingsPageWrapper>
    </>
  );

  // return (
  //   <>
  //     <div
  //       className={`max-w-[650px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //     >
  //       <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
  //         <SimpleBar style={{ height: '100%' }}>
  //           <div className={'px-8 py-3 h-full'}>
  //             <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
  //               <div className={'flex  justify-between items-start space-x-2'}>
  //                 <div
  //                   className={
  //                     'btn-accent-outline mt-[5px] text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
  //                   }
  //                 >
  //                   <DevicesIcon
  //                     className={'fill-content-accent'}
  //                     sx={{ fontSize: 'inherit' }}
  //                   />
  //                 </div>
  //                 <div>
  //                   <div className="font-bold text-xl">
  //                     My Phones and Devices
  //                   </div>
  //                   <div className="text-base text-neutral-500">
  //                     Contact an administrator if you want to add or remove
  //                     devices
  //                   </div>
  //                 </div>
  //               </div>
  //               {/*<Button
  //                 color={'accent'}
  //                 size={'sm'}
  //                 variant={'outline'}
  //                 className={'h-min self-end mb-1'}
  //                 onClick={toggleOpenCreateDialog}
  //                 startIcon={<PersonAddIcon />}
  //               >
  //                 Add Device
  //               </Button>*/}
  //             </div>
  //             <div className="p-3 h-full">
  //               <table className="w-full table-fixed border-separate border-spacing-y-3">
  //                 <tbody className="space-y-4">
  //                   {devices.map(device => {
  //                     // let onlineCount = 0;
  //
  //                     // Object.keys(device.doc.endpoints).forEach(member => {
  //                     //   if (onlineUsers?.[member]) onlineCount++;
  //                     // });
  //                     //
  //                     // const color = !device.doc.type
  //                     //   ? 'negative'
  //                     //   : onlineCount > 0
  //                     //   ? 'positive'
  //                     //   : 'attention';
  //
  //                     return (
  //                       <tr
  //                         key={device.id}
  //                         className="flex  items-center justify-between border-solid border-0 border-b pb-4 border-border-neutral/50"
  //                       >
  //                         <td className={'w-1/5'}>
  //                           <div className="flex items-center space-x-6">
  //                             <div className="">
  //                               <Link
  //                                 to={`/v2/settings/devices/${device.id}`}
  //                                 className="cursor-pointer font-semibold text-content-accent no-underline"
  //                               >
  //                                 {device.doc.name?.length
  //                                   ? device.doc.name
  //                                   : 'Unnamed'}
  //                               </Link>
  //
  //                               <div className="text-neutral-400">
  //                                 {<DeviceType device={device} />}
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </td>
  //                         <td className={'w-1/3'}>
  //                           <DeviceOwnerCell
  //                             className={'justify-center'}
  //                             device={device}
  //                             owner={device.Owner}
  //                           />
  //                           {/*<UsersAndDevicesCell device={device} />*/}
  //                         </td>
  //                         <td className={'w-1/6'}>
  //                           {device.doc.enabled ? null : (
  //                             <div className="w-min select-none bg-background-negative-secondary whitespace-nowrap text-content-negative text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
  //                               DISABLED
  //                             </div>
  //                           )}
  //                           {/* <div className="h-full whitespace-nowrap flex items-center">
  //                             <button
  //                               // @ts-ignore
  //                               onClick={e => editDeviceSet(device)}
  //                               type="button"
  //                               className={`cursor-pointer
  //                               bg-background-${color}-secondary
  //                               text-content-${color}
  //                               border-none
  //                               text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800`}
  //                             >
  //                               {!device.doc.type
  //                                 ? 'REQUIRES SET UP'
  //                                 : `${onlineCount} MEMBER${
  //                                     onlineCount > 1 || onlineCount === 0
  //                                       ? 'S'
  //                                       : ''
  //                                   } ONLINE`}*/}
  //                           {/*</button>*/}
  //                           {/*</div>*/}
  //                         </td>
  //                         <td>
  //                           <div className="h-full flex items-center">
  //                             {/* <button>Actions</button> */}
  //                             {/*<DeviceListOptions
  //                               onDelete={() => {
  //                                 refetch();
  //                               }}
  //                               device={device}
  //                               onComplete={() => {
  //                                 refetch();
  //                               }}
  //                               toggleRowSelected={() => {}}
  //                               // vertical={true}
  //                             />*/}
  //                           </div>
  //                         </td>
  //                       </tr>
  //                     );
  //                   })}
  //                 </tbody>
  //               </table>
  //               {devicesLoading ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   Loading Devices...
  //                 </div>
  //               ) : null}
  //               {!devicesLoading && !devices?.length ? (
  //                 <div className={'pt-5 text-neutral-400 w-full text-center'}>
  //                   No devices have been created yet
  //                 </div>
  //               ) : null}
  //               {/*<div>*/}
  //               {/*  <div className="">*/}
  //               {/*    <div className="text-2xl" onClick={() => refetch()}>*/}
  //               {/*      Devices*/}
  //               {/*    </div>*/}
  //               {/*    <div className="flex justify-between">*/}
  //               {/*      <div className="text-md mb-2 text-neutral-500">*/}
  //
  //               {/*      </div>*/}
  //               {/*      <div className="">*/}
  //               {/*        <button*/}
  //               {/*          className="btn whitespace-nowrap"*/}
  //               {/*          onClick={toggleOpenCreateDialog}*/}
  //               {/*        >*/}
  //               {/*        </button>*/}
  //               {/*      </div>*/}
  //               {/*    </div>*/}
  //               {/*  </div>*/}
  //               {/*  <div className="p-4 space-y-4">*/}
  //               {/*{devices.map(device => {*/}
  //               {/*  return (*/}
  //               {/*    <div key={device.id} className="flex justify-between ">*/}
  //               {/*      <div className="flex">*/}
  //               {/*        <div className="">*/}
  //               {/*          <div className="font-semibold mr-4">*/}
  //               {/*            {device.doc.device_type}*/}
  //               {/*          </div>*/}
  //               {/*        </div>*/}
  //               {/*        <div className="">*/}
  //               {/*          <div className="font-semibold">*/}
  //               {/*            <Link*/}
  //               {/*              to={`/v2/settings/devices/${device.id}`}*/}
  //               {/*              className="cursor-pointer"*/}
  //               {/*            >*/}
  //               {/*              {device.doc.name?.length*/}
  //               {/*                ? device.doc.name*/}
  //               {/*                : 'Unnamed'}*/}
  //               {/*            </Link>*/}
  //               {/*          </div>*/}
  //               {/*          <div className="text-neutral-400">*/}
  //               {/*            {device.doc.owner_id}*/}
  //               {/*          </div>*/}
  //               {/*        </div>*/}
  //               {/*      </div>*/}
  //               {/*      <div>*/}
  //               {/*        <button>Actions</button>*/}
  //               {/*      </div>*/}
  //               {/*    </div>*/}
  //               {/*  );*/}
  //               {/*})}*/}
  //               {/*{devicesLoading ? <div>Loading Devices...</div> : null}
  //               {!devicesLoading && !devices?.length ? (
  //                 <div>
  //                   No additional phones or devices have been added to your
  //                   account
  //                 </div>
  //               ) : null}*/}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );

  // return (
  //   <>
  //     <div
  //       className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
  //     >
  //       <div className="overflow-hidden relative h-screen">
  //         <SimpleBar style={{ height: '100%' }}>
  //           <div>
  //             <div className="">
  //               <div className="text-2xl">My Phones and Devices</div>
  //               <div className="flex justify-between">
  //                 <div className="text-md mb-2 text-neutral-500">
  //                   Contact an administrator if you want to add/remove devices.
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="p-4 space-y-4">
  //               {devices.map(device => {
  //                 return (
  //                   <div key={device.id} className="flex justify-between ">
  //                     <div className="flex">
  //                       <div className="">
  //                         <div className="font-semibold mr-4">
  //                           {device.doc.device_type}
  //                         </div>
  //                       </div>
  //                       <div className="">
  //                         <div className="font-semibold">
  //                           <Link
  //                             to={`/v2/settings/devices/${device.id}`}
  //                             className="cursor-pointer"
  //                           >
  //                             {device.doc.name?.length
  //                               ? device.doc.name
  //                               : 'Unnamed'}
  //                           </Link>
  //                         </div>
  //                         <div className="text-neutral-400">
  //                           {device.doc.owner_id}
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div>
  //                       <button>Actions</button>
  //                     </div>
  //                   </div>
  //                 );
  //               })}
  //               {devicesLoading ? <div>Loading Devices...</div> : null}
  //               {!devicesLoading && !devices?.length ? (
  //                 <div>
  //                   No additional phones or devices have been added to your
  //                   account
  //                 </div>
  //               ) : null}
  //             </div>
  //           </div>
  //         </SimpleBar>
  //       </div>
  //     </div>
  //   </>
  // );
}

export default UserDevices;
