import { useCallRouteEditor } from '../CallflowContext';
import { AddModuleButton } from '../AddModuleButton';
import { Button } from '../../../../../design-lib';
import { useBuilderContext } from '../../../../../components/IvrBuilder/IvrBuilder';
import { NavArrowDown, NavArrowRight, NavArrowUp } from 'iconoir-react';
import { PreviousModuleButton } from './PreviousModuleButton';

export const NextModuleButton = () => {
  const { selectedElement, elements } = useCallRouteEditor();
  const { setSelectedElementKey } = useBuilderContext();
  const connectingElement = elements.find(
    elem => elem?.source === selectedElement?.id,
  );
  const nextElement = elements.find(
    elem => elem?.id === connectingElement?.target,
  );

  // if (!nextElement) {
  //   return (
  //     <Button size={'md'} color={'attention'} variant={'outline'} disabled>
  //       Call Will Terminate After This Module
  //     </Button>
  //   );
  // }

  const connectingModule = elements.find(
    elem => elem?.source === nextElement?.id,
  );

  const nextModule = elements.find(
    elem => elem?.id === connectingModule?.target,
  );

  return (
    <div
      className={
        'flex space-x-4 border-t w-full pt-4 border-neutral-20 justify-between'
      }
    >
      <PreviousModuleButton />
      {nextElement.type === 'InsertNode' ? (
        <AddModuleButton expanded data={nextElement.data} />
      ) : (
        <Button
          className={`w-full`}
          color={'accent'}
          size={'md'}
          startIcon={<NavArrowDown fr={undefined} />}
          variant={'outline'}
          onClick={() => {
            setSelectedElementKey(nextModule.key);
          }}
        >
          Next Module
        </Button>
      )}
    </div>
  );
};
