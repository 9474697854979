import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import SimpleBar from 'simplebar-react';
import { useLocalSelector, useLocalSlice } from 'app/data/local';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import { General } from './components/General';
import { Users } from './components/Users';

import { usePipesQuery } from 'app/sdk';

const RoomView = () => {
  // @ts-ignore
  const { room_id } = useParams();

  const pipesQuery = usePipesQuery({ filters: { id: room_id } });
  const pipes = pipesQuery.data?.pipes ?? [];
  const pipe = pipes?.length ? pipes[0] : null;

  if (!pipe) {
    return null;
  }

  return (
    <div
      className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
    >
      <div className="overflow-hidden relative h-screen w-full">
        <SimpleBar style={{ height: '100%' }}>
          <div className="">
            <div className="text-sm mb-2">
              <Link to="/v2/settings/rooms">&lt;&lt; Back to Rooms</Link>
            </div>
            <div className="text-2xl mb-1">
              {pipe.metadata.name?.length ? pipe.metadata.name : 'Unnamed'} -{' '}
              <PhoneNumberDisplay ptn={pipe.key} width="auto" />
            </div>
            <div className="text-lg text-neutral-300 mb-4">
              Manage settings for this Room
            </div>
            <div className="space-y-4 ">
              <General pipe={pipe} refetch={pipesQuery.refetch} />
              <hr />
              {/* <div className="text-sm">[users - name, access_level]</div> */}
              <Users pipe={pipe} refetch={pipesQuery.refetch} />
            </div>
          </div>
        </SimpleBar>
      </div>
    </div>
  );
};

// const usePipeUpdate = () => {
//   return useMutation(async ({ id, data }) => {
//     const mutationResponse = await request(
//       `${constants.env.REACT_APP_CIO_API_SERVER}/api/graphql`,
//       gql`
//         mutation pipeUpdate($id: ID, $data: JSON) {
//           pipeUpdate(id: $id, data: $data) {
//             success
//             message
//             data
//           }
//         }
//       `,
//       {
//         id,
//         data,
//       },
//       // @ts-ignore
//       { authorization: store.getState().auth.auth_token },
//     );

//     console.log('pipes mutationResponse:', mutationResponse);

//     return mutationResponse.data;
//   });
// };

export default RoomView;
