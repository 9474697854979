import { AudioPlayer } from 'app/components/AudioPlayer';
import EditHoldMusicDialogAccount from 'app/components/EditHoldMusicDialogAccount/EditHoldMusicDialogAccount';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { BUILT_IN_AUDIO } from 'app/components/MediaDialog/components/BuiltInAudio/BUILT_IN_AUDIO';
import { TimezoneDialogAccount } from 'app/components/TimezoneDialogAccount';
import {
  Alert,
  Badge,
  Setting,
  Table,
  TableCell,
  TableRow,
} from 'app/design-lib';
import { getTimezoneName, useToggleReducer } from 'app/utilities';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Account } from 'types/account';
import { useMarkdownTranslate } from 'app/utilities/translate';
import { Chip } from 'app/design';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';
import { Cancel, EditPencil } from 'iconoir-react';
import AudioEditor from 'app/components/AudioEditor/AudioEditor';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useUpdateAccountPartial } from 'app/hooks/mutations/account';

interface AccountHoldMusicProps {
  account: Account;
  onComplete: () => void;
}

const HoldMusicAccount = ({ account, onComplete }: AccountHoldMusicProps) => {
  const { t } = useMarkdownTranslate();

  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'holdMusic',
    options: [
      {
        label: 'Hold Music',
        value: 'holdMusic',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();

    toggleShowEditDialog();
  };

  const mediaId = account.doc.music_on_hold.media_id;

  return (
    <SettingsPanel
      leftChild={
        <>
          <Setting
            label={'Hold music'}
            body={'Audio played during on-hold calls.'}
            iconButtonProps={
              showEditDialog
                ? undefined
                : {
                    children: <EditPencil fr={undefined} />,
                    onClick: toggleShowEditDialog,
                  }
            }
            buttonProps={
              showEditDialog
                ? {
                    children: 'Cancel',
                    startIcon: <Cancel fr={undefined} />,
                    color: 'neutral',
                    onClick: toggleShowEditDialog,
                  }
                : undefined
            }
          />
          {/*<EditHoldMusicDialogAccount
            open={showEditDialog}
            mediaId={account.doc.music_on_hold.media_id}
            onComplete={handleOnComplete}
            onCancel={toggleShowEditDialog}
          />*/}
        </>
      }
      rightChild={
        showEditDialog ? (
          <EditAccountHoldMusic
            mediaId={mediaId}
            onComplete={handleOnComplete}
            onCancel={toggleShowEditDialog}
          />
        ) : (
          <div className={`flex flex-col space-y-2`}>
            {mediaId ? (
              <AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />
            ) : (
              <Alert
                reverse
                color={'neutral'}
                label={'Using platform default'}
                body={
                  <div className={`flex flex-col space-y-2 pr-2`}>
                    <span>
                      Customize your hold music to help your business stand out.
                    </span>
                    <AudioPlayer mediaId={mediaId ?? BUILT_IN_AUDIO[0].link} />
                  </div>
                }
                // actionProps={{
                //   onClick: toggleShowEditDialog,
                //   children: 'Customize hold music',
                // }}
              />
            )}
          </div>
        )
      }
    />
  );
};

const EditAccountHoldMusic = ({ mediaId, onComplete, onCancel }) => {
  const { data: account } = useAccountQuery();
  const updateAccount = useUpdateAccountPartial();

  const onMutate = async (mediaId: string | null) => {
    // error display handled in editor
    const resp = await updateAccount.mutateAsync({
      id: account?.id!,
      music_on_hold: {
        media_id: mediaId,
      },
    });

    onComplete();
  };

  return (
    <AudioEditor
      loadingLabel={'Updating hold music...'}
      isLoading={updateAccount.isLoading}
      mediaId={mediaId}
      onClose={onCancel}
      onComplete={onMutate}
      subType={'hold-music'}
      hideCancel
    />
  );
};

export default HoldMusicAccount;
