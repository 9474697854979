import { ExtensionsTableCell } from 'app/components/ExtensionsTableCell';
import { QuickFinderGenericDialog } from 'app/components/QuickFinderGenericDialog';
// import { useListPipesQuery } from 'app/hooks/queries/pipe';
import { parseCallflowNumbers } from 'app/utilities';
import {
  QUERY_SEARCH_INPUT_CHANGED,
  useQueryPaginationReducer,
} from 'app/utilities/useQueryPaginationReducer';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { QuickFinderDialogProps } from 'types/components';
// import { Pipe, PipePrivLevel } from 'types/pipe';
import { DialogBuilder } from 'app/utilities';
// import { pipe } from 'app/sdk/resources/pipe';
import { Chip } from 'app/design';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';

import { usePipesQuery } from 'app/sdk';

const QuickFinderPipeDialog = (props: QuickFinderDialogProps) => {
  // query pagination state
  const [{ queryPageSize, queryPageIndex, querySearchInput }, dispatch] =
    useQueryPaginationReducer(0, 5);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (pipe: any) => pipe.metadata?.name,
      },
      {
        Header: 'Number',
        accessor: (pipe: any) => pipe.key,
        Cell: ({ row }) => {
          const { original: pipe }: any = row;

          return <PhoneNumberDisplay ptn={pipe?.key} />;
        },
        disableSortBy: true,
      },
    ],
    [],
  );

  // pipe list
  const pipeListQuery = usePipesQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    // orderBy: [['lastName', 'asc']],
    filters: {
      // searchInput: querySearchInput,
      NOT: {
        type: 'room',
      },
    },
  });
  const { pipes, totalCount = 0 } = pipeListQuery.data ?? {};

  const handleSearch = (searchInput: string) => {
    if (searchInput === querySearchInput) {
      pipeListQuery.refetch();
    } else {
      dispatch({ type: QUERY_SEARCH_INPUT_CHANGED, payload: searchInput });
    }
  };

  return (
    <QuickFinderGenericDialog
      {...props}
      // dialog
      title={props.multiple ? 'Select Multiple Lines' : 'Select Line'}
      // table
      onSearch={handleSearch}
      getRowId={(row: any) => row.id}
      searchPlaceholder={'Search Lines...'}
      totalCount={totalCount}
      columns={columns}
      data={pipes ?? []}
      // query
      queryIsFetching={pipeListQuery.isFetching}
      queryIsLoading={pipeListQuery.isLoading}
      queryPageSize={queryPageSize}
      queryPageIndex={queryPageIndex}
      queryPaginationDispatch={dispatch}
    />
  );
};

export const useQuickFinderPipeDialog = DialogBuilder(QuickFinderPipeDialog);

export default QuickFinderPipeDialog;
