import {
  Box,
  Divider,
  Grid,
  InfoTooltip,
  Link,
  Paper,
  Switch,
  Typography,
} from 'app/design';
import {
  ExpandLess as ExpandLessIcon,
  Group as GroupIcon,
} from 'app/design/icons-material';
import { QuickFinderGroupDialog } from 'app/components/QuickFinderGroupDialog';
import { useGroupQuery } from 'app/hooks/queries/group';
import { GroupRingStrategyCard } from 'app/pages/settings/Groups/components/GroupRingStrategyCard';
// import { GroupSettingsCard } from 'app/pages/admin_old/Groups/View/components/GroupSettingsCard';
import { getAtPath, setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NodeEditDialog } from '../../IvrBuilder/Dialogs';
import { NodeInfoDisplay } from '../../IvrBuilder/Flow/nodes/common/NodeInfoDisplay';
import { Link as RouterLink } from 'react-router-dom';
import { Listbox, SquareProgress, Button } from 'app/design-lib';
import { useListUsersQuery } from 'app/hooks/queries/user';
import UseGroupsListQuery from 'app/hooks/queries/group/useGroupsListQuery';
import { WarningTriangle } from 'iconoir-react';
import { useBuilderContext } from 'app/components/IvrBuilder/IvrBuilder';
import { UserAvatar } from 'app/components/Sidebar/Sidebar';

// Select groups from list of groups
export const Group = props => {
  const {
    skipEditing,
    modifyPath,
    callflow,
    setCallflow,
    variant,
    showModifyDialog,
    setShowModifyDialog,
  } = props;

  const dataModifyPath = `${modifyPath}.data`;

  const moduleItem = getAtPath(callflow, modifyPath, {}); // { ring_type: 'custom', data: {...} }
  const ringItem = moduleItem?.data;

  // console.log('moduleItem:', moduleItem);
  /*
  moduleItem = {
    type: 'RingStrategy',
    data: {
      ring_type: 'group',
      data: {
        groupId: 'xyz',
        timeout: 10
      }
    }
  }
  */

  const { data: groupsResp, isLoading: groupsIsLoading } = UseGroupsListQuery({
    // quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { groups = [] } = groupsResp ?? {};

  // const {
  //   data: group,
  //   isLoading: groupIsLoading,
  //   error: groupError,
  //   refetch: refetchGroup,
  // } = useGroupQuery(ringItem?.data?.id);

  const [isModifying, setIsModifying] = useState(null);

  useEffect(() => {
    if (setShowModifyDialog) {
      // parent is managing "display" state, NOT this component
      // setShowModifyDialog()
      setIsModifying(showModifyDialog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModifyDialog]);

  const { invalidModules, setInvalidModules } = useBuilderContext();
  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${dataModifyPath}`, compData);
    setCallflow({ ...callflow });
  };
  const { setDisableScroll } = useBuilderContext();

  const handleShowModifying = value =>
    setShowModifyDialog ? setShowModifyDialog(value) : setIsModifying(value);

  const group = groups.find(group => group.id === ringItem?.data?.id);

  return (
    <div className={`w-full flex justify-center px-2`}>
      {groupsIsLoading ? (
        <SquareProgress size={'sm'} />
      ) : groups.length ? (
        skipEditing ? (
          group ? (
            <div className={`flex space-x-2 py-2 min-w-0 text-sm items-center`}>
              <span className={'truncate'}>{group.doc.name}</span>
            </div>
          ) : (
            <Button
              color={'neutral'}
              variant={'ghost'}
              size={'sm'}
              className={`self-center`}
              startIcon={<WarningTriangle fr={undefined} />}
              // onClick={() => setShowModifyDialog(true)}
              disabled
            >
              not assigned
            </Button>
          )
        ) : (
          <Listbox
            placeholder="Select a group"
            onOpenChanged={setDisableScroll}
            size={'sm'}
            className={`w-full mt-1 self-center`}
            options={groups.map(({ id, doc: { name } }) => ({
              value: id,
              label: name!,
            }))}
            value={ringItem?.data?.id}
            onChange={id => {
              handleUpdate({
                ...ringItem,
                data: {
                  id,
                  timeout: timeoutDefault,
                },
              });
            }}
          />
        )
      ) : (
        <Button
          color={'neutral'}
          variant={'ghost'}
          size={'sm'}
          className={`self-center`}
          startIcon={<WarningTriangle fr={undefined} />}
          // onClick={() => setShowModifyDialog(true)}
          disabled
        >
          no groups
        </Button>
      )}
    </div>
  );

  // if (variant === 'ivr_builder') {
  //   return (
  //     <>
  //       {isModifying && (
  //         <ModifyRingSettings
  //           icon={<GroupIcon />}
  //           ringInfo={ringItem}
  //           onChangeRingInfo={handleUpdate}
  //           onClose={e => handleShowModifying(null)}
  //         />
  //       )}
  //       <NodeInfoDisplay
  //         type={'Ring Group'}
  //         icon={<GroupIcon />}
  //         title={
  //           group ? (
  //             group.doc?.name
  //           ) : skipEditing ? (
  //             'None selected'
  //           ) : (
  //             <Link onClick={() => handleShowModifying(true)}>
  //               Select Group
  //             </Link>
  //           )
  //         }
  //         footer={
  //           ringItem?.data?.timeout &&
  //           group &&
  //           `${ringItem?.data?.timeout} seconds`
  //         }
  //       />
  //     </>
  //   );
  // }

  // return (
  //   <>
  //     {isModifying && (
  //       <ModifyRingSettings
  //         ringInfo={ringItem}
  //         onChangeRingInfo={handleUpdate}
  //         onClose={e => handleShowModifying(null)}
  //       />
  //     )}
  //     <Paper variant="outlined" elevation={0}>
  //       {group ? (
  //         <>
  //           <Typography variant="body1">
  //             Ringing <strong>{group.doc.name}</strong>
  //           </Typography>
  //           <Typography variant="subtitle1">
  //             {ringItem?.data?.timeout} seconds
  //           </Typography>
  //         </>
  //       ) : (
  //         <Typography variant="body1" style={{ fontStyle: 'italic' }}>
  //           No group chosen for ringing
  //         </Typography>
  //       )}
  //       <div>
  //         <Link onClick={e => handleShowModifying(true)}>Modify</Link>
  //       </div>
  //     </Paper>
  //   </>
  // );
};

const timeoutDefault = 20;

const ModifyRingSettings = props => {
  const { ringInfo, onChangeRingInfo, onClose } = props;

  const [modifyData, setModifyData] = useState(() => cloneDeep(ringInfo));

  const groupId = modifyData?.data?.id;

  const {
    data: group,
    isLoading: groupIsLoading,
    error: groupError,
    refetch: refetchGroup,
  } = useGroupQuery(groupId);

  // defaults
  useEffect(() => {
    // timeout
    if (!getAtPath(modifyData, `data.timeout`)) {
      setAtPath(modifyData, `data.timeout`, timeoutDefault);
      setModifyData({ ...modifyData });
    }
  }, [modifyData, setModifyData]);

  const [initialState] = useState({
    id: ringInfo?.data?.id,
    fail_on_single_reject: ringInfo?.data?.opts?.fail_on_single_reject,
    ignore_forward: ringInfo?.data?.opts?.ignore_forward,
  });

  const saveLabel = !modifyData?.data?.id
    ? 'Leave Empty'
    : !initialState.id
    ? 'Add'
    : initialState.id !== modifyData?.data?.id ||
      initialState.fail_on_single_reject !==
        modifyData?.data?.opts?.fail_on_single_reject ||
      initialState.ignore_forward !== modifyData?.data?.opts?.ignore_forward
    ? 'Update'
    : 'Done';

  // Finder Dialog
  const [showFinderDialog, toggleShowFindGroupDialog] = useToggleReducer(
    !groupId,
  );

  const onChangeGroupSelect = selected => {
    const [groupId] = selected;
    setAtPath(modifyData, `data.id`, groupId); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindGroupDialog();
  };

  const handleClear = () => {
    setAtPath(modifyData, `data.id`, null); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindGroupDialog();
  };

  const [showAllSettings, toggleShowAllSettings] = useToggleReducer(false);

  return (
    <>
      <NodeEditDialog
        title={'Edit Ring Group Ringing'}
        maxWidth={showAllSettings ? '4xl' : 'xl'}
        onClose={onClose}
        onCancel={onClose}
        onSave={() => {
          onChangeRingInfo(modifyData);
          onClose();
        }}
        titleButton={
          group ? (
            <Button onClick={toggleShowFindGroupDialog}>Change Group</Button>
          ) : null
        }
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        isLoading={groupIsLoading}
        errorMessage={groupError ? 'Failed loading selected group' : undefined}
      >
        {group ? (
          <Grid container spacing={3}>
            <Grid item xs={showAllSettings ? 9 : 6}>
              <span className={'text-lg font-medium'}>Ring Group Settings</span>
              <Divider />
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <GroupSettings
                    {...props}
                    modifyData={modifyData}
                    setModifyData={setModifyData}
                    group={group}
                  />
                </Grid>
                {showAllSettings ? (
                  <Grid item>
                    <Button onClick={toggleShowAllSettings}>
                      <ExpandLessIcon />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>{' '}
              {showAllSettings ? (
                <>
                  <Box sx={{ paddingX: 2 }}>
                    {/* <GroupSettingsCard
                      group={group}
                      onComplete={refetchGroup}
                    /> */}
                    [GROUP SETTINGS HERE]
                    <br />
                    <GroupRingStrategyCard
                      group={group}
                      onComplete={refetchGroup}
                    />
                  </Box>
                  <br />
                  <Divider />
                </>
              ) : null}
              <br />
              <Link
                underline={'hover'}
                // component={RouterLink}
                onClick={toggleShowAllSettings}
              >
                {showAllSettings
                  ? 'Hide additional settings'
                  : 'Show all settings'}
              </Link>
            </Grid>
            <Grid item xs={showAllSettings ? 3 : 6}>
              <span className={'text-lg font-medium'}>Ring Settings</span>
              <Divider />
              <RingSettings
                {...props}
                modifyData={modifyData}
                setModifyData={setModifyData}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No Ring Group Selected...
            </Typography>
            <Button onClick={toggleShowFindGroupDialog}>
              Choose Ring Group
            </Button>
          </>
        )}
      </NodeEditDialog>
      <QuickFinderGroupDialog
        open={showFinderDialog}
        onSelect={onChangeGroupSelect}
        onCancel={toggleShowFindGroupDialog}
        onClear={handleClear}
        initialSelected={groupId ? [groupId] : []}
        selectionTitle={group ? `${group.doc.name}` : null}
        allowCreate
      />
    </>
  );
};

const GroupSettings = ({ modifyData, setModifyData, group }) => {
  const groupId = modifyData?.data?.id;
  console.log('GroupId:', groupId, modifyData);

  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <span
        className={'text-lg'}
        // to={`/admin/ring_groups/view/${groupId}`}
        // underline="hover"
        // color={'text.primary'}
        // component={RouterLink}
      >{`${group.doc.name}`}</span>
    </Box>
  );
};

const RingSettings = ({ modifyData, setModifyData }) => {
  const handleCheckboxChange = path => event => {
    setAtPath(modifyData, path, event.target.checked); // newValue?.value === id
    setModifyData({ ...modifyData });
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <SettingToggle
        label={'Fail on Single Reject'}
        info={'If any leg rejects the call, cancel all other legs'}
        checked={getAtPath(modifyData, 'data.opts.fail_on_single_reject')}
        onChange={handleCheckboxChange('data.opts.fail_on_single_reject')}
      />

      <br />

      <SettingToggle
        label={'Ignore Forwards'}
        info={'Whether to ignore forwarded phones (and users??)'}
        checked={getAtPath(modifyData, 'data.opts.ignore_forward')}
        onChange={handleCheckboxChange('data.opts.ignore_forward')}
      />
    </Box>
  );
};

const SettingToggle = ({ label, info, checked, onChange }) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      wrap={'nowrap'}
    >
      <Grid item>
        <Typography variant="rightSideBarBold">{label}</Typography>
      </Grid>
      <Grid item style={{ flex: 1, marginTop: -10 }}>
        {info && (
          <Typography variant="rightSideBarBold">
            <InfoTooltip title={info} />
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Switch
          size={'small'}
          color={'primary'}
          checked={checked}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default Group;

// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Divider,
//   EditDetailsDialog,
//   Grid,
//   IconButton,
//   Link,
//   Paper,
//   Sidebar,
//   SidebarCard,
//   SidebarCardContent,
//   SidebarCardHeader,
//   Typography,
//   Checkbox,
//   FormControlLabel,
//   FormHelperText,
//   SidebarSettingToggle,
// } from 'app/design';
// import { DialogCircularProgress } from 'app/components/DialogCircularProgress';
// import { KazooSDK } from '@KazooSDK';
// import { getAtPath, setAtPath, sleep } from '@KazooSDK/utils';
// import {
//   Add as AddIcon,
//   Edit as EditIcon,
//   FontDownload as FontDownloadIcon,
//   PhoneCallback,
//   SettingsPhone as SettingsPhoneIcon,
//   TransferWithinAStation as TransferWithinAStationIcon,
// } from 'app/design/icons-material';
// import { ToastSaving } from '@Util/toast';
// import { cloneDeep } from 'lodash';
// import React, { useEffect, useReducer, useState } from 'react';
// import { useMutation } from 'react-query';

// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { syncSingleItem } from '../../../../Actions/lists';
// import { SidebarHoldMusic } from '../../../AdminGroups/View/HoldMusic';
// import { AutocompleteGroup } from '../../AutocompleteGroup';
// import { DialogAddGroup } from '../../DialogAddGroup';
// import { DialogEditGroup } from '../../DialogEditGroup';
// import { NodeEditDialog } from '../../IvrBuilder/Dialogs';
// import { NodeInfoDisplay } from '../../IvrBuilder/Flow/nodes/common/NodeInfoDisplay';
// import { HelpToolTip } from '../../LogsAndCharts/shared/tables/components/ReportTable/HelpToolTip';
// import { RingGroup, RingGroupTypes } from '../../RingGroup';
// import { useSetupHook } from '../../SetupHook';
// import { getStrategyTemplate } from '../../Strategies';
// // import { useTranslation } from 'react-i18next';

// // Select groups from list of groups
// export const Group = props => {
//   const {
//     modifyPath,
//     callflow,
//     setCallflow,
//     variant,
//     showModifyDialog,
//     setShowModifyDialog,
//   } = props;

//   const componentData = getAtPath(callflow, modifyPath, {}); // { ringType: 'custom', data: {...} }

//   /*
//   componentData = {
//     ringType: 'group',
//     data: {
//       groupId: 'xyz',
//       timeout: 10
//     }
//   }
//   */

//   const { list: groups } = useSelector(state => state.lists.groups);
//   const group = groups.find(u => u.id === componentData?.data?.groupId);

//   const [isModifying, setIsModifying] = useState(null);

//   useEffect(() => {
//     if (setShowModifyDialog) {
//       // parent is managing "display" state, NOT this component
//       // setShowModifyDialog()
//       setIsModifying(showModifyDialog);
//     }
//   }, [showModifyDialog]);

//   const handleUpdate = compData => {
//     setAtPath(callflow, `${modifyPath}`, compData);
//     setCallflow({ ...callflow });
//   };

//   const handleShowModifying = value =>
//     setShowModifyDialog ? setShowModifyDialog(value) : setIsModifying(value);

//   if (variant === 'ivr_builder') {
//     return (
//       <>
//         {isModifying && (
//           <ModifyRingSettings
//             ringInfo={componentData}
//             onChangeRingInfo={handleUpdate}
//             onClose={e => handleShowModifying(null)}
//           />
//         )}
//         <NodeInfoDisplay
//           type={'Ring Group'}
//           // icon={<Telicon name={'user-group'} />}
//           title={
//             group ? (
//               group.doc.name
//             ) : (
//               <Link onClick={() => handleShowModifying(true)}>
//                 Select Group
//               </Link>
//             )
//           }
//         />
//         {/*<div>*/}
//         {/*  {group ? (*/}
//         {/*    <>*/}
//         {/*      <Typography variant="body1" noWrap>*/}
//         {/*        Ringing <strong>{group.doc.name}</strong>*/}
//         {/*      </Typography>*/}
//         {/*      /!* <Typography variant="subtitle1" noWrap>*/}
//         {/*        {componentData?.data?.timeout} seconds*/}
//         {/*      </Typography> *!/*/}
//         {/*    </>*/}
//         {/*  ) : (*/}
//         {/*    <Typography variant="body1" style={{ fontStyle: 'italic' }}>*/}
//         {/*      No group chosen for ringing*/}
//         {/*    </Typography>*/}
//         {/*  )}*/}
//         {/*  <div>*/}
//         {/*    <Link onClick={(e) => setIsModifying(true)}>Modify</Link>*/}
//         {/*  </div>*/}
//         {/*</div>*/}
//       </>
//     );
//   }

//   return (
//     <>
//       {isModifying && (
//         <ModifyRingSettings
//           ringInfo={componentData}
//           onChangeRingInfo={handleUpdate}
//           onClose={e => handleShowModifying(null)}
//         />
//       )}
//       <Paper variant="outlined" elevation={0} padding={1}>
//         {group ? (
//           <>
//             <Typography variant="body1">
//               Ringing <strong>{group.doc.name}</strong>
//             </Typography>
//             {/* <Typography variant="subtitle1">
//               {componentData?.data?.timeout} seconds
//             </Typography> */}
//           </>
//         ) : (
//           <Typography variant="body1" style={{ fontStyle: 'italic' }}>
//             No group chosen to ring
//           </Typography>
//         )}
//         <div>
//           <Link onClick={e => handleShowModifying(true)}>Modify</Link>
//         </div>
//       </Paper>
//     </>
//   );
// };

// const ModifyRingSettings = props => {
//   const { ringInfo, onChangeRingInfo, onClose } = props;

//   const [modifyData, setModifyData] = useState(() => cloneDeep(ringInfo));

//   const onChangeGroupSelect = newValue => {
//     setAtPath(modifyData, `data.groupId`, newValue?.value); // newValue?.value === id
//     setModifyData({ ...modifyData });
//   };
//   // const handleChangeText = (e) => {
//   //   setAtPath(ringInfo, `data.timeout`, e.target.value); // newValue?.value === id
//   //   setModifyData({ ...ringInfo });
//   // };

//   const onChange = newTypeAndData => {
//     setModifyData(newTypeAndData);
//   };

//   const handleCheckboxChange = path => event => {
//     setAtPath(modifyData, path, event.target.checked); // newValue?.value === id
//     setModifyData({ ...modifyData });
//   };

//   const [showAddGroupDialog, toggleShowAddGroupDialog] = useReducer(
//     show => !show,
//     false,
//   );
//   const handleGroupChange = groupId => {
//     setAtPath(modifyData, `data.groupId`, groupId); // newValue?.value === id
//     setModifyData({ ...modifyData });
//   };

//   const { list: groups } = useSelector(state => state.lists.groups);

//   const group = groups.find(group => group.id === modifyData.data.groupId);

//   const [initialState] = useState({
//     groupId: ringInfo?.data?.groupId,
//     fail_on_single_reject: ringInfo?.data?.opts?.fail_on_single_reject,
//     ignore_forward: ringInfo?.data?.opts?.ignore_forward,
//   });

//   const saveLabel = !modifyData?.data?.groupId
//     ? 'Leave Empty'
//     : !initialState.groupId
//     ? 'Add'
//     : initialState.groupId !== modifyData?.data?.groupId ||
//       initialState.fail_on_single_reject !==
//         modifyData?.data?.opts?.fail_on_single_reject ||
//       initialState.ignore_forward !== modifyData?.data?.opts?.ignore_forward
//     ? 'Update'
//     : 'Done';

//   return (
//     <>
//       {showAddGroupDialog && (
//         <DialogAddGroup
//           onClose={toggleShowAddGroupDialog}
//           onComplete={handleGroupChange}
//         />
//       )}
//       <NodeEditDialog
//         title={group ? 'Edit Group Ringing' : 'Select Group'}
//         titleButton={
//           group ? (
//             <Button color="primary" onClick={() => handleGroupChange(null)}>
//               Change Group
//             </Button>
//           ) : (
//             <Button color="primary" onClick={toggleShowAddGroupDialog}>
//               New Group
//             </Button>
//           )
//         }
//         maxWidth={group ? 'lg' : 'sm'}
//         onClose={onClose}
//         // onCancel={onClose}
//         onCancel={
//           modifyData?.data?.groupId && !ringInfo?.data?.groupId
//             ? () => handleGroupChange(undefined)
//             : ringInfo?.data?.groupId && !modifyData?.data?.groupId
//             ? () => setModifyData(cloneDeep(ringInfo))
//             : onClose
//         }
//         cancelLabel={
//           modifyData?.data?.groupId && !ringInfo?.data?.groupId
//             ? 'Back'
//             : ringInfo?.data?.groupId && !modifyData?.data?.groupId
//             ? 'Back'
//             : null
//         }
//         onSave={() => {
//           onChangeRingInfo(modifyData);
//           onClose();
//         }}
//         saveLabel={saveLabel}
//         hideCancel={saveLabel === 'Done'}
//       >
//         {group ? (
//           <>
//             <Grid
//               container
//               spacing={1}
//               // direction={'column'}
//               alignItems={'top'}
//               justify={'center'}
//             >
//               <Grid item xs={8}>
//                 <Grid container>
//                   <Grid item xs={12}>
//                     <Typography
//                       variant={'h7'}
//                       style={{ display: 'inline-block' }}
//                     >
//                       Group Settings
//                     </Typography>
//                     <HelpToolTip
//                       title={
//                         'Changing these settings will affect the Group wherever it is used'
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     {group ? (
//                       <Sidebar>
//                         <Divider />
//                         <GroupName group={group} />
//                         <Divider />
//                         <SidebarHoldMusic group={group} />
//                         <Divider />
//                         <SidebarRingStrategy group={group} />
//                       </Sidebar>
//                     ) : (
//                       'Invalid Group.'
//                     )}
//                   </Grid>
//                 </Grid>
//               </Grid>
//               <Grid item xs={4}>
//                 <Grid container>
//                   <Grid item xs={12}>
//                     <Typography
//                       variant={'h7'}
//                       style={{ display: 'inline-block' }}
//                     >
//                       Ring Settings
//                     </Typography>
//                     <HelpToolTip
//                       title={
//                         'These settings only affect this specific Module in your Call Handling (not every Group where it is used)'
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <Sidebar>
//                       <Divider />
//                       <CallflowSettings>
//                         <SidebarSettingToggle
//                           label={'Fail on Single Reject'}
//                           checked={getAtPath(
//                             modifyData,
//                             'data.opts.fail_on_single_reject',
//                           )}
//                           onChange={handleCheckboxChange(
//                             'data.opts.fail_on_single_reject',
//                           )}
//                           info={
//                             'If any leg rejects the call, cancel all other legs'
//                           }
//                         />
//                         <br />
//                         <SidebarSettingToggle
//                           label={'Ignore Forwards'}
//                           checked={getAtPath(
//                             modifyData,
//                             'data.opts.ignore_forward',
//                           )}
//                           onChange={handleCheckboxChange(
//                             'data.opts.ignore_forward',
//                           )}
//                           info={
//                             'Whether to ignore forwarded phones (and users??)'
//                           }
//                         />
//                       </CallflowSettings>
//                     </Sidebar>
//                     {/*<Grid item>*/}
//                     {/*  <div>*/}
//                     {/*    <FormControlLabel*/}
//                     {/*      control={*/}
//                     {/*        <Checkbox*/}
//                     {/*          // color="primary"*/}
//                     {/*          style={{ color: '#6736F1' }}*/}
//                     {/*          checked={getAtPath(*/}
//                     {/*            modifyData,*/}
//                     {/*            'data.opts.fail_on_single_reject'*/}
//                     {/*          )}*/}
//                     {/*          onChange={handleCheckboxChange(*/}
//                     {/*            'data.opts.fail_on_single_reject'*/}
//                     {/*          )}*/}
//                     {/*        />*/}
//                     {/*      }*/}
//                     {/*      label={'Fail on Single Reject'}*/}
//                     {/*    />*/}
//                     {/*    <FormHelperText style={{ marginTop: -10 }}>*/}
//                     {/*      If any leg rejects the call, cancel all other legs*/}
//                     {/*    </FormHelperText>*/}
//                     {/*  </div>*/}
//                     {/*  <br />*/}
//                     {/*</Grid>*/}

//                     {/*<Grid item>*/}
//                     {/*  <div>*/}
//                     {/*    <FormControlLabel*/}
//                     {/*      control={*/}
//                     {/*        <Checkbox*/}
//                     {/*          // color="primary"*/}
//                     {/*          style={{ color: '#6736F1' }}*/}
//                     {/*          checked={getAtPath(*/}
//                     {/*            modifyData,*/}
//                     {/*            'data.opts.ignore_forward'*/}
//                     {/*          )}*/}
//                     {/*          onChange={handleCheckboxChange(*/}
//                     {/*            'data.opts.ignore_forward'*/}
//                     {/*          )}*/}
//                     {/*        />*/}
//                     {/*      }*/}
//                     {/*      label={'Ignore Forwards'}*/}
//                     {/*    />*/}
//                     {/*    <FormHelperText style={{ marginTop: -10 }}>*/}
//                     {/*      Whether to ignore forwarded phones (and users??)*/}
//                     {/*    </FormHelperText>*/}
//                     {/*    <br />*/}
//                     {/*  </div>*/}
//                     {/*</Grid>*/}
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </>
//         ) : (
//           <AutocompleteGroup
//             // label="Select Group"
//             placeholder="Search for group by name..."
//             defaultValue={modifyData?.data?.groupId}
//             onChange={onChangeGroupSelect}
//           />
//         )}
//       </NodeEditDialog>
//       {/*<Dialog open onClose={onClose} fullWidth={true} maxWidth="sm">*/}
//       {/*  <DialogTitle disableTypography>*/}
//       {/*    <Grid*/}
//       {/*      container*/}
//       {/*      spacing={3}*/}
//       {/*      justify="space-between"*/}
//       {/*      alignItems="center"*/}
//       {/*    >*/}
//       {/*      <Grid item>Modify Group Ringing</Grid>*/}
//       {/*      <Grid item>*/}
//       {/*        <Button color="primary" onClick={toggleShowAddGroupDialog}>*/}
//       {/*          New Group*/}
//       {/*        </Button>*/}
//       {/*      </Grid>*/}
//       {/*    </Grid>*/}
//       {/*  </DialogTitle>*/}
//       {/*  <Divider />*/}
//       {/*  <DialogContent>*/}
//       {/*    <div>*/}
//       {/*      <br />*/}
//       {/*      <div>*/}
//       {/*        <AutocompleteGroup*/}
//       {/*          label="Select Group"*/}
//       {/*          placeholder=""*/}
//       {/*          defaultValue={modifyData?.data?.groupId}*/}
//       {/*          onChange={onChangeGroupSelect}*/}
//       {/*        />*/}
//       {/*      </div>*/}
//       {/*      <br />*/}
//       {/*      /!* <Grid item>*/}
//       {/*      <TextField*/}
//       {/*        label="Ring Time"*/}
//       {/*        value={ringInfo?.data?.timeout}*/}
//       {/*        onChange={handleChangeText}*/}
//       {/*      />*/}
//       {/*    </Grid> *!/*/}
//       {/*      <Grid*/}
//       {/*        container*/}
//       {/*        spacing={1}*/}
//       {/*        // direction={'column'}*/}
//       {/*        alignItems={'center'}*/}
//       {/*        justify={'center'}*/}
//       {/*      >*/}
//       {/*        <Grid item>*/}
//       {/*          <div>*/}
//       {/*            <FormControlLabel*/}
//       {/*              control={*/}
//       {/*                <Checkbox*/}
//       {/*                  // color="primary"*/}
//       {/*                  style={{ color: '#6736F1' }}*/}
//       {/*                  checked={getAtPath(*/}
//       {/*                    modifyData,*/}
//       {/*                    'data.opts.fail_on_single_reject'*/}
//       {/*                  )}*/}
//       {/*                  onChange={handleCheckboxChange(*/}
//       {/*                    'data.opts.fail_on_single_reject'*/}
//       {/*                  )}*/}
//       {/*                />*/}
//       {/*              }*/}
//       {/*              label={'Fail on Single Reject'}*/}
//       {/*            />*/}
//       {/*            <FormHelperText style={{ marginTop: -10 }}>*/}
//       {/*              If any leg rejects the call, cancel all other legs*/}
//       {/*            </FormHelperText>*/}
//       {/*          </div>*/}
//       {/*          <br />*/}
//       {/*        </Grid>*/}
//       {/*        <Grid item style={{ flexGrow: 1 }}>*/}
//       {/*          <div*/}
//       {/*            style={{*/}
//       {/*              width: 0,*/}
//       {/*              height: '100%',*/}
//       {/*              borderLeft: '1px solid rgba(0,0,0,0.3)',*/}
//       {/*            }}*/}
//       {/*          />*/}
//       {/*        </Grid>*/}
//       {/*        <Grid item>*/}
//       {/*          <div>*/}
//       {/*            <FormControlLabel*/}
//       {/*              control={*/}
//       {/*                <Checkbox*/}
//       {/*                  // color="primary"*/}
//       {/*                  style={{ color: '#6736F1' }}*/}
//       {/*                  checked={getAtPath(*/}
//       {/*                    modifyData,*/}
//       {/*                    'data.opts.ignore_forward'*/}
//       {/*                  )}*/}
//       {/*                  onChange={handleCheckboxChange(*/}
//       {/*                    'data.opts.ignore_forward'*/}
//       {/*                  )}*/}
//       {/*                />*/}
//       {/*              }*/}
//       {/*              label={'Ignore Forwards'}*/}
//       {/*            />*/}
//       {/*            <FormHelperText style={{ marginTop: -10 }}>*/}
//       {/*              Whether to ignore forwarded phones (and users??)*/}
//       {/*            </FormHelperText>*/}
//       {/*            <br />*/}
//       {/*          </div>*/}
//       {/*        </Grid>*/}
//       {/*      </Grid>*/}
//       {/*      {group && (*/}
//       {/*        <Sidebar>*/}
//       {/*          <Divider />*/}
//       {/*          <GroupName group={group} />*/}
//       {/*          <Divider />*/}
//       {/*          <SidebarHoldMusic group={group} />*/}
//       {/*          <Divider />*/}
//       {/*          <SidebarRingStrategy group={group} />*/}
//       {/*        </Sidebar>*/}
//       {/*      )}*/}
//       {/*    </div>*/}
//       {/*  </DialogContent>*/}
//       {/*  <Divider />*/}
//       {/*  <DialogActions>*/}
//       {/*    <Button*/}
//       {/*      color="primary"*/}
//       {/*      onClick={(e) => {*/}
//       {/*        onChangeRingInfo(modifyData);*/}
//       {/*        onClose();*/}
//       {/*      }}*/}
//       {/*    >*/}
//       {/*      Update*/}
//       {/*    </Button>*/}
//       {/*  </DialogActions>*/}
//       {/*</Dialog>*/}
//     </>
//   );
// };

// const CallflowSettings = ({ children }) => {
//   return (
//     <SidebarCard>
//       <SidebarCardHeader
//         label={'Call Routing'}
//         icon={
//           <PhoneCallback
//             style={{ verticalAlign: 'middle', color: '#6736F1' }}
//           />
//         }
//       />
//       <SidebarCardContent>{children}</SidebarCardContent>
//     </SidebarCard>
//   );
// };

// const GroupName = ({ group }) => {
//   const { defaultAccount, sync } = useSetupHook();

//   const dispatch = useDispatch();

//   const history = useHistory();

//   const [showDialog, setShowDialog] = useState(false);

//   const {
//     mutate: mutateGroup,
//     isLoading: isLoadingMutate,
//     error: mutateError,
//   } = useMutation(data => KazooSDK.resourceGroupPatch(data));

//   const handleSave = name => {
//     const groupData = {
//       ...group.doc,
//       name,
//     };

//     let saveToast = ToastSaving({ title: 'Saving Group Name...' });

//     mutateGroup(groupData, {
//       onSuccess: async () => {
//         setShowDialog(false);
//         dispatch(syncSingleItem({ collectionKey: 'groups', id: group.id }));
//         await sleep(1000);
//         saveToast.saved({ title: 'Group Name Saved!' });
//       },
//       onError: error => {
//         saveToast.error(error);
//       },
//     });
//   };

//   return (
//     <>
//       {showDialog && (
//         <EditDetailsDialog
//           type={'Group'}
//           progressLabel={'Saving Group Name...'}
//           name={group?.doc?.name}
//           onCancel={() => setShowDialog(false)}
//           onClose={() => setShowDialog(false)}
//           onSave={handleSave}
//           errors={[mutateError]}
//           isLoadingMutate={isLoadingMutate}
//         />
//       )}
//       <SidebarCard>
//         <SidebarCardHeader
//           label={'Group Name'}
//           icon={
//             <FontDownloadIcon
//               style={{ verticalAlign: 'middle', color: '#6736F1' }}
//             />
//           }
//           editButtonProps={{
//             onClick: () => setShowDialog(true),
//           }}
//         />
//         <SidebarCardContent>
//           <div>
//             <Typography variant="body1">{group?.doc?.name}</Typography>
//           </div>
//         </SidebarCardContent>
//       </SidebarCard>
//     </>
//   );
// };

// const SidebarRingStrategy = ({ group }) => {
//   // const [showRingStrategyDialog, toggleShowRingStrategyDialog] = useReducer(
//   //   (show) => !show,
//   //   false
//   // );
//   // const ringStrategy = RingGroupTypes.find(
//   //   (type) => type.id === group.doc.type
//   // );

//   return (
//     <>
//       <SidebarCard>
//         <SidebarCardHeader
//           label={'Ring Strategy'}
//           icon={
//             <SettingsPhoneIcon
//               style={{ verticalAlign: 'middle', color: '#6736F1' }}
//             />
//           }
//           // actionButton={
//           //   <IconButton size="small" onClick={toggleShowRingStrategyDialog}>
//           //     {ringStrategy ? <EditIcon /> : <AddIcon />}
//           //   </IconButton>
//           // }
//         />
//         <SidebarCardContent>
//           <RingStrategyDialog group={group} onClose={() => {}} />
//           {/*<Typography variant={'body1'}>*/}
//           {/*  {ringStrategy?.name ?? (*/}
//           {/*    <span style={{ fontStyle: 'italic', color: '#888' }}>*/}
//           {/*      Not Set*/}
//           {/*    </span>*/}
//           {/*  )}*/}
//           {/*</Typography>*/}
//         </SidebarCardContent>
//       </SidebarCard>
//     </>
//   );
// };

// const RingStrategyDialog = ({ group, onClose }) => {
//   const [isSaving, setIsSaving] = useState(false);
//   const { dispatchSyncSingleItem } = useSetupHook();

//   const [tmpGroup, setTmpGroup] = useState(() =>
//     JSON.parse(JSON.stringify(group)),
//   );

//   const handleSave = async () => {
//     let saveToast = ToastSaving({ title: 'Saving Group Ring Strategy...' });
//     setIsSaving(true);

//     try {
//       const returnedGroup = await KazooSDK.resourceGroupPatch(tmpGroup.doc);
//       saveToast.saved({ title: 'Group Ring Strategy Saved!' });
//       dispatchSyncSingleItem({ collectionKey: 'groups', id: group.id });
//       onClose();
//     } catch (e) {
//       saveToast.error(e);
//     }

//     setIsSaving(false);
//   };

//   const handleReset = async () => {
//     setTmpGroup(JSON.parse(JSON.stringify(group)));
//   };

//   const noneSelected = !tmpGroup.doc?.type || tmpGroup.doc?.type === 'none';
//   return (
//     // <Dialog fullWidth maxWidth="md" open>
//     isSaving ? (
//       <DialogCircularProgress title={'Updating Group Ring Strategy...'} />
//     ) : (
//       <RingGroup
//         allow={['user', 'group']}
//         modifyPath={`doc`} // root-level??
//         strategyCallflow={tmpGroup}
//         setStrategyCallflow={setTmpGroup} // SHOULD BE UNCOMMENTED!
//         customizeOptions={{}} // TODO: set defaults for timeout, strategy, limits to types of groups, etc
//         required
//         useGroupFormat
//         onSave={handleSave}
//         onReset={handleReset}
//         onCancel={onClose}
//         // startEditing
//       />
//     )
//   );
// };

// const buildFlow = async ({ callflow, componentData }) => {
//   const { ringType, data } = componentData;

//   // ringType always 'group'
//   console.log('RingStrategy Group buildflow:', componentData);

//   const stratComponents = [{ type: 'RingChooser' }];
//   const stratDataOpts = [
//     { chooserType: 'group', data: { groupId: data.groupId, opts: data.opts } },
//   ];

//   const strategyTemplate = getStrategyTemplate('blank');
//   const convertResult = await strategyTemplate.convertDataToFlow({
//     strategy: {
//       id: 'blank',
//       config: {
//         components: stratComponents,
//       },
//       data: {
//         opts: stratDataOpts,
//       },
//     },
//   });
//   const afterBuildingFlow = convertResult.flow;

//   console.log('afterBuildingFlow group ring', afterBuildingFlow);

//   return afterBuildingFlow;
// };

// Group.buildFlow = buildFlow;

// export default Group;
