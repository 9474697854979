import {
  Community,
  NavArrowRight,
  NetworkAlt,
  PhoneIncome,
  Plus,
} from 'iconoir-react';
import { IconButton, Listbox, TextField } from '../../../../design-lib';
import { Divider } from '../../../../design-lib/mobile-components';
import { UserAvatar } from '../../../../components/Sidebar/Sidebar';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../components/PhoneNumberDisplay';
import React, { useState } from 'react';
import { Callflow } from '../../../../../types/callflow';
import { cloneDeep } from 'lodash';
import { CallflowSidebar } from './CallflowSidebar';
import CallflowProvider from './CallflowContext';
import { ModuleView } from './ModuleView/ModuleView';
import { MiniMap } from './MiniMap';

export const MobileView = ({
  pipe,
  rootCallflow,
  editingCallflow,
  sharedFlow,
  setSharedFlow,
  setEditingCallflow,
  onSave,
  onCancel,
  elements,
  edgeTypes,
  nodeTypes,
  scroll,
}) => {
  return (
    <div className={`flex-1 flex h-full overflow-hidden`}>
      <CallflowProvider
        pipe={pipe}
        rootCallflow={rootCallflow}
        editingCallflow={editingCallflow}
        sharedFlow={sharedFlow}
        setSharedFlow={setSharedFlow}
        setEditingCallflow={setEditingCallflow}
        onSave={onSave}
        onCancel={onCancel}
        elements={elements}
      >
        <CallflowSidebar
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          scroll={scroll}
        />
        <ModuleView />
      </CallflowProvider>
    </div>
  );
};
