import { Tooltip } from 'app/design';
import {
  NavArrowLeft,
  NavArrowRight,
  Settings,
  Trash as DeleteIcon,
} from 'iconoir-react';

import { getAtPath, setAtPath } from 'app/utilities';
import React, { memo, useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';

// import useStyles from './styles';
import { useHoverDirty } from 'react-use';

import { InTemplate } from '../common/InTemplate';
import {
  Button,
  Checkbox,
  IconButton,
  Listbox,
  Popover,
  TextField,
} from '../../../../../design-lib';
import { Repeat, Timer } from 'iconoir-react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormListbox } from '../../../../reactHookFormComponents/HookFormListbox';
import { HookFormTextField } from '../../../../reactHookFormComponents/HookFormTextField';
import { useBuilderContext } from '../../../MessageRoutingVisualBuilder';
import { startCase } from 'lodash';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditMessageRouting/MobileView/MiniMapNode';

const OptionMatchTextNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    callflow,
    setCallflow,
    modifyPath, // to ".targets"
    targetIdx,
    // text,
    templateParent,
    target,
    matchTextData,
  } = data;

  const optionRef = useRef(null);
  const isHovering = useHoverDirty(optionRef);

  // const classes = useStyles({ mode: isHovering ? 'normal' : 'ignore' });

  const handleClick = () => {
    const text = prompt('equals', target.match.data.text);
    if (!text) {
      return false;
    }
    setAtPath(callflow, `${modifyPath}.${targetIdx}.match.data.text`, text);
    setCallflow({ ...callflow });
  };

  const handleUpdate = match => {
    setAtPath(callflow, `${modifyPath}.${targetIdx}.match`, match);
    setCallflow({ ...callflow });
  };

  const handleRemove = () => {
    const targets = getAtPath(callflow, modifyPath);
    targets.splice(targetIdx, 1);
    setAtPath(callflow, modifyPath, [...targets]);
    setCallflow({ ...callflow });
  };

  let reservedWords = {};

  const isReserved = false; //reservedWords.hasOwnProperty(text) ? true : false;
  const isWide = true; //text.length > 1 || isReserved ? true : false;

  const showLeft =
    !skipEditing &&
    targetIdx !== 0 &&
    matchTextData?.moduleItem?.data?.targets?.length > 1;
  const showRight =
    !skipEditing &&
    targetIdx < matchTextData?.moduleItem?.data?.targets?.length - 1;

  const onIncreasePriority = () => {
    const updatedTargets = matchTextData.moduleItem?.data?.targets.filter(
      (v, i) => i !== targetIdx,
    );

    updatedTargets.splice(targetIdx - 1, 0, target);

    // setAtPath(
    //   callflow,
    //   `matchTextDatas.${matchTextData_id}.moduleItem?.data?.targets`,
    //   updatedTargets,
    // );
    setAtPath(callflow, modifyPath, [...updatedTargets]);
    setCallflow({ ...callflow });
  };
  const onDecreasePriority = () => {
    const updatedTargets = matchTextData.moduleItem?.data?.targets.filter(
      (v, i) => i !== targetIdx,
    );

    updatedTargets.splice(targetIdx + 1, 0, target);

    // setAtPath(
    //   callflow,
    //   `matchTextDatas.${matchTextData_id}.moduleItem?.data?.targets`,
    //   updatedTargets,
    // );
    setAtPath(callflow, modifyPath, [...updatedTargets]);
    setCallflow({ ...callflow });
  };

  const { miniMap } = useBuilderContext();

  if (miniMap) {
    return (
      <MiniMapNode
        elementId={rest.id}
        data={data}
        type={'OptionMatchTextNode'}
      />
    );
  }

  return (
    <div
      ref={optionRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      <Handle
        type="target"
        position="top"
        style={{ top: 4, background: '#555', visibility: 'hidden' }}
        onConnect={params => console.log('handle onConnect', params)}
      />
      {showLeft ? (
        <IconButton
          pill
          onClick={onIncreasePriority}
          className={`mr-1 opacity-50 hover:opacity-100`}
          variant={'ghost'}
          color={'neutral'}
          title={'Increase priority'}
        >
          <NavArrowLeft />
        </IconButton>
      ) : null}
      {/* <Button
        // disabled
        variant={isReserved ? 'fill' : 'outline'}
        size={'sm'}
        color={isReserved ? 'neutral' : 'accent'}
        // startIcon={reservedWords[text]?.icon}
        className={`min-w-[24px]`}
        // className={`border border-neutral-60 rounded text-white drop-shadow-lg bg-neutral-50 text-sm p-1 px-2`}
        // disabled={skipEditing}
        onClick={handleClick}
      >*/}

      <Popover
        arrow
        rounded={false}
        buttonProps={{
          disabled: skipEditing,
          variant: isReserved ? 'fill' : 'outline',
          size: 'sm',
          color: isReserved ? 'neutral' : 'accent',
          className: `min-w-[24px]`,
          children: (
            <div className={`min-w-[24px] flex space-x-1`}>
              <span className="font-thin mr-1">
                {startCase(target.match?.type)}
              </span>
              <span className="font-bold inline-block  bg-accent-60 text-white px-2 rounded">
                {target.match?.data?.text?.length ? (
                  target.match?.data?.text
                ) : (
                  <span className="italic">empty text</span>
                )}
              </span>
              {skipEditing ? null : <Settings className={`text-neutral-60`} />}
            </div>
          ),
        }}
      >
        <ModifyMatchPopoverContent
          onUpdate={handleUpdate}
          match={target.match}
        />
      </Popover>
      {/*</Button>*/}
      {showRight ? (
        <IconButton
          pill
          onClick={onDecreasePriority}
          className={`ml-1 opacity-50 hover:opacity-100`}
          variant={'ghost'}
          color={'neutral'}
          title={'Decrease priority'}
        >
          <NavArrowRight />
        </IconButton>
      ) : null}
      {skipEditing || isReserved ? null : (
        <span
          className={`opacity-50 hover:opacity-100`}
          style={{ position: 'absolute', top: 0, right: -28 }}
        >
          <Tooltip
            title={<>Remove option</>}
            placement="bottom"
            type="light"
            arrow
          >
            <IconButton
              onClick={handleRemove}
              size="sm"
              variant="ghost"
              color="neutral"
              pill
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      )}
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 4,
          background: '#555',
          visibility: 'hidden',
        }}
      />
    </div>
  );
});

export default OptionMatchTextNode;

export const ModifyMatchPopoverContent = ({ match, onUpdate }) => {
  const { setDisableScroll, miniMap } = useBuilderContext();
  const formMethods = useForm({
    defaultValues: {
      ...match,
    },
  });

  useEffect(() => {
    setDisableScroll(true);
    return () => setDisableScroll(false);
  }, []);

  return (
    <FormProvider {...formMethods}>
      <div className={`flex ${miniMap ? 'space-y-4' : 'space-y-2'} flex-col`}>
        <HookFormListbox
          size={miniMap ? 'md' : 'sm'}
          label={'Match type'}
          className={`w-full`}
          name={'type'}
          options={[
            { value: 'exact', label: 'Exact' },
            // { value: 'startsWith', label: 'Starts With' },
            { value: 'contains', label: 'Contains' },
          ]}
        />
        <HookFormTextField
          name={'data.text'}
          label={'Match text'}
          placeholder={'match text...'}
        />
        <Button
          size={'md'}
          className={`w-full ${miniMap ? '!mb-4' : ''}`}
          color={`accent`}
          variant={`fill`}
          onClick={formMethods.handleSubmit(onUpdate)}
        >
          Update
        </Button>
        {/*<Checkbox checked={true} label={'Trim'} />*/}
        {/*<Checkbox checked={false} label={'Match Casing'} />*/}
      </div>
    </FormProvider>
  );
};
