import * as Sentry from '@sentry/react';
import { CustomBackdrop } from 'app/components/CustomBackdrop';
import { Onboarding } from 'app/components/Onboarding';
import constants from 'app/constants';
import { actions } from 'app/data/local';
import { useAccountQuery } from 'app/hooks/queries/account';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { store } from 'store';
import { useMediaQuery } from 'app/design';
import { useCombinedContacts } from 'app/components/Webphone/UI/ContactsView/components/CombinedContacts/CombinedContacts';

import { Layout } from './components/Layout';
import { NotFoundPage } from './components/NotFoundPage/Loadable';

import { useAuthSelector } from './data/auth';

const setSidebarKey = (key: string) =>
  store.dispatch(actions.set_sidebar_key(key));

// V2

// Pipe
// - has additional sub-routing for individual Pipe Conversations
const V2_Pipe = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/pipe/Pipe');
});

const V2_Room = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/room/Room');
});

// Contacts
// - has additional sub-routing for individual Contacts
const V2_Contacts = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/contacts/Contacts');
});

// Settings
// - has additional sub-routing for individual Settings
const V2_Settings = lazy(() => {
  // setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
  return import('app/pages/settings/Settings/Settings');
});

// // V1

// // DASHBOARD / ACCOUNT Routes
// const AdminDashboard = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DASHBOARD.sidebar_key);
//   return import('app/pages/admin/Dashboard/Dashboard');
// });

// const AdminAccountCalling = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import('app/pages/admin/AccountCalling3/AccountCalling');
// });

// const AdminOperatorExtension = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import('app/pages/admin/OperatorExtension/OperatorExtension');
// });

// const EditOperatorExtension = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import('app/pages/admin/EditOperatorExtension/EditOperatorExtension');
// });

// const EditCallHandlingAccount = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key);
//   return import(
//     'app/pages/admin/Dashboard/EditCallHandlingAccount/EditCallHandlingAccount'
//   );
// });

// const EditCallHandlingGeneral = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ALL_CALLFLOWS_LIST.sidebar_key);
//   return import(
//     'app/pages/admin/Dashboard/EditCallHandlingGeneral/EditCallHandlingGeneral'
//   );
// });

// // DEVICES / PHONES Routes
// const AdminPhonesCreate = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICES_LIST.sidebar_key);
//   return import('app/pages/admin/Phones/Create/Create');
// });

// const AdminPhonesBuy = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICES_LIST.sidebar_key);
//   return import('app/pages/admin/Phones/Buy/Buy');
// });

// const AdminDevicesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICES_LIST.sidebar_key);
//   return import('app/pages/admin/Devices/List/List');
// });

// const EditCallHandlingDevice = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICE_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Devices/View/EditCallHandlingDevice/EditCallHandlingDevice'
//   );
// });

// const AdminDeviceView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DEVICE_VIEW.sidebar_key);
//   return import('./pages/admin/Devices/View/View');
// });

// // USER Routes
// const AdminUsersCreate = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USERS_LIST.sidebar_key);
//   return import('app/pages/admin/Users/Create/Create');
// });

// const AdminUsersList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USERS_LIST.sidebar_key);
//   return import('app/pages/admin/Users/List/List');
// });

// const AdminUserView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USER_VIEW.sidebar_key);
//   return import('app/pages/admin/Users/View/View');
// });

// const EditCallHandlingUser = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USER_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Users/View/EditCallHandlingUser/EditCallHandlingUser'
//   );
// });

// LOGIN / LOGOUT Routes

const Login = lazy(() => import('app/pages/auth/Login/Login'));
const Logout = lazy(() => import('app/pages/auth/Logout/Logout'));
const ForgotPassword = lazy(
  () => import('app/pages/auth/ForgotPassword/ForgotPassword'),
);

// // VMBOX Routes
// const AdminVmboxesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_VMBOXES_LIST.sidebar_key);
//   return import('app/pages/admin/Vmboxes/List/List');
// });

// const AdminVmboxView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_VMBOX_VIEW.sidebar_key);
//   return import('app/pages/admin/Vmboxes/View/View');
// });

// const EditCallHandlingVmbox = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_VMBOX_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Vmboxes/View/EditCallHandlingVmbox/EditCallHandlingVmbox'
//   );
// });

// // CONFERENCE Routes
// const AdminConferencesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CONFERENCES_LIST.sidebar_key);
//   return import('app/pages/admin/Conferences/List/List');
// });

// const AdminConferenceView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CONFERENCE_VIEW.sidebar_key);
//   return import('app/pages/admin/Conferences/View/View');
// });

// // DIRECTORY Routes
// const AdminDirectoryView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DIRECTORY_VIEW.sidebar_key);
//   return import('app/pages/admin/Directories/View/View');
// });

// const AdminDirectoriesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_DIRECTORIES_LIST.sidebar_key);
//   return import('app/pages/admin/Directories/List/List');
// });

// // EXTENSION Routes
// const AdminExtensionsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_EXTENSIONS_LIST.sidebar_key);
//   return import('app/pages/admin/Extensions/List/List');
// });

// // GROUP Routes
// const AdminGroupView = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_GROUP_VIEW.sidebar_key);
//   return import('app/pages/admin/Groups/View/View');
// });

// const EditCallHandlingGroup = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_GROUP_CALL_HANDLING.sidebar_key);
//   return import(
//     'app/pages/admin/Groups/View/EditCallHandlingGroup/EditCallHandlingGroup'
//   );
// });

// const AdminGroupsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_GROUPS_LIST.sidebar_key);
//   return import('app/pages/admin/Groups/List/List');
// });

// // IVR Routes
// // const AdminIvrExtensionsList = lazy(
// //   () => import('app/pages/admin/IvrExtensions/List/List'),
// // );
// // const AdminIvrExtensionView = lazy(
// //   () => import('app/pages/admin/IvrExtensions/View/View'),
// // );
// // const AdminIvrEdit = lazy(() => import('app/pages/admin/Ivr/Edit/Edit'));

// // SCREENPOP Routes
// const AdminScreenpopEdit = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_SCREENPOP_VIEW.sidebar_key);
//   return import('app/pages/admin/Screenpops/Edit/Edit');
// });
// const AdminScreenpopsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_SCREENPOPS_LIST.sidebar_key);
//   return import('app/pages/admin/Screenpops/List/List');
// });

// // TEMPLATE Routes
// const AdminTemplateEdit = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_TEMPLATE_EDIT.sidebar_key);
//   return import('app/pages/admin/Templates/Edit/Edit');
// });

// const AdminTemplatesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_TEMPLATES_LIST.sidebar_key);
//   return import('app/pages/admin/Templates/List/List');
// });

// // BILLING Routes
// const AdminBillingHome = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_BILLING.sidebar_key);
//   return import('app/pages/admin/Billing/Home/Home');
// });
// const AdminBillingSummary = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_BILLING.sidebar_key);
//   return import('app/pages/admin/Billing/Summary/Summary');
// });
// const AdminBillingIntegrations = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_USER_CALL_HANDLING.sidebar_key);
//   return import('app/pages/admin/Billing/Integrations/Integrations');
// });

// // CONTACTS Route
// const AdminContactsHome = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CONTACTS.sidebar_key);
//   return import('app/pages/admin/Contacts/Home/Home');
// });

// // MESSAGING Route
// const AdminMessagingHome = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_MESSAGING.sidebar_key);
//   return import('app/pages/admin/Messaging/Home/Home');
// });

// // OFFICE Routes
// const OfficeVoicemailsView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_VOICEMAILS.sidebar_key);
//   return import('app/pages/office/Voicemails/View');
// });

// const OfficeCallingView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_CALLING.sidebar_key);
//   return import('app/pages/office/Calling/View');
// });

// const OfficeCallHistoryView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_CALL_HISTORY.sidebar_key);
//   return import('app/pages/office/CallHistory/View');
// });

// const OfficeDashboardView = lazy(() => {
//   setSidebarKey(constants.nav.OFFICE_DASHBOARD.sidebar_key);
//   return import('app/pages/office/Dashboard/View');
// });

// // CALL ROUTE Routes
// const AdminAllCallflowsList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_ALL_CALLFLOWS_LIST.sidebar_key);
//   return import('app/pages/admin/AllCallflows/List/List');
// });

// // REPORTS Routes
// const AdminCallFinder = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CALL_FINDER.sidebar_key);
//   return import('app/pages/admin/CallLogsAndReports/CallFinder/CallFinder');
// });

// const AdminCallLogsAndReports = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_CALL_LOGS.sidebar_key);
//   return import('app/pages/admin/CallLogsAndReports/CallLogsAndReports');
// });

// // MENU Routes
// const AdminMenusList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_MENUS_LIST.sidebar_key);
//   return import('app/pages/admin/Menus/List/List');
// });

// // NUMBERS Routes
// const AdminPhoneNumbersList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_NUMBERS_LIST.sidebar_key);
//   return import('app/pages/admin/PhoneNumbers/List/List');
// });

// // MEDIA Routes
// const AdminMediaList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_MEDIAS_LIST.sidebar_key);
//   return import('./pages/admin/Media/List/List');
// });

// // SCHEDULE Routes
// const AdminSchedulesList = lazy(() => {
//   setSidebarKey(constants.nav.ADMIN_SCHEDULES_LIST.sidebar_key);
//   return import('./pages/admin/Schedules/List/List');
// });

// DEV Routes
const Dev = lazy(() => import('app/pages/Dev/Dev'));

function Routing() {
  const { show_login_dialog, loggedIn, owner_id, account_id } =
    useAuthSelector();

  const { data: account } = useAccountQuery();
  const { isLoading, data: user } = useAuthenticatedUserQuery();

  useEffect(() => {
    if (loggedIn) {
      Sentry.setUser({
        ip_address: '{{auto}}',
        // @ts-ignore
        id: `${account_id}-${owner_id}`,
        owner_id,
        account_id,
        email: user?.doc?.email,
        account: account?.doc?.name,
      });
      Sentry.setTag('account_id', account_id);
      Sentry.setTag('owner_id', owner_id);
    }
  }, [account, user, loggedIn, owner_id, account_id]);

  return (
    <>
      {account?.internal?.is_canceled ? (
        <CustomBackdrop
          label={
            'Your account has been deactivated. Contact us at support@calling.io'
          }
          hideLoader
        />
      ) : null}
      {loggedIn && !account?.internal?.is_canceled ? (
        <Switch>
          <Route
            path={`/v2`}
            render={props => (
              <Redirect to={props.location.pathname.replace('/v2', '')} />
            )}
          />
          <Route
            // exact
            path={'/'}
            component={V2Routing}
            // render={() => {
            //   if (isLoading) {
            //     console.log('Loading user information before redirecting');
            //     return null;
            //   }
            //   return <Redirect to="/v2" />;
            //   // return user?.doc?.priv_level === 'admin' ? (
            //   //   <Redirect to="/admin" />
            //   // ) : (
            //   //   <Redirect to="/office" />
            //   // );
            // }}
          />
        </Switch>
      ) : (
        <Switch>
          <Route path={'/logout'} component={Logout} />
          <Route exact path={'/login'} component={Login} />
          <Route exact path={'/forgot-password'} component={ForgotPassword} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      )}
    </>
  );
}

const V2Routing = () => {
  useCombinedContacts();

  const isMobile = useMediaQuery('(max-width:680px)', { noSsr: true });

  return (
    <Layout>
      <Switch>
        <Route
          exact
          path={'/'}
          render={() => {
            if (isMobile) {
              return <Redirect to="/settings" />;
            }
            return <Redirect to="/pipe" />;
          }}
        />
        <Route path={'/settings'} component={V2_Settings} />
        <Route path={'/contacts'} component={V2_Contacts} />
        <Route path={'/pipe/:pipe_id'} component={V2_Pipe} />
        <Route path={'/room/:pipe_id'} component={V2_Room} />
        <Route path={'/pipe'} component={V2_Pipe} />
        {/* <Route path="/voicemails" component={OfficeVoicemailsView} />
        <Route path="/call_history" component={OfficeCallHistoryView} />
        <Route path="/calling" component={OfficeCallingView} /> */}
        <Route path={'/onboarding'} component={Onboarding} />
        {/*<Route path="/v2" component={V2Routing} />*/}
        <Route path={'/dev'} component={Dev} />
        <Route path={'/logout'} component={Logout} />
        {/* Redirecting login below (might not be necessary...) */}
        <Route exact path={'/login'} render={() => <Redirect to="/" />} />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
};

export default Routing;
