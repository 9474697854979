import * as React from 'react';
import { useState, useEffect } from 'react';
import constants from 'app/constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import { Button, Setting } from 'app/design-lib';
import { SettingsPanelLoader } from 'app/design-lib/components/SettingsPanelLoader.tsx';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';

import useDevMode from 'app/utilities/useDevMode';

import {
  Redirect,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from 'react-router-dom';

import {
  WarningTriangle as WarningTriangleIcon,
  DeleteCircle as DeleteCircleIcon,
  Attachment as AttachmentIcon,
  ThumbsUp as ThumbsUpIcon,
} from 'iconoir-react';

import { useAccountQuery } from 'app/hooks/queries/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { gql, request } from 'graphql-request';
import { store } from 'store';
import { usePubNub } from 'pubnub-react';

import EmojiPicker, { Emoji } from 'emoji-picker-react';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import { Popper } from 'app/design';
import { RichTooltip } from 'app/components/RichTooltip';

import { useTioMutation, usePipeUpdate, useMessagingSet } from 'app/sdk';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { usePhoneNumberQuery } from 'app/hooks/queries/phoneNumber';
import { useListExtensionsQuery } from 'app/hooks/queries/extension';
import { PftDialog } from 'app/components/PftDialog';

function Messaging({ pipe }) {
  // shows the users for the user_ids of the pipe

  const numberQuery = usePhoneNumberQuery({ id: pipe.key });
  const number = numberQuery.data;

  const [showPft, showPftSet] = React.useState(false);
  const pipeUpdate = usePipeUpdate();
  const messagingSet = useTioMutation();

  const { devMode } = useDevMode();

  const handleMessagingSet = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/messaging`,
      data: {
        enable: true,
        campaign_id: 'unchanged',
      },
    });
    alert(
      'Order submitted, please try receiving! You should not need to press this button again.',
    );
  };

  const handleMessagingSetOff = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/messaging`,
      data: {
        enable: false,
        // campaign_id: 'off',
      },
    });
    alert(
      'Order submitted, please try receiving! You should not need to press this button again.',
    );
  };

  const handleMessagingSetNoSending = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/messaging`,
      data: {
        enable: true,
        campaign_id: 'delete',
      },
    });
    alert('Order submitted.');
  };

  const handleSyncNumber = async () => {
    // @ts-ignore
    await messagingSet.mutateAsync({
      method: 'POST',
      url: `/phone_numbers/${pipe.key}/sync`, // could also sync ALL numbers?
      data: {},
    });
    // await numberQuery.refetch();
    // alert('Synced!');
  };

  const handleRefetchNumbers = async () => {
    await numberQuery.refetch();
    // alert('Synced!');
  };

  // console.log('numberQuery:', numberQuery);

  return (
    <SettingsPanel
      id="messaging"
      leftChild={
        <>
          <Setting
            label={'10DLC'}
            body={'Outgoing Messages'}
            // iconButtonProps={
            //   callflow
            //     ? {
            //         children: <EditPencil fr={undefined} />,
            //         onClick: () => {
            //           router.push(
            //             `/v2/settings/pipes/${pipe.id}/call_routing/${callflow.id}`,
            //           );
            //           // if (!isHiddenVisualBuilder) {
            //           //   // openTour('visual_builder');
            //           // }
            //           // toggleOpenDrawer();
            //         },
            //       }
            //     : undefined
            // }
          />
        </>
      }
      rightChild={
        numberQuery.isLoading ? (
          <SettingsPanelLoader label={'Loading number details...'} />
        ) : (
          <div>
            {/*<div className="text-2xl flex justify-between">
            {callflow ? (
              <Button
                variant={'ghost'}
                color={'accent'}
                onClick={() => {
                  if (!isHiddenVisualBuilder) {
                    openTour('visual_builder');
                  }
                  toggleOpenDrawer();
                }}
              >
                Edit
              </Button>
            ) : null}
          </div>*/}

            {number?.doc?.trunkingio?.messaging_campaign_id?.length ? (
              <div className="flex p-2 mt-2 space-x-2 rounded border border-neutral-60 bg-neutral-60/10 text-neutral-50 text-sm">
                <ThumbsUpIcon width={16} height={16} fr={undefined} />
                <div className="">
                  Campaign:{' '}
                  <span className="inline font-bold">
                    {number?.doc?.trunkingio?.messaging_campaign_id}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex p-2 mt-2 space-x-2 rounded border border-negative-60 bg-negative-60/10 text-negative-50 text-sm">
                <WarningTriangleIcon width={16} height={16} fr={undefined} />
                <div className="">
                  Number is not assigned to a Campaign for sending SMS/MMS
                </div>
              </div>
            )}

            <Button
              // startIcon={<AddIcon />}
              color={'negative'}
              variant={'fill'}
              size={'sm'}
              className="mt-1"
              component={Link}
              // onClick={handleMessagingCampaignDefaultLowVolume}
              // to="/v2/settings/campaigns"
              to={`/v2/settings/campaigns`}
              disabled={numberQuery.isLoading}
            >
              Go to 10DLC Settings
            </Button>

            {devMode ? (
              <div className="mt-4 flex-wrap flex">
                <Button
                  color={'accent'}
                  size={'sm'}
                  variant={'outline'}
                  className={'mt-2 mr-2'}
                  onClick={handleSyncNumber}
                  // startIcon={<PersonAddIcon />}
                >
                  Sync Number (to kazoo)
                </Button>

                <Button
                  color={'accent'}
                  size={'sm'}
                  variant={'outline'}
                  className={'mt-2 mr-2'}
                  onClick={handleRefetchNumbers}
                  // startIcon={<PersonAddIcon />}
                >
                  Refetch Numbers (look in console)
                </Button>

                <Button
                  color={'accent'}
                  size={'sm'}
                  variant={'outline'}
                  className={'mt-2 mr-2'}
                  onClick={handleMessagingSet}
                  // startIcon={<PersonAddIcon />}
                >
                  Enable Messaging
                </Button>

                <Button
                  color={'accent'}
                  size={'sm'}
                  variant={'outline'}
                  className={'mt-2 mr-2'}
                  onClick={handleMessagingSetOff}
                  // startIcon={<PersonAddIcon />}
                >
                  Disable Messaging
                </Button>

                <Button
                  color={'accent'}
                  size={'sm'}
                  variant={'outline'}
                  className={'mt-2 mr-2'}
                  onClick={handleMessagingSetNoSending}
                  // startIcon={<PersonAddIcon />}
                  disabled={
                    !number?.doc?.trunkingio?.messaging_campaign_id?.length
                  }
                >
                  Remove Campaign
                </Button>
              </div>
            ) : null}
          </div>
        )
      }
    />
  );
}

export default Messaging;
