import { SettingsPanelLoader } from 'app/design-lib/components/SettingsPanelLoader.tsx';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';
import { EditPencil, Lock, Clock, Cancel } from 'iconoir-react';
import { Dialog, IconButton, Setting, Badge } from 'app/design-lib';

import { useHistory } from 'react-router-dom';

import { useListCallflowsQuery } from 'app/hooks/queries/callflow';
import { IvrQuickDisplay } from 'app/components/IvrBuilder';

import { useGlobalTour } from 'app/components/Tourguide/Helper';
import { useHint, useHints } from 'app/utilities';
import { VisualBuilder } from './components/VisualBuilder';
import useToggleReducer from 'app/utilities/useToggleReducer';
import { getDay } from 'date-fns';
// import { AnimationDialog } from 'app/design';
// import { AnimationDialogProps } from 'app/design/components/AnimationDialog/AnimationDialog';
import { utcToZonedTime } from 'date-fns-tz';
import { useUpdateCallflowPartial } from 'app/hooks/mutations/callflow';
function CallRouting({ pipe }) {
  // shows the callflows for the user_ids of the pipe
  const router = useHistory();
  const [{ openTour }] = useGlobalTour();
  const [openDrawer, toggleOpenDrawer] = useToggleReducer(false);

  const [isHiddenVisualBuilder, hideHint, refetchHints, setHintValue] = useHint(
    'admin.setup.callflow.visual_builder',
  );

  const {
    data: callflowsPage,
    isLoading: callflowsLoading,
    refetch,
  } = useListCallflowsQuery({
    take: 1,
    filters: {
      raw: [{ variables: { docFilters: [['numbers', 'contains', pipe.key]] } }],
    },
    options: {},
  });
  const callflows = callflowsPage?.callflows ?? [];
  const callflow = callflows?.length ? callflows[0] : null;

  return (
    <SettingsPanel
      id="call_routing"
      leftChild={
        <>
          <Setting
            label={'Call routing'}
            body={
              'Determines how callers will be routed when this line is dialed'
            }
            iconButtonProps={
              callflow
                ? {
                    children: <EditPencil fr={undefined} />,
                    onClick: () => {
                      router.push(
                        `/v2/settings/pipes/${pipe.id}/call_routing/${callflow.id}`,
                      );
                      // if (!isHiddenVisualBuilder) {
                      //   // openTour('visual_builder');
                      // }
                      // toggleOpenDrawer();
                    },
                  }
                : undefined
            }
          />
          {callflow?.doc?.schedules ? (
            <ScheduleViews
              callflow={callflow}
              schedules={callflow?.doc?.schedules}
              refetch={refetch}
            />
          ) : null}
        </>
      }
      rightChild={
        <div data-tour-settings-lines-view-callrouting>
          {/*<div className="text-2xl flex justify-between">
            {callflow ? (
              <Button
                variant={'ghost'}
                color={'accent'}
                onClick={() => {
                  if (!isHiddenVisualBuilder) {
                    openTour('visual_builder');
                  }
                  toggleOpenDrawer();
                }}
              >
                Edit
              </Button>
            ) : null}
          </div>*/}
          <Dialog size={'4xl'} open={openDrawer} onClose={() => {}}>
            <div className="p-[24px] h-full overflow-hidden">
              {openDrawer ? (
                <VisualBuilder
                  callflow_id={callflow?.id}
                  onSave={toggleOpenDrawer}
                  onCancel={toggleOpenDrawer}
                />
              ) : null}
            </div>
          </Dialog>
          <div className="space-y-4">
            {!callflowsLoading && !callflow ? (
              <div className="bg-gray-50 rounded-lg py-5 px-6 mb-4 text-base text-gray-500 mb-3">
                Call routing not yet setup
              </div>
            ) : (
              <div
                className={`relative w-full h-[300px] rounded-md ${
                  callflow ? 'bg-neutral-100' : ''
                }`}
                // sx={{
                //   position: 'relative',
                //   width: '100%',
                //   height: '300px',
                //   borderRadius: '4px',
                //   border: '1px solid #efefef',
                //   background: '#f3fdfd',
                // }}
              >
                {/* Hide the QuickDisplay when the Drawer is open, otherwise we get weird errors when editing a callflow */}
                {openDrawer ? null : callflowsLoading ? (
                  <SettingsPanelLoader label={'Loading call routing...'} />
                ) : (
                  <IvrQuickDisplay
                    pipe={pipe}
                    // id={JSON.stringify(callflow)}
                    // key={mainCallflowKey} // causes a rerender when changed!
                    // @ts-ignore
                    callflow={callflow.doc}
                    showControls
                  />
                )}
              </div>
            )}

            {/* {openDrawer ? ( */}
            {/* ) : null} */}
          </div>
        </div>
      }
    />
  );

  // console.log('callflow:', callflow);
  // return (
  //   <div data-tour-settings-lines-view-callrouting>
  //     <div className="text-2xl flex justify-between">
  //       <div className="text-lg font-bold">Call Routing</div>
  //       {callflow ? (
  //         <Button
  //           variant={'ghost'}
  //           color={'accent'}
  //           onClick={() => {
  //             if (!isHiddenVisualBuilder) {
  //               openTour('visual_builder');
  //             }
  //             toggleOpenDrawer();
  //           }}
  //         >
  //           Edit
  //         </Button>
  //       ) : null}
  //     </div>
  //     <div className="p-4 space-y-4">
  //       {callflowsLoading ? <div>Loading call routing...</div> : null}
  //       {!callflowsLoading && !callflow ? (
  //         <div className="bg-gray-50 rounded-lg py-5 px-6 mb-4 text-base text-gray-500 mb-3">
  //           Call routing not yet setup
  //         </div>
  //       ) : null}
  //
  //       {/* {openDrawer ? ( */}
  //       <Dialog size={'4xl'} open={openDrawer} onClose={() => {}}>
  //         <div className="p-[24px] h-full overflow-hidden">
  //           {openDrawer ? (
  //             <VisualBuilder
  //               callflow_id={callflow?.id}
  //               onSave={toggleOpenDrawer}
  //               onCancel={toggleOpenDrawer}
  //             />
  //           ) : null}
  //         </div>
  //       </Dialog>
  //       {/* ) : null} */}
  //
  //       {callflow ? (
  //         <div>
  //           {/* <div>
  //             <Button
  //               variant={'outline'}
  //               color={'accent'}
  //               onClick={toggleOpenDrawer}
  //             >
  //               Change Call Routing
  //             </Button>
  //              <Link
  //               to={`/v2/settings/pipes/${pipe.id}/call_routing/${callflow.id}`}
  //             >
  //               Change Call Routing
  //             </Link>
  //           </div>*/}
  //           {callflow.doc.strategy.default ? (
  //             <div className={`space-y-1`}>
  //               <div className={`text-base`}>
  //                 You are setup for{' '}
  //                 <span className="font-bold">default call routing:</span>
  //               </div>
  //               <br />
  //               <div className={`pl-2 text-base`}>
  //                 1. Ring all users in Line for 20 seconds.{' '}
  //               </div>
  //               <div className={`pl-2 text-base`}>2. Go to Voicemail</div>
  //               <br />
  //               <div className={`text-base`}>
  //                 You can add more advanced routing functionality anytime by
  //                 using the Edit link in the top-right.
  //               </div>
  //             </div>
  //           ) : (
  //             <div
  //               className={`relative w-full h-[300px] rounded-md bg-neutral-100`}
  //               // sx={{
  //               //   position: 'relative',
  //               //   width: '100%',
  //               //   height: '300px',
  //               //   borderRadius: '4px',
  //               //   border: '1px solid #efefef',
  //               //   background: '#f3fdfd',
  //               // }}
  //             >
  //               {/* Hide the QuickDisplay when the Drawer is open, otherwise we get weird errors when editing a callflow */}
  //               {openDrawer ? null : (
  //                 <IvrQuickDisplay
  //                   // id={JSON.stringify(callflow)}
  //                   // key={mainCallflowKey} // causes a rerender when changed!
  //                   callflow={callflow.doc}
  //                   showControls
  //                 />
  //               )}
  //             </div>
  //           )}
  //         </div>
  //       ) : null}
  //     </div>
  //   </div>
  // );
}

const ScheduleViews = ({ schedules: schedulesMap, refetch, callflow }) => {
  const schedules = Object.entries(schedulesMap);
  // console.log('schedules:', schedules);
  if (!schedules?.length) {
    return null;
  }
  return (
    <div className={`flex flex-col mt-4 space-y-8`}>
      {/* <span className={`text-neutral-60 text-sm font-medium`}>Schedule</span> */}
      {schedules.map(([scheduleId, schedule]: any[]) => {
        return (
          <ScheduleView
            refetch={refetch}
            key={schedule.id}
            callflow={callflow}
            schedule={schedule}
            scheduleId={scheduleId}
          />
        );
      })}
    </div>
  );
};

const ScheduleView = ({ schedule, refetch, callflow, scheduleId }) => {
  let { categories, forced_category_id } = schedule;
  const updateCallflow = useUpdateCallflowPartial();
  const handleForce = async category_id => {
    try {
      await updateCallflow.mutateAsync({
        id: callflow.id,
        schedules: {
          ...callflow.doc.schedules,
          [scheduleId]: {
            ...callflow.doc.schedules[scheduleId],
            forced_category_id:
              // toggle off if already set
              category_id ===
              callflow.doc.schedules[scheduleId].forced_category_id
                ? undefined
                : category_id,
          },
        },
      });
      refetch();
    } catch (e) {
      console.error(e);
    }
  };

  let allCategories = [...categories, { id: 'fallback', ...schedule.fallback }];

  const tmpDoesMatch = -1; // this should be a value from the server, not computed locally

  return (
    <div className={`flex flex-col space-y-2`}>
      {allCategories?.map((category, categoryIndex) => {
        const { values = [] } = category;
        const now = utcToZonedTime(
          new Date(),
          category.timezone ?? schedule.timezone,
        );
        // console.log(category.timezone, schedule.timezone);
        const isForced = forced_category_id === category.id;
        const cfLiveSchedule = callflow.Schedules?.[scheduleId];
        const isMatching = cfLiveSchedule?.matching.includes(category.id);
        return (
          <div key={category.id} className={`flex flex-col space-y-1 `}>
            <div
              className={`flex flex-wrap w-full items-center justify-between`}
            >
              {/*<IconButton
                title={
                  isForced ? 'Undo Force-Active' : 'Force this time to active'
                }
                variant={'ghost'}
                color={isForced ? 'negative' : 'neutral'}
                onClick={() => handleForce(category.id)}
              >
                {isForced ? <Lock fr={undefined} /> : <NoLock fr={undefined} />}
              </IconButton>*/}
              <span
                className={`text-md ${
                  isForced
                    ? 'text-negative-60'
                    : tmpDoesMatch === categoryIndex
                    ? 'text-neutral-60'
                    : cfLiveSchedule?.current === category.id
                    ? 'text-positive-60'
                    : ''
                }`}
              >
                {category.name}
              </span>
              <div
                className={`flex-1 border border-dashed mx-4 ${
                  isForced
                    ? 'border-negative-60'
                    : tmpDoesMatch === categoryIndex
                    ? 'border-neutral-60'
                    : cfLiveSchedule?.current === category.id
                    ? 'border-positive-60'
                    : ''
                }`}
              ></div>
              {cfLiveSchedule?.current === category.id ? (
                <div className={`flex space-x-1`}>
                  {isForced ? (
                    <IconButton
                      title={
                        isForced
                          ? 'Undo Force-Active'
                          : 'Force this time to active'
                      }
                      variant={'ghost'}
                      color={isForced ? 'negative' : 'neutral'}
                      onClick={() => handleForce(category.id)}
                    >
                      <Cancel fr={undefined} />
                    </IconButton>
                  ) : null}

                  <Badge
                    startIcon={
                      isForced ? (
                        <Lock fr={undefined} />
                      ) : (
                        <Clock fr={undefined} />
                      )
                    }
                    color={isForced ? 'negative' : 'positive'}
                    size={'sm'}
                    variant={'fill'}
                    onClick={() => handleForce(category.id)}
                  >
                    {isForced ? 'Forced Active' : 'Currently Active'}
                  </Badge>
                </div>
              ) : (
                <Badge
                  startIcon={isMatching ? <Clock fr={undefined} /> : undefined}
                  color={'neutral'}
                  size={'sm'}
                  variant={'outline'}
                  onClick={() => handleForce(category.id)}
                >
                  {isMatching
                    ? 'Matching (Overridden)'
                    : 'Click to Force Active'}
                </Badge>
              )}
              {/*{isMatching ? (*/}
              {/*  <Badge*/}
              {/*    startIcon={<ArcheryMatch fr={undefined} />}*/}
              {/*    color={'positive'}*/}
              {/*    size={'sm'}*/}
              {/*    variant={'outline'}*/}
              {/*  >*/}
              {/*    matching*/}
              {/*  </Badge>*/}
              {/*) : (*/}
              {/*  <div></div>*/}
              {/*)}*/}
            </div>
            {/* {values.map((value, valueIndex) => {
              switch (value.type) {
                case 'day_of_week':
                  return (
                    <span key={valueIndex} className={`ml-2 text-sm`}>
                      {DaysOfWeek[value.data.day_of_week]}
                    </span>
                  );
                case 'day_of_week_hours':
                  return (
                    <span
                      key={valueIndex}
                      className={`ml-2 text-sm ${
                        tmpDoesMatch === categoryIndex &&
                        tmpDoesMatch === valueIndex
                          ? 'text-accent-60'
                          : ''
                      }`}
                    >
                      {DaysOfWeek[value.data.day_of_week]}:{' '}
                      {getLabel(value.data.from)} - {getLabel(value.data.to)}
                    </span>
                  );
              }
            })} */}
          </div>
        );
      })}
    </div>
  );
};

export default CallRouting;
