import { Typography } from 'app/design';
import { IvrBuilder } from 'app/components/IvrBuilder';
import {
  useCallflowQuery,
  useListCallflowsQuery,
} from 'app/hooks/queries/callflow';
import { parseGraphqlErrorCode } from 'app/utilities';
import { useParams } from 'react-router-dom';

import React from 'react';

function VisualBuilder({ callflow_id, onSave, onCancel }) {
  // @ts-ignore
  // const { callflow_id } = useParams();

  const {
    data: callflowsPageResponse,
    isLoading: callflowsAreLoading,
    isFetching: callflowsFetching,
    refetch: refetchDevices,
    error: callFlowError,
    status,
  } = useListCallflowsQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [
              ['id', '=', callflow_id],
              // ['type', '=', 'general'],
            ],
          },
        },
      ],
    },
    options: { enabled: !!callflow_id },
  });

  const [callflow] = callflowsPageResponse?.callflows ?? [];

  // console.log('CALLFLOW:', callflow);
  // useNavDetails({
  //   sidebar_key: constants.nav.ADMIN_ACCOUNT_CALLING.sidebar_key,
  //   crumbs: () => [
  //     constants.nav.ADMIN.crumb,
  //     // constants.nav.ADMIN_DASHBOARD.crumb,
  //     constants.nav.ADMIN_ACCOUNT_CALLING.crumb,
  //   ],
  // });

  if (callflowsAreLoading) {
    return <div>Loading...</div>;
  }

  if (callFlowError) {
    const error = callFlowError; // ? callFlowError : accountError;

    // @ts-ignore
    const code = parseGraphqlErrorCode(error) ?? error.message;

    return (
      <Typography sx={{ color: 'error.main' }}>
        Error loading call handing: {code}
      </Typography>
    );
  }

  // return (
  //   <CallRoutingAccount
  //     name_type="account"
  //     owner_type="account"
  //     type="main"
  //     resourceId={account?.id}
  //     defaultNumbers={[]}
  //     callflow={callflow}
  //     onComplete={() => {
  //       // toggleOpenCallRoutingDialog();
  //       // refetch();
  //     }}
  //   />
  // );

  if (!callflow) {
    return (
      <Typography sx={{ color: 'error.main' }}>
        Call Routing is not setup for Pipe
      </Typography>
    );
  }

  return (
    <IvrBuilder
      key={callflow.id}
      callflow={callflow}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
}

export default VisualBuilder;
