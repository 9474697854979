import { Tooltip } from '@mui/material';
import { Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { EditMacAddressDialog } from 'app/pages/settings/Devices/View/components/DeviceMacSettings/EditMacAddressDialog';
import { formatMacAddress } from 'app/pages/settings/Devices/Devices';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';
import { useToggleReducer } from 'app/utilities';
import copy from 'copy-to-clipboard';
import { Cancel, EditPencil } from 'iconoir-react';
import * as React from 'react';
import { Device } from 'types/device';

interface DeviceMacSettingsProps {
  device: Device;
  onComplete: () => void;
}

const DeviceMacSettings = ({ device, onComplete }: DeviceMacSettingsProps) => {
  const [showEditDialog, toggleEditDialog] = useToggleReducer();

  if (!device.doc?.mac_address) return null;

  return (
    <>
      <EditMacAddressDialog
        ContentProps={{
          deviceId: device.id,
          onComplete: () => {
            onComplete();
            toggleEditDialog();
          },
          onCancel: toggleEditDialog,
        }}
        DialogProps={{
          open: showEditDialog,
        }}
      />
      <SettingsPanel
        leftChild={
          <Setting
            iconButtonProps={
              showEditDialog
                ? undefined
                : {
                    children: <EditPencil fr={undefined} />,
                    onClick: toggleEditDialog,
                  }
            }
            buttonProps={
              showEditDialog
                ? {
                    children: 'Cancel',
                    startIcon: <Cancel fr={undefined} />,
                    color: 'neutral',
                    onClick: toggleEditDialog,
                  }
                : undefined
            }
            label={'MAC Address'}
            body={'The MAC address associated with your device.'}
          />
        }
        rightChild={
          <div className={'flex flex-col space-y-2'}>
            <Table>
              <TableRow showBorder={false}>
                <TableCell className={`font-medium w-32`}>
                  MAC Address
                </TableCell>
                <TableCell>
                  {formatMacAddress(device.doc?.mac_address)}
                </TableCell>
              </TableRow>
            </Table>
          </div>
        }
      />
    </>
  );
};

export default DeviceMacSettings;
