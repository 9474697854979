import {
  CALL_DIRECTION_OUTGOING,
  CALL_STATUS_PROGRESS,
} from 'app/lib/react-sip';
import { useWebphoneSlice } from 'app/data/webphone';
import { useLocalSelector, useLocalSlice } from 'app/data/local';
import {
  Box,
  Chip,
  Grid,
  InfoTooltip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Slider,
  Tooltip,
  Typography,
} from 'app/design';
import { Link, useHistory } from 'react-router-dom';
import { Badge, Button, Avatar, IconButton } from 'app/design-lib';
import {
  CallEnd as CallEndIcon,
  CallMerge as CallMergeIcon,
  FastForward as FastForwardIcon,
  LocalParking as LocalParkingIcon,
  Mic as MuteIcon,
  MicOff as UnMuteIcon,
  Notes as NotesIcon,
  // Pause as PauseIcon,
  // Phone as PhoneIcon,
  PhoneCallback as PhoneCallbackIcon,
  PhoneForwarded as PhoneForwardedIcon,
  PhonePausedOutlined as CallOnHoldIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  Videocam as VideocamIcon,
  VolumeDown as VolumeUpIcon,
  VolumeMute as VolumeOffIcon,
  VolumeOff as AmplifyOffIcon,
  VolumeUp as AmplifyOnIcon,
} from 'app/design/icons-material';
import {
  Message as MessageIcon,
  Phone as PhoneIcon,
  PhoneDelete as PhoneDeleteIcon,
  PhonePaused as PhonePausedIcon,
  PhoneOutcome as PhoneOutcomeIcon,
  PhoneIncome as PhoneIncomeIcon,
  Mic as MicIcon,
  MicMute as MicMuteIcon,
  Pause as PauseIcon,
  Dialpad as DialpadIcon,
  RightRoundArrow as RightRoundArrowIcon,
  PhoneDisabled as PhoneDisabledIcon,
} from 'iconoir-react';
import { sleep, validIpAddress } from 'app/utilities';
import CloseIcon from '@mui/icons-material/Close';
import { compact, flatten } from 'lodash';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import prettyMs from 'pretty-ms';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInterval } from 'react-use';
import { useLookupContact } from '../../../../../data/webphone/webphoneSlice';
import { useCallsParkedQuery } from '../../../../../hooks/queries/calls';
import useMaybeContactsQuery from '../../../../../hooks/queries/contact/useMaybeContactsQuery';
import { MaybeContactDisplay } from '../../../../MaybeContactDisplay';
import { PhoneNumberDisplay } from '../../../../PhoneNumberDisplay';
import { useWebphoneContext } from '../../../Store';
import { ContactDisplay } from '../../ContactDisplay';
import { Numpad } from '../../Numpad';
import { NotesEditor } from './NotesEditor';
import { Notifications } from './Notifications';
import { Screenpops } from './Screenpops';
import useStyles from './styles';
import constants from 'app/constants';
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import { usePipeConvosQuery, usePipesQuery } from 'app/sdk';
import { Emoji } from 'emoji-picker-react';
import { UserAvatar } from '../../../../Sidebar/Sidebar';

const CallInfo = (props, context) => {
  const { call, externalPipes } = props;
  // console.log('CALL:', call, stringify(call));
  // debugger;
  const dispatchRedux = useDispatch();
  const { actions } = useWebphoneSlice();
  const [contact] = useLookupContact(call.remoteUser);
  const { data: maybeContacts } = useMaybeContactsQuery({});
  const maybeContact = maybeContacts?.find(c => c.number === call.remoteUser);
  const [state] = useWebphoneContext();
  const { calls } = state;

  const disposition = call.getDisposition();

  const [callState, setCallState] = useState({
    showNotes: false,
    showKeypad: false,
  });

  // 'idle'
  // 'dialing'
  // 'ringing'
  // 'progress'
  // 'active'
  // 'local hold'

  let bgStatus = 'gray';
  // let bgStatus = '#edc585';
  // let bgColor = 'none';
  // let borderColor = 'none';
  // let rowColor = 'white';
  // rowFull = false; // TODO: dark mode

  // status background
  switch (disposition) {
    case 'dialing':
    case 'progress':
      // rowColor = 'primary';
      break;
    case 'ringing':
      // rowColor = '#A5D6A7';
      bgStatus = 'error';
      break;
    case 'local hold':
      // rowColor = '#FFCC80';
      bgStatus = 'warning';
      break;
    case 'active':
      // rowFull = true;
      // rowColor = '#F1F8E9';
      bgStatus = 'success';
      break;
    case 'idle':
    default:
      break;
  }
  // if (disposition === 'active') {
  //   bgStatus = 'success';
  //   // bgStatus = '#32a852';
  // } else if (
  //   disposition === 'ringing' ||
  //   disposition === 'dialing' ||
  //   disposition === 'progress'
  // ) {
  //   bgStatus = 'primary';
  //   // bgStatus = '#4f99e3';
  // }
  // if active
  // if (call.isMediaActive() || call.isEstablishing()) {
  //   bgColor = '#e0e0e0';
  // }
  const callEventHandler = (event, params) => {
    const { localVideoRef, remoteVideoRef } = props;
    switch (event) {
      case 'input.stream.opened':
      case 'input.stream.modified':
        if (params.video) {
          localVideoRef.current.srcObject = call.getInputMediaStream();
        }
        break;
      case 'output.stream.opened':
      case 'output.stream.modified':
        if (params.video) {
          remoteVideoRef.current.srcObject = call.getOutputMediaStream();
        }
        break;
      default:
    }
  };
  const onStopCall = () => {
    if (call.isRinging()) {
      call.reject();
    } else {
      call.hangup();
    }
  };
  const onAcceptCall = hasVideo => {
    // Auto Hold
    // Hold active call
    const activeCalls = calls.filter(
      item => item.isActive() && item.isOnLocalHold() === false,
    );
    activeCalls.forEach(activeCall => {
      activeCall.hold();
    });
    const startAudio = true,
      startVideo = hasVideo;
    call.accept(startAudio, startVideo, callEventHandler); // accepting BOTH audio and video??
  };
  // const handleUnhold = () => {
  //   //  only 1 active call allowed (hold any other active calls if we're unholding this line)
  //   // if (call.isOnLocalHold()) {
  //   const activeCalls = calls.filter(
  //     item => item.isActive() && item.isOnLocalHold() === false,
  //   );
  //   activeCalls.forEach(activeCall => {
  //     activeCall.hold();
  //   });
  //   call.unhold();
  //   // } else {
  //   //   call.hold();
  //   // }
  // };

  // // Ringing/Audio
  // useEffect(() => {
  //   // start ringing
  //   if (call.isRinging()) {

  //   }

  // },[])

  // handle conferences dial-in
  const runOnce = useRef(null);
  useEffect(() => {
    const opts = call.getAdditionalInfo();
    // Mute, wait for first audio "Please enter conference number", then send DTMF tones to join conference
    if (opts?.conference) {
      if (call.isActive()) {
        if (runOnce.current) {
          console.log('already run once');
          return;
        }
        runOnce.current = true;

        // // console.log('volume off (does NOT work)');
        // setSpeakerVolume(1);

        // try to send dtmf tones (do it until we fail!)
        // - try 100 times max? exponential backoff?
        (async () => {
          await sleep(6000); // wait 2 seconds before trying at all
          call.sendDTMF(`${opts.number}#`);
          // // todo: send PIN also if necessary?

          // for (let i = 0; i < 100; i++) {
          //   try {
          //     console.log('sending dtmf');
          //     call.sendDTMF(`${opts.number}#`);
          //     break;
          //   } catch (err) {
          //     console.log('not ready for dtmf yet');
          //     await sleep(100);
          //   }
          // }
          // console.log('volume reset, joined conference!');
          // setSpeakerVolume(100); // reset speaker volume
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call.isActive()]);

  // handle call ending
  // - saving important data to history
  // const runOnceEnding = useRef(null);
  useEffect(() => {
    // TODO: should be updating the CallHistory every time the call changes!
    // - otherwise a page refresh will result in NO ENTRY in the call history!

    // const opts = call.getAdditionalInfo();
    // // Mute, wait for first audio "Please enter conference number", then send DTMF tones to join conference
    // if (opts?.conference) {
    // }
    return () => {
      if (call._endType) {
        // const { call_history } = store.getState().webphone;

        console.log('call ended:', call._notes);
        const newCallEntry = {
          id: call.getId(),
          direction:
            call._direction === CALL_DIRECTION_OUTGOING
              ? 'outgoing'
              : 'incoming',
          remoteName: call.remoteName,
          remoteUser: call.remoteUser,
          startTime: call.startTime || new Date().toString(),
          endTime: call.endTime || new Date().toString(),
          endType: call._endType,
          errorReason: call._errorReason,
          additionalInfo: call._additionalInfo,
          screenpops: call._screenpops || [],
          notes: call._notes || { type: 'text', data: '' }, // markdown?
        };

        // dispatchRedux(actions.add_to_call_history(newCallEntry));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (call.isRinging()) {
    return (
      <div className={`w-full bg-black text-white py-2.5 px-4 flex space-x-2`}>
        <div>
          {contact ? (
            contact?.user ? (
              <UserAvatar user={contact?.user} size={'md'} />
            ) : (
              <Avatar
                size={'md'}
                initials={
                  `${contact.info?.firstName?.[0] ?? ''}${
                    contact.info?.lastName?.[0] ?? ''
                  }` ?? contact.info.company[0]
                }
              />
            )
          ) : (
            <Avatar size={'md'} />
          )}
        </div>
        <div className={`flex-1`}>
          <div className={`w-full flex items-center justify-between`}>
            <div className={`flex space-x-2 items-center`}>
              <span className={`text-md font-medium`}>
                {contact
                  ? contact.user?.doc.fullName ??
                    `${contact.info.firstName} ${contact.info.lastName}`
                  : call.remoteName ?? 'No caller ID'}
              </span>
            </div>
            <Badge color={'attention'} variant={'fill'}>
              Incoming call
            </Badge>
          </div>
          <div className={`flex space-x-2 items-center`}>
            <div className={`text-sm text-neutral-30`}>
              <PhoneNumberDisplay ptn={call.remoteUser} hideFlag />
            </div>
            {/*<div>
              <RightRoundArrowIcon height={12} width={12} />
            </div>
            <div className={`flex items-center space-x-1`}>
              <Emoji unified={'1f937'} size={16} />
              <span className={`font-medium text-sm`}>Primary</span>
            </div>*/}
          </div>
          <div className={`flex w-full mt-2 items-center space-x-2`}>
            <Button
              onClick={onStopCall}
              size={'md'}
              className={`flex-1`}
              startIcon={<PhoneDisabledIcon fr={undefined} />}
              color={'negative'}
            >
              Decline
            </Button>
            <Button
              onClick={e => onAcceptCall(false)}
              size={'md'}
              className={`flex-1`}
              startIcon={<PhoneIcon fr={undefined} />}
              color={'positive'}
            >
              Answer
            </Button>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div className="p-2 bg-gray-800 text-white">
    //     <div className="flex items-center space-x-2">
    //       <div className="flex-auto">
    //         <div className="text-sm mb-1">Incoming Call</div>
    //         {contact ? (
    //           <ContactDisplay contact={contact} number={call.remoteUser} />
    //         ) : maybeContact ? (
    //           <MaybeContactDisplay maybeContact={maybeContact} />
    //         ) : (
    //           <Grid
    //             container
    //             alignItems="center"
    //             wrap={'nowrap'}
    //             columnSpacing={2}
    //           >
    //             <Grid item>
    //               <Avatar sx={{ width: 40, height: 40 }} />
    //             </Grid>
    //             <Grid item sx={{ flex: 1 }}>
    //               {/*  */}
    //               {call.remoteName !== call.remoteUser.replace('+', '') ? (
    //                 <Grid container columnSpacing={1}>
    //                   <Grid item>
    //                     <Typography
    //                       sx={{
    //                         display: 'inline-block',
    //                         fontWeight: 700,
    //                         fontSize: '16px',
    //                       }}
    //                     >
    //                       <span>{call.remoteName}</span>
    //                     </Typography>
    //                   </Grid>
    //                 </Grid>
    //               ) : null}
    //               <Typography variant="body1">
    //                 <PhoneNumberDisplay
    //                   size="small"
    //                   allowExtensions
    //                   ptn={call.remoteUser}
    //                   width="auto"
    //                 />
    //               </Typography>
    //             </Grid>
    //           </Grid>
    //         )}
    //       </div>
    //       <div className="">
    //         <div className="flex space-x-1 items-center">
    //           <div className="">
    //             <GotoConversation call={call} darkMode />
    //           </div>
    //
    //           <div className="!ml-4">
    //             {/* <button
    //               onClick={e => onAcceptCall(false)}
    //               className={[
    //                 'rounded bg-green-600 text-white hover:bg-red-800 cursor-pointer grid place-items-center p-1 px-2 text-xs',
    //                 // callState.showKeypad ? 'bg-blue-100' : '',
    //               ].join(' ')}
    //             >
    //               <PhoneIcon width={16} height={16} /> Answer
    //             </button> */}
    //             <Button
    //               color={'positive'}
    //               size={'sm'}
    //               variant={'fill'}
    //               className={'h-min'}
    //               onClick={e => onAcceptCall(false)}
    //               startIcon={
    //                 <PhoneIcon width={16} height={16} className="mr-2" />
    //               }
    //             >
    //               Answer
    //             </Button>
    //           </div>
    //           <div className="!ml-4">
    //             {/* <button
    //               onClick={onStopCall}
    //               className={[
    //                 'rounded bg-red-600 text-white hover:bg-red-800 cursor-pointer grid place-items-center grid-flow-row-dense p-1 px-2 text-xs',
    //                 // callState.showKeypad ? 'bg-blue-100' : '',
    //               ].join(' ')}
    //             >
    //               <PhoneDeleteIcon width={16} height={16} />
    //               <span>Decline</span>
    //             </button> */}
    //             <Button
    //               color={'negative'}
    //               size={'sm'}
    //               variant={'fill'}
    //               className={'h-min'}
    //               onClick={onStopCall}
    //               startIcon={
    //                 <PhoneDeleteIcon width={16} height={16} className="mr-2" />
    //               }
    //             >
    //               Decline
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }

  return (
    <div>
      <div
        className={`w-full bg-white text-neutral-90 py-2.5 px-4 flex space-x-2`}
      >
        <div>
          {contact ? (
            contact?.user ? (
              <UserAvatar user={contact?.user} size={'md'} />
            ) : (
              <Avatar
                size={'md'}
                initials={
                  `${contact.info?.firstName?.[0] ?? ''}${
                    contact.info?.lastName?.[0] ?? ''
                  }` ?? contact.info.company[0]
                }
              />
            )
          ) : (
            <Avatar size={'md'} />
          )}
        </div>
        <div className={`flex-1`}>
          <div className={`w-full flex items-center justify-between`}>
            <div className={`flex space-x-2 items-center`}>
              <span className={`text-md font-medium`}>
                {contact
                  ? contact.user?.doc.fullName ??
                    `${contact.info.firstName} ${contact.info.lastName}`
                  : call.remoteName ?? 'No caller ID'}
              </span>
            </div>
            <div className={`flex items-center space-x-2`}>
              <Badge
                color={call.isOnLocalHold() ? 'accent' : 'positive'}
                variant={'fill'}
              >
                {call.isOnLocalHold() ? 'On hold' : 'Active'}
              </Badge>
              <div className={`w-6 text-neutral-60 text-end text-sm`}>
                <CallTimer call={call} />
              </div>
            </div>
          </div>
          <div className={`flex space-x-2 items-center`}>
            <div className={`text-sm text-neutral-60`}>
              <PhoneNumberDisplay ptn={call.remoteUser} hideFlag />
            </div>
            {/*<div>
              <RightRoundArrowIcon height={12} width={12} />
            </div>
            <div className={`flex items-center space-x-1`}>
              <Emoji unified={'1f937'} size={16} />
              <span className={`font-medium text-sm`}>Primary</span>
            </div>*/}
          </div>
          <div className={`flex w-full mt-2 items-center justify-between`}>
            <Tooltip title={'End call'} arrow>
              <IconButton
                onClick={onStopCall}
                color={`negative`}
                variant={'fill'}
                pill
                size={'md'}
              >
                <PhoneDisabledIcon fr={undefined} />
              </IconButton>
            </Tooltip>
            <GotoConversation call={call} />
            <Dialpad
              call={call}
              callState={callState}
              setCallState={setCallState}
            />
            <MuteMic call={call} />
            <LocalHold call={call} />
          </div>
        </div>
      </div>
      <NotesEditor
        call={call}
        callState={callState}
        setCallState={setCallState}
        showNotes={callState.showNotes}
        onUpdate={notes => {
          call._notes = notes;
        }}
      />
      <Keypad
        call={call}
        callState={callState}
        setCallState={setCallState}
        show={callState.showKeypad}
      />
      <Screenpops
        call={call}
        onUpdate={screenpops => {
          call._screenpops = screenpops;
        }}
      />
      <Notifications call={call} />
    </div>
  );

  // return (
  //   <div className="p-2">
  //     <div className="flex items-center space-x-2">
  //       {/* {call.isRinging() ? null : ( */}
  //       {/* <div className="self-start mt-4">
  //           {call._direction === CALL_DIRECTION_OUTGOING ? (
  //             <PhoneOutcomeIcon width={18} height={18} />
  //           ) : (
  //             <PhoneIncomeIcon width={18} height={18} />
  //           )}
  //         </div> */}
  //       {/* )} */}
  //       <div className="flex-auto">
  //         {contact ? (
  //           <ContactDisplay contact={contact} number={call.remoteUser} />
  //         ) : maybeContact ? (
  //           <MaybeContactDisplay maybeContact={maybeContact} />
  //         ) : (
  //           <Grid
  //             container
  //             alignItems="center"
  //             wrap={'nowrap'}
  //             columnSpacing={2}
  //           >
  //             <Grid item>
  //               <Avatar sx={{ width: 40, height: 40 }} />
  //             </Grid>
  //             <Grid item sx={{ flex: 1 }}>
  //               {/*  */}
  //               {call.remoteName !== call.remoteUser.replace('+', '') ? (
  //                 <Grid container columnSpacing={1}>
  //                   <Grid item>
  //                     <Typography
  //                       sx={{
  //                         display: 'inline-block',
  //                         fontWeight: 700,
  //                         fontSize: '16px',
  //                       }}
  //                     >
  //                       <span>{call.remoteName}</span>
  //                     </Typography>
  //                   </Grid>
  //                 </Grid>
  //               ) : null}
  //               <Typography variant="body1">
  //                 <PhoneNumberDisplay
  //                   size="small"
  //                   allowExtensions
  //                   ptn={call.remoteUser}
  //                   width="auto"
  //                 />
  //               </Typography>
  //             </Grid>
  //           </Grid>
  //         )}
  //       </div>
  //       <div className="w-[80px]">
  //         <Typography sx={{ color: '#285E70' }}>
  //           <CallTimer call={call} />
  //         </Typography>
  //       </div>
  //       <div className="">
  //         <div className="flex space-x-1 items-center">
  //           <div className="">
  //             <Dialpad
  //               call={call}
  //               callState={callState}
  //               setCallState={setCallState}
  //             />
  //           </div>
  //           <div className="">
  //             <LocalHold call={call} />
  //           </div>
  //           <div className="">
  //             <MuteMic call={call} />
  //           </div>
  //           <div className="">
  //             <GotoConversation call={call} />
  //           </div>
  //
  //           {/* {call.isOnLocalHold() && (
  //             <Grid item style={{ flex: 1 }}>
  //               <Button
  //                 size="small"
  //                 variant="contained"
  //                 fullWidth
  //                 sx={{ borderRadius: 10 }}
  //                 // startIcon={
  //                 //   call.isOnLocalHold() ? (
  //                 //     <CallOnHoldIcon style={{ color: '#732222' }} />
  //                 //   ) : (
  //                 //     <PauseIcon />
  //                 //   )
  //                 // }
  //                 onClick={handleUnhold}
  //               >
  //                 {call.isOnLocalHold() ? <CallOnHoldIcon /> : <PauseIcon />}
  //                 Unhold
  //               </Button>
  //             </Grid>
  //           )}*/}
  //           <div className="!ml-4">
  //             {/* <Button
  //               size="small"
  //               color="error"
  //               variant="contained"
  //               fullWidth
  //               sx={{ borderRadius: 10 }}
  //               // startIcon={<CallEndIcon />}
  //               onClick={onStopCall}
  //             >
  //               <CallEndIcon />
  //             </Button> */}
  //             <button
  //               onClick={onStopCall}
  //               className={[
  //                 'rounded bg-red-600 text-white hover:bg-red-800 cursor-pointer grid place-items-center p-1 px-2',
  //                 // callState.showKeypad ? 'bg-blue-100' : '',
  //               ].join(' ')}
  //             >
  //               <PhoneDeleteIcon width={16} height={16} />
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     {/* <Box
  //       sx={{
  //         width: '100%',
  //         justifyContent: 'right',
  //         mt: 2,
  //         display: 'none', // HIDING!!!!
  //       }}
  //     >
  //       <Grid
  //         container
  //         columnSpacing={1}
  //         alignItems={'center'}
  //         wrap={'nowrap'}
  //         style={{ width: '100%' }}
  //       >
  //         <Grid item sx={{ flex: 1 }}>
  //           <div>{disposition}</div>
  //           {/*<Chip
  //             label={disposition}
  //             color={bgStatus}
  //             size="small"
  //             variant="contained"
  //           />
  //           [todo]
  //         </Grid>
  //
  //         <Grid item>
  //           <MuteMic call={call} />
  //         </Grid>
  //         <SpeakerVolume call={call} />
  //         <Grid item>
  //           <LocalHold call={call} />
  //         </Grid>
  //         <Grid item>
  //           <Park call={call} />
  //         </Grid>
  //         <Grid item>
  //           <BlindTransfer call={call} />
  //         </Grid>
  //         <AttendedTransfer call={call} />
  //         <Merge call={call} />
  //         <Grid item>
  //           <Dialpad
  //             call={call}
  //             callState={callState}
  //             setCallState={setCallState}
  //           />
  //         </Grid>
  //         <Grid item>
  //           <NotesButton
  //             call={call}
  //             callState={callState}
  //             setCallState={setCallState}
  //           />
  //         </Grid>
  //
  //         <Grid item>
  //           {!call.hasLocalVideo() ? (
  //             //  && !call.hasRemoteVideo()
  //             <Video call={call} />
  //           ) : (
  //             ''
  //           )}
  //         </Grid>
  //
  //         <SpeakerAmplifier call={call} />
  //       </Grid>
  //     </Box> */}
  //     <NotesEditor
  //       call={call}
  //       callState={callState}
  //       setCallState={setCallState}
  //       showNotes={callState.showNotes}
  //       onUpdate={notes => {
  //         call._notes = notes;
  //       }}
  //     />
  //     <Keypad
  //       call={call}
  //       callState={callState}
  //       setCallState={setCallState}
  //       show={callState.showKeypad}
  //     />
  //     <Screenpops
  //       call={call}
  //       onUpdate={screenpops => {
  //         call._screenpops = screenpops;
  //       }}
  //     />
  //     <Notifications call={call} />
  //   </div>
  // );

  // return (
  //   <Box
  //     sx={{
  //       background: rowColor
  //         ? rowFull
  //           ? rowColor
  //           : `linear-gradient(to bottom, ${rowColor} 0%,rgba(255,255,255,0) 8px),
  //       linear-gradient(to bottom, rgba(255,255,255,0) calc(100% - 8px),${rowColor} 100%),
  //           linear-gradient(to right, ${rowColor} 0%,rgba(255,255,255,0) 8px),
  //           linear-gradient(to right, rgba(255,255,255,0) calc(100% - 8px),${rowColor} 100%)`
  //         : 'none',
  //       padding: 3,
  //     }}
  //     // style={{ borderLeft: `4px solid ${borderColor}` }}
  //   >
  //     <div style={{ cursor: 'pointer' }}>
  //       <Grid container alignItems={'top'} justify="space-between">
  //         <Grid item style={{ flex: 1 }}>
  //           <div>
  //             <Chip label={disposition} size="small" variant="outlined" />
  //           </div>
  //           <div style={{ margin: '4px 0' }}>
  //             {contact ? (
  //               <ContactDisplay contact={contact} number={call.remoteUser} />
  //             ) : (
  //               <>
  //                 <Typography variant="body1" strong>
  //                   {call.remoteName}
  //                 </Typography>
  //                 <Typography variant="subtitle1">
  //                   {call.remoteName !== call.remoteUser ? call.remoteUser : ''}
  //                 </Typography>
  //               </>
  //             )}
  //           </div>
  //         </Grid>
  //         <Grid item>
  //           <div style={{ padding: 4 }}>
  //             <Grid container spacing={1} noWrap>
  //               {/* {call.isDialing() && (
  //               <>
  //                 <Grid item>
  //                   <Button
  //                     size="small"
  //                     variant="outlined"
  //                     color="info"
  //                     startIcon={<PhoneIcon />}
  //                     onClick={onStopCall}
  //                   >
  //                     Hang Up
  //                   </Button>
  //                 </Grid>
  //               </>
  //             )} */}
  //               {call.isRinging() && (
  //                 <React.Fragment>
  //                   <Grid item style={{ flex: 1 }}>
  //                     <Button
  //                       size="small"
  //                       variant="outlined"
  //                       color="success"
  //                       fullWidth
  //                       startIcon={<PhoneIcon />}
  //                       onClick={e => onAcceptCall(false)}
  //                     >
  //                       Answer
  //                     </Button>
  //                   </Grid>
  //                   <Grid item style={{ flex: 1 }}>
  //                     <Button
  //                       size="small"
  //                       variant="outlined"
  //                       color="success"
  //                       fullWidth
  //                       startIcon={<VideocamIcon />}
  //                       onClick={e => onAcceptCall(true)}
  //                     >
  //                       Video
  //                     </Button>
  //                   </Grid>
  //                 </React.Fragment>
  //               )}
  //               {call.isOnLocalHold() && (
  //                 <Grid item style={{ flex: 1 }}>
  //                   <Button
  //                     size="small"
  //                     variant="outlined"
  //                     fullWidth
  //                     startIcon={
  //                       call.isOnLocalHold() ? (
  //                         <CallOnHoldIcon style={{ color: '#732222' }} />
  //                       ) : (
  //                         <PauseIcon />
  //                       )
  //                     }
  //                     onClick={handleUnhold}
  //                   >
  //                     Unhold
  //                   </Button>
  //                 </Grid>
  //               )}
  //               <Grid item style={{ flex: 1 }}>
  //                 <Button
  //                   size="small"
  //                   color="error"
  //                   variant="outlined"
  //                   fullWidth
  //                   startIcon={<CallEndIcon />}
  //                   onClick={onStopCall}
  //                 >
  //                   End
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </div>
  //           <div style={{ padding: 4, textAlign: 'right' }}>
  //             <CallTimer call={call} />
  //             &nbsp;
  //             <CallIP call={call} />
  //           </div>
  //         </Grid>
  //       </Grid>
  //     </div>
  //     <div>
  //       <Grid container noWrap style={{ overflowX: 'auto' }}>
  //         <MuteMic call={call} />
  //         {/* <SpeakerVolume call={call} /> */}
  //         <LocalHold call={call} />
  //         <Park call={call} />
  //         <BlindTransfer call={call} />
  //         {/* <AttendedTransfer call={call} /> */}
  //         {/* <Merge call={call} /> */}
  //         <Dialpad call={call} />
  //         <NotesButton
  //           call={call}
  //           callState={callState}
  //           setCallState={setCallState}
  //         />
  //         {!call.hasLocalVideo() ? (
  //           //  && !call.hasRemoteVideo()
  //           <Video call={call} />
  //         ) : (
  //           ''
  //         )}
  //         {/* <SpeakerAmplifier call={call} /> */}
  //       </Grid>
  //     </div>
  //     <NotesEditor
  //       call={call}
  //       callState={callState}
  //       setCallState={setCallState}
  //       showNotes={callState.showNotes}
  //       onUpdate={notes => {
  //         call._notes = notes;
  //       }}
  //     />
  //     <Screenpops
  //       call={call}
  //       onUpdate={screenpops => {
  //         call._screenpops = screenpops;
  //       }}
  //     />
  //     <Notifications call={call} />
  //   </Box>
  // );
};

const MuteMic = props => {
  const { call } = props;

  const handleToggleMute = () => {
    call.toggleAudioMute();
  };

  const isMuted = call.isAudioOnMute();

  return (
    <Tooltip
      title={isMuted ? 'Unmute microphone' : 'Mute microphone'}
      type="light"
      arrow
    >
      <IconButton
        color={'neutral'}
        variant={'ghost'}
        size={'md'}
        pill
        // className={[
        //   'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
        //   isMuted ? 'bg-orange-200' : '',
        // ].join(' ')}
        // variant={'outlined'}
        // size={'small'}
        // color={isMuted ? 'primary' : 'gray'}
        // sx={
        //   {
        //     // color: isMuted ? undefined : theme => theme.palette.content.color,
        //   }
        // }
        onClick={handleToggleMute}
      >
        {isMuted ? (
          <MicMuteIcon width={16} height={16} />
        ) : (
          <MicIcon width={16} height={16} />
        )}
      </IconButton>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Tooltip title={<>Toggle Microphone Mute</>} type="light" arrow>
  //       <Box sx={classes.cell} onClick={handleToggleMute}>
  //         {call.isAudioOnMute() ? (
  //           <UnMuteIcon fontSize="small" />
  //         ) : (
  //           <MuteIcon fontSize="small" />
  //         )}
  //       </Box>
  //     </Tooltip>
  //   </Grid>
  // );
};

const GotoConversation = props => {
  const {
    call,
    darkMode, // temporary! until fix w/ storybook
  } = props;

  const history = useHistory();
  // TODO: first, verify that conversation can be got to?
  // - do this in a useEffect, to get the Pipe and the PipeConversation.key?

  const [link, linkSet] = useState(null);
  const [pipeKey, pipeKeySet] = useState(null);
  const [convoKey, convoKeySet] = useState(null);

  const { channels } = useLocalSelector();
  // console.log('CHANNELS:', channels);

  // pipes/lines
  const pipesQuery = usePipesQuery(
    {
      filters: {
        key: pipeKey,
      },
    },
    pipeKey ? true : false,
  );
  const pipes = pipesQuery.data?.pipes ?? [];
  const pipe = pipes?.length ? pipes[0] : null;

  // // determine pipe
  // const [pipeNumber, otherNumber] =
  //   call._direction === CALL_DIRECTION_OUTGOING
  //     ? [
  //         // outgoing
  //         call.remoteName,
  //         call.remoteUser,
  //       ]
  //     : [
  //         // incoming
  //         call.remoteName,
  //         call.remoteUser,
  //       ];
  // // determine other side
  useEffect(() => {
    if (link) {
      return;
    }
    if (!pipe) {
      return;
    }
    // console.log('pipe:', pipe);
    linkSet(`/v2/pipe/${pipe.id}/convo/${convoKey}`);
  }, [pipe, convoKey, link]);

  useEffect(async () => {
    if (link) {
      return;
    }
    // TODO: just store the "From/CallingAs" number in the call, when making the call?
    // - simpler than trying to determine it from the channels!!!
    // let conversation = '';
    console.log('call:', { call, channels });
    // console.log('conversation1:', { conversation, pipeNumber, otherNumber });
    if (call._direction === CALL_DIRECTION_OUTGOING) {
      // outgoing
      console.log('OUTGOING CALL');
      let sipCallId = call._rtcSession?._id?.substr(0, 20);
      // get leg where Bridge-Id is sipCallId, and has correct E164 values
      // - unless internal! This breaks things? How to handle internal...?
      let foundLeg = channels.find(
        ch =>
          ch.listing.custom_channel_vars['Bridge-ID'] === sipCallId &&
          ch.listing.custom_channel_vars['E164-Destination'] &&
          ch.listing.custom_channel_vars['E164-Origination'],
      );
      if (foundLeg) {
        // get pipe for outgoing number
        let outgoingNumber =
          foundLeg.listing.custom_channel_vars['E164-Origination'];
        // console.log('OUTGOING:', {
        //   outgoingNumber,
        //   recipient: foundLeg.listing.custom_channel_vars['E164-Destination'],
        // });
        pipeKeySet(outgoingNumber);
        convoKeySet(foundLeg.listing.custom_channel_vars['E164-Destination']);
      }
    } else {
      // incoming should be easy!
      console.log('INCOMING CALL');
      let sipCallId = call._rtcSession?._id.substr(0, 36);
      // console.log('sipCallId', sipCallId);
      let foundLeg = channels.find(ch => ch.id === sipCallId);
      // console.log('foundLeg:', foundLeg);
      if (foundLeg) {
        let inboundLeg = channels.find(
          ch => ch.id === foundLeg.listing.other_leg,
        );
        // console.log('inboundLeg:', inboundLeg);
        if (inboundLeg) {
          // get pipe for outgoing number
          let pipeNumber = inboundLeg.listing.destination;
          // console.log('OUTGOING:', {
          //   outgoingNumber,
          //   recipient: foundLeg.listing.custom_channel_vars['E164-Destination'],
          // });
          pipeKeySet(pipeNumber);
          convoKeySet(
            inboundLeg.listing.custom_channel_vars['E164-Origination'],
          );
        }
      }
    }
    //
  }, [call, channels, link]);

  // console.log('link:', link);
  return (
    <Tooltip
      title={link ? 'Go to conversation' : 'Finding conversation...'}
      type="light"
      arrow
    >
      <IconButton
        color={'neutral'}
        variant={'ghost'}
        size={'md'}
        pill
        // className={[
        //   'rounded cursor-pointer grid place-items-center p-1',
        //   darkMode ? 'hover:bg-neutral-200/20' : 'hover:bg-neutral-200',
        //   link ? '' : 'text-neutral-200',
        //   link ? '' : 'cursor-not-allowed',
        // ].join(' ')}
        // variant={'outlined'}
        // size={'small'}
        // color={isMuted ? 'primary' : 'gray'}
        // sx={
        //   {
        //     // color: isMuted ? undefined : theme => theme.palette.content.color,
        //   }
        // }
        disabled={!link}
        onClick={() => {
          // let conversation = '';
          // console.log('conversation2:', conversation);
          if (link) {
            history.push(link);
          }
        }}
      >
        <MessageIcon />
      </IconButton>
    </Tooltip>
  );
};

// const CallDuration = ({ call }) => {
//   const startTime = new Date(call.startTime).getTime();
//   const endTime = new Date(call.endTime).getTime();
//
//   const duration = endTime - startTime;
//
//   // console.log(
//   //   'duration:',
//   //   duration,
//   //   call.endTime,
//   //   call.startTime,
//   //   endTime,
//   //   startTime
//   // );
//   if (call.errorReason || !duration) {
//     return null;
//   }
//
//   // console.log('duration:', duration);
//   return (
//     <span>
//       {prettyMs(duration, { secondsDecimalDigits: 0, colonNotation: true })}
//     </span>
//   );
// };

const LocalHold = props => {
  const { call } = props;

  // const classes = useStyles();

  const handleToggleLocalHold = () => {
    call.toggleHold();
  };

  const onHold = call.isOnLocalHold();
  return (
    <Tooltip title={'Toggle hold'} type="light" arrow>
      <IconButton
        color={'neutral'}
        variant={'ghost'}
        size={'md'}
        pill
        // className={[
        //   'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
        //   onHold ? 'bg-orange-200' : '',
        // ].join(' ')}
        // variant={'outlined'}
        // size={'small'}
        // color={onHold ? 'primary' : 'gray'}
        // sx={
        //   {
        //     // color: onHold ? undefined : theme => theme.palette.content.color,
        //   }
        // }
        onClick={handleToggleLocalHold}
      >
        {onHold ? <PhonePausedIcon /> : <PauseIcon />}
      </IconButton>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handleToggleLocalHold}>
  //       <Tooltip title={<>Toggle Hold</>} type="light" arrow>
  //         <span>
  //           {call.isOnLocalHold() ? (
  //             <CallOnHoldIcon fontSize="small" />
  //           ) : (
  //             <PauseIcon fontSize="small" />
  //           )}
  //         </span>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

const Park = props => {
  const { call } = props;

  // const classes = useStyles();

  const { data, refetch } = useCallsParkedQuery();

  // const parked_calls = data?.callsParked ?? [];

  const parkedCallsDictionary = useMemo(() => {
    const numDictionary = {};
    data?.callsParked.forEach(pc => {
      numDictionary[pc.num] = true;
    });
    return numDictionary;
  }, [data?.callsParked]);

  const handlePark = parkNum => {
    // const parkNum = window.prompt('Park:', '');
    if (!parkNum) {
      return false;
    }
    call.parkCall(`*3${parkNum}`);
    setTimeout(() => {
      refetch();
      // this fails if trying to hang up a picked up park call
      try {
        call.hangup();
      } catch (e) {}
    }, 100); // nick chose a random amount of time until ending our side of the call after parking the other side
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  // console.log('parked', parked_calls);

  return (
    <>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem disabled>Select Spot:</MenuItem>
        {[...Array(10).keys()].map(num => {
          const number = num + 1;
          const inUse = parkedCallsDictionary[number];

          return (
            <MenuItem
              key={number}
              value={number}
              label={number}
              onClick={() => handlePark(number)}
              disabled={inUse}
            >
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Typography>{`${
                  inUse ? 'In use - ' : ''
                } ${number}`}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>

      <Tooltip title={'Park Call'} type="light" arrow>
        <button
          className={[
            'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
            // isMuted ? 'bg-orange-200' : '',
          ].join(' ')}
          // variant={'outlined'}
          // size={'small'}
          // color={'gray'}
          // // sx={{ color: theme => theme.palette.content.color }}
          // onClick={handleClickMenu}
        >
          <LocalParkingIcon fontSize="small" />
        </button>
      </Tooltip>
    </>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handlePark}>
  //       <Tooltip title={<>Park Call</>} type="light" arrow>
  //         <span>
  //           <LocalParkingIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

const BlindTransfer = props => {
  const { call } = props;

  const classes = useStyles();

  const handleTransfer = () => {
    const destination = window.prompt('Transfer To:', '');
    if (!destination) {
      return false;
    }
    // if (!call.isOnLocalHold()) {
    //   call.hold();
    // }
    call.blindTransfer(destination);
  };

  return (
    <Tooltip title={'Blind Transfer'} type="light" arrow>
      <button
      // variant={'outlined'}
      // size={'small'}
      // color={'gray'}
      // // sx={{ color: theme => theme.palette.content.color }}
      // onClick={handleTransfer}
      >
        <FastForwardIcon fontSize="small" />
      </button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handleTransfer}>
  //       <Tooltip title={<>Blind Transfer</>} type="light" arrow>
  //         <span>
  //           <FastForwardIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

const AttendedTransfer = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `atransfer-${call._id}`,
  });

  const handleTransfer = mergeCall => () => {
    // other call must be on hold!
    if (!mergeCall.isOnLocalHold()) {
      mergeCall.hold();
    }
    call.attendedTransfer(mergeCall);
    popupState.close();
  };

  const otherCalls = calls.filter(tcall =>
    tcall.getId() !== call.getId() ? true : false,
  );

  return (
    <Grid item>
      <div
        className={classes.cell}
        {...bindTrigger(popupState)}
        style={{ opacity: otherCalls.length ? 1 : 0.5 }}
      >
        <Tooltip title={<>Attended Transfer</>} type="light" arrow>
          <span>
            <TransferWithinAStationIcon fontSize="small" />
          </span>
        </Tooltip>
      </div>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      <Menu {...bindMenu(popupState)}>
        {otherCalls.map(ocall => (
          <MenuItem onClick={handleTransfer(ocall)}>
            <ListItemIcon style={{ paddingRight: 4 }}>
              <PhoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ocall.remoteUser} />
          </MenuItem>
        ))}
        {!otherCalls.length ? (
          <MenuItem onClick={popupState.close}>
            <ListItemText primary="No calls to transfer to" />
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </Grid>
  );
};

const Merge = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `mergeMenu-${call._id}`,
  });

  const handleMerge = mergeCall => () => {
    // other call must be on hold!
    alert('this does not work correctly yet!');
    // if (!mergeCall.isOnLocalHold()) {
    //   mergeCall.hold();
    // }
    // call.attendedTransfer(mergeCall);
    popupState.close();
  };

  const otherCalls = calls.filter(tcall =>
    tcall.getId() !== call.getId() ? true : false,
  );

  return (
    <Grid item>
      <div
        className={classes.cell}
        {...bindTrigger(popupState)}
        style={{ opacity: otherCalls.length ? 1 : 0.5 }}
      >
        <Tooltip title={<>Merge Calls</>} type="light" arrow>
          <span>
            <CallMergeIcon fontSize="small" />
          </span>
        </Tooltip>
      </div>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      <Menu {...bindMenu(popupState)}>
        {otherCalls.map(ocall => (
          <MenuItem onClick={handleMerge(ocall)}>
            <ListItemIcon style={{ paddingRight: 4 }}>
              <PhoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={ocall.remoteUser} />
          </MenuItem>
        ))}
        {!otherCalls.length ? (
          <MenuItem onClick={popupState.close}>
            <ListItemText primary="No calls to merge with" />
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </Grid>
  );
};

const NotesButton = props => {
  const { call, callState, setCallState } = props;

  const classes = useStyles({
    bgcolor: call._notes?.data?.length > 0 ? '#FFF8E1' : undefined,
  });

  return (
    <Tooltip title={'Notes'} type="light" arrow>
      <button
      // variant={'outlined'}
      // size={'small'}
      // color={callState.showNotes ? 'primary' : 'gray'}
      // sx={
      //   {
      //     // color: callState.showNotes
      //     //   ? undefined
      //     //   : theme => theme.palette.content.color,
      //   }
      // }
      // onClick={e =>
      //   setCallState({ showNotes: callState.showNotes ? null : true })
      // }
      >
        <NotesIcon fontSize="small" />
      </button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <div
  //       className={classes.cell}
  //       onClick={e =>
  //         setCallState({ showNotes: callState.showNotes ? null : true })
  //       }
  //     >
  //       <Tooltip title={<>Notes</>} type="light" arrow>
  //         <span>
  //           <NotesIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </div>
  //   </Grid>
  // );
};

const Video = props => {
  const { call } = props;

  const classes = useStyles();

  const handleEnableVideo = () => {
    // this should simply enable YOUR video
    call.offerVideo();
  };

  if (call.hasLocalVideo()) {
    return null;
  }

  return (
    <Tooltip title={'Enable Video Call'} type="light" arrow>
      <button
      // variant={'outlined'}
      // size={'small'}
      // color={'gray'}
      // // sx={{ color: theme => theme.palette.content.color }}
      // onClick={handleEnableVideo}
      >
        <VideocamIcon fontSize="small" />
      </button>
    </Tooltip>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} onClick={handleEnableVideo}>
  //       <Tooltip title={<>Enable Video Call</>} type="light" arrow>
  //         <div style={{ whiteSpace: 'nowrap' }}>
  //           <VideocamIcon fontSize="small" />
  //         </div>
  //       </Tooltip>
  //     </Box>
  //   </Grid>
  // );
};

// const Dialpad = (props) => {
//   const { call } = props;

//   const classes = useStyles();

//   const handleSubmit = (input) => {};

//   <Dialer onSubmit={handleSubmit} />;
//   return (
//     <Grid item>
//       <Box sx={classes.cell}>
//         <DialpadIcon fontSize="small" />
//       </Box>
//     </Grid>
//   );
// };

const Keypad = ({ show, call }) => {
  const [charHistory, setCharHistory] = useState('');
  const handlePress = char => {
    // console.log('Key pressed');
    setCharHistory(charHistory + char);

    // not allowed during progress (ringing)
    if (call.getCallStatus() === CALL_STATUS_PROGRESS) return;

    // send dtmf tone for character
    try {
      // console.log('Sending DTMF:', { char, call });
      call.sendDTMF(char);
    } catch (e) {
      alert('error sending dtmf');
      console.error('Unable to send dtmf char', e);
    }
  };

  const clearHistory = () => {
    setCharHistory('');
  };

  // console.log('KEYPAD:', show, call);
  if (!show) return null;

  return (
    <Box sx={{ pt: 2, textAlign: 'right' }}>
      <div className="p-2 min-h-[40px]">
        <>
          <div
            className={[
              'text-2xl text-center',
              charHistory?.length ? '' : 'text-neutral-400',
            ].join(' ')}
          >
            {charHistory?.length ? charHistory : '--'}
          </div>
          {/* <IconButton onClick={clearHistory}>
              <CloseIcon />
            </IconButton> */}
          {/* [Clear] */}
        </>
      </div>
      <Numpad light onClick={handlePress} />
    </Box>
  );
};

const Dialpad = props => {
  const { call, callState, setCallState } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `keypad-${call._id}`,
  });

  const handleSubmit = input => {
    // no submit shown?
    // - dtmf tones sent one-by-one
  };

  return (
    <>
      <Tooltip title={'Show keypad'} type="light" arrow>
        <IconButton
          color={'neutral'}
          variant={'ghost'}
          size={'md'}
          pill
          onClick={() => {
            setCallState(prev => {
              return {
                ...prev,
                showKeypad: !prev.showKeypad,
              };
            });
          }}
          // className={[
          //   'rounded hover:bg-neutral-200 cursor-pointer grid place-items-center p-1',
          //   callState.showKeypad ? 'bg-blue-100' : '',
          // ].join(' ')}
          // // {...bindTrigger(popupState)}
          // variant={'outlined'}
          // size={'small'}
          // color={callState.showKeypad ? 'primary' : 'gray'}
          // sx={
          //   {
          //     // color: callState.showKeypad
          //     //   ? undefined
          //     //   : theme => theme.palette.content.color,
          //   }
          // }
          // onClick={() => {
          //   setCallState(prev => {
          //     return {
          //       ...prev,
          //       showKeypad: !prev.showKeypad,
          //     };
          //   });
          // }}
        >
          <DialpadIcon />
        </IconButton>
      </Tooltip>
      {/* <Popover {...bindMenu(popupState)}>
        <Dialer onPress={handlePress} onSubmit={handleSubmit} />
      </Popover>*/}
    </>
  );

  // return (
  //   <Grid item>
  //     <Box sx={classes.cell} {...bindTrigger(popupState)}>
  //       <Tooltip title={<>Show Dialpad</>} type="light" arrow>
  //         <span>
  //           <DialpadIcon fontSize="small" />
  //         </span>
  //       </Tooltip>
  //     </Box>
  //     {/* <IconButton size="small" {...bindTrigger(popupState)}>
  //       <MoreVertIcon />
  //     </IconButton> */}
  //     {/*<Popover {...bindMenu(popupState)}>*/}
  //     {/*  <Dialer onPress={handlePress} onSubmit={handleSubmit} />*/}
  //     {/*</Popover>*/}
  //   </Grid>
  // );
};

const SpeakerVolume = props => {
  const { call } = props;

  const [state, dispatch] = useWebphoneContext();
  const { calls } = state;

  const speakerVol = call.getOutputVolume();
  const [volume, setVolume] = useState(speakerVol * 100);

  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `dialpad-${call._id}`,
  });

  const handleChange = num => {
    const vol = num / 100;
    call.changeOutputVolume(vol);
    setVolume(num);
  };

  return (
    <Grid item>
      <Box sx={classes.cell} {...bindTrigger(popupState)}>
        <Tooltip
          title={
            <>
              Volume of Call (does NOT adjust your overall audio volume, only
              the volume of this single call)
            </>
          }
          type="light"
          arrow
        >
          <div style={{ whiteSpace: 'nowrap' }}>
            {volume === 0 ? (
              <VolumeOffIcon fontSize="small" />
            ) : (
              <VolumeUpIcon fontSize="small" />
            )}{' '}
            <span style={{ verticalAlign: 'top' }}>{volume}%</span>
          </div>
          {/* <div style={{ flex: 1, wrap: 'no-wrap' }}>
            <div>
              {volume === 0 ? (
                <VolumeOffIcon fontSize="small" />
              ) : (
                <VolumeUpIcon fontSize="small" />
              )}
            </div>
            <div>
              <span style={{ verticalAlign: 'top' }}>{volume}%</span>
            </div>
          </div> */}
        </Tooltip>
      </Box>
      {/* <IconButton size="small" {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton> */}
      <Popover {...bindMenu(popupState)}>
        <div style={{ height: 160, padding: '24px 12px', textAlign: 'center' }}>
          <Slider
            orientation="vertical"
            value={volume}
            onChange={(e, num) => handleChange(num)}
            // max={200} // increase the potential audio after switching to the WebAudio API (to increase audio above 100%)
          />
          <br />
          <Typography variant="caption">{volume}%</Typography>
        </div>
      </Popover>
    </Grid>
  );
};

const SpeakerAmplifier = props => {
  const { call } = props;
  const [ampVal, setAmpVal] = useState(100);
  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `spk-ampl-${call._id}`,
  });

  const handleChange = num => {
    if (num === 100) {
      call.amplifySpeakerOff();
    } else {
      const val = num / 100;
      call.amplifySpeakerOn(val);
    }
    setAmpVal(num);
  };

  return (
    <Grid item>
      <Box sx={classes.cell} {...bindTrigger(popupState)}>
        <Tooltip
          title={<>Amplify Speaker Volume (max 400%)</>}
          type="light"
          arrow
        >
          <div style={{ whiteSpace: 'nowrap' }}>
            {ampVal === 100 ? (
              <AmplifyOffIcon fontSize="small" />
            ) : (
              <>
                <AmplifyOnIcon fontSize="small" />
                <span style={{ verticalAlign: 'top' }}>{ampVal}%</span>
              </>
            )}
          </div>
        </Tooltip>
      </Box>
      <Popover {...bindMenu(popupState)}>
        <div style={{ height: 160, padding: '24px 12px', textAlign: 'center' }}>
          <Slider
            orientation="vertical"
            value={ampVal}
            onChange={(e, num) => handleChange(num)}
            min={100} // increase the potential audio after switching to the WebAudio API (to increase audio above 100%)
            max={400}
            step={50}
            marks
          />
          <br />
          <Typography variant="caption">{ampVal}%</Typography>
        </div>
      </Popover>
    </Grid>
  );
};

const CallTimer = props => {
  const { call } = props;
  const [duration, setDuration] = useState(
    new Date().getTime() - new Date(call.startTime).getTime(),
  );

  useInterval(() => {
    setDuration(new Date().getTime() - new Date(call.startTime).getTime());
    // trigger update
  }, 1000);

  if (!call.isActive() || !duration) {
    return '';
  }

  return prettyMs(duration, {
    secondsDecimalDigits: 0,
    colonNotation: true,
  });
};

const CallIP = props => {
  const { call } = props;

  const [state, setState] = useState({
    ip: null,
    loadingIp: null,
    ipResponse: null,
    validIpResponse: null,
  });

  const hasRunWithEnoughInfo = useRef(null);

  // NOTE: this list updates throughout the call (and any other ongoing calls)
  //   const { list: channels } = useSelector(state => state.lists.channels);
  const channels = []; //TODO: useChannelsSelector(); (have <Channels /> in <App /> publish channels to redux)

  useEffect(() => {
    (async () => {
      // TODO: should be running once per remote caller (could be multiple remote parties on the call!)
      // - for now, just doing the first "connected" remote caller

      if (hasRunWithEnoughInfo.current) {
        return;
      }

      // see if we have enough info already
      // - remote caller IP via channel api
      // - turn the call ID into an interaction_id for the call
      // - I could use the username (of the device!) to determine if it is "this" device in the call
      // console.log('all channels:', channels, call._request?.call_id);
      const thisChannel = channels.find(
        channel => channel.listing?.uuid === call._request?.call_id,
      );

      // console.log('thisChannel:', thisChannel);

      const interaction_id = thisChannel?.listing?.interaction_id;
      const interactionChannels = channels.filter(
        channel => channel.listing?.interaction_id === interaction_id,
      );

      // console.log('interactionChannels:', interactionChannels);

      const ips = compact(
        interactionChannels.map(ic => {
          // console.log(
          //   'ic:',
          //   ic,
          //   ic.listing?.uuid,
          //   ic.listing?.uuid.includes('@')
          // );
          return ic.listing?.uuid.includes('@')
            ? validIpAddress(ic.listing?.uuid.split('@')[1])
              ? ic.listing?.uuid.split('@')[1]
              : null
            : null;
        }),
      );

      console.log('ips:', ips);

      if (!ips.length) {
        return;
      }

      const tmpRemoteIp = ips[0];

      setState({ ip: tmpRemoteIp });
      setState({ loadingIp: true });

      // get channel details
      // - might take a minute for them to arrive via the channel api?
      // - can request directly cuz we know the call_id?
      //  - DO we actually know the call_id?? note sure if call.getId() or something else.
      const url = `${constants.env.REACT_APP_CIO_API_SERVER}/api/v1/ip?ip=${tmpRemoteIp}`;
      let response = await fetch(url, {
        method: 'GET', // TODO: more than GET
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      console.log('IP response:', data);
      const updatedStateProps = {};
      updatedStateProps.validIpResponse =
        data.known || data?.country ? true : null;
      updatedStateProps.ipResponse = data;
      updatedStateProps.loadingIp = null;
      updatedStateProps.ip = tmpRemoteIp;
      setState(updatedStateProps);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels]); // TODO: probably need to disassemble the channels to see if the above needs to run

  if (call._direction === CALL_DIRECTION_OUTGOING) {
    // nothing to show on outgoing calls??
    // - even after the call connects? (doesnt seem correct, wouldnt we know the IP we connect to?)
    return null;
  }

  return (
    <InfoTooltip icon="help">
      <Typography variant="body1">IP Address:</Typography>
      {state.ip ? (
        <Typography variant="body2">
          {state.ip}
          <br />
          {state.loadingIp ? (
            'Loading...'
          ) : state.validIpResponse ? (
            <>
              {flatten([
                state.ipResponse.city?.names?.en ?? 'Unknown',
                state.ipResponse.subdivisions.map(sd => sd.names?.en),
                state.ipResponse.country?.isoCode, // US
              ]).join(', ')}{' '}
              (~{state.ipResponse.location?.accuracyRadius ?? 'N/A'} km)
            </>
          ) : (
            'Invalid IP Response'
          )}
          {state.ipResponse?.known ? (
            <>
              <br />"{state.ipResponse.known}"
            </>
          ) : (
            ''
          )}
        </Typography>
      ) : (
        <Typography variant="body2">No Remote IP Address</Typography>
      )}
    </InfoTooltip>
  );
};

export default CallInfo;
