import { EmptyTableCell } from 'app/components/EmptyTableCell';
import { UserAvatar } from 'app/components/Sidebar/Sidebar';
import { divide } from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { User } from 'types/user';

interface OwnerTableCellProps {
  owner: any;
  type?: string;
  isEnabled?: boolean;
}

const OwnerTableCell = ({ owner, type, isEnabled }: OwnerTableCellProps) => {
  if (!owner) {
    return <EmptyTableCell label={'no owner'} />;
  }

  switch (type) {
    case 'user':
      return (
        <div className="flex items-center space-x-2">
          <div>
            <UserAvatar user={owner} enabled={isEnabled} />
          </div>
          <div className="">
            <div className="font-semibold">{owner.extra.fullName}</div>
            <div className="text-neutral-400">{owner.doc.username}</div>
          </div>
        </div>
      );
    // return (
    //   <RouterLink
    //     to={`/admin/users/view/${owner.doc.id}`}
    //   >{`${owner.doc.first_name} ${owner.doc.last_name}`}</RouterLink>
    // );
    case 'device':
      return (
        <RouterLink
          to={`/admin/devices/view/${owner.doc.id}`}
        >{`${owner.doc.name}`}</RouterLink>
      );
    case 'group':
      return (
        <RouterLink
          to={`/admin/ring_groups/view/${owner.doc.id}`}
        >{`${owner.doc.name}`}</RouterLink>
      );
    case 'vmbox':
      return (
        <RouterLink
          to={`/admin/vmboxes/view/${owner.doc.id}`}
        >{`${owner.doc.name}`}</RouterLink>
      );
  }

  return <div className="italic text-gray-300">unknown</div>;

  // return (
  //   <RouterLink
  //     to={`/admin/users/view/${owner.doc.id}`}
  //   >{`${owner.doc.first_name} ${owner.doc.last_name}`}</RouterLink>
  // );
};

export default OwnerTableCell;
