import React, { useState, useContext, memo } from 'react';
import { Handle } from 'react-flow-renderer';

import {
  Typography,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  TooltipLight,
  Icon,
  // IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from 'app/design';
import {
  Delete as DeleteIcon,
  Eject as EjectIcon,
  PresentToAll as PresentToAllIcon,
  FileCopy as FileCopyIcon,
  Phone as PhoneIcon,
  // Add as AddIcon,
  Edit as EditIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
} from 'app/design/icons-material';

import { getAtPath, setAtPath } from 'app/utilities/utils';

import { IvrMenuEventEmitterContext, useSharedFlow } from '../../..';

import { InTemplate } from '../common/InTemplate';

// import { USER_ADDABLE_COMPONENTS } from '../../../../Strategies/base/GenericDefault';
// import * as OptionComponents from '../../../../Strategies/components';
import {
  Plus as AddIcon,
  Cancel as CloseIcon,
  MoreHoriz as MoreHorizIcon,
  User,
  ArrowRight,
  ArrowLeft,
  Voice,
  Strategy,
  Network,
} from 'iconoir-react';
import { ChooseDialog } from '../common/ChooseDialog';
import { IconButton, Button, ButtonDropdown } from '../../../../../design-lib';
import IconButtonDropdown from '../../../../../design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { USER_ADDABLE_COMPONENTS } from '../../../strategies/blank';
import { useBuilderContext } from '../../../MessageRoutingVisualBuilder';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditMessageRouting/MobileView/MiniMapNode';

////////////////////////
// THIS IS OLD (well, the InsertNode isnt, but the rest is)!!!!!
// - Use the "MenuButton" from "./nodes/common/MenuButton" instead!

const InsertNode = memo(({ data }) => {
  const {
    skipEditing,
    callflow: rootCallflow,
    setCallflow,
    modifyPath,
    index,
    requireAllowBefore, // always false
    requireAllowAfter,
    onClose,
    onAdd, // replaces handleAdd from below!
    templateParent,
    templateRef,
    fix,
    fixData,
    fixBySpliceIdx,
    pipe,
    isFinalInsert = false,
  } = data;
  const ee = useContext(IvrMenuEventEmitterContext);

  const [showAdd, setShowAdd] = useState(null);
  const setShowAddWrap = setTo => () => setShowAdd(setTo);

  const [sharedFlow, setSharedFlow] = useSharedFlow();
  const sharedFlowState = sharedFlow?.state;

  // let icon = <AddIcon />,
  let icon = null,
    canMove = true;
  if (fix) {
    icon = <LocalHospitalOutlinedIcon />;
  } else if (
    // determine if after or on same level as trying to move-to
    sharedFlow?.state === 'move-to' &&
    (onAdd ||
      `${modifyPath}.strategy.data.modules`.indexOf(
        sharedFlow?.data?.pathLevel,
      ) > -1)
  ) {
    icon = <CloseIcon />;
    canMove = false;
  } else if (
    (sharedFlow?.state === 'duplicate-to' ||
      sharedFlow?.state === 'paste-to') &&
    onAdd
  ) {
    icon = <CloseIcon />;
  }

  const duplicateHere = () => {
    // TODO: Make sure to detect if the above element has taken over the "handle add" button!
    // - likely has a special way of adding the "duplicate" info?
    const moduleArr = sharedFlow.data.duplicateJson.moduleItem;
    // const dataArr = sharedFlow.data.duplicateJson.componentData;

    if (onAdd) {
      onAdd({
        type: 'duplicate',
        data: {
          moduleArr,
        },
      });
      return;
    }

    const modules = getAtPath(
      rootCallflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );
    // const stratDataOpts = getAtPath(
    //   rootCallflow,
    //   `${modifyPath}.strategy.data.opts`,
    //   [],
    // );

    for (let i = 0; i < moduleArr.length; i++) {
      modules.splice(index + i, 0, moduleArr[i]);
      // stratDataOpts.splice(index + i, 0, dataArr[i]);
    }

    setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);
    // setAtPath(rootCallflow, `${modifyPath}.strategy.data.opts`, stratDataOpts);

    setCallflow({ ...rootCallflow });
    setSharedFlow(s => ({ ...s, state: null, data: null }));
  };

  const pasteHere = () => {
    // TODO: Make sure to detect if the above element has taken over the "handle add" button!
    // - likely has a special way of adding the "duplicate" info?
    const moduleArr = sharedFlow.data.pasteJson.moduleItem;
    const dataArr = sharedFlow.data.pasteJson.componentData;

    if (onAdd) {
      onAdd({
        type: 'paste',
        data: {
          moduleArr,
        },
      });
      return;
    }

    const modules = getAtPath(
      rootCallflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );

    for (let i = 0; i < moduleArr.length; i++) {
      modules.splice(index + i, 0, moduleArr[i]);
    }

    setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);

    setCallflow({ ...rootCallflow });
    setSharedFlow(s => ({ ...s, state: null, data: null }));
  };

  const moveHere = () => {
    if (!canMove) {
      alert('Unable to move a branch to itself');
      return;
    }
    const moduleArr = sharedFlow.data.moveJson.moduleItem;

    if (onAdd) {
      onAdd({
        type: 'move',
        data: {
          moduleArr,
        },
      });
      return;
    }

    const modules = getAtPath(
      rootCallflow,
      `${modifyPath}.strategy.data.modules`,
      [],
    );

    for (let i = 0; i < moduleArr.length; i++) {
      modules.splice(index + i, 0, moduleArr[i]);
    }

    setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);

    // remove from old place (ie reset old array)
    // - easier to just call sharedFlow.data.onMove() ?
    const oldModules = getAtPath(
      rootCallflow,
      `${sharedFlow.data.currentCallflowPath}.strategy.data.modules`,
    );

    oldModules.splice(sharedFlow.data.infoIdx); // moduleIdx??
    setAtPath(
      rootCallflow,
      `${sharedFlow.data.currentCallflowPath}.strategy.data.modules`,
      oldModules,
    );
    setCallflow({ ...rootCallflow });
    setSharedFlow(s => ({ ...s, state: null, data: null }));
  };

  const handleClick = () => {
    // if (rootCallflow.type != 'template' && templateParent) {
    //   alert(
    //     'Note that adding to this template outside of a Template Endpoint can result in losing information when the Template is re-applied!',
    //   );
    // }
    switch (sharedFlow?.state) {
      case 'duplicate-to':
        if (onAdd) {
          alert('Unable to duplicate here');
          return;
        }
        duplicateHere();
        break;
      case 'paste-to':
        if (onAdd) {
          alert('Unable to copy here');
          return;
        }
        pasteHere();
        break;
      case 'move-to':
        if (onAdd) {
          alert('Unable to move here');
          return;
        }
        moveHere();
        break;
      default:
        if (onAdd) {
          onAdd(); // TODO: finish (used by Menu, and nothing else?)
        } else {
          setShowAdd(true);
        }
    }
  };

  // const handleFix = () => {
  //   const blankCallflow = {
  //     id: 'inline',
  //     flow: {},
  //     numbers: [],
  //     strategy: {
  //       id: 'blank',
  //       config: {
  //         components: [],
  //       },
  //       data: {
  //         opts: [],
  //       },
  //     },
  //   };
  //   if (fixBySpliceIdx > -1) {
  //     let newArr = getAtPath(callflow, modifyPath, []);
  //     newArr.splice(fixBySpliceIdx, 0, fixData || blankCallflow);
  //     setAtPath(callflow, modifyPath, newArr);
  //     setCallflow({ ...callflow }, { name: 'Fix Missing' });
  //     return;
  //   }
  //   setAtPath(callflow, modifyPath, fixData || blankCallflow);
  //   setCallflow({ ...callflow }, { name: 'Fix Missing' });
  // };

  const ringOptions = []; //USER_ADDABLE_COMPONENTS.get('ring');
  const terminationOptions = []; //USER_ADDABLE_COMPONENTS.get('termination').filter(
  //   c => !c.allowFunc,
  // );

  const handleAdd = modulesArray => {
    modulesArray.reverse().forEach(mod => {
      const { type, options, data: newDataOpts } = mod;
      const modules = getAtPath(
        rootCallflow,
        `${modifyPath}.strategy.data.modules`,
        [],
      );

      // console.log('modules:', modules, rootCallflow, modifyPath);

      // TODO: add "recently added" to the data? or use eventemitter?
      // - triggers self to run?
      // - problem if multiple things added??

      // console.log('Add:', type, options, newDataOpts);

      const data = {
        type,
        options,
        data: newDataOpts,
      };

      // const data = {
      //   type: 'MenuGreetingAndTargets',
      // };

      modules.splice(index, 0, data);

      setAtPath(rootCallflow, `${modifyPath}.strategy.data.modules`, modules);

      // console.log('set after insert:', rootCallflow);

      setCallflow({ ...rootCallflow }, { name: `Inserted ${type}` });
    });

    // // TODO: focus after rebuild, instead of on a Timeout
    // window.setTimeout(() => {
    //   ee.emit('focus-node', {
    //     key: `${modifyPath}.strategy.data.modules.${index}`,
    //   });
    // }, 100);

    // TODO: focus after rebuild, instead of on a Timeout
    window.setTimeout(() => {
      // console.log('event emitted');
      ee.emit('node-created', {
        key: `${modifyPath}.strategy.data.modules.${index}`,
        optsPath: `${modifyPath}.strategy.data.modules.${index}`,
      });
    }, 100);
  };

  const { miniMap } = useBuilderContext();

  if (skipEditing && isFinalInsert) {
    return (
      <div className="relative flex justify-center text-center">
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <IconButton
          disabled
          variant={'fill'}
          pill
          color={'negative'}
          size={'sm'}
        >
          <CloseIcon />
        </IconButton>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    );
  }

  if (skipEditing) {
    return (
      <div>
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        <div className="h-1">&nbsp;</div>
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        // width: 150,
        // height: 24,
        display: 'flex',
        justifyContent: 'center',
        // border: '1px solid #ddd',
        // borderRadius: 4,
      }}
    >
      {showAdd && (
        <ChooseDialog
          {...data}
          showTemplateList
          onClose={setShowAddWrap(null)}
        />
      )}
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Handle
          type="target"
          position="top"
          style={{ background: '#555', visibility: 'hidden' }}
        />
        {icon ? (
          <IconButton
            disabled={skipEditing || miniMap}
            onClick={handleClick}
            variant={'fill'}
            pill
            color={'negative'}
            size={'sm'}
          >
            {skipEditing ? <CloseIcon /> : icon}
          </IconButton>
        ) : skipEditing ? (
          <IconButton
            disabled={skipEditing || miniMap}
            onClick={handleClick}
            variant={'outline'}
            pill
            color={'negative'}
            size={'sm'}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <div className={`flex space-x-2 items-center`}>
            {/*<div className={`flex justify-center`}></div>*/}
            <IconButton
              variant={'outline'}
              pill
              color={'accent'}
              size={'sm'}
              disabled={skipEditing || miniMap}
              onClick={handleClick}
            >
              <AddIcon fr={undefined} />
            </IconButton>
            {/* <div className={`w-24 justify-start flex`}>
              <IconButtonDropdown
                variant={'ghost'}
                subArrowRight
                onClick={() =>
                  handleAdd([ringOptions[0], terminationOptions[1]])
                }
                pill
                align={'left'}
                color={'neutral'}
                className={`opacity-50 hover:opacity-100`}
                size={'sm'}
                disabled={skipEditing}
                menuItems={ringOptions.map(ring => ({
                  label: (
                    <div
                      key={ring.name}
                      className={`flex items-center space-x-2`}
                    >
                      <div className={`text-neutral-60`}>{ring.icon}</div>
                      <span>{ring.name}</span>
                    </div>
                  ),
                  subOptions: [
                    {
                      label: (
                        <span className={`italic text-neutral-60`}>
                          then...
                        </span>
                      ),
                      disabled: true,
                    },
                    ...terminationOptions.map(opt => {
                      return {
                        label: (
                          <div
                            key={opt.name}
                            className={`flex items-center space-x-2`}
                          >
                            <div className={`text-neutral-60`}>{opt.icon}</div>
                            <span>{opt.name}</span>
                          </div>
                        ),
                        onClick: () => handleAdd([ring, opt]),
                      };
                    }),
                  ],
                }))}
              >
                <Network />
              </IconButtonDropdown>
            </div> */}
          </div>
          /* <div
            className="inline-block cursor-pointer px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            disabled={skipEditing}
            onClick={handleClick}
          >
            <AddIcon className="-mb-0.5" />
            Add to Route
          </div>*/
        )}
        {templateParent ? <InTemplate template={templateParent} /> : ''}
        <Handle
          type="source"
          position="bottom"
          style={{
            top: 'auto',
            bottom: 4,
            background: '#555',
            visibility: 'hidden',
          }}
        />
      </div>
    </div>
  );
});

export default InsertNode;
