import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Channels } from 'app/components/Channels';
// import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
// import { useAccountQuery } from 'app/hooks/queries/account';
import { GlobalTourProvider } from 'app/components/Tourguide/Helper';

// import PubNub from 'pubnub';
// import { PubNubProvider, usePubNub } from 'pubnub-react';
import { WebphoneProvider, WebphoneSipProvider } from 'app/components/Webphone'; // for root

import constants from 'app/constants';
import {
  buildThemeWithMode,
  CircularProgress,
  createTheme,
  useMediaQuery,
} from 'app/design';
import {
  FeatureFlagProvider,
  HintProvider,
  parseGraphqlErrorCode,
} from 'app/utilities';
import { HeapComponent } from 'app/utilities/heap';
import { HotjarComponent } from 'app/utilities/hotjar';

// import datadogLogs from 'app/utilities/datadog';
import { MixpanelComponent } from 'app/utilities/mixpanel';
import { PubNubProvider } from 'app/utilities/pubnub';
import { WebsocketProvider } from 'app/utilities/websocketBinding';
import { AxiosError } from 'axios';
import { IconoirProvider, WarningTriangle } from 'iconoir-react';
import React, { lazy, Suspense, useEffect, useMemo } from 'react';
// import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'resize-observer-polyfill'; // for @rehooks/component-size
// import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-phone-number-input/style.css';
import { globalStyles } from 'styles/global-styles';
import { QueryParamProvider } from 'use-query-params';
import { AuthGate } from './components/AuthGate';
import { LoginDialog } from './components/LoginDialog';
import { ErrorBoundary } from 'app/components/ErrorBoundary';

// import {} from '@reduxjs/toolkit';
import { useAuthSelector, useAuthSlice } from './data/auth';
// import ErrorFallback from './ErrorFallback';
import { default as Routing } from './Routing';
import { Alert } from 'app/design-lib';

// console.log('datadog3:'); //, datadogLogs);
require('app/utilities/datadog');
const CheckPage = lazy(() => {
  return import('app/pages/Check/Check');
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// @ts-ignore
// console.log('STRIPE:', constants.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(
  // @ts-ignore
  constants.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

// const queryClient = new QueryClient({
//   /* default options are only a fallback and will not be used if the
//      if the respective option is set explicitly in the query/mutation option
//   */
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: process.env.NODE_ENV === 'production',
//       staleTime: 20 * 1000, // set a minimum stale time on all queries to deduplicate requests?
//     },
//   },
// });
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

function App() {
  const loaded = React.useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${constants.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const { i18n } = useTranslation();
  const { actions: authActions } = useAuthSlice();
  const { show_login_dialog, loggedIn, auth_token_decoded } = useAuthSelector();
  const dispatch = useDispatch();
  const router = useHistory();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: async error => {
            const errorCode = parseGraphqlErrorCode(error);
            // is error unauthenticated request
            if (
              // @ts-ignore
              errorCode === 'UNAUTHENTICATED'
            ) {
              // unauthenticated request, cancel queries, clear cache and log user out
              await queryClient.cancelQueries();
              await queryClient.clear();
              dispatch(authActions.logout(null));
              router?.push('/login');
              toast.error(
                // @ts-ignore
                `Authentication failed. Error: ${errorCode ?? error.message}`,
              );
            }
          },
        }),

        mutationCache: new MutationCache({
          onError: async (error, variables, context, mutation) => {
            console.log('Mutation cache level error123', error, context);
            if ((error as AxiosError).response?.status === 401) {
              // unauthenticated request, cancel queries, clear cache and log user out
              await queryClient.cancelQueries();
              await queryClient.clear();
              dispatch(authActions.logout(null));
              router?.push('/login');
              // dispatch(authActions.unauthorizedRequest({}));
            }
            // @ts-ignore
            // if (error.message === '401') {
            //   // unauthenticated request, cancel queries, clear cache and log user out
            //   await queryClient.cancelQueries();
            //   await queryClient.clear();
            //   dispatch(authActions.logout(null));
            //   toast.error(
            //     // @ts-ignore
            //     `Authentication failed. Error: ${error.message}`,
            //   );
            // }
          },
          // onSuccess: data => {},
        }),

        /* default options are only a fallback and will not be used if the
       if the respective option is set explicitly in the query/mutation option
    */
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
            retry: false,
            // staleTime: 20 * 1000, // set a minimum stale time on all queries to deduplicate requests? NO!!!
          },
          mutations: {
            // three reasons we could get 401:
            //    1. no credentials sent up (blank auth header)
            //    2. credentials have expired (we can check local)
            //    3. server side revokes auth token (deleted user, etc.)
            // useErrorBoundary: error =>
            //   (error as AxiosError).response?.status === 401,
          },
        },
      }),
    [dispatch, authActions, router],
  );

  // better way to do this? TODO: get query client into auth saga to handle there
  useEffect(() => {
    // clear query cache on log out
    if (!loggedIn) queryClient.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // @ts-ignore
  const usedTheme = React.useMemo(
    () =>
      createTheme(
        // @ts-ignore
        buildThemeWithMode(prefersDarkMode ? 'light' : 'light', MOUNT_NODE),
      ), // dark
    [prefersDarkMode],
  );

  // oauth postmessage
  // - notifies parent of access_token
  // - closes this window
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('oauthpopper')) {
    window.opener.postMessage(window.location.search);
    window.close();
  }

  const is_check_route = window.location.pathname === '/check' ? true : false;

  return (
    // @ts-ignore
    <WebsocketProvider>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <QueryClientProvider client={queryClient}>
            <PubNubProvider>
              {/*<MarkerComponent />*/}
              <HotjarComponent />
              <MixpanelComponent />
              <HeapComponent />
              <FeatureFlagProvider>
                <Elements stripe={stripePromise}>
                  <Helmet
                    titleTemplate="%s :: CallingIO"
                    defaultTitle="CallingIO"
                    // htmlAttributes={{ lang: i18n.language }}
                  >
                    <meta name="description" content="CallingIO Browser App" />
                  </Helmet>
                  {/*<StyledEngineProvider injectFirst>*/}
                  {/*<ThemeProvider theme={usedTheme}>*/}
                  <ErrorBoundary>
                    <Suspense
                      fallback={
                        <div>
                          <CircularProgress size={40} />
                        </div>
                      }
                    >
                      {/*<CssBaseline />*/}
                      {is_check_route ? (
                        <CheckPage />
                      ) : (
                        <>
                          {/*<div
                            className={`bg-white text-lg font-medium p-20 z-50 lg:hidden absolute inset-0 h-screen w-screen grid place-items-center`}
                          >
                            <Alert
                              color={'attention'}
                              reverse
                              icon={<WarningTriangle fr={undefined} />}
                              label={'Responsive Layout'}
                              body={`We apologize, CallingIO's Browser Application does
                              not support responsive layouts at this time.
                              Please use a desktop, laptop or large tablet to
                              access the app.`}
                            />
                          </div>*/}
                          {/*{show_login_dialog ? <LoginDialog /> : null}*/}
                          <AuthGate>
                            <HintProvider>
                              <WebphoneProvider>
                                {/* <WebphoneDeviceBuilder /> */}
                                <WebphoneSipProvider />
                                {/* <Onboarding /> */}
                                <Channels />
                                <GlobalTourProvider>
                                  <IconoirProvider
                                    iconProps={{ strokeWidth: 2 }}
                                  >
                                    <Routing />
                                  </IconoirProvider>
                                </GlobalTourProvider>
                              </WebphoneProvider>
                            </HintProvider>
                          </AuthGate>
                        </>
                      )}
                    </Suspense>
                  </ErrorBoundary>
                  {/*</ThemeProvider>*/}
                  {/*</StyledEngineProvider>*/}
                  <ToastContainer position={'bottom-right'} />
                  {process.env.NODE_ENV === 'development' && (
                    <ReactQueryDevtools
                      position={'bottom-right'}
                      initialIsOpen={false}
                    />
                  )}
                  {globalStyles}
                </Elements>
              </FeatureFlagProvider>
            </PubNubProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </WebsocketProvider>
  );
}

export default App;
