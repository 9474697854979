import { useCallRouteEditor } from '../CallflowContext';
import { AddModuleButton } from '../AddModuleButton';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
} from '../../../../../design-lib';
import { useBuilderContext } from '../../../../../components/IvrBuilder/IvrBuilder';
import { NavArrowDown, NavArrowRight, NavArrowUp } from 'iconoir-react';
import { PreviousModuleButton } from './PreviousModuleButton';
import React, { useState } from 'react';

export const CallTerminatesButton = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogHeader title={'Call Termination'} onClose={handleClose} />
        <DialogContent>
          <p>
            This module terminates the call and thus no modules may follow it.
          </p>
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => setOpen(true)}
        size={'md'}
        color={'neutral'}
        variant={'outline'}
      >
        Call Terminates
      </Button>
    </>
  );
};
