import { UserAvatar } from '../../../../../components/Sidebar/Sidebar';
import { Emoji } from 'emoji-picker-react';
import { UserName } from '../../../../../components/UserName';
import { PhoneNumberDisplay } from '../../../../../components/PhoneNumberDisplay';
import {
  Alert,
  Button,
  Listbox,
  SquareProgress,
} from '../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { setAtPath, useToggleReducer } from '../../../../../utilities';
import { usePipesQuery } from '../../../../../sdk';
import useEnsureModuleItemId from '../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  useListVmboxesQuery,
  useVmboxQuery,
} from '../../../../../hooks/queries/vmbox';
import { useUpdateVmboxPartial } from '../../../../../hooks/mutations/vmbox';
import { AudioPlayer } from '../../../../../components/AudioPlayer';
import { EditLineGreeting } from '../../../PipeView/components/Voicemail/Voicemail';
import { MenuButton } from '../../../../../components/IvrBuilder/Flow/nodes/common/MenuButton';
import { PreviousModuleButton } from './PreviousModuleButton';
import { CallTerminatesButton } from './CallTerminatesButton';

export const VmboxModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
  } = useCallRouteEditor();

  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);
  // Load vmbox via query
  const {
    data: vmbox,
    isLoading,
    isFetched,
  } = useVmboxQuery({ id: moduleItem?.data?.id });
  const pipeListQuery = usePipesQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    // orderBy: [['lastName', 'asc']],
    filters: {
      // searchInput: querySearchInput,
      NOT: {
        type: 'room',
      },
    },
  });
  const { pipes = [], totalCount = 0 } = pipeListQuery.data ?? {};
  const vmboxPipe = pipes?.find(p => p.id === vmbox?.doc?.pipe_id);
  const {
    data: vmboxesPage,
    isLoading: vmboxesLoading,
    refetch: vmboxesRefetch,
  } = useListVmboxesQuery({
    take: 500,
    filters: {
      // ids: pipe.vmboxes,
      // context: {
      //   ownerIds: pipe.id, // TODO: use pipe_id instead of owner_id ?
      // },
      // raw: [
      //   {
      //     variables: {
      //       docFilters: [['pipe_id', '=', pipe?.id]],
      //     },
      //   },
      // ],
    },
    options: {},
  });
  const vmboxes = vmboxesPage?.vmboxes ?? [];
  // const [vmbox] = vmboxes;
  const greeting_id = vmbox?.doc?.media?.unavailable;
  const updateVmbox = useUpdateVmboxPartial();
  const [edit, toggleEdit] = useToggleReducer();

  const boxRef = useRef(null);

  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${modifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  if (pipeListQuery.isLoading || vmboxesLoading)
    return (
      <div className={`w-full h-full grid place-items-center`}>
        <div className={`text-center`}>
          <SquareProgress />
          <span>Loading...</span>
        </div>
      </div>
    );

  return (
    <>
      <Listbox
        // disabled={skipEditing}
        // size={'sm'}
        label={'Voicemail'}
        placeholder="Select Voicemail"
        className={`w-full mt-1 self-center`}
        options={vmboxes
          // .filter(pipe => pipe.type === 'external')
          .map(vmbox => {
            const pipe = pipes.find(p => p.id === vmbox.doc?.pipe_id);
            return {
              label: (
                <div className={'flex space-x-2  min-w-0 items-center'}>
                  {pipe?.type === 'personal' ? (
                    <UserAvatar user_ext={pipe?.key} size="sm" />
                  ) : pipe?.metadata.name ? (
                    <Emoji
                      unified={pipe?.metadata?.emoji || '1f937'}
                      size={16}
                    />
                  ) : null}
                  <div className="text-left py-2">
                    {pipe?.type === 'personal' ? (
                      <div>
                        <UserName user_ext={pipe?.key} />
                      </div>
                    ) : pipe?.metadata.name ? (
                      <div>{pipe?.metadata.name}</div>
                    ) : null}
                    <div className={'truncate text-neutral-60 text-xs'}>
                      <PhoneNumberDisplay hideFlag ptn={vmbox.doc.mailbox} />
                    </div>
                  </div>
                </div>
              ),
              value: vmbox.id,
            };
          })}
        value={moduleItem?.data?.id}
        onChange={id => {
          handleUpdate({
            ...(moduleItem ?? {}),
            data: {
              ...(moduleItem?.data ?? {}),
              id,
              // timeout: timeoutDefault,
            },
          });
        }}
      />
      {vmbox ? (
        <div className={`flex my-4 items-center justify-between`}>
          <label className={' text-md font-medium text-content'}>
            Voicemail Greeting
          </label>
          <Button
            color="accent"
            variant={'ghost'}
            size={'md'}
            onClick={toggleEdit}
          >
            {greeting_id ? 'Edit Greeting' : 'Add Greeting'}
          </Button>
        </div>
      ) : null}
      {
        isLoading ? (
          <div className={`w-full h-full grid place-items-center`}>
            <div className={`text-center`}>
              <SquareProgress />
              <span>Loading...</span>
            </div>
          </div>
        ) : edit ? (
          <EditLineGreeting
            onCancel={toggleEdit}
            vmbox={vmbox}
            mediaId={greeting_id}
            onComplete={() => {
              vmboxesRefetch();
              toggleEdit();
            }}
          />
        ) : vmbox ? (
          greeting_id ? (
            <AudioPlayer mediaId={greeting_id} />
          ) : (
            <Alert
              reverse
              color={'neutral'}
              label={'No greeting'}
              body={
                <div className={`flex flex-col space-y-2 pr-2`}>
                  <span>
                    Customize your voicemail greeting to help your business
                    standout.
                  </span>
                </div>
              }
              // actionProps={{
              //   onClick: toggleEdit,
              //   children: 'Customize greeting',
              // }}
            />
          )
        ) : null /*(
        <Alert
          color={'attention'}
          label={'No voicemail box'}
          body={'We could not find a voicemail box associated with this line.'}
          icon={<WarningTriangle fr={undefined} />}
          // actionProps={{
          //   children: 'Add voicemail box',
          //   onClick: handleCreate,
          // }}
          reverse
        />
      )*/
      }
      <MenuButton
        type={'termination'}
        current={'VoicemailBox'}
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          'replace',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
        <CallTerminatesButton />
      </div>
    </>
  );
};
