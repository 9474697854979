import { IconButton } from '../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../utilities';
import useEnsureModuleItemId from '../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { DeleteCircle, EditPencil, WarningTriangle } from 'iconoir-react';
import { MenuButton } from '../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/common/MenuButton';
import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { PreviousModuleButton } from './PreviousModuleButton';
import { ModifyLastCommunicated } from '../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/LastCommunicated/LastCommunicated';

export const LastCommunicatedModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const [edit, toggleEdit] = useToggleReducer();

  const { match, no_match } = moduleItem.data.targets;

  const handleEdit = target => {
    setSelectedElementKey(
      `${modifyPath}.data.targets.${target}.callflow.message_strategy.data.modules`,
    );
  };

  const handleClear = target => {
    setAtPath(
      callflow,
      `${modifyPath}.data.targets.${target}.callflow.message_strategy.data.modules`,
      [],
    );
    setCallflow({ ...callflow });
  };

  const matchModules = match?.callflow?.message_strategy?.data?.modules ?? [];
  const noMatchModules =
    no_match?.callflow?.message_strategy?.data?.modules ?? [];

  const timeframe = moduleItem.data?.match?.[0]?.data?.timeframe;
  // console.log('timeframe', timeframe);

  const handleUpdateTimeframe = field => {
    // preserve value if set
    const value =
      timeframe === 'never' ? 0 : Object.values(timeframe?.[0]?.[1])?.[0] ?? 0;

    setAtPath(
      callflow,
      `${modifyPath}.data.match.${0}.data.timeframe`,
      field === 'never' ? field : [['sub', { [`${field}`]: value }]],
    );
    setCallflow({ ...callflow });
  };

  const handleUpdateValue = value => {
    // preserve field if set
    const field =
      timeframe === 'never' ? 0 : Object.keys(timeframe?.[0]?.[1])?.[0] ?? 0;

    setAtPath(
      callflow,
      `${modifyPath}.data.match.${0}.data.timeframe`,
      field === 'never' ? field : [['sub', { [`${field}`]: value }]],
    );
    setCallflow({ ...callflow });
  };

  return (
    <>
      <ModifyLastCommunicated
        field={
          timeframe === 'never'
            ? timeframe
            : Object.keys(timeframe?.[0]?.[1])?.[0] ?? 'never'
        }
        updateField={handleUpdateTimeframe}
        value={
          timeframe === 'never' ? 0 : Object.values(timeframe?.[0]?.[1])?.[0]
        }
        updateValue={handleUpdateValue}
        disable={false}
      />
      <span className={`text-md font-medium`}>Match Options</span>
      <p className={`text-neutral-60 text-sm my-2`}>
        <span className={`text-sm text-neutral-500`}>
          Matches if the last time you communicated with the sender is within
          the time specified above:
        </span>
      </p>
      <div
        className={
          'flex-1  divide-y divide-neutral-20 items-center flex flex-col'
        }
      >
        <div
          className={`w-full flex px-4 items-center justify-between  ${
            matchModules.length
              ? 'text-accent-60'
              : !matchModules.length
              ? 'text-attention-60 bg-attention-10'
              : 'bg-neutral-10/50 text-neutral-60'
          }`}
        >
          <div
            onClick={() => {
              handleEdit('match');
            }}
            className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
          >
            {!matchModules.length ? (
              <WarningTriangle fr={undefined} className={`text-attention-60`} />
            ) : null}
            <div className={`flex flex-col`}>
              {match.name ? (
                <div className={`flex flex-col`}>
                  <span
                  // className={
                  //   isPresent && modules.length
                  //     ? ''
                  //     : 'text-neutral-60'
                  // }
                  >
                    {match.name}
                  </span>{' '}
                  <div className={`flex text-neutral-90 space-x-1 text-sm`}>
                    (
                    <span
                    // className={
                    //   isPresent && modules.length
                    //     ? 'text-accent-60'
                    //     : 'text-neutral-60'
                    // }
                    >
                      Match
                    </span>
                    )
                  </div>
                </div>
              ) : (
                <div className={`flex flex-col`}>
                  <span
                  // className={
                  //   isPresent && modules.length
                  //     ? 'text-accent-60'
                  //     : 'text-neutral-60'
                  // }
                  >
                    Match
                  </span>
                </div>
              )}
              {!matchModules.length ? (
                <span
                  className={`text-sm ${
                    matchModules.length ? 'text-neutral-60' : 'text-negative-60'
                  }`}
                >
                  No Modules
                </span>
              ) : null}
            </div>
          </div>
          <div className={`flex space-x-2`}>
            <IconButton
              size={'md'}
              onClick={() => handleEdit('match')}
              variant={'outline'}
              color={'accent'}
            >
              <EditPencil fr={undefined} />
            </IconButton>

            <IconButton
              size={'md'}
              onClick={() => handleClear('match')}
              variant={'outline'}
              color={'negative'}
              disabled={!matchModules.length}
              title={'Clear'}
            >
              <DeleteCircle fr={undefined} />
            </IconButton>
          </div>
        </div>

        <div
          className={`w-full flex px-4 items-center justify-between  ${
            noMatchModules.length
              ? 'text-accent-60'
              : !noMatchModules.length
              ? 'text-attention-60 bg-attention-10'
              : 'bg-neutral-10/50 text-neutral-60'
          }`}
        >
          <div
            onClick={() => handleEdit('no_match')}
            className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
          >
            {!noMatchModules.length ? (
              <WarningTriangle fr={undefined} className={`text-attention-60`} />
            ) : null}
            <div className={`flex flex-col`}>
              {no_match?.name ? (
                <div className={`flex flex-col`}>
                  <span
                  // className={
                  //   isPresent && modules.length
                  //     ? ''
                  //     : 'text-neutral-60'
                  // }
                  >
                    {no_match?.name}
                  </span>{' '}
                  <span className={`text-sm text-neutral-90`}>(No Match)</span>
                </div>
              ) : (
                <span
                // className={
                //   isPresent && modules.length
                //     ? 'text-accent-60'
                //     : 'text-neutral-60'
                // }
                >
                  No Match
                </span>
              )}
              {!noMatchModules.length ? (
                <span
                  className={`text-sm ${
                    noMatchModules.length
                      ? 'text-neutral-60'
                      : 'text-negative-60'
                  }`}
                >
                  No Modules
                </span>
              ) : null}
            </div>
          </div>
          <div className={`flex space-x-2`}>
            <IconButton
              size={'md'}
              onClick={() => handleEdit('no_match')}
              variant={'outline'}
              color={'accent'}
            >
              <EditPencil fr={undefined} />
            </IconButton>
            <IconButton
              size={'md'}
              onClick={() => handleClear('no_match')}
              variant={'outline'}
              color={'negative'}
              disabled={!noMatchModules.length}
              title={'Clear'}
            >
              <DeleteCircle fr={undefined} />
            </IconButton>
          </div>
        </div>
      </div>

      {/*<div
        className={
          'flex-1 justify-center divide-y divide-neutral-20 items-center flex flex-col'
        }
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '_', 'timeout'].map(targetKey => {
          const target = targets[targetKey];
          const isPresent = target;
          const modules = target?.callflow?.strategy.data.modules ?? [];
          const required = targetKey === '_' || targetKey === 'timeout';

          const handleClear = () => {
            // clear modules if present, otherwise delete target route
            const menuData = insertMenuOptionElement.data.menuData;
            if (modules.length) {
              setAtPath(
                menuData.moduleItem,
                `data.targets.${targetKey}.callflow.strategy.data.modules`,
                [],
              );
            } else {
              setAtPath(menuData.moduleItem, `data.targets`, {
                ...menuData.moduleItem.data.targets,
                [targetKey]: undefined,
              });
            }

            setAtPath(
              menuData.callflow,
              menuData.modifyPath,
              menuData.moduleItem,
            );
            menuData.setCallflow(
              { ...menuData.callflow },
              { name: 'Edit Menu Flow' },
            );
          };

          let label;
          switch (targetKey) {
            case '_':
              label = 'Invalid Entry';
              break;
            case 'timeout':
              label = `Timeout`;
              break;
            default:
              label = `Dial ${targetKey}`;
              break;
          }

          return (
            <div
              key={targetKey}
              className={`w-full flex px-4  items-center justify-between  ${
                isPresent && modules.length
                  ? 'text-accent-60'
                  : required && !modules.length
                  ? 'text-attention-60 bg-attention-10'
                  : 'bg-neutral-10/50 text-neutral-60'
              }`}
            >
              <div
                onClick={() => {
                  if (isPresent) {
                    handleEdit(targetKey);
                    return;
                  }

                  handleAddOption(targetKey);
                }}
                // onTouchStart={e => {
                //   e.preventDefault();
                //   if (isPresent) {
                //     handleEdit(targetKey);
                //     return;
                //   }
                //
                //   handleAddOption(targetKey);
                // }}
                className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
              >
                {required && !modules.length ? (
                  <WarningTriangle
                    fr={undefined}
                    className={`text-attention-60`}
                  />
                ) : null}
                <div className={`flex flex-col`}>
                  {target?.name ? (
                    <div className={`flex space-x-2`}>
                      <span
                      // className={
                      //   isPresent && modules.length
                      //     ? ''
                      //     : 'text-neutral-60'
                      // }
                      >
                        {target?.name}
                      </span>{' '}
                      <span className={`text-neutral-60`}>({label})</span>
                    </div>
                  ) : (
                    <span
                    // className={
                    //   isPresent && modules.length
                    //     ? 'text-accent-60'
                    //     : 'text-neutral-60'
                    // }
                    >
                      {label}
                    </span>
                  )}
                  {isPresent && !modules.length ? (
                    <span
                      className={`text-sm ${
                        modules.length ? 'text-neutral-60' : 'text-negative-60'
                      }`}
                    >
                      No Modules
                    </span>
                  ) : null}
                </div>
              </div>
              <div className={`flex space-x-2`}>
                {isPresent ? (
                  <>
                    <IconButton
                      size={'md'}
                      onClick={() => handleEdit(targetKey)}
                      variant={'outline'}
                      color={'accent'}
                    >
                      <EditPencil fr={undefined} />
                    </IconButton>
                    {required && !modules.length ? null : (
                      <IconButton
                        size={'md'}
                        onClick={handleClear}
                        variant={'outline'}
                        color={'negative'}
                        title={modules.length ? 'Clear' : 'Delete'}
                      >
                        {modules.length ? (
                          <DeleteCircle fr={undefined} />
                        ) : (
                          <Trash fr={undefined} />
                        )}
                      </IconButton>
                    )}
                  </>
                ) : (
                  <>
                    <IconButton
                      size={'md'}
                      onClick={() => handleAddOption(targetKey)}
                      variant={'outline'}
                      color={'accent'}
                    >
                      <Plus fr={undefined} />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          );
        })}

        {targets.map(([key, target]) => {
          let label = key;

          if (key === '_') {
            label = 'Invalid Entry';
          } else if (key === 'timeout') {
            label = 'Timeout';
          }


          const handleClick = () => {
            console.log('path', modifyPath, key);
            const path =
              modifyPath + `.data.targets.${key}.callflow.strategy.data.module`;
            const test = getAtPath(callflow, path);
            console.log();
            setSelectedElementKey(
              modifyPath +
                `.data.targets.${key}.callflow.strategy.data.modules`,
            );
          };


          return (
            <div
              key={key}
              className={`w-full flex justify-center items-center`}
            >
              <Button size={'md'} onClick={handleClick} color={'accent'}>
                {label}
              </Button>
            </div>
          );
        })}
      </div>*/}
      <MenuButton
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
      </div>
    </>
  );
};
