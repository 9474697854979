import { alpha } from '@mui/material/styles';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from 'app/design';
import {
  Badge,
  DialogContent,
  DialogHeader,
  DialogLoading,
} from 'app/design-lib';
import {
  ClearAll as ClearAllIcon,
  Dehaze as DehazeIcon,
  FilterList as FilterListIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from 'app/design/icons-material';
import { useListDevicesQuery } from 'app/hooks/queries/device';
import { useListUsersQuery } from 'app/hooks/queries/user';
import { AllAtOnceComponent } from 'app/pages/settings/Groups/components/GroupRingStrategyCard/RingGroup/AllAtOnce';
import { CustomComponent } from 'app/pages/settings/Groups/components/GroupRingStrategyCard/RingGroup/Custom';
import { FallbackComponent } from 'app/pages/settings/Groups/components/GroupRingStrategyCard/RingGroup/Fallback';
import { SequentialComponent } from 'app/pages/settings/Groups/components/GroupRingStrategyCard/RingGroup/Sequential';
import { getAtPath, setAtPath } from 'app/utilities';
import React, { useEffect, useState } from 'react';
import { StepTab } from 'app/components/Onboarding/Vsb/Vsb';
import { Strategy, Wrench } from 'iconoir-react';

// Note: this does NOT need to be callable by convertToFlow!
// - the components each build a correct ringgroup "endpoints" value (or preEndpoints)
export const rgTypes = [
  {
    id: 'simu',
    name: 'All at Once',
    description: 'Ring users or devices all at once.',
    component: AllAtOnceComponent,
    icon: (
      <DehazeIcon
        className={`text-neutral-60 mb-0.5`}
        style={{ fontSize: 16 }}
      />
    ),
    footer: (
      <Badge size={'sm'} reverse variant={'fill'} color={'accent'}>
        Default
      </Badge>
    ),
  },
  {
    id: 'seq',
    name: 'Sequential',
    description: 'Ring users or devices one after another.',
    footer: 'Supports sub-groups',
    component: SequentialComponent,
    icon: (
      <FormatListNumberedIcon
        className={`text-neutral-60 mb-0.5`}
        style={{ fontSize: 16 }}
      />
    ),
  },
  {
    id: 'fallback',
    name: 'Fallback',
    description:
      'Ring users or devices with a delay before ringing additional users or devices.',
    footer: 'Supports sub-groups',
    component: FallbackComponent,
    icon: (
      <ClearAllIcon
        className={`text-neutral-60 mb-0.5`}
        style={{ fontSize: 16 }}
      />
    ),
  },
  {
    id: 'custom',
    name: 'Custom',
    description: 'Fully custom sliders and timing for ringing.',
    footer: 'Supports sub-groups',
    component: CustomComponent,
    icon: (
      <FilterListIcon
        className={`text-neutral-60 mb-0.5`}
        style={{ fontSize: 16 }}
      />
    ),
  },
];

// Select users from list of users
export const RingGroup = props => {
  const {
    modifyPath,
    strategyCallflow,
    setStrategyCallflow,
    // users,
    // devices,
    defaultToFirst,
    ownerUser,
    // fetchUsers,
    onSave,
    onClose,
    allow,
    useGroupFormat, // vs. "ringgroup" Format (requires conversion! has restrictions!)
  } = props;

  const ringGroup = getAtPath(strategyCallflow, `${modifyPath}`, {
    type: 'none',
    version: 1, // for if we change the type's version
    endpoints: useGroupFormat ? {} : [],
    preEndpoints: [], // gets built into "endpoints" depending on the useGroupFormat
    timeout: 20,
    strategy: 'simultaneous',
  });

  const ringGroupType = ringGroup?.type ?? 'none';

  const [startEditing, setStartEditing] = useState(
    !!ringGroup?.type ? false : true,
  );

  useEffect(() => {
    if (ringGroupType === 'none') {
      setStartEditing(true);
    }
  }, [ringGroupType]);

  // console.log('start editing', startEditing);

  const handleChooseType = type => () => {
    setAtPath(strategyCallflow, `${modifyPath}.type`, type);
    setStrategyCallflow({
      ...strategyCallflow,
    });
  };

  const rgFound = rgTypes.find(rgInfo => rgInfo.id === ringGroupType);
  // console.log('rgFound:', rgFound, ringGroupType);
  const RingGroupComponent = rgFound?.component;

  // Users
  const { data: usersResp, isLoading: usersIsLoading } = useListUsersQuery({
    mask: 'id,doc(presence_id,username,email),extra', // mask
    quick: true, // "quick" ie "docOnly"
  });
  // @ts-ignore
  const { users = [] } = usersResp?.users ?? {};

  // Devices
  const { data: devicesResp, isLoading: devicesIsLoading } =
    useListDevicesQuery({
      mask: 'id,doc(name,owner_id)', // mask
      quick: true, // "quick" ie "docOnly"
    });
  // @ts-ignore
  const { devices = [] } = devicesResp?.devices ?? {};

  const theme = useTheme();
  // const isMediaQueryMd = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediaQueryMd = false;

  if (devicesIsLoading || usersIsLoading) {
    return <DialogLoading label={'Loading ring group...'} />;
  }

  if (RingGroupComponent) {
    return (
      <RingGroupComponent
        users={users}
        devices={devices}
        {...props}
        startEditing={startEditing}
      />
    );
  }

  return (
    <>
      <DialogHeader title={'Select Ring Group Strategy'} onClose={onClose} />
      <DialogContent>
        <div
          className={`flex pb-4 justify-center sm:mx-44 mx-4 items-center space-x-4`}
        >
          <StepTab
            label={'Select Strategy'}
            icon={<Strategy width={12} height={12} fr={undefined} />}
            currentStep={0}
            step={0}
          />
          <div className={`flex-grow h-[1px] bg-neutral-20 rounded-full`}></div>
          <div className={`w-`}></div>
          <StepTab
            label={'Customize Group'}
            icon={<Wrench width={12} height={12} fr={undefined} />}
            currentStep={0}
            step={1}
          />
        </div>
        <div
          className={`flex flex-col sm:space-y-2 divide-y divide-neutral-20 sm:divide-y-0`}
        >
          {rgTypes.map(({ name, id, footer, description, icon }) => {
            return (
              <button
                onClick={handleChooseType(id)}
                className={`text-left hover:bg-gray-10 pb-2 rounded p-2 sm:flex-row  flex-col flex`}
                key={id}
              >
                <div>
                  <div className={`flex space-x-2 items-center`}>
                    <div>{icon}</div>
                    <span className={`font-medium text-md`}>{name}</span>
                  </div>
                  <p className={`text-sm`}>{description}</p>
                </div>
                <div className={`flex-1 justify-end flex`}>
                  <p className={`text-sm text-neutral-60`}>{footer}</p>
                </div>
              </button>
            );
          })}
          <p className={`text-sm sm:pt-0 pt-2 text-neutral-60`}>
            * sub-groups allow a grouping of users and devices to be rung as a
            single "group member"
          </p>
        </div>
      </DialogContent>
    </>
  );

  // return (
  //   <>
  //     <span className={'text-xl font-medium'}>Select Ring Strategy</span>
  //     <br />
  //     <Box
  //       sx={{
  //         display: 'grid',
  //         gridTemplateColumns: isMediaQueryMd
  //           ? 'repeat(4, 1fr)'
  //           : 'repeat(2, 1fr)',
  //         gap: 4,
  //         mt: 2,
  //         textAlign: 'left',
  //       }}
  //     >
  //       {rgTypes.map(rgInfo => (
  //         <Box
  //           key={rgInfo.id}
  //           onClick={handleChooseType(rgInfo.id)}
  //           // style={{ marginBottom: 8, cursor: 'pointer' }}
  //           // variant={'outlined'}
  //           sx={{
  //             border: '2px solid #285E70',
  //             borderRadius: 2,
  //             p: 2,
  //             minHeight: '100px',
  //             minWidth: '100px',
  //             display: 'flex',
  //             justifyContent: 'space-between',
  //             '&:hover': {
  //               background: theme => alpha(theme.palette.primary.main, 0.1),
  //             },
  //             cursor: 'pointer',
  //             flexDirection: 'column',
  //           }}
  //         >
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               w: '100%',
  //               justifyContent: 'center',
  //               whiteSpace: 'nowrap',
  //               alignItems: 'center',
  //             }}
  //           >
  //             <Typography variant="h7" sx={{ pt: 0.5, mr: 1 }}>
  //               {rgInfo.icon}
  //             </Typography>
  //             <Typography variant="h6">{rgInfo.name}</Typography>
  //           </Box>
  //           <Typography
  //             variant="body2"
  //             sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
  //           >
  //             {rgInfo.description}
  //           </Typography>
  //           <Typography variant="caption" sx={{ textAlign: 'right' }}>
  //             {rgInfo?.footer}
  //           </Typography>
  //         </Box>
  //       ))}
  //     </Box>
  //   </>
  // );
};

export default RingGroup; //connect(mapStateToProps, { fetchUsers })(RingGroup);
