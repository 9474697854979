import { Color, Size, Variant } from 'app/design-lib/types';
import React, {
  forwardRef,
  LegacyRef,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';

export interface ButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'size'
  > {
  component?: any;
  color?: Color;
  size?: Size;
  variant?: Variant;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  href?: string; // if using a link
  to?: string; // if using a link
  clear?: boolean; // if no padding, ie buttons on an alert
  target?: string; // if using link
}

const Button = forwardRef(
  (
    {
      component = 'button', // or "a" if you want a link
      variant = 'fill',
      size = 'sm',
      color = 'neutral',
      startIcon,
      endIcon,
      children,
      className: _className = '',
      clear,
      ...props
    }: ButtonProps,
    ref:
      | LegacyRef<HTMLButtonElement>
      | Ref<HTMLButtonElement>
      | undefined
      | any,
  ) => {
    const className = `${_className} btn font-sans ${getSizeStyling(
      size,
      clear,
    )} ${getVariantStyling(
      variant,
      color,
    )} ${_className} dark:disabled:italic dark:disabled:text-neutral-400 dark:disabled:border dark:disabled:border-neutral-60 dark:disabled:bg-transparent`;

    const buttonContent = (
      <>
        {startIcon
          ? React.cloneElement(startIcon, {
              className: getIconStyling(size),
            })
          : null}
        {children ? <div>{children}</div> : null}
        {endIcon
          ? React.cloneElement(endIcon, {
              className: getIconStyling(size),
            })
          : null}
      </>
    );

    const onClick = props.onClick;
    // let ElementType = button;
    if (typeof component === 'string') {
      return React.createElement(
        component,
        {
          className: className,
          ...(component === 'button' ? { type: 'button' } : {}),
          ...props,
          onTouchStart: e => {
            e.preventDefault();
            e.stopPropagation();

            onClick && onClick(e);
          },
          ref,
        },
        buttonContent,
      );
    }

    let Component = component;
    return (
      <Component
        className={className}
        onTouchStart={e => {
          e.preventDefault();
          e.stopPropagation();

          onClick && onClick(e);
        }}
        ref={ref}
        {...props}
      >
        {buttonContent}
      </Component>
    );
  },
);

export default Button;

const OverlapStyles = (color: Color) => `
  bg-transparent 
  hover:bg-transparency-10 
  active:bg-transparency-20
  ${color === 'neutral' ? `active:text-${color}-90` : `active:text-${color}-80`}
  text-${color}-60 
  hover:text-${color}-70 
  
  disabled:text-neutral-30
`;

const ReverseOverlapStyles = (color: Color) => `
  bg-transparent
  hover:bg-transparency-30
  active:bg-transparency-60
  text-neutral-20 
  hover:text-neutral-10 
  active:text-white
  disabled:text-neutral-60
`;

const getVariantStyling = (variant: Variant, color: Color) => {
  if (color === 'neutral-reverse') {
    switch (variant) {
      case 'fill':
        return `
      bg-white
      hover:bg-neutral-10 
      active:bg-neutral-20
      disabled:bg-neutral-60
      text-neutral-90
      hover:text-neutral-70
      active:text-neutral-90 
      `;
      case 'outline':
        return `
      border
      border-neutral-60 
      hover:border-neutral-50 
      active:border-neutral-40 
      disabled:border-neutral-60 
      ${ReverseOverlapStyles(color)}    
      `;
      case 'ghost':
        return ReverseOverlapStyles(color);
    }
  }

  switch (variant) {
    case 'fill':
      return `
      bg-${color}-60 
      hover:bg-${color}-70 
      active:bg-${color}-80
      text-white disabled:bg-neutral-40`;
    case 'outline':
      return `
      border
      border-neutral-30 
      hover:border-neutral-40 
      active:border-neutral-50 
      disabled:border-neutral-20 
      ${OverlapStyles(color)}    
      `;
    case 'ghost':
      return OverlapStyles(color);
  }
};

const getSizeStyling = (size: Size, clear?: boolean) => {
  switch (size) {
    case 'sm':
      return `h-6 ${clear ? '' : 'rounded'} text-sm font-medium py-1 px-1.5`;
    case 'md':
      return `h-8  ${clear ? '' : 'rounded-md'} text-md font-medium p-2`;
    case 'lg':
      return `h-12 ${clear ? '' : 'rounded-md'} text-md font-medium p-3`;
  }
};

const getIconStyling = (size: Size) => {
  switch (size) {
    case 'sm':
      return `h-4 w-4`;
    case 'md':
      return `h-4 w-4`;
    case 'lg':
      return `h-5 w-5`;
  }
};
