import { DetailsDialogAccount } from 'app/components/DetailsDialogAccount';
import { useFindInPage } from 'app/components/FindInPage';
import { GenericSettingsCardField } from 'app/components/GenericSettingsCardField';
import { Setting, Table, TableCell, TableRow } from 'app/design-lib';
import { getTimezoneName, useToggleReducer } from 'app/utilities';
import * as React from 'react';
import { Account } from 'types/account';
import { SettingsPanel } from 'app/pages/settings/shared/SettingsPanel';
import { EditPencil } from 'iconoir-react';

interface NameAccountProps {
  account: Account;
  onComplete: () => void;
}

const NameAccount = ({ account, onComplete }: NameAccountProps) => {
  const [showEditDialog, toggleShowEditDialog] = useToggleReducer(false);
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'name',
    options: [
      {
        label: 'Name',
        value: 'name',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  const handleOnComplete = () => {
    onComplete();
    toggleShowEditDialog();
  };

  return (
    <SettingsPanel
      leftChild={
        <>
          <Setting
            label={'Name'}
            body={'Display name for account.'}
            iconButtonProps={{
              children: <EditPencil fr={undefined} />,
              pill: true,
              onClick: toggleShowEditDialog,
            }}
          />
          <DetailsDialogAccount
            DialogProps={{
              open: showEditDialog,
            }}
            ContentProps={{
              onCancel: toggleShowEditDialog,
              onComplete: handleOnComplete,
            }}
          />
        </>
      }
      rightChild={
        <Table>
          <TableRow showBorder={false}>
            <TableCell className={`font-medium`}>{account.doc.name}</TableCell>
          </TableRow>
        </Table>
      }
    />
  );

  // return (
  //   // @ts-ignore
  //   <div ref={ref}>
  //     <DetailsDialogAccount
  //       DialogProps={{
  //         open: showEditDialog,
  //       }}
  //       ContentProps={{
  //         onCancel: toggleShowEditDialog,
  //         onComplete: handleOnComplete,
  //       }}
  //     />
  //     <HighlightPulse {...highlightPulseProps} />
  //     <GenericSettingsCardField
  //       title={'Name'}
  //       content={account.doc.name}
  //       editOnClick={toggleShowEditDialog}
  //     />
  //   </div>
  // );
};

export default NameAccount;
