import { BuyPhoneNumberDialog } from 'app/components/BuyPhoneNumberDialog';
import { Group, Cart, Plus, Settings } from 'iconoir-react';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ButtonDropdown } from 'app/design-lib/components/ButtonDropdown';
import { Dialog, DialogContent } from 'app/design';
import { Button, IconButton } from 'app/design-lib';
import { useToggleReducer } from 'app/utilities';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { AddSharedLineDialog } from 'app/pages/settings/Pipes/components/AddSharedLineDialog';
import { useQueryClient } from 'react-query';
import IconButtonDropdown from 'app/design-lib/components/IconButtonDropdown/IconButtonDropdown';
import { UserPrivLevel } from 'types/user';

interface LinesHeaderProps {}

const LinesHeader = ({}: LinesHeaderProps) => {
  const [open, toggleOpen] = useToggleReducer();
  const history = useHistory();
  const { data: user } = useAuthenticatedUserQuery();
  const [openNewPipeDialog, toggleNewPipeDialog] = useToggleReducer();
  const queryClient = useQueryClient();
  return (
    <div className={'px-4 items-center flex justify-between w-full py-1'}>
      {/*<AddSharedLineDialog
        onCancel={toggleNewPipeDialog}
        onComplete={pipeId => {
          queryClient.refetchQueries(['pipes']);
          toggleNewPipeDialog();
          history.push(`/v2/settings/pipes/${pipeId}`);
        }}
        user={user}
        open={openNewPipeDialog}
      />*/}
      <BuyPhoneNumberDialog open={open} onClose={toggleOpen} />
      {/* <BuyPhoneNumberDialog
        DialogProps={{
          open,
        }}
        ContentProps={{
          onComplete: numberArr => {
            // alert('done! add numbers to here');
            // console.log('numberArr:', numberArr);
            toggleOpen();
            onComplete(numberArr);
            // setNumbers([...numbers, ...numberArr]);
          },
          onCancel: toggleOpen,
        }}
      /> */}
      {/*<Dialog open={open} onClose={toggleOpen}>
        <DialogContent>
          <div className="text-base">
            Buying numbers is disabled in this testing stage, sorry!
            <br />
            <br />
            <div className="">
              <Button
                color="positive"
                variant={'outline'}
                className="ml-auto"
                onClick={toggleOpen}
              >
                OK, I'll try again next time
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>*/}
      <span className={'text-sm'}>Lines</span>
      <div className={'text-gray-70 items-center space-x-2 flex'}>
        {/*<IconButtonDropdown
          align={'right'}
          variant={'ghost'}
          hideArrow
          menuItems={[
            {
              label: 'Buy Numbers',
              icon: <Cart fr={undefined} height={16} width={16} />,
              onClick: toggleOpen,
            },
            {
              color: 'accent',
              label: 'Add Internal Group',
              icon: <Group fr={undefined} height={16} width={16} />,
              onClick: toggleNewPipeDialog,
            },
          ]}
        >
          {<Plus fr={undefined} />}
        </IconButtonDropdown>*/}
        {user?.doc.priv_level === UserPrivLevel.Admin ? (
          <>
            <IconButton size={'sm'} variant={'ghost'} onClick={toggleOpen}>
              <Plus fr={undefined} />
            </IconButton>
            <IconButton
              size={'sm'}
              variant={'ghost'}
              component={Link}
              to={`/v2/settings/pipes`}
            >
              <Settings fr={undefined} />
            </IconButton>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default LinesHeader;
