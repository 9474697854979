import { Card, CardContent, CardHeader, Typography } from 'app/design';
import { useFindInPage } from 'app/components/FindInPage';
import { HoldMusicAccount } from '../HoldMusicAccount';
import { NameAccount } from '../NameAccount';
// import { OperatorExtensionAccount } from '../OperatorExtensionAccount';
import { TimezoneAccount } from '../TimezoneAccount';
import * as React from 'react';
import { Account } from 'types/account';

interface SettingsCardProps {
  account: Account;
  onComplete: () => void;
}

const SettingsCard = ({ account, onComplete }: SettingsCardProps) => {
  const { ref, HighlightPulse, highlightPulseProps } = useFindInPage({
    name: 'settings',
    options: [
      {
        label: 'Settings',
        value: 'settings',
      },
    ],
    onChoose: 'matchAnyScrollToRef',
  });

  return (
    <>
      <NameAccount account={account} onComplete={onComplete} />

      <TimezoneAccount account={account} onComplete={onComplete} />

      {/* Hold Music */}
      <HoldMusicAccount account={account} onComplete={onComplete} />

      {/* Operator ext */}
      {/* <OperatorExtensionAccount account={account} onComplete={onComplete} />
      <br /> */}
      <span className={'text-sm text-neutral-60'}>ID: {account?.doc?.id}</span>
    </>
  );
};

export default SettingsCard;
