import { useToggleReducer } from '../../../../utilities';
import { ChooseDialog } from '../../../../components/MessageRoutingVisualBuilder/Flow/nodes/common/ChooseDialog';
import { Button, IconButton } from '../../../../design-lib';
import { Plus } from 'iconoir-react';
import React from 'react';

export const AddModuleButton = ({ data, expanded = false, noFill = false }) => {
  const [showAdd, toggleShowAdd] = useToggleReducer();

  return (
    <>
      {showAdd && (
        <ChooseDialog {...data} showTemplateList onClose={toggleShowAdd} />
      )}
      {expanded ? (
        <Button
          startIcon={<Plus fr={undefined} />}
          onClick={toggleShowAdd}
          size={'md'}
          color={'attention'}
          variant={'outline'}
          className={`${noFill ? '' : 'w-full'}`}
        >
          Add Module
        </Button>
      ) : (
        <IconButton
          onClick={toggleShowAdd}
          size={'md'}
          variant={'outline'}
          color={'attention'}
        >
          <Plus fr={undefined} />
        </IconButton>
      )}
    </>
  );
};
