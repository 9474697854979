import { cloneDeep } from 'lodash';
import React, { useState, useContext, useRef, memo, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';

import { Button } from 'app/design-lib';

import { UserAvatar } from 'app/components/Sidebar/Sidebar';
import { UserName } from 'app/components/UserName';

import {
  Phone as PhoneIcon,
  Add as AddIcon,
  TransferWithinAStation as TransferWithinAStationIcon,
  Dialpad as DialpadIcon,
} from 'app/design/icons-material';

import { useSelector } from 'react-redux';
import { getAtPath, setAtPath } from 'app/utilities';

import { IvrMenuEventEmitterContext } from '../../..';
import { useListExtensionsQuery } from '../../../../../hooks/queries/extension';

// import { AutocompleteExtension } from '../../../../AutocompleteExtension';
import { NodeEditDialog } from '../../../Dialogs';

import { Box, Box as IvrBox } from '../common/Box';
import { MenuButton } from '../common/MenuButton';
import { InTemplate } from '../common/InTemplate';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';

import {
  InsertAfterButton,
  InsertBeforeButton,
  RemoveButton,
  ModifyButton,
} from '../Insert';

import { useHoverDirty } from 'react-use';
import { ModifyNodeDialogVmbox } from '../Voicemail/ModifyNodeDialogVmbox';
import { ModifyNodeDialogTransfer } from './ModifyNodeDialogTransfer';
import { usePipesQuery } from '../../../../../sdk';
import { Listbox, SquareProgress } from '../../../../../design-lib';
import { Emoji } from 'emoji-picker-react';
import { PhoneNumberDisplay } from '../../../../PhoneNumberDisplay';
import { Message, Strategy, Voice, WarningTriangle } from 'iconoir-react';
import { useBuilderContext } from '../../../IvrBuilder';
import { VmboxGreetingDialog } from '../Voicemail/VmboxGreetingDialog';
import { AudioPlayer } from '../../../../AudioPlayer';
import { Tooltip } from '@mui/material';
import useEnsureModuleItemId from '../common/useEnsureModuleItemId';
import { ModeSelectModuleThis } from '../common/ModeSelectModule';
import { EditModuleNameDialog } from '../../../../EditModuleNameDialog';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditCallRouting/MobileView/MiniMapNode';

const TransferNode = memo(({ data }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
  } = data;

  // add id if does not exist
  useEnsureModuleItemId(data);

  const { setDisableScroll, miniMap } = useBuilderContext();
  const [showModifyDialog, setShowModifyDialog] = useState(null);
  const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);

  // const callflows = useSelector(state => state.lists.callflows.list);
  // const extCallflow = callflows.find(cf =>
  //   cf.doc.numbers.includes(moduleItem.target),
  // );
  const lineNumber = moduleItem?.data?.target;
  const pipeListQuery = usePipesQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    // orderBy: [['lastName', 'asc']],
    filters: {
      // searchInput: querySearchInput,
      NOT: {
        type: 'room',
      },
    },
  });
  const { pipes = [], totalCount = 0 } = pipeListQuery.data ?? {};
  // const {
  //   data: extensionsPage,
  //   isLoading: extensionIsLoading,
  //   error: extensionError,
  //   refetch: refetchExtension,
  // } = useListExtensionsQuery({
  //   filters: {
  //     context: {
  //       numbersQuoted: [extensionId],
  //     },
  //   },
  //   options: {
  //     enabled: !!extensionId,
  //   },
  // });
  // const extension = extensionsPage?.extensions?.[0];
  // const extCallflow = extension?.callflow;

  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [
    {
      id: 'extension',
      name: 'Extension',
      read: {
        type: 'extension',
      },
      write: {
        type: 'set',
        data: {
          moduleItemModifyPath: 'data.target',
        },
      },
    },
  ];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    if (data.optsPath === modifyPath) {
      setShowModifyDialog(true);
    }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pipe = pipes.find(pipe => pipe.key === lineNumber);

  const handleUpdateName = name => {
    setAtPath(callflow, `${modifyPath}.name`, name);
    setCallflow({ ...callflow });
  };

  const { invalidModules, setInvalidModules } = useBuilderContext();

  if (miniMap) {
    return <MiniMapNode pref={boxRef} data={data} />;
  }

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <ModeSelectModuleThis id={moduleItem?.id} />
      <div
        className={`flex flex-col items-center  whitespace-nowrap w-full pt-4 px-2 pb-2`}
      >
        <EditModuleNameDialog
          icon={<Strategy />}
          name={moduleItem.name || 'Transfer to Line'}
          onUpdate={handleUpdateName}
          moduleType={'Transfer to Line'}
          skipEditing={skipEditing}
          allowEmpty
        />
        <div className={`w-full flex justify-center px-2`}>
          {pipeListQuery.isLoading ? (
            <SquareProgress size={'sm'} />
          ) : skipEditing ? (
            lineNumber?.length ? (
              <div className={'flex space-x-2  min-w-0 mx-auto items-center'}>
                {pipe?.type === 'personal' ? (
                  <UserAvatar user_ext={pipe?.key} size="sm" />
                ) : pipe?.metadata.name ? (
                  <Emoji unified={pipe?.metadata?.emoji || '1f937'} size={16} />
                ) : null}
                <div className="text-left py-2">
                  {pipe?.type === 'personal' ? (
                    <div>
                      <UserName user_ext={pipe?.key} />
                    </div>
                  ) : pipe?.metadata.name ? (
                    <div>{pipe?.metadata.name}</div>
                  ) : null}
                  <div className={'truncate text-neutral-60 text-xs'}>
                    <PhoneNumberDisplay hideFlag ptn={pipe?.key} />
                  </div>
                </div>
              </div>
            ) : (
              <Button
                color={'neutral'}
                variant={'ghost'}
                size={'sm'}
                className={`self-center`}
                startIcon={<WarningTriangle fr={undefined} />}
                // onClick={() => setShowModifyDialog(true)}
                disabled
              >
                not assigned
              </Button>
            )
          ) : (
            <Listbox
              placeholder="Select a Line"
              size={'sm'}
              className={`w-full mt-1 self-center`}
              onOpenChanged={setDisableScroll}
              options={pipes
                // .filter(pipe => pipe.type !== 'personal')
                .map(pipe => ({
                  label: (
                    <div className={'flex space-x-2  min-w-0 items-center'}>
                      {pipe?.type === 'personal' ? (
                        <UserAvatar user_ext={pipe?.key} size="sm" />
                      ) : pipe?.metadata.name ? (
                        <Emoji
                          unified={pipe?.metadata?.emoji || '1f937'}
                          size={16}
                        />
                      ) : null}
                      <div className="text-left py-2">
                        {pipe?.type === 'personal' ? (
                          <div>
                            <UserName user_ext={pipe?.key} />
                          </div>
                        ) : pipe?.metadata.name ? (
                          <div>{pipe?.metadata.name}</div>
                        ) : null}
                        <div className={'truncate text-neutral-60 text-xs'}>
                          <PhoneNumberDisplay hideFlag ptn={pipe.key} />
                        </div>
                      </div>
                    </div>
                  ),
                  value: pipe.key,
                }))}
              value={lineNumber}
              onChange={key => {
                if (invalidModules.includes(moduleItem.id)) {
                  setInvalidModules(prev =>
                    prev.filter(id => id !== moduleItem.id),
                  );
                }

                setAtPath(callflow, `${modifyPath}.data.target`, key);
                setCallflow({ ...callflow });
              }}
            />
          )}
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            type={'termination'}
            current={'Transfer'}
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              'replace',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );

  // return (
  //   <>
  //     <IvrBox pref={boxRef} borderColor="#E65100" data={data}>
  //       {showModifyDialog && (
  //         <ModifyNodeDialogTransfer
  //           {...data}
  //           extensionId={moduleItem?.data?.target}
  //           onClose={setShowModifyDialogWrap(null)}
  //         />
  //       )}
  //       {showVariables && (
  //         <VariablesEditor // Dialog
  //           {...data}
  //           possibleVariables={possibleVariables}
  //           onClose={setShowVariablesWrap(null)}
  //         />
  //       )}
  //       <Handle
  //         type="target"
  //         position="top"
  //         style={{ background: '#555', visibility: 'hidden' }}
  //       />
  //       <div>
  //         <NodeInfoDisplay
  //           type={'Transfer'}
  //           icon={<TransferWithinAStationIcon />}
  //           title={
  //             moduleItem.data.target ? (
  //               `${moduleItem.data.target}: ${
  //                 extCallflow?.doc?.name ?? 'Unnamed Callflow'
  //               }`
  //             ) : skipEditing ? (
  //               'None Selected'
  //             ) : (
  //               <Link onClick={setShowModifyDialogWrap(true)}>
  //                 Select Transfer
  //               </Link>
  //             )
  //           }
  //         />
  //         {skipEditing ? null : (
  //           <>
  //             <MenuButton
  //               data={data}
  //               mode={isHovering ? 'normal' : 'ignore'}
  //               menuItems={[
  //                 {
  //                   type: 'modify',
  //                   props: { onClick: setShowModifyDialogWrap(true) },
  //                 },
  //                 // 'add',
  //                 'add-before',
  //                 'move',
  //                 'duplicate',
  //                 'copy',
  //                 process.env.NODE_ENV === 'development'
  //                   ? 'create_template'
  //                   : null,
  //                 templateParent ||
  //                 callflow.type === 'template' ||
  //                 process.env.NODE_ENV === 'development'
  //                   ? {
  //                       type: 'variables',
  //                       props: { onClick: setShowVariablesWrap(true) },
  //                     }
  //                   : null,
  //                 'remove',
  //               ]}
  //             />
  //           </>
  //         )}
  //       </div>
  //       {templateParent ? <InTemplate template={templateParent} /> : ''}
  //     </IvrBox>
  //   </>
  // );
});

export const ChooseDialog = props => {
  const { callflow, setCallflow, modifyPath, index, moduleItem, onClose } =
    props;

  const [selectedValue, setSelectedValue] = useState(() =>
    cloneDeep(moduleItem.data.target),
  );

  const handleChangeSelect = async selected => {
    setSelectedValue(selected?.value);
    // console.log('selected:', selected);
    // moduleItem.data.target = selected?.value;
    // setAtPath(callflow, modifyPath, moduleItem);
    // setCallflow({ ...callflow });
    // onClose();
  };

  const handleSave = () => {
    moduleItem.data.target = selectedValue;
    setAtPath(callflow, modifyPath, moduleItem);
    setCallflow({ ...callflow });
    onClose();
  };

  const [initialState] = useState({
    target: moduleItem.data.target,
  });

  const saveLabel = !selectedValue
    ? 'Leave Empty'
    : !initialState.target
    ? 'Add'
    : initialState.target === selectedValue
    ? 'Done'
    : 'Update';

  return (
    <NodeEditDialog
      title={'Select Transfer Target'}
      maxWidth={'sm'}
      onClose={onClose}
      onCancel={onClose}
      onSave={handleSave}
      saveLabel={saveLabel}
      hideCancel={saveLabel === 'Done'}
      // isLoading={isSaving}
      // loadingLabel={'Saving Menu Settings...'}
      // error={error}
    >
      {/* <AutocompleteExtension
        label=""
        placeholder={'Search transfer target by name or extension...'}
        // placeholder="Search for Extension or Name"
        defaultValue={selectedValue}
        onChange={handleChangeSelect}
      /> */}
      Autocomplete extension...
    </NodeEditDialog>
  );
};

export default TransferNode;
