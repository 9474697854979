import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { Button, IconButton } from 'app/design-lib';

import { useVmboxQuery } from 'app/hooks/queries/vmbox';
import { useMediaByIdQuery } from 'app/hooks/queries/media';

import { Audiotrack as AudiotrackIcon } from 'app/design/icons-material';

import { Handle } from 'react-flow-renderer';
import { useHoverDirty } from 'react-use';
import { IvrMenuEventEmitterContext } from '../../..';
import useMediaUrl from '../../../../../hooks/queries/media/useMediaUrl';
import { setAtPath, useToggleReducer } from '../../../../../utilities';
import { AudioPlayer } from '../../../../AudioPlayer';
import { MediaDialog } from '../../../../MediaDialog';

// import {
//   ModifyDialog as ModifyDialogAudio,
//   SmallMediaPlayer,
// } from '../../../../SingleMedia';

import { Box } from '../common/Box';
// import { ModifyDialog } from './ModifyDialog';
import { InTemplate } from '../common/InTemplate';
import { MenuButton } from '../common/MenuButton';
import { NodeInfoDisplay } from '../common/NodeInfoDisplay';
import { VariablesEditor } from '../common/VariablesEditor';
import { GreetingDialog } from '../Menu/GreetingDialog';
import {
  Dialpad,
  MusicNote,
  WarningTriangle,
  Message,
  EditPencil,
} from 'iconoir-react';
import { AudioDialog } from './AudioDialog';
import { Tooltip } from '@mui/material';
import { MessageDialog } from '../../../../IvrBuilder/Flow/nodes/Message/MessageDialog';
import useEnsureModuleItemId from '../../../../IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import { ModeSelectModuleThis } from '../common/ModeSelectModule';
import { MiniMapNode } from '../../../../../pages/settings/PipeEditMessageRouting/MobileView/MiniMapNode';
import { useBuilderContext } from '../../../MessageRoutingVisualBuilder';

// why use memo??
const MessageNode = memo(({ data, ...rest }) => {
  const {
    skipEditing,
    insertBefore, // bool
    insertAfterData,
    moduleItem,
    callflow,
    setCallflow,
    modifyPath,
    templateParent,
    // element, // used in Box
    // setRootElements, // used in Box
  } = data;

  // add id if does not exist
  useEnsureModuleItemId(data);

  const [showModifyDialog, toggleModifyDialog] = useToggleReducer(false);
  const { miniMap } = useBuilderContext();
  // const setShowModifyDialogWrap = setTo => () => setShowModifyDialog(setTo);
  const [tempId, setTempId] = useState(undefined);
  const [showVariables, setShowVariables] = useState(null);
  const setShowVariablesWrap = setTo => () => setShowVariables(setTo);

  const boxRef = useRef(null);
  const isHovering = useHoverDirty(boxRef);

  let possibleVariables = [];

  // action for onCreate
  const ee = useContext(IvrMenuEventEmitterContext);
  const onNodeCreated = data => {
    // if (data.optsPath === modifyPath) {
    //   setShowModifyDialog(true);
    // }
  };
  useEffect(() => {
    ee.on('node-created', onNodeCreated);
    return () => {
      ee.removeListener('node-created', onNodeCreated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeText = text => {
    // let text = prompt('Message Text:', moduleItem?.data?.text ?? '');
    if (!text?.length) {
      return false;
    }
    setAtPath(callflow, `${modifyPath}.data.text`, text);
    setCallflow({ ...callflow });
    setTempId(null);
    // setShowModifyDialogWrap(null);
  };

  // TODO: support multiple sources of info (template, API)
  const text = moduleItem?.data?.text ?? '';

  if (miniMap) {
    return <MiniMapNode pref={boxRef} data={data} />;
  }

  return (
    <Box
      pref={boxRef}
      // borderColor="#1E88E5"
      position={data?.position}
      data={data}
      // onHeight={}
      // onDimensions={handleDimensions}
    >
      <MessageDialog
        text={text}
        onComplete={handleChangeText}
        open={showModifyDialog}
        onClose={toggleModifyDialog}
      />
      <Handle
        type="target"
        position="top"
        style={{ background: '#555', visibility: 'hidden' }}
      />
      <ModeSelectModuleThis id={moduleItem?.id} modifyPath={modifyPath} />
      <div className={`flex flex-col items-center w-full pt-4 px-2 pb-2`}>
        <div className={`flex space-x-2 text-neutral-60`}>
          <div
            className={`text-sm flex items-center space-x-2 whitespace-nowrap`}
          >
            <Message width={14} height={14} />
            <span>Send Message</span>
          </div>
        </div>
        <div
          className={`text-md  pl-2 w-full flex flex-col text-center justify-center font-medium`}
        >
          {text?.length ? (
            <div
              className={`flex space-x-0 w-full justify-center items-center`}
            >
              <Tooltip placement={'left'} arrow title={text}>
                <p className={`line-clamp-2 text-left`}>
                  {text.split('\n').map((txt, i) => (
                    <div key={i}>{txt}&nbsp;</div>
                  ))}
                </p>
              </Tooltip>
              {skipEditing ? null : (
                <IconButton
                  onClick={toggleModifyDialog}
                  pill
                  color={'neutral'}
                  variant={'ghost'}
                >
                  <EditPencil />
                </IconButton>
              )}
            </div>
          ) : (
            <Button
              color={'attention'}
              variant={'ghost'}
              size={'sm'}
              className={`self-center`}
              startIcon={<WarningTriangle />}
              onClick={toggleModifyDialog}
              disabled={skipEditing}
            >
              {skipEditing ? 'No' : 'Add'} text
            </Button>
          )}
        </div>
      </div>
      <Handle
        type="source"
        position="bottom"
        style={{
          top: 'auto',
          bottom: 1,
          background: '#555',
          visibility: 'hidden',
        }}
      />
      {skipEditing ? null : (
        <>
          <MenuButton
            data={data}
            mode={isHovering ? 'normal' : 'ignore'}
            menuItems={[
              // {
              //   type: 'modify',
              //   props: { onClick: setShowModifyDialogWrap(true) },
              // },
              // 'add',
              'add-before',
              // 'move',
              // 'duplicate',
              // 'copy',
              // process.env.NODE_ENV === 'development' ? 'create_template' : null,
              // templateParent ||
              // callflow.type === 'template' ||
              // process.env.NODE_ENV === 'development'
              //   ? {
              //       type: 'variables',
              //       props: { onClick: setShowVariablesWrap(true) },
              //     }
              //   : null,
              'remove',
            ]}
          />
        </>
      )}
    </Box>
  );
});

export default MessageNode;
