import { AddUsersFormFields } from 'app/components/CreateUsersBulk/components/AddUsersBulkForm/types';
import setupUserCallflow from 'app/components/Onboarding/Vsb/Step3/fn/setupUserCallflow';
import { useAuthSelector } from 'app/data/auth';
import { useCreateCallflow } from 'app/hooks/mutations/callflow';
import { useCreateUser } from 'app/hooks/mutations/user';
import { useCreateVmbox } from 'app/hooks/mutations/vmbox';
import { useListVmboxesQuery } from 'app/hooks/queries/vmbox';
import { parseAndSetKazooMutationErrors } from 'app/utilities';
import { UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { v4 as uuidv4 } from 'uuid';
import * as vm from 'vm';
import { usePipeNew } from 'app/pages/settings/Pipes/Pipes';
import { usePipeUpdate } from 'app/sdk';

interface AddUsersBulkParams {
  onComplete: () => void;
  onError: () => void;
  setValue: UseFormSetValue<AddUsersFormFields>;
  setError: UseFormSetError<AddUsersFormFields>;

  lines: any[];
}

export const useAddUsersBulk = ({
  onComplete,
  onError,
  setValue,
  setError: setFormError,
  lines,
}: AddUsersBulkParams) => {
  const { auth_token, account_id } = useAuthSelector();
  const createUser = useCreateUser();
  const createVmbox = useCreateVmbox();
  const pipeUpdate = usePipeUpdate();
  const createCallflow = useCreateCallflow();
  const pipeNew = usePipeNew();
  const [isLoading, setIsLoading] = useImmer(false);
  const [isSuccess, setIsSuccess] = useImmer(false);
  const [error, setError] = useImmer<Error | null>(null);
  const { data: vmboxesPage } = useListVmboxesQuery({
    filters: {
      raw: [
        {
          variables: {
            docFilters: [['owner_type', '=', 'account']],
          },
        },
      ],
    },
  });

  const [companyVmbox] = vmboxesPage?.vmboxes ?? [];

  const mutateAsync = async (form: AddUsersFormFields) => {
    setIsLoading(true);
    setError(null);
    setIsSuccess(false);

    const { users } = form;

    // @ts-ignore
    for (let [index, userFields] of users.entries()) {
      let user, userMainCallflow, userVmbox;
      // skip if user already saved
      if (
        userFields.save_status === 'saved' ||
        userFields.save_status === 'partial_error'
      ) {
        continue;
      }

      const fieldName = `users.${index}`;

      try {
        // @ts-ignore
        setValue(`${fieldName}.save_status`, 'saving');
        const fullName = [
          userFields.info.first_name,
          userFields.info.last_name,
        ].join(' ');

        try {
          const userResp = await createUser.mutateAsync({
            presence_id: userFields.presence_id.toString(),
            priv_level: userFields.info.priv_level,
            first_name: userFields.info.first_name,
            last_name: userFields.info.last_name,
            username: userFields.info.email,
            timezone: userFields.info.timezone,
            title: userFields.info.title,
            // email: '',
            password: userFields.info.rand_password
              ? uuidv4()
              : userFields.info.password,
            caller_id: {
              internal: {
                name: fullName,
                number: userFields.presence_id.toString(),
              },
            },
            vm_to_email_enabled: true,
            send_email_on_creation: userFields.emails.welcome,
            cio_allow_appphone: userFields.devices.appphone,
          });
          if (userResp?.status !== 'success') {
            throw new Error(userResp);
          }
          user = userResp.data;
        } catch (err: any) {
          parseAndSetKazooMutationErrors({
            response: err.response,
            setError: (field, value) => {
              // map info fields to correct path
              switch (field) {
                case 'presence_id':
                case 'first_name':
                case 'last_name':
                case 'priv_level':
                case 'password':
                  // @ts-ignore
                  setFormError(`users.${index}.info.${field}`, value);
                  break;
                case 'username':
                  // @ts-ignore
                  setFormError(`users.${index}.info.email`, value);
                  break;
                default:
                  setFormError(field, value);
              }
            },
          });

          console.error('Failed saving user', err);
          throw new Error('Failed saving user');
        }

        // create user personal pipe for extension
        let users = {};
        if (user) {
          // @ts-ignore
          users[user.id] = { enabled: true };
        }
        const pipe1 = await pipeNew.mutateAsync({
          name: 'Personal',
          ext: userFields.presence_id.toString(),
          type: 'personal',
          users,
        });

        // // Create User Main Callflow
        // // - build from template?
        // // console.log('user:', user);
        // try {
        //   const userMainCallflowResp = await createCallflow.mutateAsync({
        //     numbers: [`cfid:tmp:${Date.now()}`, userFields.presence_id],
        //     owner_id: user.id,
        //     owner_type: 'user',
        //     type: 'main',
        //     strategy: {
        //       type: 'blank',
        //       data: {
        //         modules: [],
        //       },
        //     },
        //   });
        //   // console.log('userMainCallflowResp', userMainCallflowResp);
        //   if (userMainCallflowResp?.success !== true) {
        //     throw new Error(userMainCallflowResp);
        //   }
        //   userMainCallflow = userMainCallflowResp.data;
        // } catch (err) {
        //   // TODO: since graphql mutation not alot of info to display
        //   // as to why failed

        //   // @ts-ignore
        //   // setValue(`${fieldName}.save_status`, 'partial_error');
        //   console.error('Failed saving userMainCallflow', err);
        //   throw new Error('Failed saving userMainCallflow');
        // }

        try {
          // @ts-ignore add to lines
          if (userFields.lines?.length) {
            //@ts-ignore
            for (let lineId of userFields.lines) {
              const line = lines.find(line => line.id === lineId);

              await pipeUpdate.mutateAsync({
                id: line.id,
                data: {
                  users: {
                    ...line.users,
                    [user?.id]: { enabled: true },
                  },
                },
              });
            }
          }
        } catch (e) {
          throw new Error(`Failed adding user to line`);
        }

        // Create Voicemail Box
        if (userFields.voicemail.create) {
          try {
            const userVmboxResp = await createVmbox.mutateAsync({
              owner_id: user?.id,
              owner_type: 'user',
              name: `${fullName} Vmbox`, // default is to set it to `{owner_name} Vmbox`
              mailbox: userFields.presence_id.toString(), // TODO: allow different for some reason
              // mailbox: '101', // test vmbox error
              pin: undefined,
              require_pin: false,
              announcement_only: false,
              include_message_on_notify: true,
              include_transcription_on_notify: true,
              is_setup: false,
              is_voicemail_ff_rw_enabled: false,
              check_if_owner: true,
              delete_after_notify: false,
              save_after_notify: false,
              skip_envelope: false,
              skip_greeting: false,
              skip_instructions: false,
              transcribe: false,
              transcribe2: true,
              notify_email_addresses: [],
              oldest_message_first: false,
            });
            if (userVmboxResp?.status !== 'success') {
              throw new Error(userVmboxResp);
            }
            userVmbox = userVmboxResp.data;
          } catch (err: any) {
            parseAndSetKazooMutationErrors({
              response: err.response,
              // use set value to persist error showing on UI
              setError: setValue,
              prefix: `${fieldName}.save_error.vmbox`,
            });

            console.error('Failed saving userVmbox', err);
            throw new Error('Failed saving userVmbox');
          }
        }

        // // setup main callflow with personal or company vmbox
        // try {
        //   await setupUserCallflow({
        //     auth_token,
        //     userId: user.id,
        //     isUserVmboxId: !!userVmbox,
        //     vmboxId: userVmbox?.id ?? companyVmbox.id,
        //     presenceId: user.presence_id,
        //   });
        // } catch (e) {
        //   console.error(e);
        //   // @ts-ignore
        //   setValue(`${fieldName}.save_status`, 'partial_error');
        // }

        // all mutations succeeded
        // @ts-ignore
        setValue(`${fieldName}.save_status`, 'saved');
      } catch (err: any) {
        onError();
        setError(err);
        setIsLoading(false);

        // set values separately to not overwite other error data

        // @ts-ignore
        setValue(`${fieldName}.save_error.msg`, err.message);
        // @ts-ignore
        setValue(`${fieldName}.save_error.callflow_saved`, !!userMainCallflow);
        // @ts-ignore
        setValue(`${fieldName}.save_error.vmbox_saved`, !!userVmbox);

        // @ts-ignore
        setValue(`${fieldName}.save_status`, user ? 'partial_error' : 'error');
        // @ts-ignore

        return;
      }
    }
    onComplete();
    setIsLoading(false);
    setIsSuccess(true);
  };

  return {
    mutateAsync,
    isLoading,
    error,
    isSuccess,
  };
};
