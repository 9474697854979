import { Alert, Button } from '../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import { useToggleReducer } from '../../../../../utilities';
import { WarningTriangle } from 'iconoir-react';
import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import {
  deserializeValues,
  ScheduleNodeContent,
  ValuesDisplay,
} from '../../../../../components/IvrBuilder/Flow/nodes/OptionSchedule/ScheduleNodeDialog';
import { format } from 'date-fns';
import { DaysOfWeek } from 'app/components/IvrBuilder/Flow/nodes/OptionSchedule/optionSchedule';
import { getScheduleLabel } from '../../../../../components/IvrBuilder/Flow/nodes/OptionSchedule/optionSchedule';
import { useAccountQuery } from '../../../../../hooks/queries/account';
import { FormProvider, useForm } from 'react-hook-form';
import { NextModuleButton } from './NextModuleButton';

export const ScheduleOptionModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { category, modifyPath } = selectedElement.data;
  const [edit, toggleEdit] = useToggleReducer();
  const isFallback = selectedElement.data.category.id === 'fallback';

  return (
    <div>
      <div className={`w-full flex items-center justify-between`}>
        <span className={`text-md font-medium`}>Route Matching Rules</span>
        <Button
          variant={'ghost'}
          color={'accent'}
          size={'md'}
          onClick={toggleEdit}
        >
          {edit
            ? 'Cancel Editing'
            : category.values.length
            ? 'Edit Rules'
            : 'Add Rules'}
        </Button>
      </div>
      {edit ? (
        <Editor
          callflow={callflow}
          currentScheduleId={selectedElement.data.schedule_id}
          category={selectedElement.data.category}
          onClose={toggleEdit}
          onComplete={() => {}}
        />
      ) : (
        <div className={`flex mt-2 flex-col`}>
          {isFallback ? (
            <Alert
              reverse
              color={'neutral'}
              label={'Schedule Fallback'}
              body={
                'This fallback option will be triggered when no other schedule options match the current time'
              }
            />
          ) : category.values.length ? (
            category.values.map((value, valueIndex) => {
              switch (value.type) {
                case 'day_of_week':
                  return (
                    <span key={valueIndex} className={`w-full text-sm`}>
                      {DaysOfWeek[value.data.day_of_week]}
                    </span>
                  );
                case 'day_of_week_hours':
                  return (
                    <span key={valueIndex} className={`w-full text-sm`}>
                      {DaysOfWeek[value.data.day_of_week]}:{' '}
                      {getScheduleLabel(value.data.from)} -{' '}
                      {getScheduleLabel(value.data.to)}
                    </span>
                  );
                case 'weekdays':
                  return (
                    <span key={valueIndex} className={`w-full text-sm`}>
                      Weekdays: {getScheduleLabel(value.data.from)} -{' '}
                      {getScheduleLabel(value.data.to)}
                    </span>
                  );
                case 'weekends':
                  return (
                    <span key={valueIndex} className={`w-full text-sm`}>
                      Weekends: {getScheduleLabel(value.data.from)} -{' '}
                      {getScheduleLabel(value.data.to)}
                    </span>
                  );
                case 'date':
                  return (
                    <span key={valueIndex} className={`w-full text-sm`}>
                      {format(new Date(value.data.date), 'M/dd/yyyy')}
                    </span>
                  );
                case 'date_range':
                  return (
                    <span key={valueIndex} className={`w-full text-sm`}>
                      {format(new Date(value.data.date_range[0]), 'M/dd/yyyy')}{' '}
                      -{' '}
                      {format(new Date(value.data.date_range[1]), 'M/dd/yyyy')}
                    </span>
                  );
              }
            })
          ) : (
            <Alert
              reverse
              color={'attention'}
              icon={<WarningTriangle fr={undefined} />}
              label={'No Rules Specified'}
              body={
                'Add temporal rules to determine when a caller should be directed to this route.'
              }
              actionProps={{ onClick: toggleEdit, children: 'Add Rules' }}
            />
          )}
        </div>
      )}
      <div className={`flex [&>*]:mt-8 flex-wrap justify-between`}>
        <NextModuleButton />
      </div>
    </div>
  );
};

const Editor = ({
  category,
  callflow,
  onClose,
  onComplete,
  currentScheduleId,
}) => {
  const { data: account } = useAccountQuery();
  // console.log('category', category);
  const formMethods = useForm({
    defaultValues: {
      id: category?.id ?? Date.now() + 103,
      name: category?.name ?? '',
      // timezone: TIMEZONE_LIST.find(
      //   tz => tz.value === category?.timezone ?? account?.doc?.timezone,
      // ),
      values: deserializeValues(category.values),
    },
  });

  const onSubmit = (form: any) => {
    onComplete({
      ...form,
      timezone: form.timezone?.value,
      values: form.values.map(value => {
        const [fromHours, fromMinutes] = value.data.from?.split(':') ?? [];
        const [toHours, toMinutes] = value.data.to?.split(':') ?? [];

        return {
          ...value,
          data: {
            ...value.data,
            // TODO: replace need for conversion for select box
            from: fromHours
              ? {
                  hours: Number.parseInt(fromHours),
                  minutes: Number.parseInt(fromMinutes),
                }
              : undefined,
            to: toHours
              ? {
                  hours: Number.parseInt(toHours),
                  minutes: Number.parseInt(toMinutes),
                }
              : undefined,
          },
        };
      }),
    });
  };

  return (
    <>
      <div className={`w-full mt-2 flex justify-between`}>
        {/*<Button*/}
        {/*  variant={'fill'}*/}
        {/*  color={'negative'}*/}
        {/*  size={'sm'}*/}
        {/*  onClick={onClose}*/}
        {/*>*/}
        {/*  Cancel Editing*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  variant={'fill'}*/}
        {/*  color={'accent'}*/}
        {/*  size={'sm'}*/}
        {/*  onClick={formMethods.handleSubmit(onSubmit)}*/}
        {/*>*/}
        {/*  Confirm Route Times*/}
        {/*</Button>*/}
      </div>
      <FormProvider {...formMethods}>
        <ValuesDisplay
          mobile
          callflow={callflow}
          currentScheduleId={currentScheduleId}
          categoryId={category.id}
        />
      </FormProvider>
    </>
  );
};
