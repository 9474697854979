import { useCallRouteEditor } from '../CallflowContext';
import { AddModuleButton } from '../AddModuleButton';
import { Button } from '../../../../../design-lib';
import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { NavArrowDown, NavArrowRight, NavArrowUp } from 'iconoir-react';

export const PreviousModuleButton = () => {
  const { selectedElement, elements } = useCallRouteEditor();
  const { setSelectedElementKey } = useBuilderContext();

  const prevConnectingElement = elements.find(
    elem => elem?.target === selectedElement?.id,
  );
  const prevElement = elements.find(
    elem => elem?.id === prevConnectingElement?.source,
  );

  if (
    prevElement?.type === 'MenuNode' ||
    prevElement?.type === 'ScheduleNode' ||
    prevElement?.type === 'LastCommunicatedNode' ||
    prevElement?.type === 'MatchTextNode'
  ) {
    return (
      <Button
        className={`w-full`}
        color={'accent'}
        size={'md'}
        startIcon={<NavArrowUp fr={undefined} />}
        variant={'outline'}
        onClick={() => {
          setSelectedElementKey(prevElement.key);
        }}
      >
        Previous Module
      </Button>
    );
  }

  const prevConnectingModule = elements.find(
    elem => elem?.target === prevElement?.id,
  );

  const prevModule = elements.find(
    elem => elem?.id === prevConnectingModule?.source,
  );

  // if (!nextElement) {
  //   return (
  //     <Button size={'md'} color={'attention'} variant={'outline'} disabled>
  //       Call Will Terminate After This Module
  //     </Button>
  //   );
  // }

  return (
    <>
      {prevElement ? (
        <Button
          className={`w-full`}
          color={'accent'}
          size={'md'}
          startIcon={<NavArrowUp fr={undefined} />}
          variant={'outline'}
          onClick={() => {
            setSelectedElementKey(prevModule.key);
          }}
        >
          Previous Module
        </Button>
      ) : null}
    </>
  );
};
