import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from 'app/design';
import {
  ExpandLess as ExpandLessIcon,
  Person as PersonIcon,
  Group as GroupIcon,
} from 'app/design/icons-material';
import { QuickFinderPipeDialog } from 'app/components/QuickFinderPipeDialog';
// import { usePipeQuery } from 'app/hooks/queries/pipe';
import { usePipesQuery } from 'app/sdk';
import { getAtPath, setAtPath, useToggleReducer } from 'app/utilities';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NodeEditDialog } from '../../IvrBuilder/Dialogs';
import { NodeInfoDisplay } from '../../IvrBuilder/Flow/nodes/common/NodeInfoDisplay';
import { Link as RouterLink } from 'react-router-dom';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import { Listbox, SquareProgress, Button } from 'app/design-lib';
import { WarningTriangle } from 'iconoir-react';
import { Emoji } from 'emoji-picker-react';
import { useBuilderContext } from 'app/components/IvrBuilder/IvrBuilder';
import { UserAvatar } from 'app/components/Sidebar/Sidebar';
import { UserName } from 'app/components/UserName';

// import Select from 'react-select';

// Select pipes from list of pipes
export const Line = props => {
  const {
    skipEditing,
    modifyPath,
    callflow,
    setCallflow,
    variant,
    showModifyDialog,
    setShowModifyDialog,
  } = props;
  const { setDisableScroll } = useBuilderContext();
  const dataModifyPath = `${modifyPath}.data`;

  const moduleItem = getAtPath(callflow, modifyPath, {}); // { ring_type: 'custom', data: {...} }
  const ringItem = moduleItem?.data;

  // console.log({ ringItem, moduleItem });

  // console.log('moduleItem:', moduleItem);
  /*
  moduleItem = {
    type: 'RingStrategy',
    data: {
      ring_type: 'pipe',
      data: {
        pipeId: 'xyz',
        timeout: 10
      }
    }
  }
  */

  // const {
  //   data: pipesData,
  //   isLoading: pipesIsLoading,
  //   error: pipesError,
  //   refetch: refetchPipe,
  // } = usePipesQuery({
  //   filters: {
  //     id: ringItem?.data?.id,
  //   },
  // });
  // const pipes = pipesData?.pipes ?? [];
  // const pipe = pipes?.length ? pipes[0] : null;

  const pipeListQuery = usePipesQuery({
    // skip: queryPageIndex * queryPageSize,
    // take: queryPageSize,
    // orderBy: [['lastName', 'asc']],
    filters: {
      // searchInput: querySearchInput,
      NOT: {
        type: 'room',
      },
    },
  });
  const { pipes = [], totalCount = 0 } = pipeListQuery.data ?? {};

  // console.log({ pipesData, id: ringItem?.data?.id, pipes, pipe });

  const [isModifying, setIsModifying] = useState(null);

  useEffect(() => {
    if (setShowModifyDialog) {
      // parent is managing "display" state, NOT this component
      // setShowModifyDialog()
      setIsModifying(showModifyDialog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModifyDialog]);

  const { invalidModules, setInvalidModules } = useBuilderContext();
  const handleUpdate = compData => {
    if (invalidModules.includes(moduleItem.id)) {
      setInvalidModules(prev => prev.filter(id => id !== moduleItem.id));
    }
    setAtPath(callflow, `${dataModifyPath}`, compData);
    setCallflow({ ...callflow });
  };

  const handleShowModifying = value =>
    setShowModifyDialog ? setShowModifyDialog(value) : setIsModifying(value);

  const pipe = pipes?.find(pipe => pipe.id === ringItem?.data?.id);

  return (
    <div className={`w-full flex justify-center px-2`}>
      {pipeListQuery.isLoading ? (
        <SquareProgress size={'sm'} />
      ) : pipes.length ? (
        skipEditing ? (
          pipe ? (
            <div className={'flex space-x-2  min-w-0 items-center'}>
              {pipe?.type === 'personal' ? (
                <UserAvatar user_ext={pipe.key} size="sm" />
              ) : (
                <Emoji unified={pipe?.metadata?.emoji || '1f937'} size={16} />
              )}
              <div className="text-left py-2">
                {pipe?.type === 'personal' ? (
                  <div>
                    <UserName user_ext={pipe.key} />
                  </div>
                ) : (
                  <div>{pipe?.metadata.name}</div>
                )}
                <div className={'truncate text-neutral-60 text-xs'}>
                  <PhoneNumberDisplay hideFlag ptn={pipe?.key} />
                </div>
              </div>
            </div>
          ) : (
            <Button
              color={'neutral'}
              variant={'ghost'}
              size={'sm'}
              className={`self-center`}
              startIcon={<WarningTriangle fr={undefined} />}
              // onClick={() => setShowModifyDialog(true)}
              disabled
            >
              not assigned
            </Button>
          )
        ) : (
          <Listbox
            size={'sm'}
            placeholder="Select a Line"
            className={`w-full mt-1 self-center`}
            onOpenChanged={setDisableScroll}
            options={pipes
              // .filter(pipe => pipe.type !== 'personal')
              .map(pipe => ({
                label: (
                  <div className={'flex space-x-2  min-w-0 items-center'}>
                    {pipe?.type === 'personal' ? (
                      <UserAvatar user_ext={pipe.key} size="sm" />
                    ) : (
                      <Emoji
                        unified={pipe?.metadata?.emoji || '1f937'}
                        size={16}
                      />
                    )}
                    <div className="text-left py-2">
                      {pipe?.type === 'personal' ? (
                        <div>
                          <UserName user_ext={pipe.key} />
                        </div>
                      ) : (
                        <div>{pipe?.metadata.name}</div>
                      )}
                      <div className={'truncate text-neutral-60 text-xs'}>
                        <PhoneNumberDisplay hideFlag ptn={pipe?.key} />
                      </div>
                    </div>
                  </div>
                ),
                value: pipe.id,
              }))}
            value={ringItem?.data?.id}
            onChange={id => {
              handleUpdate({
                ...ringItem,
                data: {
                  id,
                  timeout: timeoutDefault,
                },
              });
            }}
          />
        )
      ) : (
        <Button
          color={'neutral'}
          variant={'ghost'}
          size={'sm'}
          className={`self-center`}
          startIcon={<WarningTriangle fr={undefined} />}
          // onClick={() => setShowModifyDialog(true)}
          disabled
        >
          no lines
        </Button>
      )}
    </div>
  );

  // if (variant === 'ivr_builder') {
  //   return (
  //     <>
  //       {isModifying && (
  //         <ModifyRingSettings
  //           ringInfo={ringItem}
  //           onChangeRingInfo={handleUpdate}
  //           onClose={e => handleShowModifying(null)}
  //         />
  //       )}
  //       <NodeInfoDisplay
  //         type={'Ring Line Users'}
  //         icon={<GroupIcon />}
  //         title={
  //           pipe ? (
  //             <div>
  //               <div>{pipe.metadata?.name}</div>
  //               <div>
  //                 <PhoneNumberDisplay ptn={pipe.key} />
  //               </div>
  //             </div>
  //           ) : skipEditing ? (
  //             ''
  //           ) : (
  //             <Link onClick={() => handleShowModifying(true)}>Select Line</Link>
  //           )
  //         }
  //         footer={
  //           ringItem?.data?.timeout &&
  //           pipe &&
  //           `${ringItem?.data?.timeout} seconds`
  //         }
  //       />
  //     </>
  //   );
  // }

  // return (
  //   <>
  //     {isModifying && (
  //       <ModifyRingSettings
  //         ringInfo={ringItem}
  //         onChangeRingInfo={handleUpdate}
  //         onClose={e => handleShowModifying(null)}
  //       />
  //     )}
  //     <Paper variant="outlined" elevation={0}>
  //       {pipe ? (
  //         <>
  //           <Typography variant="body1">
  //             Ringing <strong>{pipe.metadata?.name ?? 'Unnamed'}</strong>
  //           </Typography>
  //           <Typography variant="subtitle1">
  //             {ringItem?.data?.timeout} seconds
  //           </Typography>
  //         </>
  //       ) : (
  //         <Typography variant="body1" style={{ fontStyle: 'italic' }}>
  //           No line chosen for ringing
  //         </Typography>
  //       )}
  //       <div>
  //         <Link onClick={e => handleShowModifying(true)}>Modify</Link>
  //       </div>
  //     </Paper>
  //   </>
  // );
};

const timeoutDefault = 20;

const ModifyRingSettings = props => {
  const { ringInfo, onChangeRingInfo, onClose } = props;

  const [modifyData, setModifyData] = useState(() => cloneDeep(ringInfo));

  const pipeId = modifyData?.data?.id;

  const {
    data: pipes,
    isLoading: pipeIsLoading,
    error: pipeError,
    refetch: refetchPipe,
  } = usePipesQuery({
    filters: {
      id: pipeId,
    },
  });
  const pipe = pipes?.pipes?.length ? pipes.pipes[0] : null;

  // defaults
  useEffect(() => {
    // timeout
    if (!getAtPath(modifyData, `data.timeout`)) {
      setAtPath(modifyData, `data.timeout`, timeoutDefault);
      setModifyData({ ...modifyData });
    }
  }, [modifyData, setModifyData]);

  const [initialState] = useState({
    id: ringInfo?.data?.id,
    timeout: ringInfo?.data?.timeout ?? timeoutDefault,
  });

  const saveLabel = !pipeId
    ? 'Leave Empty'
    : !initialState.id
    ? 'Add'
    : initialState.id === pipeId &&
      Number(initialState.timeout) === Number(modifyData?.data?.timeout)
    ? 'Done'
    : 'Update';

  // Finder Dialog
  const [showFinderDialog, toggleShowFindPipeDialog] = useToggleReducer(
    !pipeId,
  );

  const onChangePipeSelect = selected => {
    const [pipeId] = selected;
    setAtPath(modifyData, `data.id`, pipeId); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindPipeDialog();
  };

  const handleClear = () => {
    setAtPath(modifyData, `data.id`, null); // newValue?.value === id
    setModifyData({ ...modifyData });
    toggleShowFindPipeDialog();
  };

  const [showAllSettings, toggleShowAllSettings] = useToggleReducer(false);

  return (
    <>
      <NodeEditDialog
        title={'Edit Line Ringing'}
        maxWidth={showAllSettings ? 'md' : 'sm'}
        onClose={onClose}
        onCancel={onClose}
        onSave={() => {
          onChangeRingInfo(modifyData);
          onClose();
        }}
        titleButton={
          pipe ? (
            <Button onClick={toggleShowFindPipeDialog}>Change Line</Button>
          ) : null
        }
        saveLabel={saveLabel}
        hideCancel={saveLabel === 'Done'}
        isLoading={pipeIsLoading}
        errorMessage={pipeError ? 'Failed loading selected pipe' : undefined}
      >
        {pipe ? (
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <span className={'text-xl font-medium'}>Ringing:</span>
              <Divider />
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <PipeSettings
                    {...props}
                    modifyData={modifyData}
                    setModifyData={setModifyData}
                    pipe={pipe}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <span className={'text-xl font-medium'}>Ring Settings</span>
              <Divider />
              <RingSettings
                {...props}
                modifyData={modifyData}
                setModifyData={setModifyData}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
              No Line Selected...
            </Typography>
            <Button onClick={toggleShowFindPipeDialog}>Choose Line</Button>
          </>
        )}
      </NodeEditDialog>
      <QuickFinderPipeDialog
        open={showFinderDialog}
        onSelect={onChangePipeSelect}
        onCancel={toggleShowFindPipeDialog}
        onClear={handleClear}
        initialSelected={pipeId ? [pipeId] : []}
        selectionTitle={pipe ? pipe.metadata?.name : null}
      />
    </>
  );
};

const PipeSettings = ({ modifyData, setModifyData, pipe }) => {
  const pipeId = modifyData?.data?.id;
  // console.log('PipeId:', pipeId, modifyData);

  return (
    <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Link
        className={'text-lg text-content-accent'}
        to={`/v2/pipe/${pipeId}`}
        underline="hover"
        // color={'text.primary'}
        component={RouterLink}
      >{`${pipe.metadata?.name}`}</Link>
    </Box>
  );
};

const RingSettings = ({ modifyData, setModifyData }) => {
  const handleChangeText = e => {
    setAtPath(modifyData, `data.timeout`, e.target.value); // newValue?.value === id
    setModifyData({ ...modifyData });
  };
  return (
    <Box sx={{ marginTop: 2 }}>
      <div>
        <TextField
          label="Ring Duration (seconds)"
          value={modifyData?.data?.timeout}
          onChange={handleChangeText}
          type="number"
          inputProps={{
            min: 0,
          }}
          size="small"
        />
      </div>
    </Box>
  );
};

export default Line;
