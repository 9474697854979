import { CityStateAutocomplete } from 'app/components/CityStateAutocomplete';
import { PhoneNumberDisplay } from 'app/components/PhoneNumberDisplay';
import constants from 'app/constants';
import { useAuthSelector } from 'app/data/auth';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogLoading,
  IconButton,
  SquareProgress,
  TextField,
} from 'app/design-lib';
import { RadioGroup } from 'app/design-lib/components/RadioGroup';
import { useListPhoneNumbersQuery } from 'app/hooks/queries/phoneNumber';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { usePipeNew } from 'app/pages/settings/Pipes/Pipes';
import { sdk } from 'app/sdk';
import { sleep } from 'app/utilities';
import axios from 'axios';
import { Emoji } from 'emoji-picker-react';
import {
  Cancel as CloseIcon,
  PinAlt as LocationIcon,
  WarningHexagon as WarningIcon,
  PhoneAdd as ActivateIcon,
  Check as CheckIcon,
  Antenna as AntennaIcon,
} from 'iconoir-react';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { useAccountQuery } from 'app/hooks/queries/account';
import useDevMode from 'app/utilities/useDevMode';
interface BuyPhoneNumberDialogProps {
  open: boolean;
  onClose: () => void;
}

const BuyPhoneNumberDialog = ({ open, onClose }: BuyPhoneNumberDialogProps) => {
  const { data: numbers, isLoading } = useListPhoneNumbersQuery({});
  const { data: account, isLoading: isLoadingAccount } = useAccountQuery();

  const { devMode } = useDevMode();

  return (
    <Dialog open={open} size={'md'} onClose={onClose}>
      {isLoading || isLoadingAccount ? (
        <DialogLoading
          label={'Loading account details...'}
          secondaryText={'This may take a moment'}
        />
      ) : !account?.internal?.integrations?.trunkingio?.account_id ? (
        <>
          <DialogContent>
            <Alert
              color={'attention'}
              reverse
              label={'TrunkingIO is Coming Soon!'}
              labelSize="lg"
              body={
                <>
                  We're working as fast as possible with our partner TrunkingIO
                  to bring you a low-cost, fantastic approach to numbers,
                  minutes and SMS/MMS.
                </>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color={'accent'} size={'md'}>
              Dismiss
            </Button>
          </DialogActions>
        </>
      ) : // @ts-ignore
      (numbers?.totalCount ?? 0) < (account?.internal?.number_limit || 1) ? (
        <Content onCancel={onClose} />
      ) : (
        <>
          <DialogContent>
            <div className={'w-full text-md'}>
              {/* <p className={'text-lg font-medium'}>
                Sorry, accounts are currently limited in phone numbers.
              </p> */}
              <p className={'text-md'}>
                Please contact support if you need more phone numbers!
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color={'accent'} size={'md'}>
              Dismiss
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

interface ContentProps {
  onCancel: () => void;
}

const Content = ({ onCancel }: ContentProps) => {
  const [error, setError] = useState('');
  const { data: user } = useAuthenticatedUserQuery();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty, isValid, isSubmitting },
    setValue,
    // setError,
    clearErrors,
    watch,
    trigger,
    control,
  } = useForm<{
    numbers: any[];
    results: any;
    area_codes: any;
    city_state: any;
    prefix: any;
  }>({
    defaultValues: {
      area_codes: [],
      numbers: [],
    },
    // resolver: joiResolver(schema), // pass in custom validation
  });
  const { auth_token, account_id } = useAuthSelector();
  const [isSearching, setSearching] = useState(false);
  const [isSearched, setSearched] = useState(false);
  const [filterAreaCodes, setFilterAreaCodes] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [isSetup, setIsSetup] = useState(false);
  console.log('form state', watch());
  // const isSubmitting = true;
  const onSubmit = async (ptnForm: any) => {
    // send purchase to trunkingio
    // - get resulting order_ids to track the status of the order

    try {
      // await sleep(5000);
      // throw new Error('test');

      // const numbersToBuy = Object.keys(ptnForm.numbers).filter(
      //   num => ptnForm.numbers[num],
      // );

      const numbersToBuy = ptnForm.numbers;
      // setBuyState(v => ({
      //   ...v,
      //   buying: true,
      //   order_results: {},
      //   numbersToBuy,
      //   form: ptnForm,
      // }));

      // const buyPhoneNumberMutationPromise = buyPhoneNumberMutation.mutateAsync({
      //   numbers: numbersToBuy,
      // });
      console.log('numbersToBuy:', numbersToBuy);

      const purchaseResult = await sdk.phoneNumber.mutate.buyTrunkingio(
        { numbers: numbersToBuy },
        { auth_token },
      );
      console.log('Purchase result:', purchaseResult);
      const order_id = purchaseResult.data.data.id;

      let order_results;
      let tryNum = 1;
      while (true) {
        await sleep(5000);
        console.log('Checking order results again...');
        const order_results =
          await sdk.phoneNumber.query.orderResultsTrunkingio(
            { order_id },
            { auth_token },
          );

        if (order_results.status === 'complete') {
          break;
        }
        if (order_results.status === 'failed') {
          break;
        }
        tryNum += 1;
      }

      console.log(
        'Number(s) purchased, waiting a few seconds for sync to complete trunkingio<>callingio',
      );
      await sleep(2000);

      // enable messaging for each number
      for (let number of numbersToBuy) {
        // try {
        // sdk.phoneNumber.mutate.setMessagingCampaignDefaultLowVolume({
        sdk.phoneNumber.mutate.messagingSet({
          number,
          data: {
            enable: true,
            campaign_id: 'unchanged',
          },
        });

        // } catch (err) {}
      }

      setIsSuccess(true);
    } catch (e) {
      setError('Error in purchasing number');
      console.error('e', e);
    }

    await setupPipe();
  };

  const pipeNew = usePipeNew();
  const setupPipe = async () => {
    setIsSettingUp(true);

    try {
      await pipeNew.mutateAsync({
        name: 'New Line',
        emoji: '1f389', // party popper
        ext: number,
        type: 'external',
        users: user?.id
          ? {
              [user.id]: { enabled: true },
            }
          : {},
      });
      setIsSetup(true);
    } catch (e) {
      console.error(e);
      setError('Error in setting up line.');
    }

    setIsSettingUp(false);
  };

  const searchCityState = async city_state => {
    setSearching(true);

    const { city, state } = city_state;

    try {
      const body = {
        data: {
          region: state,
          locality: city,
        },
      };
      console.log('Searching for area codes in city,state:', city, state);

      let area_codes = [];
      try {
        const result = await axios.post(
          `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/start_patterns/lookup`,
          body,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${auth_token}`, // will be converted to correct trunkingio token
            },
          },
        );
        area_codes = result.data.data;
      } catch (e: any) {
        // allow 400 - locality error
        if (!e.message.includes('400')) throw e;
      }
      // console.log('city lookup results', result);

      // TODO: if no area_codes found, use the lat/lon to return the nearest city/state and area_codes
      if (!area_codes.length) {
        // @ts-ignore
        const geocoder = new window.google.maps.Geocoder();

        const georesults = await geocoder.geocode({
          address: `${city}, ${state}`,
        });

        // map.setCenter(georesults[0].geometry.location);
        console.log('georesults:', georesults);
        if (!georesults.results?.length) {
          console.error('Invalid georesults for city/state');
        } else {
          const bodyLatLon = {
            data: {
              latitude: georesults.results[0].geometry.location.lat(),
              longitude: georesults.results[0].geometry.location.lng(),
            },
          };
          console.log(
            'Searching for area codes in city,state:',
            city,
            state,
            bodyLatLon,
          );
          const resultLatLon = await axios.post(
            `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/start_patterns/lookup`,
            bodyLatLon,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${auth_token}`, // will be converted to correct trunkingio token
              },
            },
          );
          area_codes = resultLatLon.data.data ?? [];
          if (!area_codes.length) {
            console.error('Unable to find ANY area codes!');
          }
        }
      }

      searchAreaCodes(area_codes);
      setValue('area_codes', area_codes);
      // setTabValue(area_codes?.length ? area_codes[0] : null);
    } catch (e) {
      console.log('city lookup error:');
      console.error(e);
      setSearching(false);
      setError('Error searching for numbers.');
    }
    // setSearching(false);
  };

  const searchAreaCodes = async area_codes => {
    // note: the api response is different depending on if you include the “prefix” query param
    // - (and “quantity” is ignored if “prefix” is not included)
    // const prefix = getValue('prefix');
    setSearching(true);
    setFilterAreaCodes([]);
    setValue('results', null);
    try {
      const body = {
        data: {
          start_patterns: area_codes,
        },
      };
      const result = await axios.post(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/tproxy/api/phone_numbers/US/lookup`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${auth_token}`, // will be converted to correct trunkingio token
          },
        },
      );
      console.log('lookup results', result);
      setValue('results', result.data.data);
      setSearched(true);
    } catch (e) {
      console.log('lookup error:');
      console.error(e);
    }

    // const respJson = await resp.json();

    // console.log('respJSOn:', respJson);

    // // fail, return response as error for error message
    // if (!resp.ok) {
    //   return { userAuth: null, error: respJson };
    // }
    setSearching(false);
  };

  const area_codes = watch('area_codes');

  const removeAreaCode = (code: string) => {
    setValue(
      'area_codes',
      area_codes.filter(ac => ac !== code),
    );
  };

  const results = watch('results', null);
  const resultsArray: string[] = [];
  Object.entries(results ?? {}).forEach(([areaCode, numbersDict]) => {
    if (filterAreaCodes.includes(areaCode)) return;

    const numbers = Object.keys(numbersDict as any) as string[];

    resultsArray.push(...numbers);
  });

  // console.log('results', results);
  // console.log('Results:', area_code, results);
  // const area_code_results = results[area_code];
  // const area_code_numbers = isArray(area_code_results)
  //   ? area_code_results
  //   : Object.keys(area_code_results ?? {});
  const [number] = watch('numbers', []);

  const title = isSetup
    ? 'Number added'
    : isSettingUp
    ? 'Activating line'
    : isSuccess
    ? 'Number added'
    : isSubmitting
    ? 'Buying number'
    : 'Add number';

  const subTitle = isSetup
    ? 'Hurrah!'
    : isSettingUp
    ? 'Please wait...'
    : isSuccess
    ? 'Hurrah!'
    : isSubmitting
    ? 'Please wait...'
    : 'Create new line.';

  return (
    <div className={`flex flex-col`}>
      <div
        className={`pl-4 pr-[6px] pt-2 flex space-x-2 items-center justify-between`}
      >
        <span className={'text-lg font-medium'}>{title}</span>
        <span className={'text-sm text-neutral-60 flex-1'}>{subTitle}</span>
        <IconButton
          onClick={onCancel}
          size={'md'}
          variant={'ghost'}
          color={'neutral'}
          disabled={isSubmitting || isSettingUp}
        >
          <CloseIcon fr={undefined} />
        </IconButton>
      </div>
      {error && !isSubmitting && !isSettingUp ? (
        <div
          className={
            'bg-negative-60 mt-1 pl-4 space-x-2 text-white flex items-center'
          }
        >
          <WarningIcon fr={undefined} height={16} strokeWidth={2} width={16} />
          <span className={'text-md font-medium'}>{error}</span>
          <span className={'text-sm flex-1 text-neutral-20'}>Try again</span>
          <IconButton
            clear
            size={'md'}
            onClick={() => setError('')}
            variant={'ghost'}
            color={'neutral-reverse'}
          >
            <CloseIcon fr={undefined} />
          </IconButton>
        </div>
      ) : null}
      {isSetup ? (
        <div className={'px-4 text-md flex flex-col space-y-4 py-4'}>
          <Alert
            label={'New Line'}
            body={
              <p className={'text-sm'}>
                <PhoneNumberDisplay ptn={number} hideFlag /> has been
                successfully purchased and is ready for use.
              </p>
            }
            color={'positive'}
            reverse
            emoji={
              <div>
                <Emoji unified={'1f389'} size={16} />
              </div>
            }
          />
        </div>
      ) : isSettingUp ? (
        <div className={'px-4 text-md py-4  space-y-1 flex flex-col'}>
          <div className={'w-full flex justify-center'}>
            <SquareProgress />
          </div>
          <p>Setting up and activating your line.</p>
          <p>This may take a few seconds.</p>
        </div>
      ) : isSuccess ? (
        <div className={'px-4 text-md flex flex-col space-y-4 py-4'}>
          <Alert
            label={'New Line'}
            body={
              <p className={'text-sm'}>
                <PhoneNumberDisplay ptn={number} hideFlag /> successfully
                purchased. Activate line to begin using it. This may take up to
                15 seconds.
              </p>
            }
            color={'positive'}
            reverse
            emoji={<Emoji unified={'1f389'} size={16} />}
          />
        </div>
      ) : isSubmitting ? (
        <div className={'px-4 text-md py-4  space-y-1 flex flex-col'}>
          <div className={'w-full flex justify-center'}>
            <SquareProgress />
          </div>
          <p>Contacting your carrier TrunkingIO to purchase number.</p>
          <p>This may take up to 15 seconds.</p>
        </div>
      ) : (
        <div className={'px-4 py-4 space-y-2 flex flex-col'}>
          <div
            className={
              ' flex w-full space-x-2 items-center divide-x divide-neutral-20'
            }
          >
            <div className={'flex-[2]'}>
              <Controller
                control={control}
                name={'city_state'}
                render={({ field: { onChange, value, ...props } }) => (
                  <>
                    <CityStateAutocomplete
                      label="Location"
                      placeholder={'Search by city or zip'}
                      startIcon={<LocationIcon fr={undefined} />}
                      onChange={v => {
                        console.log('citystate change value:', v);
                        onChange(v);
                        if (v) {
                          searchCityState({
                            city: v.terms[0].value,
                            state: v.terms[1].value,
                          });
                        }
                      }}
                    />
                  </>
                )}
              />
            </div>
            {/*<div className={'pl-2'}>
          <Controller
            control={control}
            name={'prefix'}
            render={({ field: { onChange, value, ...props } }) => (
              <>
                <TextField
                  startIcon={<AntennaIcon fr={undefined} />}
                  className={'w-28'}
                  type={'number'}
                  label="Area Code"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter' && watch('prefix')?.length === 3) {
                      setValue('area_codes', [
                        ...new Set([watch('prefix'), ...area_codes]),
                      ]);
                      searchAreaCodes([watch('prefix')]);
                      setValue('prefix', '');
                    }
                  }}
                />
              </>
            )}
          />
        </div>*/}
          </div>
          {isSearching && !results ? (
            <span className={'text-sm text-neutral-60 italic'}>
              Searching for numbers...
            </span>
          ) : area_codes?.length ? (
            <>
              <div className={'pt-2 flex flex-col space-y-1'}>
                <div className={'text-sm font-medium'}>Area code results</div>
                <div className={'flex flex-wrap'}>
                  {area_codes.map(code => {
                    const filtered = filterAreaCodes.includes(code);
                    return (
                      <Badge
                        key={code}
                        className={'mr-2'}
                        size={'sm'}
                        color={filtered ? 'neutral' : 'accent'}
                        variant={filtered ? 'outline' : 'fill'}
                        onClick={() => {
                          if (filterAreaCodes.includes(code)) {
                            setFilterAreaCodes(prev => [
                              ...prev.filter(num => num != code),
                            ]);
                            return;
                          }

                          setFilterAreaCodes(prev => [...prev, code]);
                        }}
                        // onClose={() => removeAreaCode(code)}
                      >
                        {code}
                      </Badge>
                    );
                  })}
                </div>
              </div>
              <div className={'flex flex-col space-y-1'}>
                {resultsArray.length ? (
                  <>
                    <div className={'text-sm font-medium'}>Choose a number</div>
                    <div className={'max-h-40 h-40 overflow-hidden'}>
                      <SimpleBar
                        className={
                          'h-full border p-2 border-neutral-20 rounded-lg'
                        }
                      >
                        <RadioGroup
                          value={number}
                          onChange={value => {
                            setValue('numbers', value ? [value] : []);
                          }}
                          options={resultsArray}
                          render={num => (
                            <PhoneNumberDisplay hideFlag ptn={num} />
                          )}
                        />
                        {/*
                  {resultsArray.map(([areaCode, numbersDict]) => {
                    if (filterAreaCodes.includes(areaCode)) return null;

                    const numbers = Object.keys(numbersDict as any) as string[];

                    return numbers.map(num => (
                      <div key={num} className={'flex space-x-2 items-center'}>
                        <button
                          className={`rounded-full border-neutral-30 h-5 w-5 aspect-square border-[2px]`}
                        ></button>
                        <PhoneNumberDisplay ptn={num} />
                      </div>
                    ));
                  })}
*/}
                      </SimpleBar>
                    </div>
                  </>
                ) : (
                  <span className={'text-sm text-neutral-60 italic'}>
                    Could not find any numbers matching search criteria
                  </span>
                )}
              </div>
            </>
          ) : null}
        </div>
      )}
      {isSetup ? (
        <div
          className={
            'border-t px-4 py-1 flex items-center justify-end border-neutral-20'
          }
        >
          <Button
            component={Link}
            to={`/settings/pipes/${pipeNew.data?.id}`}
            variant={'fill'}
            size={'md'}
            color={'accent'}
            onClick={onCancel}
          >
            View line
          </Button>
        </div>
      ) : isSuccess && !isSettingUp ? (
        <div
          className={
            'border-t px-4 py-1 flex items-center justify-end border-neutral-20'
          }
        >
          <Button
            onClick={setupPipe}
            variant={'fill'}
            size={'md'}
            color={'accent'}
          >
            Activate line
          </Button>
        </div>
      ) : number && !isSubmitting && !isSuccess ? (
        <div
          className={
            'border-t px-4 py-1 flex items-center justify-between border-neutral-20'
          }
        >
          <Badge size={'sm'} color={'neutral'} reverse variant={'fill'}>
            <PhoneNumberDisplay ptn={number} hideFlag />
          </Badge>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant={'fill'}
            size={'md'}
            color={'accent'}
          >
            Buy number for $1
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default BuyPhoneNumberDialog;
