import { IconButton } from '../../../../../design-lib';
import React, { useRef, useState } from 'react';
import { useCallRouteEditor } from '../CallflowContext';
import {
  getAtPath,
  setAtPath,
  useToggleReducer,
} from '../../../../../utilities';
import useEnsureModuleItemId from '../../../../../components/IvrBuilder/Flow/nodes/common/useEnsureModuleItemId';
import {
  Cancel,
  DeleteCircle,
  EditPencil,
  NavArrowDown,
  NavArrowUp,
  Plus,
  Trash,
  WarningTriangle,
} from 'iconoir-react';
import { MenuButton } from '../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/common/MenuButton';
import { useBuilderContext } from '../../../../../components/MessageRoutingVisualBuilder/MessageRoutingVisualBuilder';
import { INLINE_BLANK_CALLFLOW } from '../../../../../components/MessageRoutingVisualBuilder/Flow/nodes/InsertMatchTextOption/InsertMatchTextOption';
import { PreviousModuleButton } from './PreviousModuleButton';
import { startCase } from 'lodash';

export const MatchTextModule = () => {
  const {
    selectedElement,
    invalidModules,
    setInvalidModules,
    callflow,
    setCallflow,
    elements,
  } = useCallRouteEditor();
  const { setSelectedElementKey, selectedElementKey } = useBuilderContext();
  const [selectedTarget, setSelectedTarget] = useState<string | null>(null);
  const data = selectedElement.data;
  const { moduleItem, modifyPath } = selectedElement.data;

  // add id if does not exist
  useEnsureModuleItemId(selectedElement.data);

  const [edit, toggleEdit] = useToggleReducer();

  const insertMatchTextOptionElement = elements.find(
    elem =>
      elem.type === 'InsertMatchTextOptionNode' &&
      elem.data.matchTextData.moduleItem.id === moduleItem.id,
  );
  const matchTextData = insertMatchTextOptionElement?.data?.matchTextData;

  const greeting_id = moduleItem?.data?.greeting_id;

  const targets = moduleItem.data.targets;

  const handleEdit = targetIndex => {
    setSelectedElementKey(
      modifyPath +
        `.data.targets.${targetIndex}.callflow.message_strategy.data.modules`,
    );
  };

  const handleAddOption = () => {
    setAtPath(matchTextData.moduleItem, `data.targets`, [
      ...matchTextData.moduleItem.data.targets,
      {
        match: {
          type: 'exact', // starts_with, contains, ends_with, exact
          data: {
            text: '',
            options: {
              trim: true,
              matchCase: false, // default is case-insensitive
            },
          },
        },
        callflow: INLINE_BLANK_CALLFLOW,
      },
    ]);
    setAtPath(
      matchTextData.callflow,
      matchTextData.modifyPath,
      matchTextData.moduleItem,
    );
    matchTextData.setCallflow(
      { ...matchTextData.callflow },
      { name: 'Add Match Text Flow' },
    );

    handleEdit(matchTextData.moduleItem.data.targets.length - 1);
  };

  const noMatch = moduleItem.data.no_match;
  const noMatchModules = noMatch.callflow.message_strategy.data.modules;

  const clearNoMatch = () => {
    setAtPath(
      matchTextData.moduleItem,
      `data.no_match.callflow.message_strategy.data.modules`,
      [],
    );
    setCallflow({ ...callflow });
  };

  const editNoMatch = () => {
    setSelectedElementKey(
      `${modifyPath}.data.no_match.callflow.message_strategy.data.modules`,
    );
  };

  return (
    <>
      <span className={`text-md font-medium`}>Match Options</span>
      <p className={`text-neutral-60 text-sm my-2`}>
        <span className={`text-sm text-neutral-500`}>
          Matches <span className={`italic`}>only one (1)</span>, in descending
          order:
        </span>
      </p>
      <div
        className={
          'flex-1  divide-y divide-neutral-20 items-center flex flex-col'
        }
      >
        {targets.map((target, index) => {
          const { match, name } = target;

          const modules =
            target.callflow?.message_strategy?.data?.modules ?? [];

          const handleClear = () => {
            if (modules.length) {
              setAtPath(
                matchTextData.moduleItem,
                `data.targets.${index}.callflow.message_strategy.data.modules`,
                [],
              );
              setCallflow({ ...callflow });

              return;
            }

            const newTargets = [...targets];
            newTargets.splice(index, 1);
            setAtPath(matchTextData.moduleItem, `data.targets`, newTargets);
            setCallflow({ ...callflow });
          };

          // const handleClear = () => {
          //   // clear modules if present, otherwise delete target route
          //   const menuData = insertMenuOptionElement.data.menuData;
          //   if (modules.length) {
          //     setAtPath(
          //       menuData.moduleItem,
          //       `data.targets.${targetKey}.callflow.strategy.data.modules`,
          //       [],
          //     );
          //   } else {
          //     const targets = getAtPath(callflow, modifyPath);
          //     targets.splice(targetIdx, 1);
          //     setAtPath(callflow, modifyPath, [...targets]);
          //     setCallflow({ ...callflow });
          //   }
          //
          //   setAtPath(
          //     menuData.callflow,
          //     menuData.modifyPath,
          //     menuData.moduleItem,
          //   );
          //   menuData.setCallflow(
          //     { ...menuData.callflow },
          //     { name: 'Edit Menu Flow' },
          //   );
          // };

          const onIncreasePriority = () => {
            const updatedTargets =
              matchTextData.moduleItem?.data?.targets.filter(
                (v, i) => i !== index,
              );

            updatedTargets.splice(index - 1, 0, target);

            // setAtPath(
            //   callflow,
            //   `matchTextDatas.${matchTextData_id}.moduleItem?.data?.targets`,
            //   updatedTargets,
            // );
            setAtPath(matchTextData.moduleItem, `data.targets`, [
              ...updatedTargets,
            ]);
            setCallflow({ ...callflow });
          };
          const onDecreasePriority = () => {
            const updatedTargets =
              matchTextData.moduleItem?.data?.targets.filter(
                (v, i) => i !== index,
              );

            updatedTargets.splice(index + 1, 0, target);

            // setAtPath(
            //   callflow,
            //   `matchTextDatas.${matchTextData_id}.moduleItem?.data?.targets`,
            //   updatedTargets,
            // );
            setAtPath(matchTextData.moduleItem, `data.targets`, [
              ...updatedTargets,
            ]);
            setCallflow({ ...callflow });
          };

          const enableIncrease =
            index !== 0 && matchTextData?.moduleItem?.data?.targets?.length > 1;
          const enableDecrease =
            index < matchTextData?.moduleItem?.data?.targets?.length - 1;

          return (
            <div
              key={index}
              className={`w-full flex px-4 items-center justify-between  ${
                modules.length
                  ? 'text-accent-60'
                  : !modules.length
                  ? 'text-attention-60 bg-attention-10'
                  : 'bg-neutral-10/50 text-neutral-60'
              }`}
            >
              <div
                onClick={() => {
                  handleEdit(index);
                }}
                className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
              >
                {!modules.length ? (
                  <WarningTriangle
                    fr={undefined}
                    className={`text-attention-60`}
                  />
                ) : null}
                <div className={`flex flex-col`}>
                  {name ? (
                    <div className={`flex flex-col`}>
                      <span
                      // className={
                      //   isPresent && modules.length
                      //     ? ''
                      //     : 'text-neutral-60'
                      // }
                      >
                        {name}
                      </span>{' '}
                      <div className={`flex text-neutral-90 space-x-1 text-sm`}>
                        (
                        <span
                        // className={
                        //   isPresent && modules.length
                        //     ? 'text-accent-60'
                        //     : 'text-neutral-60'
                        // }
                        >
                          {startCase(match.type)} Match:
                        </span>
                        <span
                          className={
                            match.data.text
                              ? 'text-neutral-90'
                              : `text-neutral-60 italic`
                          }
                        >
                          {match.data.text || 'Empty Text'}
                        </span>
                        )
                      </div>
                    </div>
                  ) : (
                    <div className={`flex flex-col`}>
                      <span
                      // className={
                      //   isPresent && modules.length
                      //     ? 'text-accent-60'
                      //     : 'text-neutral-60'
                      // }
                      >
                        {startCase(match.type)} Match:
                      </span>
                      <span
                        className={
                          match.data.text
                            ? 'text-neutral-90'
                            : `text-neutral-60 italic`
                        }
                      >
                        {match.data.text || 'Empty Text'}
                      </span>
                    </div>
                  )}
                  {!modules.length ? (
                    <span
                      className={`text-sm ${
                        modules.length ? 'text-neutral-60' : 'text-negative-60'
                      }`}
                    >
                      No Modules
                    </span>
                  ) : null}
                </div>
              </div>
              <div className={`flex flex-col space-y-1`}>
                <IconButton
                  size={'md'}
                  onClick={() => handleEdit(index)}
                  variant={'outline'}
                  color={'accent'}
                >
                  <EditPencil fr={undefined} />
                </IconButton>

                <IconButton
                  size={'md'}
                  onClick={handleClear}
                  variant={'outline'}
                  color={'negative'}
                  title={modules.length ? 'Clear' : 'Delete'}
                >
                  {modules.length ? (
                    <DeleteCircle fr={undefined} />
                  ) : (
                    <Trash fr={undefined} />
                  )}
                </IconButton>
              </div>
              <div className={`flex flex-col ml-4 space-y-2`}>
                <IconButton
                  size={'sm'}
                  onClick={onIncreasePriority}
                  variant={'ghost'}
                  color={'accent'}
                  disabled={!enableIncrease}
                >
                  <NavArrowUp fr={undefined} />
                </IconButton>
                <IconButton
                  disabled={!enableDecrease}
                  size={'sm'}
                  onClick={onDecreasePriority}
                  variant={'ghost'}
                  color={'accent'}
                >
                  <NavArrowDown fr={undefined} />
                </IconButton>
              </div>
            </div>
          );
        })}
        <div
          className={`w-full flex px-4 items-center justify-between  ${
            noMatchModules.length
              ? 'text-accent-60'
              : !noMatchModules.length
              ? 'text-attention-60 bg-attention-10'
              : 'bg-neutral-10/50 text-neutral-60'
          }`}
        >
          <div
            onClick={editNoMatch}
            className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
          >
            {!noMatchModules.length ? (
              <WarningTriangle fr={undefined} className={`text-attention-60`} />
            ) : null}
            <div className={`flex flex-col`}>
              {noMatch?.name ? (
                <div className={`flex space-x-2`}>
                  <span
                  // className={
                  //   isPresent && modules.length
                  //     ? ''
                  //     : 'text-neutral-60'
                  // }
                  >
                    {noMatch?.name}
                  </span>{' '}
                  <span className={`text-neutral-60`}>(No Match)</span>
                </div>
              ) : (
                <span
                // className={
                //   isPresent && modules.length
                //     ? 'text-accent-60'
                //     : 'text-neutral-60'
                // }
                >
                  No Match
                </span>
              )}
              {!noMatchModules.length ? (
                <span
                  className={`text-sm ${
                    noMatchModules.length
                      ? 'text-neutral-60'
                      : 'text-negative-60'
                  }`}
                >
                  No Modules
                </span>
              ) : null}
            </div>
          </div>
          <div className={`flex space-x-2`}>
            <IconButton
              size={'md'}
              onClick={editNoMatch}
              variant={'outline'}
              color={'accent'}
            >
              <EditPencil fr={undefined} />
            </IconButton>
            <IconButton
              size={'md'}
              onClick={clearNoMatch}
              variant={'outline'}
              color={'negative'}
              disabled={!noMatchModules.length}
              title={'Clear'}
            >
              <DeleteCircle fr={undefined} />
            </IconButton>
          </div>
        </div>
        <div
          className={`w-full flex px-4 items-center justify-between text-accent-60`}
        >
          <div
            onClick={handleAddOption}
            className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
          >
            <span
            // className={
            //   isPresent && modules.length
            //     ? 'text-accent-60'
            //     : 'text-neutral-60'
            // }
            >
              Add Match Option
            </span>
          </div>
          <IconButton
            size={'md'}
            onClick={handleAddOption}
            variant={'outline'}
            color={'accent'}
          >
            <Plus fr={undefined} />
          </IconButton>
        </div>
      </div>

      {/*<div
        className={
          'flex-1 justify-center divide-y divide-neutral-20 items-center flex flex-col'
        }
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '_', 'timeout'].map(targetKey => {
          const target = targets[targetKey];
          const isPresent = target;
          const modules = target?.callflow?.strategy.data.modules ?? [];
          const required = targetKey === '_' || targetKey === 'timeout';

          const handleClear = () => {
            // clear modules if present, otherwise delete target route
            const menuData = insertMenuOptionElement.data.menuData;
            if (modules.length) {
              setAtPath(
                menuData.moduleItem,
                `data.targets.${targetKey}.callflow.strategy.data.modules`,
                [],
              );
            } else {
              setAtPath(menuData.moduleItem, `data.targets`, {
                ...menuData.moduleItem.data.targets,
                [targetKey]: undefined,
              });
            }

            setAtPath(
              menuData.callflow,
              menuData.modifyPath,
              menuData.moduleItem,
            );
            menuData.setCallflow(
              { ...menuData.callflow },
              { name: 'Edit Menu Flow' },
            );
          };

          let label;
          switch (targetKey) {
            case '_':
              label = 'Invalid Entry';
              break;
            case 'timeout':
              label = `Timeout`;
              break;
            default:
              label = `Dial ${targetKey}`;
              break;
          }

          return (
            <div
              key={targetKey}
              className={`w-full flex px-4  items-center justify-between  ${
                isPresent && modules.length
                  ? 'text-accent-60'
                  : required && !modules.length
                  ? 'text-attention-60 bg-attention-10'
                  : 'bg-neutral-10/50 text-neutral-60'
              }`}
            >
              <div
                onClick={() => {
                  if (isPresent) {
                    handleEdit(targetKey);
                    return;
                  }

                  handleAddOption(targetKey);
                }}
                // onTouchStart={e => {
                //   e.preventDefault();
                //   if (isPresent) {
                //     handleEdit(targetKey);
                //     return;
                //   }
                //
                //   handleAddOption(targetKey);
                // }}
                className={`space-x-4 py-5 cursor-pointer flex-1 flex items-center`}
              >
                {required && !modules.length ? (
                  <WarningTriangle
                    fr={undefined}
                    className={`text-attention-60`}
                  />
                ) : null}
                <div className={`flex flex-col`}>
                  {target?.name ? (
                    <div className={`flex space-x-2`}>
                      <span
                      // className={
                      //   isPresent && modules.length
                      //     ? ''
                      //     : 'text-neutral-60'
                      // }
                      >
                        {target?.name}
                      </span>{' '}
                      <span className={`text-neutral-60`}>({label})</span>
                    </div>
                  ) : (
                    <span
                    // className={
                    //   isPresent && modules.length
                    //     ? 'text-accent-60'
                    //     : 'text-neutral-60'
                    // }
                    >
                      {label}
                    </span>
                  )}
                  {isPresent && !modules.length ? (
                    <span
                      className={`text-sm ${
                        modules.length ? 'text-neutral-60' : 'text-negative-60'
                      }`}
                    >
                      No Modules
                    </span>
                  ) : null}
                </div>
              </div>
              <div className={`flex space-x-2`}>
                {isPresent ? (
                  <>
                    <IconButton
                      size={'md'}
                      onClick={() => handleEdit(targetKey)}
                      variant={'outline'}
                      color={'accent'}
                    >
                      <EditPencil fr={undefined} />
                    </IconButton>
                    {required && !modules.length ? null : (
                      <IconButton
                        size={'md'}
                        onClick={handleClear}
                        variant={'outline'}
                        color={'negative'}
                        title={modules.length ? 'Clear' : 'Delete'}
                      >
                        {modules.length ? (
                          <DeleteCircle fr={undefined} />
                        ) : (
                          <Trash fr={undefined} />
                        )}
                      </IconButton>
                    )}
                  </>
                ) : (
                  <>
                    <IconButton
                      size={'md'}
                      onClick={() => handleAddOption(targetKey)}
                      variant={'outline'}
                      color={'accent'}
                    >
                      <Plus fr={undefined} />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          );
        })}

        {targets.map(([key, target]) => {
          let label = key;

          if (key === '_') {
            label = 'Invalid Entry';
          } else if (key === 'timeout') {
            label = 'Timeout';
          }


          const handleClick = () => {
            console.log('path', modifyPath, key);
            const path =
              modifyPath + `.data.targets.${key}.callflow.strategy.data.module`;
            const test = getAtPath(callflow, path);
            console.log();
            setSelectedElementKey(
              modifyPath +
                `.data.targets.${key}.callflow.strategy.data.modules`,
            );
          };


          return (
            <div
              key={key}
              className={`w-full flex justify-center items-center`}
            >
              <Button size={'md'} onClick={handleClick} color={'accent'}>
                {label}
              </Button>
            </div>
          );
        })}
      </div>*/}
      <MenuButton
        data={data}
        mode={'mobile'}
        menuItems={[
          // {
          //   type: 'modify',
          //   props: { onClick: setShowModifyDialogWrap(true) },
          // },
          // 'add',
          'add-before',
          // 'move',
          // 'duplicate',
          // 'copy',
          // process.env.NODE_ENV === 'development' ? 'create_template' : null,
          // templateParent ||
          // callflow.type === 'template' ||
          // process.env.NODE_ENV === 'development'
          //   ? {
          //       type: 'variables',
          //       props: { onClick: setShowVariablesWrap(true) },
          //     }
          //   : null,
          'remove',
        ]}
      />
      <div
        className={
          'flex space-x-4 border-t pt-4 border-neutral-20 justify-between'
        }
      >
        <PreviousModuleButton />
      </div>
    </>
  );
};
