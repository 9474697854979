import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import * as Sentry from '@sentry/react';
import { DeviceListOptions } from 'app/components/ListDevices/components/DeviceListOptions';
import { DeviceOwnerCell } from 'app/components/ListDevices/components/DeviceOwnerCell';
import { DeviceType } from 'app/components/ListDevices/components/DeviceType';
import { UserAvatar } from 'app/components/Sidebar/Sidebar';
import { SettingsPageWrapper } from 'app/design-lib/components/SettingsPageWrapper';
import { DeviceDisableSettings } from 'app/pages/settings/Devices/View/components/DeviceDisableSettings';
import { Plus as PlusIcon } from 'iconoir-react';
import React, { useState, useEffect, useRef } from 'react';

import { useQueryClient } from 'react-query';
import { userQueryKeys } from 'app/hooks/queries/user';
import { useAuthenticatedUserQuery } from 'app/hooks/queries/user';
import { useUpdateUserPartial } from 'app/hooks/mutations/user';
import { useUserQuery } from 'app/hooks/queries/user';
import PersonIcon from '@mui/icons-material/Person';
import { AvatarCell } from 'app/components/ListUsers/components/AvatarCell';
import { Link } from 'react-router-dom';
import { UserDoc, UserPrivLevel } from 'types/user';
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from 'app/design';
import { Button, Table, TableCell, TableRow, TextField } from 'app/design-lib';
import { toast } from 'react-toastify';
import {
  Settings as SettingsIcon,
  Refresh as RefreshIcon,
  LocalPolice as LocalPoliceIcon,
  Build as BuildIcon,
} from 'app/design/icons-material';

import SimpleBar from 'simplebar-react';

import { useAuthSelector } from 'app/data/auth';
import ImgReducer from 'image-blob-reduce';
import constants from 'app/constants';

const UserProfile = ({}) => {
  const { account_id, owner_id, auth_token } = useAuthSelector();
  const [isUploading, setIsUploading] = useState(false);
  const avatarRef = useRef<HTMLInputElement>(null);
  const updateUser = useUpdateUserPartial();
  const { data: user, refetch, isLoading } = useUserQuery(owner_id);
  const [userDoc, setUserDoc] = useState<UserDoc | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user && !userDoc) {
      // @ts-ignore
      setUserDoc(user.doc);
    }
  }, [user]);

  const handleUpdateUser = async () => {
    if (!userDoc) {
      return;
    }
    const resp = await updateUser.mutateAsync({
      // @ts-ignore
      id: owner_id,
      // @ts-ignore
      first_name: userDoc.first_name,
      // @ts-ignore
      last_name: userDoc.last_name,
      // @ts-ignore
      title: userDoc.title,
    });
    // TODO: centralize invalidation (for now just making sure to invalidate this user, so sidebar is correct)
    queryClient.invalidateQueries({ queryKey: userQueryKeys.all });
  };

  const handleChangeFile = async file => {
    setIsUploading(true);
    try {
      const reducer = ImgReducer();
      const newFile = await reducer.toBlob(file, { max: 200 });
      const headers = new Headers();
      // @ts-ignore
      headers.set('X-AUTH-TOKEN', auth_token);
      headers.set('Content-Type', 'application/octet-stream');
      const result = await fetch(
        `${constants.env.REACT_APP_CIO_API_SERVER}/api/kproxy/accounts/${account_id}/users/${user?.id}/photo`,
        {
          method: 'POST',
          headers,
          body: newFile,
        },
      );
    } catch (err) {
      console.error('Failed uploading', err);
      toast.error(
        'Sorry, there was an error when uploading, please try again, or try a different image',
        {},
      );
      Sentry.captureException(err);
    }
    setIsUploading(false);
    // refetchUser();
    refetch();
  };

  const isUpdated =
    user?.doc.first_name !== userDoc?.first_name ||
    user?.doc.last_name !== userDoc?.last_name ||
    user?.doc.title !== userDoc?.title;

  return (
    <>
      <SettingsPageWrapper
        isLoading={isLoading}
        loadingLabel={'Loading profile...'}
        label={'Profile'}
        // subLabel={'Manage external devices and third party apps'}
        // buttonProps={}
      >
        {userDoc ? (
          <div
            className={`sm:w-[70%] max-w-[20rem] sm:max-w-[80%] flex flex-col space-y-2 pl-0.5`}
          >
            <div className={'flex space-x-2 items-center'}>
              <div className="inline-block cursor-pointer">
                <input
                  ref={avatarRef}
                  id="icon-button-file"
                  type="file"
                  // accept=".txt"
                  style={{ display: 'none' }}
                  // @ts-ignore
                  onChange={e => handleChangeFile(e.target.files[0])}
                />
                <label htmlFor="icon-button-file" style={{ cursor: 'pointer' }}>
                  {/* <Tooltip
                        title={<>Change Avatar</>}
                        placement="bottom"
                        // type="light"
                        arrow
                      >*/}
                  <div className={'flex space-x-2 items-center'}>
                    <div>
                      {isUploading ? (
                        <Box
                          sx={{
                            width: '72px',
                            height: '72px',
                            borderRadius: '50%',
                            border: '1px solid #ededed',
                            display: 'grid',
                            justifyContent: 'center',
                            alignContent: 'center',
                          }}
                        >
                          <CircularProgress size={28} />
                        </Box>
                      ) : (
                        <UserAvatar user={user} size={'xl'} enabled={true} />
                      )}
                    </div>
                    <Button
                      onClick={() => {
                        avatarRef.current?.click();
                      }}
                      // @ts-ignore
                      // onChange={e => handleChangeFile(e.target.files[0])}
                      variant={'fill'}
                      color={'accent'}
                      size={'sm'}
                    >
                      Change Avatar
                    </Button>
                  </div>
                  {/*</Tooltip>*/}
                  {/* <Avatar
              alt={user.extra.fullName}
              src={KazooSDK.getUserPhotoUrl(user.id)}
              style={{ height: 30, width: 30 }}
            /> */}
                </label>
              </div>
              <label
                htmlFor="icon-button-file"
                style={{ cursor: 'pointer' }}
              ></label>
            </div>
            <div className={`grid gap-2  grid-cols-1 sm:grid-cols-2`}>
              <TextField
                type="text"
                placeholder="First"
                // @ts-ignore
                value={userDoc?.first_name}
                onChange={e =>
                  // @ts-ignore
                  setUserDoc({ ...userDoc, first_name: e.target.value })
                }
                label={'First name'}
              />
              <TextField
                type="text"
                placeholder="Last"
                // @ts-ignore
                value={userDoc?.last_name}
                onChange={e =>
                  // @ts-ignore
                  setUserDoc({ ...userDoc, last_name: e.target.value })
                }
                label={'Last name'}
              />
              <div className={`sm:col-span-2`}>
                <TextField
                  type="text"
                  placeholder="Title"
                  // @ts-ignore
                  value={userDoc?.title}
                  onChange={e =>
                    // @ts-ignore
                    setUserDoc({ ...userDoc, title: e.target.value })
                  }
                  label={'Title'}
                  helperText={'Optional'}
                />
              </div>
              <Button
                {...{
                  children: 'Update',
                  onClick: handleUpdateUser,
                  disabled: !isUpdated,
                  size: 'md',
                  color: 'accent',
                  // startIcon: <PlusIcon fr={undefined} />,
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={`w-full pt-20 grid font-medium text-neutral-60 place-items-center`}
          >
            <span>Error loading profile</span>
          </div>
        )}
      </SettingsPageWrapper>
    </>
  );

  return (
    <>
      <div
        className={`max-w-[600px] flex-auto border-solid border-0 border-r-2 border-neutral-200`}
      >
        <div className="overflow-hidden bg-background-neutral/75 relative h-screen">
          <SimpleBar className={'h-full'} style={{ height: '100%' }}>
            <div className={'px-8 py-3 h-full'}>
              <div className="flex border-solid pb-3 p-2 border-0 border-b-2 border-border-neutral justify-between items-baseline">
                <div className={'flex  items-center space-x-2'}>
                  <div
                    className={
                      'btn-accent-outline text-2xl pointer-events-none rounded-full p-2 flex items-center justify-center'
                    }
                  >
                    <PersonIcon
                      className={'fill-content-accent '}
                      sx={{ fontSize: 'inherit' }}
                    />
                  </div>
                  <div>
                    <div className="font-bold text-xl">My Profile</div>
                    <div className="text-base text-neutral-500">
                      Information displayed to other users on your team
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 flex flex-col space-y-6 mt-4 h-full">
                <div className={'flex space-x-2 items-center'}>
                  <div className="inline-block cursor-pointer">
                    <input
                      ref={avatarRef}
                      id="icon-button-file"
                      type="file"
                      // accept=".txt"
                      style={{ display: 'none' }}
                      // @ts-ignore
                      onChange={e => handleChangeFile(e.target.files[0])}
                    />
                    <label
                      htmlFor="icon-button-file"
                      style={{ cursor: 'pointer' }}
                    >
                      {/* <Tooltip
                        title={<>Change Avatar</>}
                        placement="bottom"
                        // type="light"
                        arrow
                      >*/}
                      <div className={'flex space-x-2 items-center'}>
                        <div>
                          {isUploading ? (
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '50%',
                                border: '1px solid #ededed',
                                display: 'grid',
                                justifyContent: 'center',
                                alignContent: 'center',
                              }}
                            >
                              <CircularProgress size={28} />
                            </Box>
                          ) : (
                            <UserAvatar
                              user={user}
                              size={'md'}
                              enabled={true}
                            />
                          )}
                        </div>
                        <Button
                          onClick={() => {
                            avatarRef.current?.click();
                          }}
                          // @ts-ignore
                          // onChange={e => handleChangeFile(e.target.files[0])}
                          variant={'outline'}
                          color={'accent'}
                          size={'sm'}
                        >
                          Change Avatar
                        </Button>
                      </div>
                      {/*</Tooltip>*/}
                      {/* <Avatar
              alt={user.extra.fullName}
              src={KazooSDK.getUserPhotoUrl(user.id)}
              style={{ height: 30, width: 30 }}
            /> */}
                    </label>
                  </div>
                  <label
                    htmlFor="icon-button-file"
                    style={{ cursor: 'pointer' }}
                  ></label>
                </div>
                <div className="flex space-x-4 items-end">
                  <div className="">
                    <div className={'text-sm font-bold'}>First Name</div>
                    <input
                      className={
                        'rounded text-base border-solid border border-border-neutral py-1.5 px-3 font-sans'
                      }
                      type="text"
                      placeholder="First"
                      // @ts-ignore
                      value={userDoc?.first_name}
                      onChange={e =>
                        // @ts-ignore
                        setUserDoc({ ...userDoc, first_name: e.target.value })
                      }
                    />
                  </div>
                  <div className="">
                    <div className={'text-sm font-bold'}>Last Name</div>
                    <input
                      className={
                        'rounded text-base  border-solid border border-border-neutral py-1.5 px-3 font-sans'
                      }
                      type="text"
                      placeholder="Last"
                      // @ts-ignore
                      value={userDoc?.last_name}
                      onChange={e =>
                        // @ts-ignore
                        setUserDoc({ ...userDoc, last_name: e.target.value })
                      }
                    />
                  </div>
                  <Button
                    variant={'outline'}
                    color={'positive'}
                    onClick={handleUpdateUser}
                  >
                    {updateUser.isLoading ? 'please wait...' : 'Update'}
                  </Button>
                </div>
                <div className="flex space-x-4 items-end">
                  <div className="">
                    <div className={'text-sm font-bold'}>Title (optional)</div>
                    <input
                      className={
                        'rounded text-base border-solid border border-border-neutral py-1.5 px-3 font-sans'
                      }
                      type="text"
                      placeholder="Title"
                      // @ts-ignore
                      value={userDoc?.title}
                      onChange={e =>
                        // @ts-ignore
                        setUserDoc({ ...userDoc, title: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 text-sm text-neutral-400">
                ID: {user?.id}
              </div>
            </div>
          </SimpleBar>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
